@import "../../../base/colors.scss";

.range {
  .irs--round {
    .irs-bar {
      background-color: #045E51;
      //right: 0px !important;
      //left: auto !important;
      height: 12px;
      border-radius: 0 4px 4px 0;
    }

    .irs-handle {
      border: 3px solid #7D7D7D;
      //right: 0;
      //left: auto !important;
      top: 31px;
      //pointer-events: none;

      &:before {
        background: #7d7d7d;
        bottom: -10px;
        left: 9px;
        content: "";
        display: block;
        height: 10px;
        position: absolute;
        width: 2px;
      }
    }

    .irs-line {
      background-color: #87B29A;
      height: 12px;
    }

    .irs-single {
      background-color: transparent;
      color: #000;
      top: 65px;

      &:before {
        content: none;
      }
    }

    .irs-max {
      background-color: transparent;
      color: #000;
      top: 65px;
    }

    .irs-min {
      display: none;
    }
  }
}
