@charset "UTF-8";
/*
Theme Name: CenterParcs
Description: Theme CenterParcs
Author: Omnis
Author URI: https://www.madebyomnis.com/
Tags: One Column, Top Menu, Post Thumbnails, Theme Options, Custom Background, Editor Style.
Version: 1.0.0
*/
/* __________ TYPOGRAPHY BEGIN __________*/
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.eot");
  src: url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.woff") format("woff"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.woff2") format("woff2"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.ttf") format("truetype"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewThin/NotoSansHebrewThin.eot?#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.eot");
  src: url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.woff") format("woff"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.woff2") format("woff2"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.ttf") format("truetype"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight.eot?#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.eot");
  src: url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.woff") format("woff"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.woff2") format("woff2"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.ttf") format("truetype"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewLight/NotoSansHebrewLight.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.eot");
  src: url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.woff") format("woff"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.woff2") format("woff2"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.ttf") format("truetype"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.eot");
  src: url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.woff") format("woff"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.woff2") format("woff2"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.ttf") format("truetype"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.eot");
  src: url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.woff") format("woff"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.woff2") format("woff2"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.ttf") format("truetype"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.eot");
  src: url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.woff") format("woff"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.woff2") format("woff2"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.ttf") format("truetype"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewBold/NotoSansHebrewBold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.eot");
  src: url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.woff") format("woff"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.woff2") format("woff2"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.ttf") format("truetype"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold.eot?#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "NotoSansHebrew";
  src: url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.eot");
  src: url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.woff") format("woff"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.woff2") format("woff2"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.ttf") format("truetype"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.svg#NotoSansHebrew") format("svg"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.eot") format("embedded-opentype"), url("../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack.eot?#iefix") format("embedded-opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterThin/InterThin.eot");
  src: url("../fonts/InterThin/InterThin.eot?#iefix") format("embedded-opentype"), url("../fonts/InterThin/InterThin.woff") format("woff"), url("../fonts/InterThin/InterThin.woff2") format("woff2"), url("../fonts/InterThin/InterThin.ttf") format("truetype"), url("../fonts/InterThin/InterThin.svg#Inter") format("svg"), url("../fonts/InterThin/InterThin.eot") format("embedded-opentype"), url("../fonts/InterThin/InterThin.eot?#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterExtraLight/InterExtraLight.eot");
  src: url("../fonts/InterExtraLight/InterExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/InterExtraLight/InterExtraLight.woff") format("woff"), url("../fonts/InterExtraLight/InterExtraLight.woff2") format("woff2"), url("../fonts/InterExtraLight/InterExtraLight.ttf") format("truetype"), url("../fonts/InterExtraLight/InterExtraLight.svg#Inter") format("svg"), url("../fonts/InterExtraLight/InterExtraLight.eot") format("embedded-opentype"), url("../fonts/InterExtraLight/InterExtraLight.eot?#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterLight/InterLight.eot");
  src: url("../fonts/InterLight/InterLight.eot?#iefix") format("embedded-opentype"), url("../fonts/InterLight/InterLight.woff") format("woff"), url("../fonts/InterLight/InterLight.woff2") format("woff2"), url("../fonts/InterLight/InterLight.ttf") format("truetype"), url("../fonts/InterLight/InterLight.svg#Inter") format("svg"), url("../fonts/InterLight/InterLight.eot") format("embedded-opentype"), url("../fonts/InterLight/InterLight.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterRegular/InterRegular.eot");
  src: url("../fonts/InterRegular/InterRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/InterRegular/InterRegular.woff") format("woff"), url("../fonts/InterRegular/InterRegular.woff2") format("woff2"), url("../fonts/InterRegular/InterRegular.ttf") format("truetype"), url("../fonts/InterRegular/InterRegular.svg#Inter") format("svg"), url("../fonts/InterRegular/InterRegular.eot") format("embedded-opentype"), url("../fonts/InterRegular/InterRegular.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterMedium/InterMedium.eot");
  src: url("../fonts/InterMedium/InterMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/InterMedium/InterMedium.woff") format("woff"), url("../fonts/InterMedium/InterMedium.woff2") format("woff2"), url("../fonts/InterMedium/InterMedium.ttf") format("truetype"), url("../fonts/InterMedium/InterMedium.svg#Inter") format("svg"), url("../fonts/InterMedium/InterMedium.eot") format("embedded-opentype"), url("../fonts/InterMedium/InterMedium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterSemiBold/InterSemiBold.eot");
  src: url("../fonts/InterSemiBold/InterSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/InterSemiBold/InterSemiBold.woff") format("woff"), url("../fonts/InterSemiBold/InterSemiBold.woff2") format("woff2"), url("../fonts/InterSemiBold/InterSemiBold.ttf") format("truetype"), url("../fonts/InterSemiBold/InterSemiBold.svg#Inter") format("svg"), url("../fonts/InterSemiBold/InterSemiBold.eot") format("embedded-opentype"), url("../fonts/InterSemiBold/InterSemiBold.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterBold/InterBold.eot");
  src: url("../fonts/InterBold/InterBold.eot?#iefix") format("embedded-opentype"), url("../fonts/InterBold/InterBold.woff") format("woff"), url("../fonts/InterBold/InterBold.woff2") format("woff2"), url("../fonts/InterBold/InterBold.ttf") format("truetype"), url("../fonts/InterBold/InterBold.svg#Inter") format("svg"), url("../fonts/InterBold/InterBold.eot") format("embedded-opentype"), url("../fonts/InterBold/InterBold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterExtraBold/InterExtraBold.eot");
  src: url("../fonts/InterExtraBold/InterExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/InterExtraBold/InterExtraBold.woff") format("woff"), url("../fonts/InterExtraBold/InterExtraBold.woff2") format("woff2"), url("../fonts/InterExtraBold/InterExtraBold.ttf") format("truetype"), url("../fonts/InterExtraBold/InterExtraBold.svg#Inter") format("svg"), url("../fonts/InterExtraBold/InterExtraBold.eot") format("embedded-opentype"), url("../fonts/InterExtraBold/InterExtraBold.eot?#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../fonts/InterBlack/InterBlack.eot");
  src: url("../fonts/InterBlack/InterBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/InterBlack/InterBlack.woff") format("woff"), url("../fonts/InterBlack/InterBlack.woff2") format("woff2"), url("../fonts/InterBlack/InterBlack.ttf") format("truetype"), url("../fonts/InterBlack/InterBlack.svg#Inter") format("svg"), url("../fonts/InterBlack/InterBlack.eot") format("embedded-opentype"), url("../fonts/InterBlack/InterBlack.eot?#iefix") format("embedded-opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsThin/PoppinsThin.eot");
  src: url("../fonts/PoppinsThin/PoppinsThin.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsThin/PoppinsThin.woff") format("woff"), url("../fonts/PoppinsThin/PoppinsThin.woff2") format("woff2"), url("../fonts/PoppinsThin/PoppinsThin.ttf") format("truetype"), url("../fonts/PoppinsThin/PoppinsThin.svg#Poppins") format("svg"), url("../fonts/PoppinsThin/PoppinsThin.eot") format("embedded-opentype"), url("../fonts/PoppinsThin/PoppinsThin.eot?#iefix") format("embedded-opentype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsExtraLight/PoppinsExtraLight.eot");
  src: url("../fonts/PoppinsExtraLight/PoppinsExtraLight.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.woff") format("woff"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.woff2") format("woff2"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.ttf") format("truetype"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.svg#Poppins") format("svg"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.eot") format("embedded-opentype"), url("../fonts/PoppinsExtraLight/PoppinsExtraLight.eot?#iefix") format("embedded-opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsLight/PoppinsLight.eot");
  src: url("../fonts/PoppinsLight/PoppinsLight.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsLight/PoppinsLight.woff") format("woff"), url("../fonts/PoppinsLight/PoppinsLight.woff2") format("woff2"), url("../fonts/PoppinsLight/PoppinsLight.ttf") format("truetype"), url("../fonts/PoppinsLight/PoppinsLight.svg#Poppins") format("svg"), url("../fonts/PoppinsLight/PoppinsLight.eot") format("embedded-opentype"), url("../fonts/PoppinsLight/PoppinsLight.eot?#iefix") format("embedded-opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsRegular/PoppinsRegular.eot");
  src: url("../fonts/PoppinsRegular/PoppinsRegular.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsRegular/PoppinsRegular.woff") format("woff"), url("../fonts/PoppinsRegular/PoppinsRegular.woff2") format("woff2"), url("../fonts/PoppinsRegular/PoppinsRegular.ttf") format("truetype"), url("../fonts/PoppinsRegular/PoppinsRegular.svg#Poppins") format("svg"), url("../fonts/PoppinsRegular/PoppinsRegular.eot") format("embedded-opentype"), url("../fonts/PoppinsRegular/PoppinsRegular.eot?#iefix") format("embedded-opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsMedium/PoppinsMedium.eot");
  src: url("../fonts/PoppinsMedium/PoppinsMedium.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsMedium/PoppinsMedium.woff") format("woff"), url("../fonts/PoppinsMedium/PoppinsMedium.woff2") format("woff2"), url("../fonts/PoppinsMedium/PoppinsMedium.ttf") format("truetype"), url("../fonts/PoppinsMedium/PoppinsMedium.svg#Poppins") format("svg"), url("../fonts/PoppinsMedium/PoppinsMedium.eot") format("embedded-opentype"), url("../fonts/PoppinsMedium/PoppinsMedium.eot?#iefix") format("embedded-opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsSemiBold/PoppinsSemiBold.eot");
  src: url("../fonts/PoppinsSemiBold/PoppinsSemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.woff") format("woff"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.woff2") format("woff2"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.ttf") format("truetype"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.svg#Poppins") format("svg"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.eot") format("embedded-opentype"), url("../fonts/PoppinsSemiBold/PoppinsSemiBold.eot?#iefix") format("embedded-opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsBold/PoppinsBold.eot");
  src: url("../fonts/PoppinsBold/PoppinsBold.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsBold/PoppinsBold.woff") format("woff"), url("../fonts/PoppinsBold/PoppinsBold.woff2") format("woff2"), url("../fonts/PoppinsBold/PoppinsBold.ttf") format("truetype"), url("../fonts/PoppinsBold/PoppinsBold.svg#Poppins") format("svg"), url("../fonts/PoppinsBold/PoppinsBold.eot") format("embedded-opentype"), url("../fonts/PoppinsBold/PoppinsBold.eot?#iefix") format("embedded-opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsExtraBold/PoppinsExtraBold.eot");
  src: url("../fonts/PoppinsExtraBold/PoppinsExtraBold.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.woff") format("woff"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.woff2") format("woff2"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.ttf") format("truetype"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.svg#Poppins") format("svg"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.eot") format("embedded-opentype"), url("../fonts/PoppinsExtraBold/PoppinsExtraBold.eot?#iefix") format("embedded-opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Poppins";
  src: url("../fonts/PoppinsBlack/PoppinsBlack.eot");
  src: url("../fonts/PoppinsBlack/PoppinsBlack.eot?#iefix") format("embedded-opentype"), url("../fonts/PoppinsBlack/PoppinsBlack.woff") format("woff"), url("../fonts/PoppinsBlack/PoppinsBlack.woff2") format("woff2"), url("../fonts/PoppinsBlack/PoppinsBlack.ttf") format("truetype"), url("../fonts/PoppinsBlack/PoppinsBlack.svg#Poppins") format("svg"), url("../fonts/PoppinsBlack/PoppinsBlack.eot") format("embedded-opentype"), url("../fonts/PoppinsBlack/PoppinsBlack.eot?#iefix") format("embedded-opentype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
h1 {
  font-size: calc(45px + 5 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 48px;
}
@media screen and (max-width: 480px) {
  h1 {
    font-size: 45px;
  }
}
@media screen and (min-width: 1264px) {
  h1 {
    font-size: 50px;
  }
}

h2 {
  font-size: calc(30px + 5 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 43.2px;
}
@media screen and (max-width: 480px) {
  h2 {
    font-size: 30px;
  }
}
@media screen and (min-width: 1264px) {
  h2 {
    font-size: 35px;
  }
}

h3 {
  font-size: calc(20px + 5 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 39.9px;
}
@media screen and (max-width: 480px) {
  h3 {
    font-size: 20px;
  }
}
@media screen and (min-width: 1264px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: calc(18px + 2 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 31.92px;
}
@media screen and (max-width: 480px) {
  h4 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1264px) {
  h4 {
    font-size: 20px;
  }
}

h5 {
  font-size: calc(16px + 2 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
}
@media screen and (max-width: 480px) {
  h5 {
    font-size: 16px;
  }
}
@media screen and (min-width: 1264px) {
  h5 {
    font-size: 18px;
  }
}

h6 {
  font-size: calc(14px + 2 * (100vw - 480px) / 784);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 25.92px;
}
@media screen and (max-width: 480px) {
  h6 {
    font-size: 14px;
  }
}
@media screen and (min-width: 1264px) {
  h6 {
    font-size: 16px;
  }
}

p {
  font-size: calc(16px + 2 * (100vw - 480px) / 784);
  margin: 0px;
  font-weight: 400;
  line-height: 27px;
}
@media screen and (max-width: 480px) {
  p {
    font-size: 16px;
  }
}
@media screen and (min-width: 1264px) {
  p {
    font-size: 18px;
  }
}

/* __________ TYPOGRAPHY STYLE END __________*/
/* __________ BASE STYLE BEGIN __________*/
html,
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "NotoSansHebrew", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  min-width: 320px;
  overflow-x: hidden;
  background-color: #ffffff;
}
body.disable-scroll {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

* {
  font-family: "NotoSansHebrew", Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

main {
  flex: 1;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}

.container-lg {
  margin: 0px auto;
  width: 100%;
  max-width: 1630px;
  padding: 0px 10px;
}

.container-md {
  margin: 0px auto;
  width: 100%;
  max-width: 1530px;
  padding: 0px 10px;
}

.container-sm {
  margin: 0px auto;
  width: 100%;
  max-width: 1040px;
  padding: 0px 10px;
}

.overflow {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
}

.ui-autocomplete {
  position: absolute;
  cursor: default;
  z-index: 99999999 !important;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 50px;
}
.ui-autocomplete li div {
  display: flex;
  align-items: center;
  height: 40px;
}

body > #header > .error-alert {
  opacity: 0;
  position: absolute;
  max-width: 300px;
  width: max-content;
  padding: 5px 10px 5px 40px;
  transform: translate(-50%, 0);
  background: #ffffff;
  left: 50%;
  border-radius: 10px;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  font-size: 14px;
  top: -30px;
  color: #ff001f;
  z-index: 20;
}
body > #header > .error-alert::after {
  content: "";
  position: absolute;
  transform: translate(0, -50%);
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg height='32' style='overflow:visible;enable-background:new 0 0 32 32' viewBox='0 0 32 32' width='32' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cg id='Error_1_'%3E%3Cg id='Error'%3E%3Ccircle cx='16' cy='16' id='BG' r='16' style='fill:%23D72828;'/%3E%3Cpath d='M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z' id='Exclamatory_x5F_Sign' style='fill:%23E6E6E6;'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  top: 50%;
  height: 15px;
  width: 15px;
  left: 10px;
}
body > #header > .success-alert {
  opacity: 0;
  position: absolute;
  max-width: 300px;
  width: max-content;
  padding: 5px 10px 5px 40px;
  transform: translate(-50%, 0);
  background: #ffffff;
  left: 50%;
  border-radius: 10px;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  font-size: 14px;
  top: -30px;
  color: #4e944f;
}
body > #header > .success-alert::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill="%234e944f" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"%3E%3C/path%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  width: 15px;
}

@media (max-width: 1264px) {
  .hide-xl-down {
    display: none !important;
  }
}
@media (min-width: 1264px) {
  .hide-xl-up {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .hide-lg-down {
    display: none !important;
  }
}
@media (min-width: 991px) {
  .hide-lg-up {
    display: none !important;
  }
}
@media (max-width: 767px) {
  .hide-md-down {
    display: none !important;
  }
}
@media (min-width: 767px) {
  .hide-md-up {
    display: none !important;
  }
}
@media (max-width: 575px) {
  .hide-sm-down {
    display: none !important;
  }
}
@media (min-width: 575px) {
  .hide-sm-up {
    display: none !important;
  }
}
@media (max-width: 479px) {
  .hide-xs-down {
    display: none !important;
  }
}
@media (min-width: 479px) {
  .hide-xs-up {
    display: none !important;
  }
}

/* __________   CONTACT FORM 7 BEGIN __________*/
.wpcf7 {
  direction: rtl;
}
.wpcf7 input,
.wpcf7 textarea {
  text-align: right;
}
.wpcf7 form .wpcf7-not-valid {
  border-color: #ff0000 !important;
}
.wpcf7 form .wpcf7-spinner {
  display: none !important;
}

.nice-select {
  overflow: hidden;
}
@media (max-width: 991px) {
  .nice-select {
    white-space: wrap;
  }
}
.nice-select.open {
  overflow: visible;
}
.nice-select .list {
  max-height: 50dvh;
  overflow-y: scroll;
}

/* __________  CONTACT FORM 7 END  __________*/
.rtl {
  direction: rtl;
}
.rtl .wpcf7 {
  direction: rtl;
  text-align: right;
}
.rtl .wpcf7-text,
.rtl .wpcf7-textarea,
.rtl .wpcf7-select {
  direction: rtl !important;
  text-align: right;
}

/* __________ BASE STYLE END __________*/
/* __________ LAYOUT  BEGIN __________*/
.lock {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
}

#header {
  position: sticky;
  top: 0;
  z-index: 10;
}
#header .representative_note_error {
  background: #CE2116;
  color: white;
  padding: 10px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  display: none;
}
#header .header-top {
  background-color: #d7ffd0;
}
#header .header-top .container-lg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
}
#header .header-top .connect-with-us {
  display: flex;
  align-items: center;
  gap: 20px;
}
#header .header-top .connect-with-us a {
  color: #404040;
  font-family: Inter;
  font-size: 20px;
}
#header .header-top .info {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
@media (max-width: 767px) {
  #header .header-top .info {
    font-size: 14px;
    text-align: center;
  }
}
#header .header-top .info a {
  color: #000;
  transition: all 0.3s;
}
#header .header-top .info a:hover {
  color: #045e51;
}
#header .header-bottom {
  height: 90px;
  background-color: #045e51;
  display: flex;
  align-items: center;
}
@media (max-width: 1445px) {
  #header .header-bottom {
    height: 180px;
    display: block;
  }
}
@media (max-width: 767px) {
  #header .header-bottom {
    position: relative;
    height: 70px;
    display: flex;
  }
}
#header .header-bottom .container-lg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 1445px) {
  #header .header-bottom .main-container {
    padding: 15px 10px;
  }
}
@media (max-width: 767px) {
  #header .header-bottom .main-container {
    padding: 0 10px;
  }
}
#header .header-bottom .sub-container {
  display: none;
}
@media (max-width: 1445px) {
  #header .header-bottom .sub-container {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 767px) {
  #header .header-bottom .sub-container {
    display: none;
  }
}
#header .header-bottom .user {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  #header .header-bottom .user .logo {
    display: none;
  }
}
#header .header-bottom .user .cart {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
#header .header-bottom .user .cart .tooltip-content {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  width: 130px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.3s;
}
#header .header-bottom .user .cart:hover .tooltip-content {
  display: inline-block;
  opacity: 1;
}
#header .header-bottom .navigation {
  display: flex;
  align-items: center;
  gap: 45px;
}
@media (max-width: 767px) {
  #header .header-bottom .navigation {
    gap: 20px;
  }
}
#header .header-bottom .navigation .burger {
  display: none;
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .burger {
    display: block;
    z-index: 11;
    position: relative;
  }
  #header .header-bottom .navigation .burger svg {
    display: block;
  }
  #header .header-bottom .navigation .burger svg + svg {
    display: none;
  }
  #header .header-bottom .navigation .burger.active {
    margin-left: 30px;
  }
  #header .header-bottom .navigation .burger.active svg {
    display: none;
  }
  #header .header-bottom .navigation .burger.active svg + svg {
    display: block;
  }
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .logo {
    z-index: 11;
    position: relative;
  }
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .logo img {
    height: 36px;
    width: auto;
  }
}
#header .header-bottom .navigation .sub-menu {
  display: none;
}
@media (max-width: 1445px) {
  #header .header-bottom .navigation .sub-menu {
    display: block;
  }
}
@media (max-width: 991px) {
  #header .header-bottom .navigation .sub-menu > ul {
    gap: 13px !important;
  }
  #header .header-bottom .navigation .sub-menu > ul > li > a {
    font-size: 16px !important;
  }
}
@media (max-width: 1445px) {
  #header .header-bottom .navigation .menu {
    display: none;
  }
}
@media only screen and (max-width: 1605px) {
  #header .header-bottom .navigation .menu > ul {
    gap: 10px !important;
  }
}
@media only screen and (max-width: 1605px) {
  #header .header-bottom .navigation .menu > ul {
    gap: 10px !important;
  }
}
@media only screen and (max-width: 1490px) {
  #header .header-bottom .navigation .menu > ul {
    gap: 13px !important;
  }
  #header .header-bottom .navigation .menu > ul > li > a {
    font-size: 16px !important;
  }
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .menu, #header .header-bottom .navigation .sub-menu {
    display: none;
  }
}
#header .header-bottom .navigation .menu > ul, #header .header-bottom .navigation .sub-menu > ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
#header .header-bottom .navigation .menu > ul > li, #header .header-bottom .navigation .sub-menu > ul > li {
  height: 90px;
  line-height: 90px;
  cursor: pointer;
  position: relative;
}
#header .header-bottom .navigation .menu > ul > li:hover > a, #header .header-bottom .navigation .sub-menu > ul > li:hover > a {
  background: #003a32;
}
#header .header-bottom .navigation .menu > ul > li:hover .sub-menu, #header .header-bottom .navigation .sub-menu > ul > li:hover .sub-menu {
  display: block;
}
#header .header-bottom .navigation .menu > ul > li > a, #header .header-bottom .navigation .sub-menu > ul > li > a {
  color: #d7ffd0;
  font-size: 18px;
  transition: all 0.3s linear;
  padding: 4px 12px;
  border-radius: 20px;
}
#header .header-bottom .navigation .menu .sub-menu-map, #header .header-bottom .navigation .sub-menu .sub-menu-map {
  display: none;
  z-index: 999;
  height: 400px;
  width: 950px;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
}
#header .header-bottom .navigation .menu .sub-menu-map::after, #header .header-bottom .navigation .sub-menu .sub-menu-map::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: 0%;
  transform: translate(-50%, -100%);
  right: 30px;
  z-index: 999;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs {
  display: flex;
  height: 100%;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-nav, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-nav {
  width: 295px;
  background-color: #e9e9e9;
  padding: 30px 30px 30px 0px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-nav .tab, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-nav .tab {
  color: #000000;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding: 10px 30px;
  border-radius: 0px 18px 18px 0px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-nav .tab:last-child, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-nav .tab:last-child {
  margin-bottom: 0px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-nav .tab::after, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-nav .tab::after {
  content: "\f104";
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  font-size: 24px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-nav .tab.active, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-nav .tab.active {
  background-color: #ffffff;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents {
  flex: 1;
  width: calc(100% - 295px);
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-heading, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-heading {
  display: none;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content {
  display: none;
  padding: 15px;
  position: relative;
  height: 100%;
  padding: 15px 30px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content.active, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content.active {
  display: flex;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations {
  flex-shrink: 0;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul {
    padding-left: 40px;
  }
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item {
  position: relative;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount {
  background: #FF4380;
  padding: 6px;
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  display: flex;
  align-items: center;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount > span:first-child, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount > span:first-child {
  margin-right: 7px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li {
  line-height: normal;
  margin-bottom: 10px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li .tag, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li:last-child, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li:last-child {
  margin-bottom: 0px;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li:last-child a, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li:last-child a {
  color: #000000;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
  width: 240px;
  display: flex;
  align-items: center;
  direction: ltr;
  text-align: left;
  gap: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a .marker.marker_discount, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a .marker.marker_discount {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-direction: row-reverse;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a:hover, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a:hover {
  color: #045e51;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map {
  display: inline-block;
  position: relative;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map img, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker {
  line-height: 1;
  transform: translate(-50%, -22.5px);
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker::before, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker::before {
  content: "\f3c5";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  display: inline-block;
  font-size: 30px;
  color: #045e51;
  line-height: 1;
}
#header .header-bottom .navigation .menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker.active::before, #header .header-bottom .navigation .sub-menu .sub-menu-map .tabs .tabs-contents .tab-content .maps .map .marker.active::before {
  color: #003a32;
}
#header .header-bottom .navigation .menu .sub-menu-cells, #header .header-bottom .navigation .sub-menu .sub-menu-cells {
  display: none;
  z-index: 999;
  width: 950px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  padding: 60px 30px;
  z-index: 999;
}
#header .header-bottom .navigation .menu .sub-menu-cells::after, #header .header-bottom .navigation .sub-menu .sub-menu-cells::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 999;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell:first-child a:after, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell:first-child a:after {
  display: none;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell:nth-child(5n) a:after, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell:nth-child(5n) a:after {
  display: none;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  position: relative;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a .menu-discount, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a .menu-discount {
  background: #FF4380;
  border-radius: 3px;
  padding: 6px;
  color: #FFF;
  font-size: 10px;
  font-weight: 700;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a:hover, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a:hover {
  background: #f3f5f5;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a:hover h5, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a:hover h5 {
  color: #025f52;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a:after, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  display: block;
  width: 0;
  border-left: 1px solid #d2d3d3;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a img, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a img {
  margin-bottom: 16px;
}
#header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a h5, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a h5 {
  color: #000000;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}
@media (max-width: 575px) {
  #header .header-bottom .navigation .menu .sub-menu-cells .cells .cell a h5, #header .header-bottom .navigation .sub-menu .sub-menu-cells .cells .cell a h5 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    white-space: wrap;
  }
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_1, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_1 {
  width: auto;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_1 .cells, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_1 .cells {
  display: block;
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_1 .cells .cell, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_1 .cells .cell {
  width: 200px;
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_1 .cells .cell a, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_1 .cells .cell a {
  padding: 0;
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_2, #header .header-bottom .navigation .menu .sub-menu-cells.elements_3, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_2, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_3 {
  width: auto;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_2 .cells, #header .header-bottom .navigation .menu .sub-menu-cells.elements_3 .cells, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_2 .cells, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_3 .cells {
  display: flex;
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_2 .cells .cell, #header .header-bottom .navigation .menu .sub-menu-cells.elements_3 .cells .cell, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_2 .cells .cell, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_3 .cells .cell {
  width: 200px;
}
#header .header-bottom .navigation .menu .sub-menu-cells.elements_2 .cells .cell a, #header .header-bottom .navigation .menu .sub-menu-cells.elements_3 .cells .cell a, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_2 .cells .cell a, #header .header-bottom .navigation .sub-menu .sub-menu-cells.elements_3 .cells .cell a {
  padding: 0;
}
#header .header-bottom .navigation .menu .sub-menu-columns, #header .header-bottom .navigation .sub-menu .sub-menu-columns {
  display: none;
  z-index: 999;
  width: 950px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
}
#header .header-bottom .navigation .menu .sub-menu-columns::after, #header .header-bottom .navigation .sub-menu .sub-menu-columns::after {
  content: "";
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 999;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 60px 0px 20px 0px;
  width: 100%;
  height: 100%;
  position: relative;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:hover, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:hover {
  background: #f3f5f5;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:hover h5, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:hover h5 {
  color: #003a32;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:hover svg path, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:hover svg path {
  fill: #003a32;
  transition: all 0.1s ease;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column a, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column a {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column img,
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column svg, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column img,
#header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column svg {
  height: 68px;
  width: 68px;
  margin-bottom: 16px;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column h5, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column h5 {
  color: #000000;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 30px;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column ul, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  text-align: center;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column ul li, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column ul li {
  line-height: normal;
  margin-bottom: 10px;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column ul li a, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column ul li a {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column ul li a:hover, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column ul li a:hover {
  color: #025f52;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:first-child:after, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:first-child:after {
  display: none;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:nth-child(5n):after, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:nth-child(5n):after {
  display: none;
}
#header .header-bottom .navigation .menu .sub-menu-columns .columns .column:after, #header .header-bottom .navigation .sub-menu .sub-menu-columns .columns .column:after {
  content: "";
  position: absolute;
  top: 60px;
  right: 0;
  bottom: 20px;
  display: block;
  width: 0;
  border-left: 1px solid #d2d3d3;
}
@media (max-width: 767px) {
  #header .header-bottom .navigation .menu.active, #header .header-bottom .navigation .sub-menu.active {
    display: block;
    position: fixed;
    z-index: 5;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    background: #045E51;
    padding-top: 100px;
  }
  #header .header-bottom .navigation .menu.active > ul, #header .header-bottom .navigation .sub-menu.active > ul {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: start;
    justify-content: flex-start;
    gap: 43px 0;
    height: 100%;
    overflow-y: auto;
  }
  #header .header-bottom .navigation .menu.active > ul > li, #header .header-bottom .navigation .sub-menu.active > ul > li {
    line-height: normal;
    height: auto;
    width: 100%;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-cells, #header .header-bottom .navigation .sub-menu.active .sub-menu-cells {
    width: 100%;
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
    margin-top: 30px;
    padding: 50px 24px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-cells:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-cells:after {
    left: 90%;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-cells .cells, #header .header-bottom .navigation .sub-menu.active .sub-menu-cells .cells {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-cells .cells .cell, #header .header-bottom .navigation .sub-menu.active .sub-menu-cells .cells .cell {
    width: calc(50% - 10px);
  }
  #header .header-bottom .navigation .menu.active .sub-menu-cells .cells .cell:nth-child(odd) a:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-cells .cells .cell:nth-child(odd) a:after {
    display: none;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map, #header .header-bottom .navigation .sub-menu.active .sub-menu-map {
    width: 100%;
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
    margin-top: 30px;
    height: auto;
    min-height: 100px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-map:after {
    left: 90%;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs {
    display: block;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-nav, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-nav {
    display: none;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents {
    width: 100%;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-content, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-content {
    display: none !important;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li {
    margin-bottom: 20px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li a {
    width: 100%;
    font-size: 18px;
    grid-template-columns: 110px auto;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li .tag, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-content .locations ul li .tag {
    font-size: 18px;
    padding: 12px;
    border-radius: 5.294px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-heading, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-heading {
    display: block;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #404040;
    padding: 12px 20px;
    background: #e9e9e9;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-heading:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-heading:after {
    content: "\f078";
    font-family: Font Awesome\ 6 Pro;
    font-weight: 400;
    transition: all 0.3s;
    color: #404040;
    transform: rotate(90deg);
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open {
    background: #ffffff;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open:after {
    transform: rotate(180deg);
  }
  #header .header-bottom .navigation .menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open + .tab-content, #header .header-bottom .navigation .sub-menu.active .sub-menu-map .tabs .tabs-contents .tab-heading.open + .tab-content {
    display: block !important;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-columns, #header .header-bottom .navigation .sub-menu.active .sub-menu-columns {
    width: 100%;
    position: relative;
    top: 0%;
    left: 0%;
    transform: none;
    margin-top: 30px;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-columns:after, #header .header-bottom .navigation .sub-menu.active .sub-menu-columns:after {
    left: 90%;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-columns .columns, #header .header-bottom .navigation .sub-menu.active .sub-menu-columns .columns {
    display: flex;
    flex-direction: column-reverse;
  }
  #header .header-bottom .navigation .menu.active .sub-menu-columns .columns .column h5 a, #header .header-bottom .navigation .sub-menu.active .sub-menu-columns .columns .column h5 a {
    color: #000000;
  }
}
#header .promotions-header {
  background: #FF4380;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#header .promotions-header h3 {
  font-size: calc(18px + 14 * (100vw - 480px) / 720);
  font-weight: 700;
  color: #FFF;
}
@media screen and (max-width: 480px) {
  #header .promotions-header h3 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  #header .promotions-header h3 {
    font-size: 32px;
  }
}
#header .promotions-header p {
  font-size: calc(13px + 7 * (100vw - 480px) / 720);
  font-weight: 200;
  color: #FFF;
}
@media screen and (max-width: 480px) {
  #header .promotions-header p {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) {
  #header .promotions-header p {
    font-size: 20px;
  }
}
#header .promotions-header .banner-promo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: relative;
}
#header .promotions-header .banner-promo input {
  visibility: hidden;
  max-height: 0;
  outline: none;
  position: absolute;
  z-index: -1;
}
#header .promotions-header .banner-promo .tooltip {
  visibility: hidden;
  width: 140px;
  background-color: white;
  color: #045e51;
  text-align: center;
  border: 1px solid #045e51;
  border-radius: 5px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 110%);
  opacity: 0;
  transition: opacity 0.3s;
}
#header .promotions-header .banner-promo .tooltip::after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -105%) rotate(180deg);
  border-width: 5px;
  border-style: solid;
  border-color: #045e51 #ff4380 #ff4380;
}
#header .promotions-header .banner-promo:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

#footer {
  margin-top: 200px;
}
@media (max-width: 991px) {
  #footer {
    margin-top: 0px;
  }
}
@media (max-width: 991px) {
  #footer {
    margin-top: 100px;
  }
}
#footer .footer-top {
  background: #045e51;
}
@media (max-width: 991px) {
  #footer .footer-top {
    padding: 30px 0px;
  }
}
@media (max-width: 767px) {
  #footer .footer-top {
    padding-top: 0;
  }
}
#footer .footer-top > .container-md {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 65px;
  margin-bottom: -70px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md {
    gap: 0px;
    height: 420px;
    margin-top: 280px;
    margin-bottom: -350px;
    transform: translateY(-360px);
    align-items: end;
  }
}
#footer .footer-top > .container-md .our-app {
  display: flex;
  transform: translateY(-50%);
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  overflow: hidden;
  min-height: 218px;
  max-width: 588px;
  width: 100%;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app {
    min-height: unset;
    max-width: unset;
    width: max-content;
    transform: translateY(0);
  }
}
#footer .footer-top > .container-md .our-app .image {
  width: 256px;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .image {
    width: 131px;
    border-radius: 10px;
    overflow: hidden;
  }
}
#footer .footer-top > .container-md .our-app .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
#footer .footer-top > .container-md .our-app .content {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 40px 46px 40px 13px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .content {
    padding: 25px 25px 17px 17px;
  }
}
#footer .footer-top > .container-md .our-app .content .title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 17px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .content .title {
    margin-bottom: unset;
  }
  #footer .footer-top > .container-md .our-app .content .title img {
    display: none;
  }
}
#footer .footer-top > .container-md .our-app .content .title h5 {
  font-size: 24px;
  line-height: 125%;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .content .title h5 {
    font-size: 17px;
    line-height: 18px;
    letter-spacing: 0.03em;
    font-weight: normal;
  }
}
#footer .footer-top > .container-md .our-app .content .text {
  font-size: 18px;
  line-height: normal;
  letter-spacing: 0.03em;
  margin-bottom: 25px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .content .text {
    font-size: 17px;
  }
}
#footer .footer-top > .container-md .our-app .content .app-btn {
  font-size: 18px;
  padding: 11px 20px;
  width: max-content;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .our-app .content .app-btn {
    font-size: 16px;
  }
}
#footer .footer-top > .container-md .contact-form-7 {
  display: flex;
  transform: translateY(-50%);
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  overflow: hidden;
  min-height: 218px;
  max-width: 840px;
  width: 100%;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 {
    padding: 18px 12px;
    transform: translateY(0);
    min-height: unset;
  }
}
#footer .footer-top > .container-md .contact-form-7 .title {
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .title {
    justify-content: center;
    margin-bottom: 24px;
  }
  #footer .footer-top > .container-md .contact-form-7 .title h5 {
    text-align: center;
  }
}
#footer .footer-top > .container-md .contact-form-7 .title h5 {
  font-size: 24px;
  line-height: 125%;
}
#footer .footer-top > .container-md .contact-form-7 .image {
  width: 365px;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 991px) {
  #footer .footer-top > .container-md .contact-form-7 .image {
    display: none;
  }
}
#footer .footer-top > .container-md .contact-form-7 .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
#footer .footer-top > .container-md .contact-form-7 .form {
  display: flex;
  flex-flow: column;
  padding: 20px 47px 20px 36px;
  width: 100%;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form {
    padding: 0;
  }
}
#footer .footer-top > .container-md .contact-form-7 .form .screen-reader-response {
  display: none;
}
#footer .footer-top > .container-md .contact-form-7 .form form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
}
@media (max-width: 991px) {
  #footer .footer-top > .container-md .contact-form-7 .form form {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form {
    gap: 0;
  }
}
#footer .footer-top > .container-md .contact-form-7 .form form.invalid .wpcf7-response-output {
  color: #ff001f;
}
#footer .footer-top > .container-md .contact-form-7 .form form.sent .wpcf7-response-output {
  color: #4e944f;
}
#footer .footer-top > .container-md .contact-form-7 .form form .wpcf7-not-valid-tip {
  display: none;
}
#footer .footer-top > .container-md .contact-form-7 .form form .wpcf7-response-output {
  font-size: 16px;
  line-height: normal;
  margin-top: 15px;
}
#footer .footer-top > .container-md .contact-form-7 .form form .form-group {
  display: flex;
  width: 100%;
  gap: 30px;
  position: relative;
}
#footer .footer-top > .container-md .contact-form-7 .form form .input {
  width: 100%;
}
#footer .footer-top > .container-md .contact-form-7 .form form .input input {
  color: #000000;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form .input input {
    font-size: 14px;
    padding-inline-end: 160px;
    border-radius: 100px;
  }
  #footer .footer-top > .container-md .contact-form-7 .form form .input input::placeholder {
    color: #c4c4c4;
  }
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form .submit {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}
#footer .footer-top > .container-md .contact-form-7 .form form .submit input {
  padding: 10.882px 33.168px;
  font-size: 18px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form .submit input {
    font-size: 16px;
    height: 45px;
  }
}
#footer .footer-top > .container-md .contact-form-7 .form form .agreement {
  margin-top: 30px;
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form .agreement {
    margin-top: 16px;
  }
}
@media (max-width: 767px) {
  #footer .footer-top > .container-md .contact-form-7 .form form .agreement .checkbox .wpcf7-list-item-label {
    font-size: 14px;
  }
}
#footer .footer-top > .container-md .contact-form-7 .form form .agreement .checkbox .wpcf7-list-item-label::before {
  border-radius: 0px !important;
}
#footer .footer-top .entertainment {
  padding-top: 70px;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment {
    padding-top: 50px;
  }
}
#footer .footer-top .entertainment .heading {
  text-align: center;
  margin-bottom: 36px;
}
#footer .footer-top .entertainment .heading h2 {
  color: #ffffff;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .heading h2 {
    font-size: 24px;
  }
}
#footer .footer-top .entertainment .cards {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(5, 1fr);
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
}
#footer .footer-top .entertainment .cards .card {
  width: 170px;
  margin: 0px auto;
  text-align: center;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .cards .card {
    width: calc(50% - 10px);
  }
}
#footer .footer-top .entertainment .cards .card .image {
  display: inline-block;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .cards .card .image img {
    height: 42px;
  }
}
#footer .footer-top .entertainment .cards .card .title h5 {
  color: #ffffff;
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .cards .card .title h5 {
    font-size: 14px;
  }
}
#footer .footer-top .entertainment .navigation {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 1fr);
  border-top: 1px solid #ffffff;
  padding-top: 50px;
  padding-bottom: 60px;
  gap: 10px;
}
@media (max-width: 1264px) {
  #footer .footer-top .entertainment .navigation {
    grid-template-columns: repeat(4, 1fr);
    place-self: center;
  }
}
@media (max-width: 991px) {
  #footer .footer-top .entertainment .navigation {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .navigation {
    padding-top: 20px;
    padding-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
}
#footer .footer-top .entertainment .navigation .menu {
  width: 170px;
  margin: 0px auto;
}
@media (max-width: 1264px) {
  #footer .footer-top .entertainment .navigation .menu {
    margin-bottom: 30px;
  }
  #footer .footer-top .entertainment .navigation .menu:nth-child(5) {
    grid-column: 2/3;
  }
  #footer .footer-top .entertainment .navigation .menu:nth-child(6) {
    grid-column: 3/4;
  }
}
@media (max-width: 991px) {
  #footer .footer-top .entertainment .navigation .menu:nth-child(5) {
    grid-column: inherit;
  }
  #footer .footer-top .entertainment .navigation .menu:nth-child(6) {
    grid-column: inherit;
  }
}
@media (max-width: 767px) {
  #footer .footer-top .entertainment .navigation .menu:first-child {
    order: 1;
  }
}
#footer .footer-top .entertainment .navigation .menu h5 {
  color: #ffffff;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
}
#footer .footer-top .entertainment .navigation .menu ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
#footer .footer-top .entertainment .navigation .menu ul li a {
  color: #ffffff;
  text-align: right;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
}
#footer .footer-top .entertainment .navigation .menu ul li a img {
  height: 38px;
  object-fit: contain;
}
#footer .footer-bottom {
  background-color: #3d4246;
  padding: 20px;
}
@media (max-width: 767px) {
  #footer .footer-bottom {
    padding: 16px 6px;
  }
}
#footer .footer-bottom .container-md {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
@media (max-width: 991px) {
  #footer .footer-bottom .container-md {
    flex-direction: column-reverse;
  }
}
@media (max-width: 767px) {
  #footer .footer-bottom .container-md {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}
#footer .footer-bottom .logos {
  display: flex;
  align-items: center;
  gap: 30px;
  max-width: 1000px;
  grid-row: 1/3;
}
@media (max-width: 991px) {
  #footer .footer-bottom .logos {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    place-items: center;
    width: 100%;
    gap: 20px;
  }
}
@media (max-width: 767px) {
  #footer .footer-bottom .logos {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px 5px;
  }
}
#footer .footer-bottom .logos .logo {
  text-align: center;
  width: 100%;
}
#footer .footer-bottom .logos .logo.madebyomnis-logo a {
  width: max-content;
}
#footer .footer-bottom .logos .logo.madebyomnis-logo img {
  height: 35px;
}
@media (max-width: 991px) {
  #footer .footer-bottom .logos .logo.madebyomnis-logo img {
    height: inherit;
  }
}
@media (max-width: 767px) {
  #footer .footer-bottom .logos .logo.madebyomnis-logo {
    flex-basis: 100%;
  }
}
#footer .footer-bottom .logos .logo img {
  width: 100%;
  height: 60px;
  object-fit: contain;
}
@media (max-width: 991px) {
  #footer .footer-bottom .logos .logo img {
    grid-row: 3/3;
    height: inherit;
  }
}
@media (max-width: 767px) {
  #footer .footer-bottom .logos .logo img {
    max-height: 32px;
    width: auto;
  }
}
@media (max-width: 991px) {
  #footer .footer-bottom .logos .logo {
    grid-row: 3/3;
  }
  #footer .footer-bottom .logos .logo:nth-child(4) {
    grid-row: 2/3;
    grid-column: 2/4;
  }
  #footer .footer-bottom .logos .logo:nth-child(5) {
    grid-row: 1/2;
    grid-column: 1/3;
  }
  #footer .footer-bottom .logos .logo:nth-child(6) {
    grid-row: 1/2;
    grid-column: 3/3;
  }
}
@media (max-width: 767px) {
  #footer .footer-bottom .logos .logo {
    width: calc(33.3333333333% - 10px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#footer .footer-bottom .cards {
  display: flex;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
}
@media (max-width: 991px) {
  #footer .footer-bottom .cards {
    display: grid;
    gap: 20px;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
  }
}
#footer .footer-bottom .cards .card a img {
  width: 100%;
}

/* __________ LAYOUT  BEGIN __________*/
/* __________ COMPONENTS  BEGIN __________*/
.btn-pink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 22px;
  border-radius: 70px;
  background-color: #ff4380;
  outline: none;
  border: 1px solid #ff4380;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s linear;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .btn-pink {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    gap: 10px;
  }
}
@media (max-width: 575px) {
  .btn-pink {
    padding: 10px 26px;
    font-size: 16px;
  }
}
.btn-pink:hover {
  background-color: #ce3364;
}
.btn-pink:focus {
  box-shadow: 0px 0px 10px 0px #ff4380;
}
.btn-pink:active {
  box-shadow: 0px 0px 10px 0px #ff4380;
}
.btn-pink:disabled {
  color: rgba(9, 25, 50, 0.3);
  background: rgba(219, 222, 231, 0.3);
  border: 1px solid rgba(9, 25, 50, 0.3);
}

.btn-pink-rectangle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 50px;
  font-weight: 600;
  font-size: 22px;
  border-radius: 10px;
  background-color: #ff4380;
  outline: none;
  border: 1px solid #ff4380;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s linear;
  gap: 12px;
}
@media (max-width: 767px) {
  .btn-pink-rectangle {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    gap: 10px;
  }
}
@media (max-width: 575px) {
  .btn-pink-rectangle {
    padding: 10px 26px;
    font-size: 16px;
  }
}
.btn-pink-rectangle:hover {
  background-color: #ce3364;
}
.btn-pink-rectangle:focus {
  box-shadow: 0px 0px 10px 0px #ff4380;
}
.btn-pink-rectangle:active {
  box-shadow: 0px 0px 10px 0px #ff4380;
}
.btn-pink-rectangle:disabled {
  color: rgba(9, 25, 50, 0.3);
  background: rgba(219, 222, 231, 0.3);
  border: 1px solid rgba(9, 25, 50, 0.3);
}

.btn-green {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 22px;
  border-radius: 70px;
  background-color: #045e51;
  outline: none;
  border: 1px solid #045e51;
  cursor: pointer;
  color: #ffffff;
  transition: all 0.3s linear;
  gap: 12px;
}
@media (max-width: 767px) {
  .btn-green {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    gap: 10px;
  }
}
@media (max-width: 575px) {
  .btn-green {
    padding: 10px 26px;
    font-size: 16px;
  }
}
.btn-green:hover {
  background-color: #003a32;
}
.btn-green:focus {
  box-shadow: 0px 0px 10px 0px #d7ffd0;
}
.btn-green:active {
  box-shadow: 0px 0px 10px 0px #025f52;
}
.btn-green:disabled {
  color: rgba(9, 25, 50, 0.3);
  background: rgba(219, 222, 231, 0.3);
  border: 1px solid rgba(9, 25, 50, 0.3);
}

.btn-white {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 22px;
  border-radius: 70px;
  background-color: #ffffff;
  outline: none;
  border: 1px solid #404040;
  cursor: pointer;
  color: #404040;
  transition: all 0.3s linear;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 767px) {
  .btn-white {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    gap: 10px;
  }
}
@media (max-width: 575px) {
  .btn-white {
    padding: 10px 26px;
    font-size: 16px;
  }
}

.input label {
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: rgba(9, 25, 50, 0.5);
  margin-bottom: 5px;
}
.input input {
  display: block;
  padding: 12px 16px;
  gap: 10px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0.42px;
  outline: none;
  font-weight: 400;
  background-color: #ffffff;
  border-radius: 4px;
  border: none;
  color: #abb5b7;
  outline: none;
  border: 1px solid #7d7d7d;
  height: 45px;
}

.input-number {
  display: inline-flex;
  align-items: center;
  background-color: #ffffff;
  border-radius: 4px;
  padding: 0px 10px;
}
.input-number span {
  cursor: pointer;
  width: 35px;
  height: 35px;
  flex-shrink: 0;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #404040;
}
.input-number input {
  width: 40px;
  border: none;
  padding: 8px;
  text-align: center;
  line-height: 24px;
  outline: none;
  color: #404040;
  font-size: 20px;
  font-weight: 500;
}

.checkbox-rectangle {
  display: flex;
  align-items: center;
  border-radius: 5px;
  background: #f4f5f5;
  padding: 25px;
}
.checkbox-rectangle label {
  margin: 0px;
  font-size: 18px;
  line-height: 133%;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.checkbox-rectangle label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #abb5b7;
  margin-left: 14px;
  border-radius: 5px;
  flex-shrink: 0;
}
.checkbox-rectangle input[type=checkbox] {
  display: none;
}
.checkbox-rectangle input[type=checkbox]:disabled + label {
  color: #d0d0d0 !important;
}
.checkbox-rectangle input[type=checkbox]:disabled + label::before {
  background-color: #d0d0d0 !important;
  border: 2px solid #abb5b7 !important;
}
.checkbox-rectangle input[type=checkbox]:disabled + label::after {
  color: #404040 !important;
}
.checkbox-rectangle input[type=checkbox]:checked + label::before {
  background-color: transparent;
}
.checkbox-rectangle input[type=checkbox]:checked + label::after {
  position: absolute;
  right: 4px;
  font-weight: 700;
  color: #045e51;
  font-size: 16px;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}

.checkboxes-rectangle .checkboxes-horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
}
.checkboxes-rectangle .checkboxes-vertical {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 18px;
}

.checkbox-rectangle-white:hover {
  border-color: #045e51 !important;
}
.checkbox-rectangle-white:hover label::before {
  border-color: #045e51 !important;
}
.checkbox-rectangle-white label {
  display: flex;
  align-items: center;
  border-radius: 15px;
  background: #ffffff;
  border: 2px solid #f4f5f5;
  padding: 25px;
  margin: 0px;
  font-size: 18px;
  line-height: 133%;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.checkbox-rectangle-white label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #abb5b7;
  margin-left: 14px;
  border-radius: 5px;
  flex-shrink: 0;
}
.checkbox-rectangle-white input[type=checkbox] {
  display: none;
}
.checkbox-rectangle-white input[type=checkbox]:disabled + label {
  color: #d0d0d0 !important;
}
.checkbox-rectangle-white input[type=checkbox]:disabled + label::before {
  background-color: #d0d0d0 !important;
  border: 2px solid #abb5b7 !important;
}
.checkbox-rectangle-white input[type=checkbox]:disabled + label::after {
  color: #404040 !important;
}
.checkbox-rectangle-white input[type=checkbox]:checked + label::before {
  background-color: transparent;
}
.checkbox-rectangle-white input[type=checkbox]:checked + label::after {
  position: absolute;
  width: 24px;
  padding: 4px;
  font-weight: 700;
  color: #045e51;
  font-size: 16px;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  width: 24px;
  height: 24px;
  line-height: 1;
  text-align: center;
}

.checkbox {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border-radius: 5px;
}
.checkbox label {
  margin: 0px;
  font-size: 18px;
  line-height: 133%;
  font-weight: 400;
  display: flex;
  align-items: center;
  color: #000000;
  cursor: pointer;
  position: relative;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #abb5b7;
  margin-left: 14px;
  border-radius: 5px;
  flex-shrink: 0;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox input[type=checkbox]:disabled + label {
  color: #d0d0d0 !important;
}
.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #d0d0d0 !important;
  border: 2px solid #abb5b7 !important;
}
.checkbox input[type=checkbox]:disabled + label::after {
  color: #404040 !important;
}
.checkbox input[type=checkbox]:checked + label::before {
  background-color: transparent;
}
.checkbox input[type=checkbox]:checked + label::after {
  position: absolute;
  right: 4px;
  font-weight: 700;
  color: #045e51;
  font-size: 16px;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
}
.checkbox.cf7 label::before {
  display: none;
}
.checkbox.cf7 label .wpcf7-list-item-label::before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 2px solid #abb5b7;
  margin-left: 14px;
  border-radius: 5px;
  flex-shrink: 0;
  vertical-align: middle;
}
.checkbox.cf7 label input[type=checkbox]:checked + .wpcf7-list-item-label::before {
  background-color: transparent;
}
.checkbox.cf7 label input[type=checkbox]:checked + .wpcf7-list-item-label::after {
  position: absolute;
  right: 4px;
  font-weight: 700;
  color: #045e51;
  font-size: 16px;
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  top: 4px;
}

.card-apartment {
  border-radius: 5px;
  background: #f4f5f5;
  height: 315px;
  flex-shrink: 0;
  padding: 20px;
}
.card-apartment .checkbox {
  margin-bottom: 10px;
}
.card-apartment .thumbnail {
  margin-bottom: 10px;
}

.chosen-container {
  width: 255px !important;
}
.chosen-container.chosen-with-drop .chosen-single div b::before {
  transform: translate(-50%, -50%) rotate(180deg);
}
.chosen-container .chosen-single {
  height: auto;
  padding: 0px;
  border-radius: 5px !important;
  background-image: none !important;
  border: 1px solid #abb5b7;
  background: #ffffff;
}
.chosen-container .chosen-single span {
  padding: 10px 20px;
}
.chosen-container .chosen-single div b {
  background: none;
}
.chosen-container .chosen-single div b::before {
  content: "\f078";
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  color: #404040;
  transition: all 0.3s linear;
}
.chosen-container .chosen-drop {
  border-radius: 5px;
  border: 1px solid #abb5b7;
  background: #ffffff;
  margin-top: 2px;
  overflow: hidden;
  box-shadow: none;
}
.chosen-container .chosen-drop .chosen-results {
  max-height: 600px;
  padding: 0px;
  margin: 0px;
}
.chosen-container .chosen-drop .chosen-results li {
  padding: 10px 20px;
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.chosen-container .chosen-drop .chosen-results li.result-selected {
  color: #4e944f;
  font-size: 16px;
  font-weight: 700;
}
.chosen-container .chosen-drop .chosen-results li.highlighted {
  background-color: #f3f5f5 !important;
  background-image: none !important;
}

.range .irs--round .irs-bar {
  background-color: #045E51;
  height: 12px;
  border-radius: 0 4px 4px 0;
}
.range .irs--round .irs-handle {
  border: 3px solid #7D7D7D;
  top: 31px;
}
.range .irs--round .irs-handle:before {
  background: #7d7d7d;
  bottom: -10px;
  left: 9px;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  width: 2px;
}
.range .irs--round .irs-line {
  background-color: #87B29A;
  height: 12px;
}
.range .irs--round .irs-single {
  background-color: transparent;
  color: #000;
  top: 65px;
}
.range .irs--round .irs-single:before {
  content: none;
}
.range .irs--round .irs-max {
  background-color: transparent;
  color: #000;
  top: 65px;
}
.range .irs--round .irs-min {
  display: none;
}

@media (max-width: 991px) {
  .single-park .search-form-parks, .page-template-event-page .search-form-parks {
    border-radius: 12px !important;
    padding-top: 0 !important;
  }
}
@media (max-width: 991px) {
  .single-park .search-form-parks .arrival::before, .page-template-event-page .search-form-parks .arrival::before {
    content: none !important;
  }
}

.search-form-tabs {
  background-color: white;
  color: #7d7d7d;
  font-size: 18px;
  font-weight: 400;
  padding: 22px 44px 0 44px;
  border-radius: 20px 28px 0 0;
  text-align: center;
  display: none;
  box-shadow: 0 3.729px 27.961px 0 rgba(0, 0, 0, 0.15);
}
@media (max-width: 991px) {
  .search-form-tabs {
    width: 100%;
    max-width: 400px;
  }
}
.search-form-tabs ul {
  display: flex;
  gap: 20px;
  list-style: none;
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  padding: 0;
}
@media (max-width: 991px) {
  .search-form-tabs ul {
    justify-content: center;
  }
}
.search-form-tabs li {
  border-bottom: 5px solid transparent;
  padding-bottom: 17px;
  cursor: pointer;
  font-weight: 700;
}
@media (max-width: 767px) {
  .search-form-tabs li {
    padding-bottom: 7px;
  }
}
.search-form-tabs li[data-tab-type=flights] {
  display: none;
}
.search-form-tabs li.active {
  color: #045e51;
  border-bottom: 5px solid #045e51;
}

.home .search-form-tabs {
  display: block;
}

.page-template-search-packages form .number-of-nights {
  display: none !important;
}
.page-template-search-packages form .packages {
  display: block !important;
}
@media (max-width: 767px) {
  .page-template-search-packages form .packages .select .drop-down {
    position: relative !important;
    transform: none !important;
    padding: 0 !important;
    border-radius: 0 !important;
    min-width: unset !important;
  }
  .page-template-search-packages form .packages .select .drop-down .packages-drop-down-row {
    background-color: transparent !important;
    justify-content: center;
  }
  .page-template-search-packages form .packages .select .drop-down .close {
    display: none;
  }
}
.page-template-search-packages form .parks .select {
  border-radius: 0;
  border-right: 1px solid #d0d0d0;
}

form.search.search-parks[selected-tab=packages] .search-form {
  padding-inline: 30px;
}
@media (max-width: 767px) {
  form.search.search-parks[selected-tab=packages] .search-form {
    padding-inline: 0;
  }
}
form.search.search-parks[selected-tab=packages] .accommodation .select .label {
  padding: 0 30px 0 0;
  gap: 40px;
}
@media (max-width: 767px) {
  form.search.search-parks[selected-tab=packages] .accommodation .select .label {
    padding: 0 30px 0 15px;
  }
}
form.search.search-parks[selected-tab=packages] .arrival {
  gap: 20px;
}
@media (max-width: 767px) {
  form.search.search-parks[selected-tab=packages] .arrival {
    gap: 5px;
  }
}
form.search.search-parks[selected-tab=packages] .arrival .arrival-date {
  padding: 0;
}
form.search.search-parks[selected-tab=packages] .arrival .departure-date {
  padding: 0;
}
form.search.search-parks[selected-tab=packages] .packages {
  display: block;
}
form.search.search-parks[selected-tab=packages] .parks .select {
  border-radius: 0;
  border-right: 1px solid #d0d0d0;
}
form.search.search-parks[selected-tab=packages] .number-of-nights {
  display: none !important;
}
form.search.search-parks .packages {
  position: relative;
  display: none;
}
@media (max-width: 991px) {
  form.search.search-parks .packages {
    padding: 0;
    margin-bottom: 20px;
  }
}
form.search.search-parks .packages .select {
  height: 100%;
}
@media (max-width: 991px) {
  form.search.search-parks .packages .select {
    position: relative;
    padding-bottom: 20px;
  }
}
@media (max-width: 991px) {
  form.search.search-parks .packages .select::after {
    content: "";
    z-index: 3;
    position: absolute;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    bottom: 0;
    left: 50%;
    background: #25368e;
    opacity: 0.1;
    display: block;
    height: 1px;
  }
}
form.search.search-parks .packages .select .label {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 0 30px 0 15px;
  min-width: 260px;
  gap: 15px;
  border-radius: 0 0 30px 0;
  cursor: pointer;
  justify-content: space-between;
}
form.search.search-parks .packages .select .label:hover {
  box-shadow: inset 0 -5px 0 #045e51;
}
@media (max-width: 767px) {
  form.search.search-parks .packages .select .label {
    height: auto;
  }
  form.search.search-parks .packages .select .label:hover {
    box-shadow: unset;
  }
}
form.search.search-parks .packages .select .label .text {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
  overflow: hidden;
  white-space: nowrap;
}
@media (max-width: 479px) {
  form.search.search-parks .packages .select .label .text {
    font-size: 18px;
  }
}
form.search.search-parks .packages .select .label .arrow {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
}
@media (max-width: 767px) {
  form.search.search-parks .packages .select .label .arrow {
    pointer-events: none;
  }
}
form.search.search-parks .packages .select .label .arrow img {
  height: 30px;
}
form.search.search-parks .packages .select .label .arrow i {
  font-size: 25px;
}
form.search.search-parks .packages .select.open .arrow i {
  transform: rotate(180deg);
}
form.search.search-parks .packages .select.open .packages-drop-down {
  display: flex;
}
form.search.search-parks .packages .select .drop-down {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(calc(100% + 20px));
  background-color: white;
  min-width: 430px;
  border-radius: 5px;
  padding: 40px;
  display: none;
  flex-direction: column;
  gap: 18px;
  z-index: 1;
}
form.search.search-parks .packages .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #404040;
}
form.search.search-parks .packages .select .drop-down::before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDBMIDI1LjEyNDQgMTQuMjVIMC44NzU2NDVMMTMgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
  position: absolute;
  top: -10px;
  left: 75%;
  width: 28px;
  height: 19px;
}

.packages-drop-down-row {
  padding: 25px;
  border-radius: 5px;
  background-color: #f4f5f5;
  display: flex;
  align-items: center;
  gap: 10px;
}
.packages-drop-down-row .icon-package-wrap {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background: #e9e9e9;
}
.packages-drop-down-row .packages-drop-down-checkbox {
  border: 2px solid #7d7d7d;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
}
.packages-drop-down-row .packages-drop-down-checkbox input {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.packages-drop-down-row .packages-drop-down-checkbox .drop-down-checkbox-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: white;
  top: 0;
  left: 0;
  pointer-events: none;
}
.packages-drop-down-row .packages-drop-down-checkbox input[type=radio]:checked + .drop-down-checkbox-wrapper::after {
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU4NyAwLjYzMTMzN0MxNC4zMjg4IDAuNjQwNDUxIDE0LjA5MTggMC43NzcxIDEzLjk1NTEgMC45OTU5Mkw2LjYzOTExIDEyLjE4ODZMMS45OTUyIDguMDkzMTRDMS42OTU5NiA3Ljg4MTkgMS4zOTA2MyA3LjgwMjk5IDEuMTA5NTkgNy44OTExQzAuODMwNzk5IDcuOTc3NjkgMC42MjE5NjMgOC4yMTQ2NyAwLjU3MzUyMiA4LjUwMzMwQzAuNTI0NzQxIDguNzkzNDUgMC42NDE3MTEgOS4wODUxMSAwLjg3ODY5IDkuMjU5ODFMNi4yOTg4MyAxMy45NTA4QzYuNDY4OTcgMTQuMDk1MSA2LjY5Mzc5IDE0LjE1ODkgNi45MTQwNiAxNC4xMjdDNy4xMzU4NSAxNC4wOTM2IDcuMzMxODIgMTMuOTY3NSA3LjQ1MzM0IDEzLjc4MDZMMTUuMjY3NiAxLjg0NjYxQzE1LjQzNDcgMS42MDIwNCAxNS40NDg0IDEuMjg2MDcgMTUuMzA0IDEuMDI3ODJDMTUuMTU5NyAwLjc3MTA5NCAxNC44ODE3IDAuNjE3NjY1IDE0LjU4NyAwLjYzMTMzN1oiIGZpbGw9IiMwNDVFNTIiIHN0cm9rZT0iIzA0NUU1MiIgc3Ryb2tlLXdpZHRoPSIwLjgzMzMzMyIvPgo8L3N2Zz4=");
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  pointer-events: none;
}
.packages-drop-down-row label {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  font-size: 18px;
  font-weight: 500;
}

form.search.search-parks {
  display: flex;
  justify-content: center;
  width: 100%;
}

.event-page form.search.search-parks {
  width: fit-content;
  margin: 0 auto;
}

.search-form-parks {
  max-width: 1600px;
  border-radius: 70px;
  background: #ffffff;
  display: flex;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}
@media (max-width: 991px) {
  .search-form-parks {
    height: 50px;
  }
}
@media (max-width: 991px) {
  .search-form-parks {
    height: unset;
  }
}
.search-form-parks .calendar-clear {
  height: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
}
@media (max-width: 991px) {
  .search-form-parks .calendar-clear {
    padding: 0;
    width: 50px;
    justify-content: center;
  }
}
@media (max-width: 991px) {
  .search-form-parks {
    flex-direction: column;
    padding-top: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 20px 28px 0 rgba(0, 0, 0, 0.15);
  }
}
.search-form-parks .parks {
  border-left: 1px solid #d0d0d0;
  max-width: 270px;
  width: 100%;
}
@media (max-width: 991px) {
  .search-form-parks .parks {
    border-left: unset;
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
    cursor: pointer;
    max-width: unset;
  }
}
.search-form-parks .parks .select {
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 70px 70px 0;
  gap: 114px;
  width: 100%;
}
@media (max-width: 991px) {
  .search-form-parks .parks .select {
    pointer-events: none;
    min-width: 231px;
  }
}
.search-form-parks .parks .select.open, .search-form-parks .parks .select:hover {
  box-shadow: inset 0 -5px 0 #045e51;
}
.search-form-parks .parks .select.open .label .fa-chevron-down {
  rotate: 180deg;
}
.search-form-parks .parks .select .label {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 30px 0px 15px;
  height: 100%;
  width: 100%;
  justify-content: space-evenly;
  cursor: pointer;
}
.search-form-parks .parks .select .label::after {
  content: attr(data-tooltip);
  padding: 5px 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7098039216);
  color: #ffffff;
  pointer-events: none;
  opacity: 0;
  position: absolute;
  width: max-content;
  transform: translate(0, 0);
  top: -40px;
  right: 5px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .label::after {
    display: none;
  }
}
.search-form-parks .parks .select .label[data-show-tooltip="1"]:hover::after {
  opacity: 1;
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .label {
    gap: 8px;
    justify-content: space-between;
    width: 100%;
  }
}
.search-form-parks .parks .select .label .arrow {
  display: flex;
  align-items: center;
  gap: 22px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.search-form-parks .parks .select .label .text {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (max-width: 1264px) {
  .search-form-parks .parks .select .label .text {
    font-size: 18px;
    line-height: 1.2;
  }
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .label .text {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .label .text {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.03em;
  }
}
@media (max-width: 479px) {
  .search-form-parks .parks .select .label .text {
    font-size: 18px;
  }
}
.search-form-parks .parks .select .drop-down {
  display: none;
  max-width: 1000px;
  border-radius: 5px;
  background: #ffffff;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
}
.search-form-parks .parks .select .drop-down:before {
  content: "";
  z-index: 3;
  position: absolute;
  transform: translateX(-50%);
  top: -15px;
  right: 10%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0px 15px 15px;
}
.search-form-parks .parks .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #404040;
  z-index: 999;
}
.search-form-parks .parks .select .drop-down .content .tabs {
  display: flex;
  z-index: 999;
  height: 400px;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav {
  width: 295px;
  background-color: #e9e9e9;
  padding: 30px 30px 30px 0px;
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .drop-down .content .tabs .tabs-nav {
    width: 240px;
  }
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab {
  color: #000000;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  padding: 10px 30px;
  border-radius: 0px 18px 18px 0px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab > div {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab > div .item-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 7px;
  border-radius: 100px;
  background-color: #045e51;
  color: #ffffff;
  font-weight: 600;
  font-size: 12px;
  height: 22px;
  min-width: 22px;
  width: max-content;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab:last-child {
  margin-bottom: 0px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab::after {
  content: "\f104";
  font-family: "Font Awesome 6 Pro";
  font-weight: 300;
  font-size: 24px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-nav .tab.active {
  background-color: #ffffff;
  color: #045e51;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents {
  flex: 1;
  width: calc(100% - 295px);
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content {
  display: none;
  padding: 15px;
  position: relative;
  height: 100%;
  padding: 15px 70px;
}
@media (max-width: 991px) {
  .search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content {
    padding: 15px 35px;
  }
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content.active {
  display: flex;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations {
  flex-shrink: 0;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations > a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  display: none;
  align-items: center;
  direction: ltr;
  text-align: left;
  gap: 10px;
  margin-bottom: 16px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations > a:hover {
  color: #045e51;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations > a:hover .checkbox > label::before {
  border: 2px solid #045e51;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  padding-inline-end: 14px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul .marker_item {
  position: relative;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount {
  position: absolute;
  left: 0;
  top: 50%;
  background: #ff4380;
  padding: 6px;
  transform: translate(-100%, -50%);
  border-radius: 3px;
  color: #fff;
  font-size: 10px;
  font-weight: 700;
  display: flex;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul .marker_item .marker_item_discount > span:last-child {
  margin-right: 7px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li {
  line-height: normal;
  margin-bottom: 14px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li:last-child {
  margin-bottom: 0px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li a {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  direction: ltr;
  text-align: left;
  gap: 10px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li a:not(.disabled):hover {
  color: #045e51;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li a:not(.disabled):hover .checkbox > label::before {
  border: 2px solid #045e51;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li a.disabled {
  pointer-events: none;
  color: #abb5b7;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .locations ul li a.disabled label::before {
  border: 2px solid #d0d0d0;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps .map {
  display: inline-block;
  position: relative;
  margin: 0px;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps .map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps .map .marker {
  line-height: 1;
  transform: translate(-50%, -22.5px);
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps .map .marker::before {
  content: "\f3c5";
  font-family: "Font Awesome 6 Pro";
  font-weight: 900;
  display: inline-block;
  font-size: 30px;
  color: #045e51;
  line-height: 1;
}
.search-form-parks .parks .select .drop-down .content .tabs .tabs-contents .tab-content .maps .map .marker.active::before {
  color: #003a32;
}
.search-form-parks .search-form {
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 991px) {
  .search-form-parks .search-form {
    flex-flow: column;
    cursor: pointer;
  }
}
.search-form-parks .search-form .arrival {
  display: flex;
  align-items: center;
  height: 100%;
}
.search-form-parks .search-form .arrival #calendar-container-start-date {
  top: calc(100% + 15px);
  right: 0;
  position: absolute;
  width: 100%;
  display: none;
  background-color: #ffffff;
  padding: 50px 80px;
  z-index: 9;
}
.search-form-parks .search-form .arrival #calendar-container-start-date:before {
  content: "";
  position: absolute;
  top: -15px;
  right: 12%;
  transform: translateX(-50%);
  border-width: 0px 15px 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .error-message {
  display: none;
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  top: 15px;
  color: #ff001f;
  font-size: 18px;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #404040;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights span {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  width: 100px;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  gap: 5px;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights ul li span {
  display: flex;
  width: 49px;
  height: 39px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights ul li span.disabled {
  pointer-events: none;
  color: #7d7d7d;
  opacity: 0.3;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights ul li span:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
.search-form-parks .search-form .arrival #calendar-container-start-date .number-of-nights ul li span.active {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
.search-form-parks .search-form .arrival #calendar-container-end-date {
  top: calc(100% + 15px);
  right: 0;
  position: absolute;
  width: 100%;
  display: none;
  background-color: #ffffff;
  padding: 50px 80px;
  z-index: 999;
}
.search-form-parks .search-form .arrival #calendar-container-end-date:before {
  content: "";
  position: absolute;
  top: -15px;
  left: 48%;
  transform: translateX(-50%);
  border-width: 0px 15px 15px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #404040;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights span {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  width: 100px;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  gap: 5px;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights ul li span {
  display: flex;
  width: 49px;
  height: 39px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights ul li span:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
.search-form-parks .search-form .arrival #calendar-container-end-date .number-of-nights ul li span.active {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival {
    padding: 20px 16px;
    position: relative;
    width: 100%;
    gap: 5px;
    margin-bottom: 20px;
  }
  .search-form-parks .search-form .arrival:before, .search-form-parks .search-form .arrival:after {
    content: "";
    z-index: 3;
    position: absolute;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    top: 0px;
    left: 50%;
    background: #25368e;
    opacity: 0.1;
    display: block;
    height: 1px;
  }
  .search-form-parks .search-form .arrival:after {
    top: 100%;
  }
}
.search-form-parks .search-form .arrival .arrival-date {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0px 30px;
  color: #404040;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.641px;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .arrival .arrival-date {
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrival-date {
    padding: 0 10px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrival-date {
    gap: 5px;
    padding: 0 !important;
  }
}
.search-form-parks .search-form .arrival .arrival-date input {
  border: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.27;
  color: #000000;
  outline: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrival-date input i {
    font-size: 15px;
  }
}
.search-form-parks .search-form .arrival .arrival-date input[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .arrival .arrival-date input {
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrival-date input {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrival-date input {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.03em;
    width: 130px;
    pointer-events: none;
  }
}
@media (max-width: 479px) {
  .search-form-parks .search-form .arrival .arrival-date input {
    font-size: 18px;
  }
}
.search-form-parks .search-form .arrival .arrival-date input:not([disabled]):hover, .search-form-parks .search-form .arrival .arrival-date input:not([disabled]):focus {
  box-shadow: inset 0 -0.25rem 0 #00806c;
  color: #00806c;
}
.search-form-parks .search-form .arrival .arrival-date input box-shadow ::placeholder {
  color: #000000;
}
.search-form-parks .search-form .arrival .arrow-date {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #d0d0d0;
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .arrow-date {
    font-size: 14px;
  }
}
.search-form-parks .search-form .arrival .departure-date {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0px 30px;
  color: #d0d0d0;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.791px;
  letter-spacing: 0.641px;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .arrival .departure-date {
    padding: 0px 20px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .departure-date {
    padding: 0px 10px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .departure-date {
    padding: 0px;
  }
}
.search-form-parks .search-form .arrival .departure-date input {
  border: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.27;
  color: #000000;
  outline: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .arrival .departure-date input {
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .departure-date input {
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .arrival .departure-date input {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: 0.03em;
    width: 130px;
  }
}
@media (max-width: 479px) {
  .search-form-parks .search-form .arrival .departure-date input {
    font-size: 18px;
  }
}
.search-form-parks .search-form .arrival .departure-date input:disabled {
  color: #000000;
  cursor: not-allowed;
}
.search-form-parks .search-form .arrival .departure-date input:disabled::placeholder {
  color: #d0d0d0;
}
.search-form-parks .search-form .arrival .departure-date input:not([disabled]):hover, .search-form-parks .search-form .arrival .departure-date input:not([disabled]):focus {
  box-shadow: inset 0 -0.25rem 0 #00806c;
  color: #00806c;
}
.search-form-parks .search-form .arrival .departure-date input box-shadow ::placeholder {
  color: #000000;
}
.search-form-parks .search-form .accommodation {
  border-right: 1px solid #d0d0d0;
  height: 100%;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .accommodation {
    border-right: unset;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .accommodation {
    width: 100%;
  }
}
.search-form-parks .search-form .accommodation .select {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 114px;
  position: relative;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .search-form-parks .search-form .accommodation .select {
    justify-content: center;
    pointer-events: none;
    width: 100%;
  }
}
.search-form-parks .search-form .accommodation .select.open, .search-form-parks .search-form .accommodation .select:hover {
  box-shadow: inset 0 -5px 0 #045e51;
}
.search-form-parks .search-form .accommodation .select.open .label .fa-chevron-down {
  rotate: 180deg;
}
.search-form-parks .search-form .accommodation .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0px 60px 0px 30px;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .accommodation .select .label {
    padding: 0 30px 0 15px;
    gap: 40px;
  }
}
@media (max-width: 991px) {
  .search-form-parks .search-form .accommodation .select .label {
    margin-bottom: 20px;
    gap: 100px;
    width: 100%;
    justify-content: space-between;
  }
}
.search-form-parks .search-form .accommodation .select .label .arrow {
  display: flex;
  align-items: center;
  gap: 22px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.search-form-parks .search-form .accommodation .select .label .text {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
@media (max-width: 1264px) {
  .search-form-parks .search-form .accommodation .select .label .text {
    font-size: 18px;
    line-height: 1.2;
  }
}
@media (max-width: 767px) {
  .search-form-parks .search-form .accommodation .select .label .text {
    font-size: 22px;
  }
}
@media (max-width: 479px) {
  .search-form-parks .search-form .accommodation .select .label .text {
    font-size: 18px;
  }
}
.search-form-parks .search-form .accommodation .select .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: 416px;
  flex-shrink: 0;
  position: absolute;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  padding: 25px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .search-form-parks .search-form .accommodation .select .drop-down {
    width: 100%;
  }
}
.search-form-parks .search-form .accommodation .select .drop-down:before {
  content: "";
  z-index: 3;
  position: absolute;
  transform: translateX(-50%);
  top: -15px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0px 15px 15px;
}
.search-form-parks .search-form .accommodation .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
  font-size: 16px;
  color: #404040;
}
.search-form-parks .search-form .accommodation .select .drop-down .options {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .seniors,
.search-form-parks .search-form .accommodation .select .drop-down .options .adults,
.search-form-parks .search-form .accommodation .select .drop-down .options .children,
.search-form-parks .search-form .accommodation .select .drop-down .options .pets {
  display: flex;
  justify-content: space-between;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children {
  flex-wrap: wrap;
  gap: 12px;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  flex-direction: column;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .tip {
  display: block;
  min-width: 100px;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
  color: #D43C33;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages select {
  height: 46px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #aaa;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages select.error-val + .chosen-container .chosen-single {
  border: 1px solid #D43C33;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages .chosen-container {
  width: 100% !important;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages .chosen-single span {
  padding: 10px 8px;
}
.search-form-parks .search-form .accommodation .select .drop-down .options .children .children-ages .ages .chosen-results {
  max-height: 180px;
}
.search-form-parks .submit button {
  display: flex;
  width: 207px;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 13.335px;
  flex-shrink: 0;
  border-radius: 70px 0px 0px 70px;
  background: #ff4380;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  outline: none;
  border: 0px;
  cursor: pointer;
}
@media (max-width: 1264px) {
  .search-form-parks .submit button {
    width: 180px;
    font-size: 18px;
    line-height: 1.2;
  }
}
@media (max-width: 991px) {
  .search-form-parks .submit button {
    font-size: 16px;
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    height: 70px;
  }
}
.search-form-parks.without-parks, .search-form-parks.event-page {
  max-width: 1005px;
}
.search-form-parks.without-parks .search-form .arrival .arrival-date, .search-form-parks.event-page .search-form .arrival .arrival-date {
  padding: 0 35px;
}

.search-form-accommodations {
  border-radius: 70px;
  background: #ffffff;
  display: inline-flex;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  position: relative;
}
@media (max-width: 767px) {
  .search-form-accommodations {
    flex-direction: column;
    border-radius: 12px;
    gap: 20px 0;
    padding-top: 20px;
  }
}
.search-form-accommodations .arrival {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 767px) {
  .search-form-accommodations .arrival {
    flex-direction: column;
    padding: 20px 0;
    position: relative;
  }
  .search-form-accommodations .arrival:before, .search-form-accommodations .arrival:after {
    content: "";
    z-index: 3;
    position: absolute;
    width: calc(100% - 32px);
    transform: translateX(-50%);
    top: 0px;
    left: 50%;
    background: #25368e;
    opacity: 0.1;
    display: block;
    height: 1px;
  }
  .search-form-accommodations .arrival:after {
    top: 100%;
  }
}
.search-form-accommodations .arrival .arrival-date {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0px 60px;
  color: #404040;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.641px;
}
.search-form-accommodations .arrival .arrow-date {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #d0d0d0;
}
@media (max-width: 767px) {
  .search-form-accommodations .arrival .arrow-date {
    transform: rotate(-90deg);
  }
}
.search-form-accommodations .arrival .departure-date {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  padding: 0px 60px;
  color: #d0d0d0;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.791px;
  letter-spacing: 0.641px;
}
.search-form-accommodations .accommodation {
  border-right: 1px solid #d0d0d0;
}
.search-form-accommodations .accommodation .select {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 114px;
  padding: 0px 60px 0px 30px;
  position: relative;
}
@media (max-width: 767px) {
  .search-form-accommodations .accommodation .select {
    justify-content: center;
  }
}
.search-form-accommodations .accommodation .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  cursor: pointer;
}
.search-form-accommodations .accommodation .select .label .arrow {
  display: flex;
  align-items: center;
  gap: 22px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.search-form-accommodations .accommodation .select .label .text {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.search-form-accommodations .accommodation .select .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: 416px;
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  margin-top: 10px;
  padding: 25px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .search-form-accommodations .accommodation .select .drop-down {
    width: 100%;
  }
}
.search-form-accommodations .accommodation .select .drop-down:before {
  content: "";
  z-index: 3;
  position: absolute;
  transform: translateX(-50%);
  top: -10px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
}
.search-form-accommodations .accommodation .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.search-form-accommodations .accommodation .select .drop-down .options {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.search-form-accommodations .accommodation .select .drop-down .options .seniors {
  display: flex;
  justify-content: space-between;
}
.search-form-accommodations .accommodation .select .drop-down .options .adults {
  display: flex;
  justify-content: space-between;
}
.search-form-accommodations .accommodation .select .drop-down .options .children {
  display: flex;
  justify-content: space-between;
}
.search-form-accommodations .accommodation .select .drop-down .options .pets {
  display: flex;
  justify-content: space-between;
}
.search-form-accommodations .submit button {
  display: flex;
  width: 207px;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 13.335px;
  flex-shrink: 0;
  border-radius: 70px 0px 0px 70px;
  background: #ff4380;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
  outline: none;
  border: 0px;
}
@media (max-width: 575px) {
  .search-form-accommodations .submit button {
    width: 100%;
    border-radius: 0px 0px 12px 12px;
    height: 70px;
  }
}

.pika-single {
  z-index: 9999;
  display: block;
  position: relative;
  color: #333;
  background: #fff;
  border-bottom-color: #bbb;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: none;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 0px -50px;
}
.pika-single.is-hidden {
  display: none;
}
.pika-single.is-bound {
  position: absolute;
  box-shadow: 0 5px 15px -5px rgba(0, 0, 0, 0.5);
}

.pika-single:before, .pika-single:after {
  content: " ";
  display: table;
}
.pika-single:after {
  clear: both;
}

.pika-lendar {
  width: 50%;
  margin: 0px;
  padding: 0px;
}

.pika-title {
  position: relative;
  text-align: center;
}
.pika-title select {
  cursor: pointer;
  position: absolute;
  z-index: 9998;
  margin: 0;
  left: 0;
  top: 5px;
  opacity: 0;
}

.pika-label {
  display: inline-block;
  position: relative;
  z-index: 9999;
  overflow: hidden;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: #333;
  background-color: #fff;
}

.pika-prev,
.pika-next {
  display: block;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 0;
  padding: 0;
  width: 20px;
  height: 30px;
  text-indent: 20px;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  opacity: 0.5;
}
.pika-prev:hover,
.pika-next:hover {
  opacity: 1;
}
.pika-prev.is-disabled,
.pika-next.is-disabled {
  cursor: default;
  opacity: 0.2;
}

.pika-prev,
.is-rtl .pika-next {
  float: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}

.pika-next,
.is-rtl .pika-prev {
  float: right;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}

.pika-select {
  display: inline-block;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
}
.pika-table td {
  padding-top: 14.5%;
  position: relative;
  width: 14.5%;
}
.pika-table th {
  color: #999;
  font-size: 12px;
  line-height: 25px;
  font-weight: bold;
  text-align: center;
}
.pika-table abbr {
  border-bottom: none;
  cursor: help;
}

.pika-button {
  cursor: pointer;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  color: #666;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  background: #ffffff;
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 100%;
  position: absolute;
  top: 0px;
}
.has-event .pika-button {
  color: #fff;
  background: #33aaff;
}
.is-today .pika-button {
  color: #33aaff;
  font-weight: bold;
}
.is-selected .pika-button {
  color: #fff;
  background: #045e51;
  border-radius: 50%;
}
.is-disabled .pika-button, .is-outside-current-month .pika-button {
  color: #999;
  opacity: 0.3;
}
.is-disabled .pika-button {
  pointer-events: none;
  cursor: default;
}
.is-disabled.is-inrange .pika-button {
  color: #cdcdcd !important;
}
.pika-button:hover {
  box-shadow: none;
  border: 1px solid #045e51;
  border-radius: 50%;
}
.pika-button .is-selection-disabled {
  pointer-events: none;
  cursor: default;
}

.pika-week {
  font-size: 11px;
  color: #999;
}

.is-inrange .pika-button {
  color: #666;
  background: #d7ffd0;
  opacity: 1 !important;
  border-radius: 0px;
}

.is-startrange {
  background-color: #d7ffd0;
  background-image: linear-gradient(270deg, #fff, #fff 50%, #d7ffd0 50.1%, #d7ffd0);
}
.is-startrange .pika-button {
  background: #045e51;
  color: #fff;
  box-shadow: none;
  border-radius: 50%;
  opacity: 1 !important;
}

.is-endrange {
  background-color: #d7ffd0;
  background-image: linear-gradient(90deg, #fff, #fff 50%, #d7ffd0 50.1%, #d7ffd0);
}
.is-endrange .pika-button {
  background: #045e51;
  color: #fff;
  box-shadow: none;
  border-radius: 50%;
}

.is-startrange.is-endrange {
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
}

.pika-table {
  direction: ltr;
}

.mfp-container,
.mfp-content {
  direction: rtl;
  text-align: right;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close,
.mfp-ajax-holder .mfp-close {
  right: auto;
  left: 0;
}

.mfp-image-holder .mfp-figure {
  padding-right: 0;
  padding-left: 40px; /* Adjust as needed */
}

.mfp-figure figcaption {
  text-align: right;
}

.mfp-bottom-bar {
  text-align: right;
}

.mfp-counter {
  margin-right: 10px;
}

.mfp-title {
  text-align: right;
}

#addon-popup {
  padding: 20px 20px 20px 40px;
  max-width: 900px;
  width: 100%;
  background: #ffffff;
  align-self: center;
  border-radius: 9px;
  position: relative;
  margin: 40px auto;
}
@media (max-width: 991px) {
  #addon-popup {
    margin: 40px auto 0;
    border-radius: 9px 9px 0 0;
  }
}
#addon-popup .mfp-close {
  display: inline-flex;
  position: absolute;
  top: 22px;
  inset-inline-end: 22px;
  width: 20px;
  height: 20px;
}
#addon-popup .addon-popup-container {
  display: flex;
  gap: 30px;
}
#addon-popup .addon-popup-container .addon-popup-image {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  #addon-popup .addon-popup-container .addon-popup-image {
    display: none;
  }
}
#addon-popup .addon-popup-container .addon-popup-image img {
  border-radius: 10px;
  width: 500px;
  object-fit: cover;
}
#addon-popup .addon-popup-container .addon-popup-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-header {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-header .addon-popup-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.03em;
  color: #404040;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-header .addon-popup-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: 0.03em;
  color: #404040;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .price-container {
  display: flex;
  flex-direction: column;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .price-container .variations-item-name {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  color: #404040;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .price-container .variations-item-price {
  font-size: 16px;
  font-weight: 700;
  line-height: 21.76px;
  color: #404040;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container {
  position: relative;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .price-result {
  position: absolute;
  inset-inline-end: 0;
  bottom: calc(100% + 5px);
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  color: #045e51;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity {
  display: flex;
  align-items: center;
  gap: 12px;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity input {
  width: min-content;
  border: unset;
  font-size: 20px;
  font-weight: 500;
  line-height: 27.2px;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: transparent;
  border-radius: 18px;
  border: 1px solid #404040;
  color: #404040;
  cursor: pointer;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity button:disabled {
  border: 1px solid #D0D0D0;
  color: #D0D0D0;
  fill: #D0D0D0;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity button:disabled img {
  opacity: 0.5;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity button:disabled svg path {
  fill: #D0D0D0;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity .quantity-error {
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  width: max-content;
  transform: translateX(-50%);
  padding: 4px 8px;
  background: #FAFCFC;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1490196078);
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity .quantity-error:before {
  position: absolute;
  content: "";
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: 11px;
  height: 5px;
  background-image: url('data:image/svg+xml,<svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 0.000488281L0 5.00049L11 5.00049L5.5 0.000488281Z" fill="%23FAFCFC"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .variations-list .variations-item .quantity-container .quantity .quantity-error .quantity-error-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: -0.017em;
  text-align: center;
  color: #D43C33;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .add-to-cart-button {
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.03em;
}
#addon-popup .addon-popup-container .addon-popup-info .addon-popup-info-add-to-cart .add-to-cart-button:disabled {
  border: unset;
  background: #e9e9e9;
  color: #9EA0A2;
}

.agent-icon {
  position: fixed;
  left: 0;
  z-index: 8;
  top: 50%;
  background: #f3f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 5px 5px 0;
  padding: 24px;
  max-width: 170px;
  text-align: center;
  gap: 10px;
  border-right: 3px solid #045e51;
  font-weight: 600;
  line-height: 1.5em;
  color: #404040;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.1490196078);
  font-size: calc(12px + 6 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .agent-icon {
    font-size: 12px;
  }
}
@media screen and (min-width: 1200px) {
  .agent-icon {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  .agent-icon {
    max-width: 104px;
    padding: 12px 16px;
  }
}

.login-popup {
  opacity: 0;
  pointer-events: none;
  background: rgba(0, 0, 0, 0.4588235294);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  z-index: 11;
  overflow-y: auto;
}
@media (max-width: 575px) {
  .login-popup {
    z-index: 9;
    padding-top: 120px;
    height: 100dvh;
  }
}
.login-popup .error-login {
  color: #ff001f;
  text-align: center;
  display: none;
}
.login-popup .popup {
  background: white;
  position: absolute;
  left: 0;
  height: 100dvh;
  max-width: 580px;
  transform: translateX(-100%);
  transition: all 500ms ease-in-out;
}
.login-popup .popup > div {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.login-popup .popup h5 {
  color: #404040;
  font-weight: 300;
  font-size: calc(18px + 4 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .login-popup .popup h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .login-popup .popup h5 {
    font-size: 22px;
  }
}
.login-popup .popup .popup-img {
  position: relative;
}
.login-popup .popup .popup-img.agent h4 {
  bottom: 45px;
}
.login-popup .popup .popup-img .close-login-popup {
  position: absolute;
  left: 0;
  cursor: pointer;
  opacity: 0.6;
  transition: all 200ms linear;
}
@media (max-width: 575px) {
  .login-popup .popup .popup-img .close-login-popup {
    top: 16px;
    left: 16px;
  }
}
.login-popup .popup .popup-img .close-login-popup path {
  pointer-events: none;
}
.login-popup .popup .popup-img .close-login-popup:hover {
  opacity: 1;
}
.login-popup .popup .popup-img h4 {
  position: absolute;
  color: white;
  bottom: 45px;
  right: 30px;
  font-weight: 700;
  font-size: calc(32px + 3 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .login-popup .popup .popup-img h4 {
    font-size: 32px;
  }
}
@media screen and (min-width: 1200px) {
  .login-popup .popup .popup-img h4 {
    font-size: 35px;
  }
}
.login-popup .popup .popup-img .user-type {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  transform: translateY(50%);
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  z-index: 9;
}
@media (max-width: 575px) {
  .login-popup .popup .popup-img .user-type {
    padding: 0 20px;
  }
}
.login-popup .popup .popup-img .user-type ul {
  background: #F3F5F5;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 8px;
  list-style: none;
  padding: 0;
  overflow: hidden;
}
.login-popup .popup .popup-img .user-type ul li {
  padding: 20px 10px 15px;
  color: #404040;
  border-bottom: 5px solid transparent;
  cursor: pointer;
}
.login-popup .popup .popup-img .user-type ul li.active {
  color: #045E51;
  font-size: 22px;
  font-weight: 700;
  border-bottom: 5px solid #045E51;
  font-size: calc(20px + 2 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .login-popup .popup .popup-img .user-type ul li.active {
    font-size: 20px;
  }
}
@media screen and (min-width: 1200px) {
  .login-popup .popup .popup-img .user-type ul li.active {
    font-size: 22px;
  }
}
.login-popup .popup .login-form-wrap {
  display: flex;
  flex-direction: column;
  padding: 50px 30px 30px 30px;
  gap: 20px;
  background-color: #ffffff;
}
@media (max-width: 575px) {
  .login-popup .popup .login-form-wrap {
    padding: 50px 20px 20px 20px;
  }
}
.login-popup .popup .login-form-wrap form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.login-popup .popup .login-form-wrap form .chosen-container {
  width: 100% !important;
}
.login-popup .popup .login-form-wrap form .chosen-container .chosen-drop {
  max-height: 200px;
}
.login-popup .popup .login-form-wrap form .fr-fr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}
@media (max-width: 575px) {
  .login-popup .popup .login-form-wrap form .fr-fr {
    grid-template-columns: 1fr;
  }
}
.login-popup .popup .login-form-wrap form .half-fr {
  display: grid;
  grid-template-columns: 1fr 30%;
  gap: 16px;
}
@media (max-width: 575px) {
  .login-popup .popup .login-form-wrap form .half-fr {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 575px) {
  .login-popup .popup .login-form-wrap form .half-fr.first {
    grid-template-columns: 1fr 1fr;
  }
}
.login-popup .popup .login-form-wrap form label {
  color: #515358;
  line-height: 30px;
  position: relative;
}
.login-popup .popup .login-form-wrap form label svg {
  position: absolute;
  left: 10px;
  top: 40px;
  cursor: pointer;
}
.login-popup .popup .login-form-wrap form label.checkbox {
  flex-direction: row-reverse;
  gap: 8px;
  align-items: flex-start;
  justify-content: flex-end;
}
.login-popup .popup .login-form-wrap form input {
  color: #515358;
  padding-inline-end: 30px;
}
.login-popup .popup .login-form-wrap form input::placeholder {
  color: #515358;
  opacity: 0.6;
}
.login-popup .popup .login-form-wrap form .checkbox-custom {
  position: relative;
  width: 24px;
  height: 24px;
  min-width: 24px;
  cursor: pointer;
}
.login-popup .popup .login-form-wrap form .checkbox-custom input[type=checkbox] {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: block;
  cursor: pointer;
}
.login-popup .popup .login-form-wrap form .checkbox-custom input[type=checkbox] + div {
  pointer-events: none;
}
.login-popup .popup .login-form-wrap form .checkbox-custom input[type=checkbox] + div:before {
  content: "";
  display: inline-block;
  width: 100%;
  height: 24px;
  border: 2px solid #abb5b7;
  margin-left: 14px;
  border-radius: 5px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  vertical-align: middle;
}
.login-popup .popup .login-form-wrap form .checkbox-custom input[type=checkbox]:checked + div:after {
  position: absolute;
  right: 4px;
  font-weight: 700;
  color: #045e51;
  font-size: 16px;
  content: "\f00c";
  font-family: Font Awesome\ 6 Pro;
  width: 16px;
  height: 16px;
  line-height: 1;
  text-align: center;
  top: 8px;
}
.login-popup.active {
  opacity: 1;
  pointer-events: painted;
}
.login-popup.active .popup {
  transform: translateX(0);
}
.login-popup.active .popup.hide {
  transform: translateX(-100%);
}
.login-popup.active button {
  font-size: 17px;
}
.login-popup.active button.btn-white {
  border: 1px solid #045E51;
  color: #045E51;
}
.login-popup.active .forgot-password, .login-popup.active .forgot-password-agent, .login-popup.active .to-login:not(.btn-green), .login-popup.active .to-login-agent:not(.btn-green) {
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  color: #045E51;
}
.login-popup.active .to-login.btn-green {
  font-size: 17px;
}
.login-popup .btn-green {
  margin-top: 20px;
}

#header #logout i {
  color: white;
  font-size: 30px;
  font-weight: 100;
}

.button.representative .btn-pink {
  font-size: 18px;
  width: 100%;
  margin-bottom: 26px;
}

.representative-text {
  direction: ltr;
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.representative-text p {
  font-size: 14px;
  font-weight: 200;
  line-height: 1.4em;
}

@media (max-width: 991px) {
  #pdf-container .check-out {
    flex-direction: row;
  }
}
#pdf-container .check-out .button.agent-btn {
  display: none;
}
#pdf-container .check-out .check-out-sidebar {
  box-shadow: none;
  border-bottom: 1px solid black;
}
@media (max-width: 991px) {
  #pdf-container .check-out .check-out-sidebar {
    width: 350px;
  }
}
#pdf-container .check-out .location-preferences, #pdf-container .check-out .services {
  box-shadow: none;
  border-bottom: 1px solid black;
}
#pdf-container .check-out .steps {
  box-shadow: none;
  border-bottom: 1px solid black;
}
@media (max-width: 991px) {
  #pdf-container .check-out .steps {
    justify-content: space-around;
  }
}
@media (max-width: 991px) {
  #pdf-container .check-out .steps .title {
    display: block;
  }
}
@media (max-width: 991px) {
  #pdf-container .check-out .steps .fa {
    display: block;
  }
}
@media (max-width: 991px) {
  #pdf-container .check-out .button.mobile {
    display: none;
  }
}
@media (max-width: 1264px) {
  #pdf-container .check-out .locations {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
  }
}
@media (max-width: 991px) {
  #pdf-container .check-out .head .button {
    display: block;
  }
}
#pdf-container .check-out .chosen-container .chosen-single {
  box-shadow: none;
  border: 1px solid black;
}
@media (max-width: 991px) {
  #pdf-container .check-out .chosen-container .chosen-single {
    border-radius: 70px !important;
    border: 1px solid black;
  }
}
#pdf-container .check-out .service .head {
  box-shadow: none;
  border: 1px solid black;
}
#pdf-container #footer {
  display: none;
}
#pdf-container #footer .footer-top > .container-md {
  display: none;
}
@media (max-width: 1264px) {
  #pdf-container #footer .navigation {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 991px) {
  #pdf-container #footer .navigation {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media (max-width: 767px) {
  #pdf-container #footer .content {
    padding: 40px 46px 40px 13px;
  }
}

.accommodation-box {
  border-radius: 10px;
  background-color: #ffffff;
  height: 420px;
  display: flex !important;
  flex-direction: column;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  overflow: hidden;
}
@media (max-width: 767px) {
  .accommodation-box {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    height: auto;
  }
}
.accommodation-box .image {
  position: relative;
}
.accommodation-box .image img {
  width: 100%;
  height: 206px;
  object-fit: cover;
  object-position: 50% 50%;
  border-radius: 10px 10px 0 0;
}
.accommodation-box .content {
  padding: 10px 30px;
}
@media (max-width: 767px) {
  .accommodation-box .content {
    padding: 10px 15px;
  }
}
.accommodation-box .content .info .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 15px;
  margin-bottom: 5px;
}
.accommodation-box .content .info .title a {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
}
@media (max-width: 767px) {
  .accommodation-box .content .info .title a {
    color: #404040;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.72px;
  }
}
.accommodation-box .content .info .title .people {
  display: flex;
  gap: 0 4px;
  align-items: center;
}
@media (max-width: 767px) {
  .accommodation-box .content .info .title .people {
    font-size: 14px;
  }
  .accommodation-box .content .info .title .people img {
    height: 16px;
  }
}
.accommodation-box .content .info .description {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: right;
  height: 45px;
  overflow: hidden;
}
.accommodation-box .content .info .description p {
  margin-bottom: 12px;
}
.accommodation-box .content .info .description ul {
  display: flex;
  flex-direction: column;
  gap: 0px;
  margin: 0;
}
.accommodation-box .content .period {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.03em;
  margin-bottom: 2px;
}
.accommodation-box .content .cost {
  display: flex;
  flex-direction: column;
  transform: translateY(-2px);
}
.accommodation-box .content .cost .price {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  gap: 0 6px;
}
@media (max-width: 767px) {
  .accommodation-box .content .cost .price {
    margin-top: 0;
    width: calc(50% - 10px);
    margin: 0;
  }
}
.accommodation-box .content .cost .price .summa {
  display: flex;
  align-items: flex-end;
  gap: 0 5px;
}
.accommodation-box .content .cost .price .summa .now {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(232, 84, 129);
  text-align: center;
  font-size: 28.844px;
  font-weight: 700;
  line-height: 37.497px;
  letter-spacing: 0.03em;
}
.accommodation-box .content .cost .price .summa .now i {
  color: #7d7d7d;
  font-size: 16px;
  transform: translate(-8px, -2px);
}
.accommodation-box .content .cost .price .summa .now .currency {
  font-size: 25px;
  color: rgb(255, 67, 128);
  transform: translateY(-2px);
  font-weight: 400;
}
.accommodation-box .content .cost .price .summa .now .number {
  line-height: 1;
}
.accommodation-box .content .cost .price .summa .was {
  display: flex;
  gap: 5px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
  text-decoration: line-through;
}
.accommodation-box .content .cost .price .summa .was .currency {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  transform: translateY(-2px);
}
.accommodation-box .content .cost .price .price-per-stay {
  color: #404040;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  transform: translateY(3px);
}

/* __________ COMPONENTS  END __________*/
/* __________ MODAL  BEGIN __________*/
.modal-activities {
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  gap: 30px;
  direction: rtl;
}
@media (max-width: 767px) {
  .modal-activities {
    display: block;
  }
}
.modal-activities.white-popup {
  position: relative;
  background: #ffffff;
  padding: 22px;
  width: auto;
  max-width: 1130px;
  margin: 0 auto;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.modal-activities .text {
  margin: 20px 0px;
  text-align: right;
}
.modal-activities .text .title {
  margin-bottom: 14px;
}
.modal-activities .text .tags {
  margin-bottom: 14px;
}
.modal-activities .text .tags ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
}
.modal-activities .text .tags ul li {
  padding: 10px 20px;
  border-radius: 100px;
  background: #f3f5f5;
  direction: ltr;
}
.modal-activities .text .price {
  margin-bottom: 14px;
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.modal-activities .text .price .title {
  color: #404040;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
  margin: 0px;
}
.modal-activities .text .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.modal-activities .text .price .description {
  color: #404040;
}
.modal-activities .thumbnail img {
  object-fit: cover;
  width: 100%;
  height: 100;
}

.mfp-close {
  left: 0;
  right: inherit;
  font-size: 30px;
}

.page-template-search-packages #modal-search.modal-search .number-of-nights {
  display: none !important;
}

#modal-search.modal-search {
  display: none;
  z-index: 15;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100dvh;
  background: #ffffff;
  color: #404040;
  flex-direction: column;
}
#modal-search.modal-search.active {
  display: flex;
}
#modal-search.modal-search.without-parks .search-body .item.parcs, #modal-search.modal-search.event-page .search-body .item.parcs {
  display: none;
}
#modal-search.modal-search .search-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #e9e9e9;
}
#modal-search.modal-search .search-header .title {
  font-size: 25px;
  font-weight: 700;
  line-height: 34px;
}
#modal-search.modal-search .search-header .close-popup {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: 25px;
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40765 0L0.0449251 1.36614L7.63727 8.97748L0 16.6489L1.34775 18L9 10.3436L16.6373 18L18 16.6339L10.3627 8.97748L17.9551 1.36614L16.5923 0L9 7.61134L1.40765 0Z' fill='%23404040'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
#modal-search.modal-search .search-header .back-arrow {
  position: absolute;
  width: 28px;
  height: 28px;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.418 14L18.5391 19.8789L17.3086 18.6211L21.0547 14.875L3.5 14.875L3.5 13.125L21.0547 13.125L17.3086 9.37891L18.5391 8.12109L24.418 14Z' fill='%23404040'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(0, -50%);
  top: 50%;
  right: 25px;
  cursor: pointer;
}
#modal-search.modal-search .search-body {
  display: flex;
  flex-flow: column;
  gap: 20px;
  width: 100%;
  padding: 25px 10px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
}
#modal-search.modal-search .search-body > .item {
  display: flex;
  width: 100%;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  padding: 15px;
  height: 86px;
  border-radius: 8px;
  cursor: pointer;
}
#modal-search.modal-search .search-body > .item.packages {
  display: none;
}
#modal-search.modal-search .search-body > .item.packages.active {
  display: flex;
}
#modal-search.modal-search .search-body > .item.packages, #modal-search.modal-search .search-body > .item.parcs, #modal-search.modal-search .search-body > .item.accommodation {
  position: relative;
}
#modal-search.modal-search .search-body > .item.packages::after, #modal-search.modal-search .search-body > .item.parcs::after, #modal-search.modal-search .search-body > .item.accommodation::after {
  content: "";
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  left: 35px;
  background-image: url("data:image/svg+xml,%3Csvg width='9' height='18' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.189884 9.49395L7.89771 17.7964C8.15025 18.0683 8.55912 18.0679 8.81123 17.795C9.06315 17.5223 9.0625 17.0804 8.80993 16.8083L1.56089 8.99997L8.81019 1.19165C9.06273 0.919544 9.06338 0.477949 8.81149 0.205137C8.68511 0.0683804 8.51954 0 8.35397 0C8.18882 0 8.0239 0.0679226 7.89775 0.20373L0.189884 8.50602C0.0682526 8.63673 0 8.81462 0 8.99997C0 9.18531 0.0684481 9.36299 0.189884 9.49395Z' fill='%23404040'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 9px;
  height: 18px;
}
#modal-search.modal-search .search-body > .item .text-info {
  display: flex;
  flex-flow: column;
  row-gap: 5px;
  width: 100%;
}
#modal-search.modal-search .search-body > .item .text-info .title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
#modal-search.modal-search .search-body > .item .text-info .text {
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
}
#modal-search.modal-search .search-body > .item .text-info .text.calendar-dates {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  gap: 24px;
}
#modal-search.modal-search .search-body > .item .text-info .text.calendar-dates .arrival-date {
  width: 120px;
  margin-inline-end: 5vw;
}
#modal-search.modal-search .search-body > .item .text-info .text.calendar-dates .departure-date {
  width: 120px;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  color: #d0d0d0;
}
#modal-search.modal-search .search-body > .item .text-info .text.calendar-dates .departure-date.active {
  color: #404040;
}
#modal-search.modal-search .search-body > .item .text-info .text.calendar-dates .arrow {
  width: 28px;
  height: 28px;
  background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.58203 14L9.46094 19.8789L10.6914 18.6211L6.94531 14.875L24.5 14.875L24.5 13.125L6.94531 13.125L10.6914 9.37891L9.46094 8.12109L3.58203 14Z' fill='%23D0D0D0'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
#modal-search.modal-search .search-footer {
  display: flex;
  align-items: center;
  padding: 0 10px;
  box-shadow: 0px 3.728569746px 27.9607849121px 0px rgba(0, 0, 0, 0.1490196078);
  background-color: #ffffff;
  height: 85px;
  min-height: 85px;
  width: 100%;
  z-index: 1;
  position: relative;
}
#modal-search.modal-search .search-footer .submit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
  color: #ffffff;
  appearance: none;
  width: 100%;
  cursor: pointer;
  background-color: #ff4380;
  border: none;
}
#modal-search.modal-search > .search-section {
  position: absolute;
  background-color: #ffffff;
  top: 0;
  left: 100%;
  width: 100%;
  transition: left 0.3s;
  display: flex;
  flex-direction: column;
  height: 100dvh !important;
}
#modal-search.modal-search > .search-section .search-body {
  flex-grow: 1;
}
#modal-search.modal-search > .search-section.active {
  left: 0;
}
#modal-search.modal-search > .search-section.parcs {
  z-index: 2;
  height: 100%;
}
#modal-search.modal-search > .search-section.parcs .search-body {
  gap: 15px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion {
  display: flex;
  flex-flow: column;
  background-color: #ffffff;
  border: 1px solid #f3f5f5;
  border-radius: 7px;
  width: 100%;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .parc-title {
  position: relative;
  padding: 14px 16px 15px;
  padding-inline-end: 50px;
  background-color: #f3f5f5;
  font-size: 17px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.03em;
  cursor: pointer;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .parc-title .arrow {
  position: absolute;
  translate: 0 -50%;
  top: 50%;
  left: 22px;
  background-image: url("data:image/svg+xml,%3Csvg width='17' height='8' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.0335 7.83121L0.192242 0.979811C-0.064517 0.755333 -0.0640854 0.391897 0.19357 0.167795C0.451192 -0.0561333 0.868521 -0.0555547 1.12548 0.168953L8.50003 6.61254L15.8745 0.168721C16.1315 -0.0557573 16.5486 -0.0563359 16.8063 0.167564C16.9354 0.279904 17 0.427078 17 0.574252C17 0.721049 16.9359 0.867644 16.8076 0.979782L8.96653 7.83121C8.84308 7.93933 8.67508 8 8.50003 8C8.32499 8 8.15718 7.93916 8.0335 7.83121Z' fill='%23404040'/%3E%3C/svg%3E%0A");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 17px;
  height: 8px;
  transition: all 0.3s;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations {
  padding: 25px 30px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations > a {
  color: #000000;
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  align-items: center;
  direction: ltr;
  text-align: left;
  gap: 10px;
  margin-bottom: 22px;
  display: none;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations > a:hover {
  color: #045e51;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations > a:hover .checkbox > label::before {
  border: 2px solid #045e51;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  padding-inline-end: 14px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li {
  line-height: normal;
  margin-bottom: 19px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li:last-child {
  margin-bottom: 0px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li a {
  color: #000000;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  direction: ltr;
  text-align: left;
  gap: 10px;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li a:not(.disabled):hover {
  color: #045e51;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li a:not(.disabled):hover .checkbox > label::before {
  border: 2px solid #045e51;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li a.disabled {
  pointer-events: none;
  color: #abb5b7;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion .locations ul li a.disabled label::before {
  border: 2px solid #d0d0d0;
}
#modal-search.modal-search > .search-section.parcs .search-body .accordion.active .parc-title .arrow {
  transform: rotate(180deg);
}
#modal-search.modal-search > .search-section.accommodation {
  z-index: 2;
  height: 100%;
}
#modal-search.modal-search > .search-section.accommodation .search-body {
  padding: 20px 10px;
  gap: 29px;
}
#modal-search.modal-search > .search-section.accommodation .search-body .seniors,
#modal-search.modal-search > .search-section.accommodation .search-body .adults,
#modal-search.modal-search > .search-section.accommodation .search-body .children,
#modal-search.modal-search > .search-section.accommodation .search-body .pets {
  display: flex;
  justify-content: space-between;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children {
  flex-wrap: wrap;
  gap: 10px;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages {
  display: flex;
  justify-content: space-between;
  gap: 6px;
  width: 100%;
  flex-direction: column;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .tip {
  display: block;
  min-width: 100px;
  white-space: normal;
  font-size: 14px;
  font-weight: 500;
  color: #D43C33;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages .chosen-container {
  width: 100% !important;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages select {
  width: 70px;
  height: 46px;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #aaa;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages .chosen-single span {
  padding: 10px 8px;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages .chosen-results {
  max-height: 180px;
}
#modal-search.modal-search > .search-section.accommodation .search-body .children .children-ages .ages .chosen-results .active-result {
  padding: 10px 15px;
}
#modal-search.modal-search > .search-section.calendar {
  z-index: 2;
  height: 100%;
}
#modal-search.modal-search > .search-section.calendar .search-body {
  align-items: center;
  gap: 0;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights {
  margin-bottom: 30px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  max-width: max-content;
  gap: 8px;
  width: 100%;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights span {
  color: #404040;
  font-size: 14px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 5px;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights ul li span {
  display: flex;
  width: 49px;
  height: 39px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background-color: #ffffff;
  border: 1px solid #d0d0d0;
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights ul li span.disabled {
  pointer-events: none;
  color: #7d7d7d;
  opacity: 0.3;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights ul li span:hover {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
#modal-search.modal-search > .search-section.calendar .search-body .number-of-nights ul li span.active {
  cursor: pointer;
  color: #ffffff;
  background-color: #045e51;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 42px;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .calendar-clear {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  width: 30px;
  height: 100%;
  cursor: pointer;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrow {
  font-size: 26px;
  color: #d0d0d0;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrival-date,
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date {
  position: relative;
  cursor: pointer;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrival-date.active input,
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrival-date.active input::placeholder,
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.active input,
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.active input::placeholder {
  color: #045e51;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrival-date.active::after,
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.active::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #045e51;
  cursor: default;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .arrival-date input {
  border: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.26;
  color: #000000;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;
  pointer-events: none;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.disabled {
  cursor: not-allowed;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.disabled input,
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date.disabled input::placeholder {
  color: #d0d0d0;
}
#modal-search.modal-search > .search-section.calendar .search-body .dates .departure-date input {
  border: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.26;
  color: #000000;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;
  pointer-events: none;
}
#modal-search.modal-search > .search-section.calendar .search-body #mobile-calendar-container-start-date,
#modal-search.modal-search > .search-section.calendar .search-body #mobile-calendar-container-end-date {
  width: 100%;
}
#modal-search.modal-search > .search-section.calendar .search-body #mobile-calendar-container-start-date .pika-single,
#modal-search.modal-search > .search-section.calendar .search-body #mobile-calendar-container-end-date .pika-single {
  margin: 0 -140px;
}
#modal-search.modal-search > .search-section.calendar .search-body #mobile-calendar-container-start-date .error-message {
  display: block;
  width: 100%;
  text-align: center;
  color: #ff001f;
  font-size: 18px;
  margin-bottom: 16px;
}

.map-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: #404040;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-popup.hide {
  display: none;
}
.map-popup .home-map {
  height: 90%;
  width: 100%;
  max-height: 932px;
  background: #ffffff;
  padding: 22px;
}
@media (max-width: 991px) {
  .map-popup .home-map {
    padding: 0;
    max-height: unset;
    height: 100dvh;
  }
}
.map-popup .home-map .map-wrapper {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  position: relative;
}
.map-popup .home-map .control-search-form-mobile {
  display: none;
  transition: all 200ms linear;
  cursor: pointer;
}
.map-popup .home-map .control-search-form-mobile.active {
  transform: rotateX(180deg);
}
@media (max-width: 991px) {
  .map-popup .home-map .control-search-form-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.map-popup .home-map .map-section {
  flex-grow: 1;
}
@media (max-width: 991px) {
  .map-popup .home-map .map-section #map {
    height: 100dvh !important;
  }
}
.map-popup .home-map .map-section .cluster {
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-popup .home-map .map-section .cluster > div {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.map-popup .home-map .map-section .gm-style-iw.gm-style-iw-c {
  padding: 0 !important;
}
.map-popup .home-map .map-section .gm-style-iw.gm-style-iw-c .gm-style-iw-d {
  overflow: auto !important;
}
.map-popup .home-map .map-section .gm-style-iw.gm-style-iw-c .gm-style-iw-ch {
  position: absolute !important;
}
.map-popup .home-map .map-section .gm-style-iw.gm-style-iw-c button.gm-ui-hover-effect {
  position: absolute !important;
  left: 0;
  top: 0;
}
.map-popup .home-map .map-section .info-window-content-map {
  display: flex;
  flex-direction: row-reverse;
  gap: 20px;
  padding: 10px;
  min-height: 140px;
  min-width: 290px;
}
.map-popup .home-map .map-section .info-window-content-map > div:first-child {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.map-popup .home-map .map-section .info-window-content-map img {
  height: 100% !important;
  object-fit: cover;
  object-position: center center;
}
.map-popup .home-map .map-section .info-window-content-map .info-window-btn-map {
  border: 1px solid #000;
  padding: 5px 30px;
  border-radius: 30px;
  color: #404040;
  font-size: 14px;
  font-weight: 500;
}
.map-popup .home-map .map-section .info-window-content-map h5 {
  font-size: 12px;
  font-weight: 500;
}
.map-popup .home-map .map-section .info-window-content-map h4 {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 5px;
}
.map-popup .home-map .locations-list {
  max-width: 650px;
  width: 40%;
  height: 100%;
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list {
    position: absolute;
    left: 0;
    width: 100%;
    max-width: unset;
    z-index: 1;
    height: fit-content;
  }
}
.map-popup .home-map .locations-list .locations-list-search {
  width: 100%;
  height: 360px;
  background: #025f52;
  position: relative;
  padding-top: 15%;
  padding-inline: 15%;
}
@media (max-width: 1445px) {
  .map-popup .home-map .locations-list .locations-list-search {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 25px 5%;
  }
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-search > div {
    width: 100%;
    position: relative;
    padding-bottom: 15px;
  }
}
.map-popup .home-map .locations-list .locations-list-search .close-home-map {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-search .close-home-map {
    top: 0;
  }
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-search .close-home-map svg {
    width: 24px;
    height: 24px;
  }
}
.map-popup .home-map .locations-list .locations-list-search h2 {
  color: #ffffff;
  padding-bottom: 30px;
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-search h2 {
    margin: 0 auto 15px;
    text-align: center;
    padding-bottom: 0;
    width: 90%;
    font-size: 21px;
  }
}
.map-popup .home-map .locations-list .locations-list-search form {
  border: 1px solid #ffffff;
  border-radius: 50px;
  height: 46px;
  display: flex;
  flex-direction: row-reverse;
  background: #025f52;
  position: relative;
  width: 100%;
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-search form {
    display: none;
  }
}
.map-popup .home-map .locations-list .locations-list-search form input {
  height: 100%;
  border: none;
  outline: none;
  background: #025F52;
  flex-grow: 1;
  border-radius: 0 50px 50px 0;
  padding: 12px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.map-popup .home-map .locations-list .locations-list-search form input::placeholder {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.map-popup .home-map .locations-list .locations-list-search form button {
  height: 100%;
  background: #fff;
  border-radius: 50px;
  width: 100px;
  border: 1px solid #fff;
  color: #404040;
  cursor: pointer;
}
.map-popup .home-map .locations-list .locations-list-search form .search-list-result {
  position: absolute;
  width: calc(100% - 30px);
  background: #ffffff;
  bottom: -1px;
  left: 15px;
  transform: translateY(100%);
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #025f52;
}
.map-popup .home-map .locations-list .locations-list-search form .search-list-result .list-search {
  padding: 10px;
  margin: 0;
}
.map-popup .home-map .locations-list .locations-list-search form .search-list-result .list-search li {
  font-size: 14px;
  font-weight: 300;
  list-style: none;
  color: #404040;
  cursor: pointer;
  margin-bottom: 5px;
}
.map-popup .home-map .locations-list h3 {
  padding: 10px 20px 10px 50px;
  height: 50px;
  font-size: 21px;
}
@media (max-width: 1445px) {
  .map-popup .home-map .locations-list h3 {
    height: 70px;
  }
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list h3 {
    display: none;
  }
}
.map-popup .home-map .locations-list .locations-list-result {
  overflow-y: auto;
  height: calc(100% - 410px);
  padding: 20px 20px 20px 50px;
}
@media (max-width: 1445px) {
  .map-popup .home-map .locations-list .locations-list-result {
    height: calc(100% - 330px);
  }
}
@media (max-width: 991px) {
  .map-popup .home-map .locations-list .locations-list-result {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    left: -9999999px;
  }
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 0 12px 12px;
  gap: 58px;
  border-bottom: 1px solid #d0d0d0;
  cursor: pointer;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item.active {
  background: #d7ffd0;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item:hover {
  background: #d7ffd0;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item .thumbnail_map_wrap {
  width: 40%;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item .thumbnail_map_wrap img {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item .map-park-title {
  color: #404040;
  font-size: 16px;
  font-weight: 500;
}
.map-popup .home-map .locations-list .locations-list-result .list-location-item .map-park-location {
  color: #404040;
  font-size: 16px;
  font-weight: 300;
}

.open-floating-form-modal {
  position: fixed;
  top: 80%;
  left: 0;
  transform: translate(-40%, -50%) rotate(270deg);
  cursor: pointer;
  background-color: #ff4380;
  z-index: 8;
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  color: white;
  border-radius: 0 0 15px 15px;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  opacity: 1;
  pointer-events: painted;
  transition: 500ms;
}
.open-floating-form-modal svg {
  transform: rotate(90deg);
}
.open-floating-form-modal.active {
  opacity: 0;
  pointer-events: none;
  transform: translate(-400%, -50%) rotate(270deg);
}

.modal-floating-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.7137254902);
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 200ms;
  padding: 40px 0;
  overflow-y: auto;
}
.modal-floating-form .wpcf7-response-output {
  margin-top: 10px;
  border: none !important;
}
.modal-floating-form.active {
  opacity: 1;
  pointer-events: painted;
  z-index: 10;
}
.modal-floating-form.active .floating_form {
  transform: scale(1) !important;
}
.modal-floating-form > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.modal-floating-form .floating_form {
  transform: scale(0.6);
  height: auto;
  width: 90%;
  max-width: 580px;
  background: #ffffff;
  padding: 20px 40px;
  border-radius: 9px;
  position: relative;
  transition: 200ms;
  margin: auto;
}
.modal-floating-form .floating_form .close-floating_form {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.modal-floating-form .floating_form .form-row {
  display: flex;
  width: 100%;
  gap: 16px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .modal-floating-form .floating_form .form-row {
    flex-direction: column;
  }
}
.modal-floating-form .floating_form .form-row.form-header {
  flex-direction: column;
  gap: 12px;
}
.modal-floating-form .floating_form .form-row.form-header h3 {
  font-size: 24px;
  font-weight: 700;
  color: #404040;
}
.modal-floating-form .floating_form .form-row.form-header p {
  font-size: 18px;
  color: #404040;
}
.modal-floating-form .floating_form .form-row label {
  flex-basis: 50%;
  font-size: 16px;
  line-height: 2em;
  color: #515358;
}
.modal-floating-form .floating_form .form-row input {
  padding: 18px 16px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #A4A4A4;
  color: rgba(81, 83, 88, 0.4);
  font-size: 16px;
}
.modal-floating-form .floating_form button[type=submit] {
  background: #045e51;
  border: none;
  width: 100%;
  text-align: center;
  height: 64px;
  border-radius: 64px;
  color: #ffffff;
}
.modal-floating-form .floating_form button[type=submit].block {
  cursor: not-allowed;
  pointer-events: none;
}
@media (max-width: 991px) {
  .modal-floating-form .floating_form {
    padding: 24px 20px;
  }
}

/* __________ MODAL  END __________*/
/* __________  PAGE BEGIN __________*/
.home-page {
  overflow: hidden;
}
.home-page .banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 540px;
  position: relative;
}
.home-page .banner .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home-page .banner .video video {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}
@media (max-width: 767px) {
  .home-page .banner {
    padding: 0 16px;
    background-size: auto 600px;
    background-position: 25% -117px;
    padding-top: 205px;
    height: auto;
  }
}
.home-page .banner .container-lg {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}
@media (max-width: 767px) {
  .home-page .banner .container-lg {
    padding: 0;
    flex-flow: unset;
    flex-direction: column;
  }
}
.home-page .banner .banner-text {
  font-size: 35px;
  line-height: 47px;
  color: #fff;
  margin-bottom: 38px;
}
.home-page .map {
  margin-top: -60px;
  margin-bottom: 175px;
}
@media (max-width: 767px) {
  .home-page .map {
    margin-top: 50px;
    margin-bottom: 32px;
  }
}
.home-page .map .open-map {
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  padding: 33px 40px;
  padding-right: calc(25% + 30px);
  border-radius: 15px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  position: relative;
}
@media (max-width: 991px) {
  .home-page .map .open-map {
    box-shadow: none;
    padding: 0px;
    margin-bottom: 32px;
    border-radius: 15px;
    background: #ffffff;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    flex-direction: column;
  }
}
.home-page .map .open-map .text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  font-size: 22px;
  line-height: 28.6px;
  letter-spacing: 0.66px;
}
@media (max-width: 991px) {
  .home-page .map .open-map .text {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 25px;
    padding: 16px;
  }
}
.home-page .map .open-map .text .description {
  width: 100%;
  font-size: 22px;
  line-height: 28.6px;
  letter-spacing: 0.66px;
}
.home-page .map .open-map .text .description p {
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  letter-spacing: 0.66px;
}
@media (max-width: 767px) {
  .home-page .map .open-map .text .description p {
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: 0.54px;
  }
}
.home-page .map .open-map .image {
  position: relative;
  width: 25%;
  height: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
@media (max-width: 991px) {
  .home-page .map .open-map .image {
    position: relative;
    width: 100%;
    height: 127px;
    border-radius: 15px 15px 0px 0px;
  }
}
.home-page .map .open-map .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page .map .open-map .button {
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .home-page .map .open-map .button {
    display: block;
    width: 100%;
  }
  .home-page .map .open-map .button a {
    width: 100%;
    height: 60px;
    font-size: 18px;
  }
}
.home-page .map .our-values {
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 22px;
}
@media (max-width: 767px) {
  .home-page .map .our-values {
    grid-template-columns: repeat(1, 1fr);
    place-items: center;
    gap: 12px;
  }
}
.home-page .map .our-values .value {
  display: flex;
  align-items: center;
  gap: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  padding: 26px 30px;
  border-radius: 15px;
}
@media (max-width: 991px) {
  .home-page .map .our-values .value {
    padding: 16px 60px 16px 16px;
    gap: 10px;
    width: 100%;
  }
}
.home-page .map .our-values .value .image {
  flex-shrink: 0;
}
.home-page .map .our-values .value .title {
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .home-page .map .our-values .value .title h5 {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .home-page .map .our-values .value .description p {
    font-size: 16px;
  }
}
.home-page .our-goals {
  margin-bottom: 77px;
  background-color: #ffffff;
}
.home-page .our-goals .heading {
  margin-bottom: 30px;
  color: #404040;
}
@media (max-width: 767px) {
  .home-page .our-goals .heading {
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (max-width: 767px) {
  .home-page .our-goals .container-md {
    padding: 0px;
  }
}
.home-page .our-goals .slider {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .home-page .our-goals .slider {
    margin-bottom: 12px;
  }
}
.home-page .our-goals .slider .slide {
  overflow: hidden;
  height: auto;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .image {
    height: 206px;
  }
}
.home-page .our-goals .slider .slide .image img {
  width: 100%;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .image img {
    object-fit: cover;
    height: 100%;
  }
}
.home-page .our-goals .slider .slide .text {
  padding: 16px 32px;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .text {
    font-size: 12px 28px;
  }
}
.home-page .our-goals .slider .slide .text .title {
  margin-bottom: 8px;
  color: #000000;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .text .title {
    font-size: 18px;
  }
}
.home-page .our-goals .slider .slide .text .description {
  margin-bottom: 8px;
}
.home-page .our-goals .slider .slide .text .description p {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .text .description p {
    font-size: 14px;
  }
}
.home-page .our-goals .slider .slide .text .date {
  margin-bottom: 8px;
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .text .date {
    font-size: 16px;
  }
}
.home-page .our-goals .slider .slide .text .price {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
  color: #000000;
}
@media (max-width: 575px) {
  .home-page .our-goals .slider .slide .text .price {
    font-size: 16px;
    line-height: 22px;
  }
}
.home-page .our-goals .slider .slide .button {
  margin-top: auto;
}
@media (max-width: 767px) {
  .home-page .our-goals .slider .slide.slick-cloned {
    display: none !important;
  }
  .home-page .our-goals .slider .slide:nth-child(6n) {
    display: none !important;
  }
}
.home-page .our-goals .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 767px) {
  .home-page .our-goals .slider .slick-list {
    margin: 0;
    padding: 0;
  }
}
.home-page .our-goals .slider .slick-slide {
  margin: 0 15px;
}
.home-page .our-goals .slider .slick-slide .image {
  height: 206px;
}
.home-page .our-goals .slider .slick-slide .image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.home-page .our-goals .slider .slick-track {
  padding: 10px 0px;
  display: flex !important;
}
@media (max-width: 767px) {
  .home-page .our-goals .slider .slick-track {
    width: 100% !important;
    transform: none !important;
    flex-direction: column;
    gap: 12px;
  }
}
.home-page .our-goals .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  left: -70px;
}
.home-page .our-goals .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.home-page .our-goals .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.home-page .our-goals .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .our-goals .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .our-goals .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  right: -70px;
}
.home-page .our-goals .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.home-page .our-goals .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.home-page .our-goals .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .our-goals .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .home-page .our-goals .button {
    text-align: center;
    display: block;
    width: 100%;
  }
  .home-page .our-goals .button a {
    width: 100%;
    height: 60px;
    font-size: 18px;
    max-width: 280px;
  }
}
.home-page .our-best-offers {
  margin-bottom: 77px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers {
    margin-bottom: 40px;
  }
}
.home-page .our-best-offers .heading {
  margin-bottom: 30px;
  color: #404040;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .heading h2 {
    font-size: 32px;
    text-align: center;
    padding: 0 16px;
  }
}
.home-page .our-best-offers .container-md {
  padding: 0px;
}
.home-page .our-best-offers .gallery {
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 1264px) {
  .home-page .our-best-offers .gallery {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery {
    padding: 10px;
    gap: 15px;
  }
}
.home-page .our-best-offers .gallery > :first-child {
  flex: 0 1 705px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery > :first-child {
    flex: 1 1 100%;
  }
}
.home-page .our-best-offers .gallery > * {
  flex: 0 0 361px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery > * {
    flex: 1 1 100%;
  }
}
.home-page .our-best-offers .gallery .image {
  padding: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.home-page .our-best-offers .gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page .our-best-offers .gallery .image.drop {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-page .our-best-offers .gallery .image.drop .figura {
  background-color: #ffe2a1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 0% 50% 50%;
  width: 268px;
  height: 268px;
}
.home-page .our-best-offers .gallery .image.drop .figura .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery .image.drop .figura .text {
    padding: 0 20px;
  }
}
.home-page .our-best-offers .gallery .image.drop .figura .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
.home-page .our-best-offers .gallery .image.drop .figura .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
.home-page .our-best-offers .gallery .image.semicircle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #f5f5f5;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery .image.semicircle {
    justify-content: center;
  }
}
.home-page .our-best-offers .gallery .image.semicircle .figura {
  background-color: #d3dfff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 50% 0% 0%;
  width: 268px;
  height: 268px;
}
.home-page .our-best-offers .gallery .image.semicircle .figura .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery .image.semicircle .figura .text {
    padding: 0 30px;
  }
}
.home-page .our-best-offers .gallery .image.semicircle .figura .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
.home-page .our-best-offers .gallery .image.semicircle .figura .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
.home-page .our-best-offers .gallery .image.burger {
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-page .our-best-offers .gallery .image.burger .figura {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  border-radius: 50% 50% 0% 0%;
  width: 100%;
  height: 268px;
}
.home-page .our-best-offers .gallery .image.burger .figura::before {
  top: 0px;
  left: 0px;
  position: absolute;
  content: "";
  background-color: #d7ffd0;
  height: 51%;
  width: 100%;
  border-radius: 100px;
}
.home-page .our-best-offers .gallery .image.burger .figura::after {
  bottom: 0px;
  left: 0px;
  position: absolute;
  content: "";
  background-color: #d7ffd0;
  height: 51%;
  width: 100%;
  border-radius: 100px;
}
.home-page .our-best-offers .gallery .image.burger .figura .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .gallery .image.burger .figura .text {
    padding: 0 20px;
  }
}
.home-page .our-best-offers .gallery .image.burger .figura .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
.home-page .our-best-offers .gallery .image.burger .figura .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .home-page .our-best-offers .button {
    text-align: center;
    display: block;
    width: 100%;
  }
  .home-page .our-best-offers .button a {
    width: 100%;
    height: 60px;
    font-size: 18px;
    max-width: 280px;
  }
}
.home-page .experience-the-joy {
  padding: 60px 0px;
  background-color: #045e51;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy {
    padding: 32px 0;
  }
}
.home-page .experience-the-joy .heading {
  margin-bottom: 30px;
  color: #ffffff;
  margin-bottom: 10px;
}
.home-page .experience-the-joy .description {
  color: #ffffff;
  margin-bottom: 30px;
}
.home-page .experience-the-joy .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 266px 266px;
  gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    margin: 0;
  }
}
.home-page .experience-the-joy .gallery .image {
  position: relative;
}
.home-page .experience-the-joy .gallery .image h5 {
  position: absolute;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 3.927px 29.453px #000000;
  font-size: 25px;
  font-weight: 700;
}
.home-page .experience-the-joy .gallery .image:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: 3;
  grid-column-end: 5;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(1) {
    display: none;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(1) img {
  border-radius: 10px;
}
.home-page .experience-the-joy .gallery .image:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 4;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(2) {
    order: 2;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(2) img {
  border-radius: 200px 200px 10px 10px;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(2) img {
    height: 337px;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(3) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 3;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(3) {
    order: 1;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(3) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(3) img {
    height: 230px;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(4) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
  mask-image: url(../images/masks/mask-image-burger.svg);
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(4) {
    order: 3;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(4) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(4) img {
    height: 266px;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(5) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(5) {
    order: 4;
  }
}
.home-page .experience-the-joy .gallery .image:nth-child(5) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .home-page .experience-the-joy .gallery .image:nth-child(5) img {
    height: 266px;
  }
}
.home-page .experience-the-joy .gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page .accommodations {
  margin: 60px 0px;
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .home-page .accommodations {
    margin: 20px 0;
  }
}
.home-page .accommodations .tabs {
  position: relative;
}
.home-page .accommodations .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  background-color: #f3f5f5;
  padding: 0px 30px;
  overflow: auto;
}
@media (max-width: 767px) {
  .home-page .accommodations .tabs .tabs-nav {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    display: none;
    top: 44px;
  }
}
.home-page .accommodations .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  padding-right: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .home-page .accommodations .tabs .tabs-nav .tab {
    font-size: 14px;
    padding: 10px 40px 10px 10px;
  }
}
.home-page .accommodations .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.home-page .accommodations .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.home-page .accommodations .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
@media (max-width: 767px) {
  .home-page .accommodations .tabs .tabs-nav .tab.active {
    display: none;
  }
}
.home-page .accommodations .tabs .tabs-select {
  display: none;
}
@media (max-width: 767px) {
  .home-page .accommodations .tabs .tabs-select {
    border-radius: 8px;
    background: #F3F5F5;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .home-page .accommodations .tabs .tabs-select:after {
    content: "\f078";
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    color: #045e51;
    transition: all 0.3s;
  }
  .home-page .accommodations .tabs .tabs-select.open:after {
    transform: rotate(180deg);
  }
  .home-page .accommodations .tabs .tabs-select .tab {
    display: flex;
    align-items: center;
    gap: 0 12px;
  }
  .home-page .accommodations .tabs .tabs-select .tab span {
    color: #045e51;
    font-size: 14px;
    font-weight: 700;
  }
  .home-page .accommodations .tabs .tabs-select .tab img {
    height: 24px;
    width: auto;
  }
}
.home-page .accommodations .tabs .tabs-contents .tab-content {
  display: none;
}
.home-page .accommodations .tabs .tabs-contents .tab-content.active {
  display: block;
}
.home-page .accommodations .title, .home-page .accommodations .heading {
  margin-bottom: 30px;
  margin-bottom: 10px;
  color: #404040;
}
.home-page .accommodations .description {
  margin-bottom: 30px;
}
.home-page .accommodations .slider {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider {
    margin-bottom: 30px;
  }
}
.home-page .accommodations .slider .slide {
  overflow: hidden;
  height: auto;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
}
.home-page .accommodations .slider .slide .image img {
  width: 100%;
}
@media (max-width: 767px) {
  .home-page .accommodations .slider .slide .image img {
    height: 160px;
  }
}
.home-page .accommodations .slider .slide .text {
  padding: 16px 32px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slide .text {
    font-size: 12px 28px;
  }
}
.home-page .accommodations .slider .slide .text .title {
  margin-bottom: 8px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slide .text .title {
    font-size: 18px;
  }
}
.home-page .accommodations .slider .slide .text .description {
  margin-bottom: 8px;
}
.home-page .accommodations .slider .slide .text .description p {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slide .text .description p {
    font-size: 14px;
  }
}
.home-page .accommodations .slider .slide .text .date {
  margin-bottom: 8px;
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slide .text .date {
    font-size: 16px;
  }
}
.home-page .accommodations .slider .slide .text .price {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slide .text .price {
    font-size: 16px;
    line-height: 22px;
  }
}
.home-page .accommodations .slider .slide .button {
  margin-top: auto;
}
.home-page .accommodations .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 575px) {
  .home-page .accommodations .slider .slick-list {
    margin: 0;
  }
}
.home-page .accommodations .slider .slick-slide {
  margin: 0 15px;
}
@media (max-width: 767px) {
  .home-page .accommodations .slider .slick-slide {
    margin: 0;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .home-page .accommodations .slider .slick-slide.slick-cloned {
    display: none !important;
  }
}
.home-page .accommodations .slider .slick-track {
  padding: 10px 0px;
  display: flex !important;
}
@media (max-width: 767px) {
  .home-page .accommodations .slider .slick-track {
    display: flex !important;
    transform: none !important;
    width: 100% !important;
    flex-direction: column;
    gap: 12px 0;
  }
}
.home-page .accommodations .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 9;
  left: -70px;
}
.home-page .accommodations .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.home-page .accommodations .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.home-page .accommodations .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .accommodations .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .accommodations .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 9;
  right: -70px;
}
.home-page .accommodations .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.home-page .accommodations .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.home-page .accommodations .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.home-page .accommodations .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .home-page .accommodations .button {
    text-align: center;
    display: block;
    width: 100%;
  }
  .home-page .accommodations .button a {
    width: 100%;
    height: 60px;
    font-size: 18px;
    max-width: 280px;
  }
}
.home-page .recommended-parks {
  margin: 60px 0px;
}
.home-page .recommended-parks .heading {
  margin-bottom: 30px;
  color: #404040;
}
@media (max-width: 767px) {
  .home-page .recommended-parks .heading {
    text-align: center;
  }
}
.home-page .recommended-parks .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 45px 16px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .home-page .recommended-parks .cards {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.home-page .recommended-parks .cards .card {
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
}
@media (max-width: 767px) {
  .home-page .recommended-parks .cards .card {
    flex-direction: column;
  }
}
.home-page .recommended-parks .cards .card .text {
  padding: 35px 60px;
}
@media (max-width: 1264px) {
  .home-page .recommended-parks .cards .card .text {
    padding: 30px;
  }
}
@media (max-width: 767px) {
  .home-page .recommended-parks .cards .card .text {
    padding: 20px;
    width: 100%;
  }
}
.home-page .recommended-parks .cards .card .text .title {
  margin-bottom: 8px;
}
.home-page .recommended-parks .cards .card .text .description {
  margin-bottom: 8px;
  color: #abb5b7;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.54px;
  overflow: hidden;
}
.home-page .recommended-parks .cards .card .text .description p {
  display: -webkit-box;
  /* Это позволит использовать -webkit-line-clamp для обрезки текста */
  -webkit-line-clamp: 2;
  /* Это ограничивает текст двумя строками */
  -webkit-box-orient: vertical;
  /* Необходимо для работы -webkit-line-clamp */
  overflow: hidden;
  /* Обрезает лишний текст */
  text-overflow: ellipsis;
  /* Это, на самом деле, не будет нужно с -webkit-line-clamp, но хорошо иметь на всякий случай */
  max-width: 250px;
}
.home-page .recommended-parks .cards .card .image {
  width: 214px;
  height: 100%;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  .home-page .recommended-parks .cards .card .image {
    width: 100%;
    height: 180px;
  }
}
.home-page .recommended-parks .cards .card .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page .recommended-parks .cards .card .link a {
  color: #4e944f;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
@media (max-width: 767px) {
  .home-page .recommended-parks .button {
    text-align: center;
    display: block;
    width: 100%;
  }
  .home-page .recommended-parks .button a {
    width: 100%;
    height: 60px;
    font-size: 18px;
    max-width: 280px;
  }
}
.home-page .vacation-for-everyone {
  margin: 60px 0px;
}
.home-page .vacation-for-everyone .heading {
  margin-bottom: 30px;
  color: #404040;
}
@media (max-width: 767px) {
  .home-page .vacation-for-everyone .heading {
    text-align: center;
  }
}
.home-page .vacation-for-everyone .vacations {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
}
@media (max-width: 991px) {
  .home-page .vacation-for-everyone .vacations {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .home-page .vacation-for-everyone .vacations {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
@media (max-width: 575px) {
  .home-page .vacation-for-everyone .vacations {
    gap: 10px;
  }
}
.home-page .vacation-for-everyone .vacations .vacation {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
  height: 188px;
  padding: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
}
.home-page .vacation-for-everyone .vacations .vacation:before {
  content: "";
  display: block;
  width: 100%;
  height: 50%;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.7));
  position: absolute;
  left: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .home-page .vacation-for-everyone .vacations .vacation {
    padding: 15px;
    height: 187px;
  }
}
.home-page .vacation-for-everyone .vacations .vacation:hover {
  background-size: 125% 125%;
}
.home-page .vacation-for-everyone .vacations .vacation h4 {
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  position: relative;
  z-index: 5;
}
@media (max-width: 767px) {
  .home-page .vacation-for-everyone .vacations .vacation h4 {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .home-page .vacation-for-everyone .vacations .vacation h4 {
    font-size: 18px;
  }
}
.home-page .vacation-for-everyone .vacations .vacation .image {
  width: 214px;
  height: 210px;
  flex-shrink: 0;
}
.home-page .vacation-for-everyone .vacations .vacation .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.home-page .vacation-for-everyone .vacations .vacation .link a {
  color: #4e944f;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}

.search-page .banner, .search-packages .banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 202px;
}
@media (max-width: 991px) {
  .search-page .banner, .search-packages .banner {
    height: auto;
    padding: 24px 0;
    background-color: #cadac7;
  }
}
.search-page .banner .container-md, .search-packages .banner .container-md {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.search-page .banner .container-md .search, .search-packages .banner .container-md .search {
  width: 100%;
}
.search-page .pre-filter, .search-packages .pre-filter {
  display: none;
}
@media (max-width: 767px) {
  .search-page .pre-filter, .search-packages .pre-filter {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 16px;
    align-items: center;
  }
  .search-page .pre-filter .start-filter, .search-packages .pre-filter .start-filter {
    border-radius: 8px;
    border: 1px solid #d0d0d0;
    height: 50px;
    width: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
  }
  .search-page .pre-filter .map, .search-packages .pre-filter .map {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.48px;
    text-decoration-line: underline;
  }
}
.search-page .filter, .search-packages .filter {
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background: #ffffff;
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  height: 70px;
}
@media (max-width: 767px) {
  .search-page .filter, .search-packages .filter {
    display: none;
  }
}
.search-page .filter .container-md, .search-packages .filter .container-md {
  display: flex;
  align-items: center;
  position: relative;
}
.search-page .filter .sorting, .search-packages .filter .sorting {
  display: flex;
  align-items: center;
  height: 70px;
}
.search-page .filter .sorting .title, .search-packages .filter .sorting .title {
  color: #d0d0d0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}
.search-page .filter .sorting .select, .search-packages .filter .sorting .select {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 114px;
  border-left: 1px solid #d0d0d0;
  border-bottom: 5px solid transparent;
  cursor: pointer;
}
.search-page .filter .sorting .select.open, .search-packages .filter .sorting .select.open {
  border-bottom: 5px solid #045e51;
}
.search-page .filter .sorting .select.open .label:before, .search-packages .filter .sorting .select.open .label:before {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
  z-index: 4;
}
.search-page .filter .sorting .select.open .label .arrow, .search-packages .filter .sorting .select.open .label .arrow {
  transform: rotate(180deg);
}
.search-page .filter .sorting .select .label, .search-packages .filter .sorting .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0px 30px;
  height: 100%;
  position: relative;
  z-index: 6;
}
.search-page .filter .sorting .select .label .arrow, .search-packages .filter .sorting .select .label .arrow {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  transition: transform 0.3s ease;
}
.search-page .filter .sorting .select .label .text, .search-packages .filter .sorting .select .label .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.search-page .filter .sorting .select .drop-down, .search-packages .filter .sorting .select .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: calc(100% - 20px);
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 10px;
  z-index: 3;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  margin-top: 10px;
  padding: 25px;
  padding-top: 50px;
}
.search-page .filter .sorting .select .drop-down .close, .search-packages .filter .sorting .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.search-page .filter .sorting .select .drop-down .content .checkboxes-rectangle, .search-packages .filter .sorting .select .drop-down .content .checkboxes-rectangle {
  margin-bottom: 50px;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  border-bottom: 1px solid #d0d0d0;
  overflow: auto;
}
@media (max-width: 575px) {
  .search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav {
    padding: 0px 50px;
  }
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 10px 50px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  text-align: center;
  position: relative;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab span, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab img, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab.active, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content {
  display: none;
}
.search-page .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content.active, .search-packages .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content.active {
  display: block;
}
.search-page .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal, .search-packages .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.search-page .filter .sorting .select .drop-down .content .form .apartments, .search-packages .filter .sorting .select .drop-down .content .form .apartments {
  margin-bottom: 35px;
}
.search-page .filter .sorting .select .drop-down .content .form .apartments .cards, .search-packages .filter .sorting .select .drop-down .content .form .apartments .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.search-page .filter .sorting .select .drop-down .content .form .nav, .search-packages .filter .sorting .select .drop-down .content .form .nav {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 50px;
}
.search-page .filter .sorting .select .drop-down .content .form .nav .reset, .search-packages .filter .sorting .select .drop-down .content .form .nav .reset {
  outline: none;
  background: none;
  border: none;
  color: #045e51;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
  text-decoration-line: underline;
}
.search-page .filter .sorting .range, .search-packages .filter .sorting .range {
  direction: ltr;
}
.search-page .order-by, .search-packages .order-by {
  margin-bottom: 30px;
  margin-top: 30px;
}
@media (max-width: 767px) {
  .search-page .order-by, .search-packages .order-by {
    padding: 16px 0;
    margin-bottom: 7px;
    margin-top: 7px;
  }
}
.search-page .order-by .container-md, .search-packages .order-by .container-md {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search-page .order-by .title, .search-packages .order-by .title {
  color: #404040;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  .search-page .order-by .title, .search-packages .order-by .title {
    font-size: 16px;
  }
}
.search-page .order-by .select, .search-packages .order-by .select {
  display: flex;
  min-width: 300px;
  align-items: baseline;
}
@media (max-width: 767px) {
  .search-page .order-by .select, .search-packages .order-by .select {
    min-width: unset;
  }
}
@media (max-width: 767px) {
  .search-page .order-by .select .chosen-container, .search-packages .order-by .select .chosen-container {
    min-width: auto;
  }
}
.search-page .order-by .select label, .search-packages .order-by .select label {
  color: #404040;
  font-size: 16px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .search-page .order-by .select label, .search-packages .order-by .select label {
    margin-left: 8px;
  }
}
@media (max-width: 767px) {
  .search-page .order-by .select select, .search-packages .order-by .select select {
    border-radius: 5px;
    border: 1px solid #A4A4A4;
    width: 130px;
    height: 46px;
    color: #515358;
  }
}
.search-page .posts .container-md, .search-packages .posts .container-md {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-height: 150px;
}
@media (max-width: 767px) {
  .search-page .posts .container-md, .search-packages .posts .container-md {
    gap: 12px;
  }
}
.search-page .posts .post, .search-packages .posts .post {
  display: flex;
  gap: 40px;
  border-radius: 9.065px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
  padding: 22px;
  color: #404040;
}
@media (max-width: 767px) {
  .search-page .posts .post, .search-packages .posts .post {
    flex-direction: column;
    padding: 16px;
    gap: 12px;
  }
}
.search-page .posts .post .thumbnail, .search-packages .posts .post .thumbnail {
  flex: 0 1 650px;
  height: 451px;
}
@media (max-width: 767px) {
  .search-page .posts .post .thumbnail, .search-packages .posts .post .thumbnail {
    width: 100%;
    height: 200px;
    flex: unset;
  }
}
.search-page .posts .post .thumbnail img, .search-packages .posts .post .thumbnail img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.search-page .posts .post > .text, .search-packages .posts .post > .text {
  flex: 0 0 777px;
  width: 100%;
}
@media (max-width: 1264px) {
  .search-page .posts .post > .text, .search-packages .posts .post > .text {
    flex-shrink: 1;
  }
}
@media (max-width: 767px) {
  .search-page .posts .post > .text, .search-packages .posts .post > .text {
    flex: unset;
  }
}
.search-page .posts .post > .text .title, .search-packages .posts .post > .text .title {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .search-page .posts .post > .text .title h4, .search-packages .posts .post > .text .title h4 {
    font-size: 24px;
    color: #404040;
  }
}
.search-page .posts .post > .text .title .status, .search-packages .posts .post > .text .title .status {
  text-align: center;
  font-size: 13.479px;
  font-weight: 700;
  line-height: 17.523px;
  letter-spacing: 0.404px;
  border-radius: 4px;
  background: #d7ffd0;
  padding: 9px;
}
.search-page .posts .post > .text .location-info, .search-packages .posts .post > .text .location-info {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .search-page .posts .post > .text .location-info h6, .search-packages .posts .post > .text .location-info h6 {
    color: #404040;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
@media (max-width: 767px) {
  .search-page .posts .post > .text .description, .search-packages .posts .post > .text .description {
    display: none;
  }
}
.search-page .posts .post > .text .description p, .search-packages .posts .post > .text .description p {
  margin-bottom: 12px;
}
.search-page .posts .post > .text .description p.sub-title, .search-packages .posts .post > .text .description p.sub-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}
.search-page .posts .post > .text .meta-data, .search-packages .posts .post > .text .meta-data {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .search-page .posts .post > .text .meta-data, .search-packages .posts .post > .text .meta-data {
    flex-wrap: wrap;
    gap: 14px 25px;
    color: #404040;
  }
}
.search-page .posts .post > .text .meta-data .events, .search-packages .posts .post > .text .meta-data .events {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search-page .posts .post > .text .meta-data .events .title, .search-packages .posts .post > .text .meta-data .events .title {
  margin-bottom: 0px;
}
.search-page .posts .post > .text .meta-data .prestige-levels, .search-packages .posts .post > .text .meta-data .prestige-levels {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search-page .posts .post > .text .meta-data .prestige-levels .title, .search-packages .posts .post > .text .meta-data .prestige-levels .title {
  margin-bottom: 0px;
}
.search-page .posts .post > .text .meta-data .special-accommodations, .search-packages .posts .post > .text .meta-data .special-accommodations {
  display: flex;
  align-items: center;
  gap: 6px;
}
.search-page .posts .post > .text .meta-data .special-accommodations .title, .search-packages .posts .post > .text .meta-data .special-accommodations .title {
  margin-bottom: 0px;
}
.search-page .posts .post > .text .read-more, .search-packages .posts .post > .text .read-more {
  margin-bottom: 18px;
}
.search-page .posts .post > .text .read-more a, .search-packages .posts .post > .text .read-more a {
  color: #045e51;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  line-height: 18.2px;
  letter-spacing: 0.42px;
}
.search-page .posts .post .cost, .search-packages .posts .post .cost {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  background: #f3f5f5;
  padding: 20px;
  margin-bottom: 18px;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost, .search-packages .posts .post .cost {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
  }
}
.search-page .posts .post .cost .duration-of-stay-and-number-of-people, .search-packages .posts .post .cost .duration-of-stay-and-number-of-people {
  color: #404040;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.36px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .duration-of-stay-and-number-of-people, .search-packages .posts .post .cost .duration-of-stay-and-number-of-people {
    text-align: center;
  }
}
.search-page .posts .post .cost .price .summa, .search-packages .posts .post .cost .price .summa {
  display: flex;
  align-items: flex-end;
  gap: 10px;
  justify-content: flex-end;
}
.search-page .posts .post .cost .price .summa .main-price, .search-packages .posts .post .cost .price .summa .main-price {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e85481;
  text-align: center;
  font-size: 28.844px;
  font-weight: 700;
  line-height: 37.497px;
  letter-spacing: 0.865px;
}
.search-page .posts .post .cost .price .summa .main-price i, .search-packages .posts .post .cost .price .summa .main-price i {
  color: #abb5b7;
  font-size: 20px;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .price .summa .main-price i, .search-packages .posts .post .cost .price .summa .main-price i {
    color: #7d7d7d;
    font-size: 16px;
  }
}
.search-page .posts .post .cost .price .summa .reduced-price, .search-packages .posts .post .cost .price .summa .reduced-price {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .price .summa .reduced-price, .search-packages .posts .post .cost .price .summa .reduced-price {
    font-size: 17px;
    gap: 4px;
  }
}
.search-page .posts .post .cost .price .summa .reduced-price .number, .search-packages .posts .post .cost .price .summa .reduced-price .number {
  text-decoration: line-through;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .price .summa .reduced-price .currency, .search-packages .posts .post .cost .price .summa .reduced-price .currency {
    font-size: 15px;
  }
}
.search-page .posts .post .cost .price .summa .text, .search-packages .posts .post .cost .price .summa .text {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .price .summa .text, .search-packages .posts .post .cost .price .summa .text {
    font-size: 15px;
  }
}
.search-page .posts .post .cost .price .price-per-stay, .search-packages .posts .post .cost .price .price-per-stay {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
}
.search-page .posts .post .cost .price .taxes, .search-packages .posts .post .cost .price .taxes {
  color: #abb5b7;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0.3px;
  text-align: left;
}
@media (max-width: 767px) {
  .search-page .posts .post .cost .price .taxes, .search-packages .posts .post .cost .price .taxes {
    text-align: center;
    justify-content: center;
  }
}
.search-page .posts .post .button, .search-packages .posts .post .button {
  display: flex;
  justify-content: end;
  align-items: center;
}
.search-page .posts .post .button .info, .search-packages .posts .post .button .info {
  color: #ff4380;
  text-align: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 15.6px;
  letter-spacing: 0.36px;
}
@media (max-width: 767px) {
  .search-page .posts .post .button .info, .search-packages .posts .post .button .info {
    display: none;
  }
}
@media (max-width: 767px) {
  .search-page .posts .post .button button, .search-packages .posts .post .button button {
    width: 100%;
    max-width: 100%;
    padding: 12px 26px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.48px;
  }
}

.overview-tab .searchbox {
  margin-bottom: 20px;
  padding-bottom: 40px;
  background: rgb(250, 251, 251);
}
@media (max-width: 767px) {
  .overview-tab .searchbox {
    padding: 0 15px;
  }
}
.overview-tab .searchbox .heading h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .overview-tab .searchbox .heading h3 {
    font-size: 24px;
  }
}
.overview-tab .searchbox .heading > div {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: center;
  color: #404040;
}
.overview-tab .searchbox .heading > div p, .overview-tab .searchbox .heading > div a, .overview-tab .searchbox .heading > div li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: center;
  color: #404040;
}
.overview-tab .park .container-md {
  display: flex;
  gap: 135px;
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .overview-tab .park .container-md {
    flex-direction: column-reverse;
    gap: 12px;
  }
}
.overview-tab .park .thumbnails {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 10px;
  flex-shrink: 0;
  flex: 1.1;
}
@media (max-width: 767px) {
  .overview-tab .park .thumbnails {
    display: flex;
    flex-direction: row-reverse;
  }
}
.overview-tab .park .thumbnails .image:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 3;
}
@media (max-width: 767px) {
  .overview-tab .park .thumbnails .image:nth-child(1) {
    display: none;
  }
}
.overview-tab .park .thumbnails .image:nth-child(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 430px;
}
.overview-tab .park .thumbnails .image:nth-child(2) {
  grid-row-start: 3;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 3;
}
.overview-tab .park .thumbnails .image:nth-child(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  height: 211px;
}
.overview-tab .park .thumbnails .image:nth-child(3) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 3;
  mask-image: url(../images/masks/mask-image-burger.svg);
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}
.overview-tab .park .thumbnails .image:nth-child(3) img {
  width: 100%;
  height: 209px;
}
.overview-tab .park .thumbnails .image:nth-child(2) {
  grid-row-start: 2;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 3;
}
.overview-tab .park .thumbnails .image:nth-child(2) img {
  border-radius: 0px 0px 0px 10px;
  object-fit: cover;
}
.overview-tab .park .text {
  flex: 1;
}
.overview-tab .park .text .sub-title {
  color: #abb5b7;
  text-align: right;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .overview-tab .park .text .sub-title {
    width: 100%;
    font-size: 14px;
  }
}
.overview-tab .park .text .title {
  width: 663px;
  color: #404040;
  font-size: 35px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .overview-tab .park .text .title {
    width: 100%;
    font-size: 24px;
  }
}
.overview-tab .park .text .description {
  color: #404040;
  font-style: normal;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: 0.54px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .overview-tab .park .text .description {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.48px;
  }
}
.overview-tab .advice .container-md {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 767px) {
  .overview-tab .advice .container-md {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 5px;
    justify-content: center;
  }
}
.overview-tab .advice .advice-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  height: 100%;
  position: relative;
}
@media (max-width: 767px) {
  .overview-tab .advice .advice-item {
    width: calc(50% - 10px);
    padding: 0;
  }
}
.overview-tab .advice .advice-item:hover {
  background: #f3f5f5;
}
.overview-tab .advice .advice-item:hover .title {
  color: #025f52;
}
.overview-tab .advice .advice-item:first-child:after {
  display: none;
}
.overview-tab .advice .advice-item:nth-child(6n):after {
  display: none;
}
.overview-tab .advice .advice-item:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  display: block;
  width: 0;
  border-left: 1px solid #d2d3d3;
}
@media (max-width: 767px) {
  .overview-tab .advice .advice-item:after {
    display: none;
  }
}
.overview-tab .advice .advice-item img {
  margin-bottom: 16px;
}
.overview-tab .advice .advice-item .title {
  color: #404040;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
@media (max-width: 767px) {
  .overview-tab .advice .advice-item .title {
    font-size: 16px;
  }
}
.overview-tab .advice .advice-item .description {
  color: #404040;
  text-align: center;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.overview-tab .advice .advice-item .description p {
  font-size: 18px;
}
@media (max-width: 767px) {
  .overview-tab .advice .advice-item .description p {
    font-size: 14px;
  }
}
.overview-tab .accommodations {
  margin: 86px 0 40px 0;
  background-color: #ffffff;
}
.overview-tab .accommodations .title {
  margin-bottom: 13px;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .title {
    font-size: 24px;
  }
}
.overview-tab .accommodations .description {
  margin-bottom: 37px;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: right;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.03em;
    text-align: right;
    color: rgb(64, 64, 64);
  }
}
.overview-tab .accommodations .tabs {
  position: relative;
}
.overview-tab .accommodations .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 10px;
  z-index: 1;
  position: relative;
  background-color: #f3f5f5;
  padding: 0px 50px;
  overflow: auto;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .tabs .tabs-nav {
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    display: none;
    top: 44px;
  }
}
.overview-tab .accommodations .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 15px 5px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  padding-right: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .tabs .tabs-nav .tab {
    font-size: 14px;
    padding: 10px 40px 10px 10px;
  }
}
.overview-tab .accommodations .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.overview-tab .accommodations .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.overview-tab .accommodations .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .tabs .tabs-nav .tab.active {
    display: none;
  }
}
.overview-tab .accommodations .tabs .tabs-select {
  display: none;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .tabs .tabs-select {
    border-radius: 8px;
    background: #f3f5f5;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .overview-tab .accommodations .tabs .tabs-select:after {
    content: "\f078";
    font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    color: #045e51;
    transition: all 0.3s;
  }
  .overview-tab .accommodations .tabs .tabs-select.open:after {
    transform: rotate(180deg);
  }
  .overview-tab .accommodations .tabs .tabs-select .tab {
    display: flex;
    align-items: center;
    gap: 0 12px;
  }
  .overview-tab .accommodations .tabs .tabs-select .tab span {
    color: #045e51;
    font-size: 14px;
    font-weight: 700;
  }
  .overview-tab .accommodations .tabs .tabs-select .tab img {
    height: 24px;
    width: auto;
  }
}
.overview-tab .accommodations .tabs .tabs-contents .tab-content {
  display: none;
}
.overview-tab .accommodations .tabs .tabs-contents .tab-content.active {
  display: block;
}
.overview-tab .accommodations .slider {
  margin-bottom: 0px;
}
.overview-tab .accommodations .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 575px) {
  .overview-tab .accommodations .slider .slick-list {
    padding-left: 80px;
  }
}
.overview-tab .accommodations .slider .slick-slide {
  margin: 0 15px;
}
.overview-tab .accommodations .slider .slick-track {
  padding: 30px 0px;
  display: flex !important;
}
.overview-tab .accommodations .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  left: -70px;
}
.overview-tab .accommodations .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.overview-tab .accommodations .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.overview-tab .accommodations .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .accommodations .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .accommodations .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  right: -70px;
}
.overview-tab .accommodations .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.overview-tab .accommodations .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.overview-tab .accommodations .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .accommodations .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .overview-tab .accommodations .button {
    text-align: center;
  }
}
.overview-tab .accommodations .button a {
  width: 278px;
  height: 60px;
  transform: translateY(-10px);
}
@media (max-width: 767px) {
  .overview-tab .accommodations .button a {
    transform: none;
  }
}
.overview-tab .do-not-miss {
  margin-bottom: 77px;
}
.overview-tab .do-not-miss .title {
  margin-bottom: 18px;
}
@media (max-width: 767px) {
  .overview-tab .do-not-miss .title h2 {
    font-size: 24px;
  }
}
.overview-tab .do-not-miss .list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
}
@media (max-width: 767px) {
  .overview-tab .do-not-miss .list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.overview-tab .do-not-miss .list .item {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
}
.overview-tab .do-not-miss .list .item .thumbnail img {
  border-radius: 8px;
  width: 100%;
  height: 153px;
  object-fit: cover;
}
@media (max-width: 767px) {
  .overview-tab .do-not-miss .list .item .thumbnail img {
    border-radius: 8px 8px 0 0;
  }
}
.overview-tab .do-not-miss .list .item .text {
  padding: 13px 18px;
}
.overview-tab .do-not-miss .list .item .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 6px;
}
.overview-tab .do-not-miss .list .item .text .description {
  color: #404040;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: right;
}
.overview-tab .do-not-miss .list .item .text .read-more a {
  color: #4e944f;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.overview-tab .our-best-offers {
  margin-bottom: 84px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers {
    display: none;
  }
}
.overview-tab .our-best-offers .heading {
  margin-bottom: 16px;
}
.overview-tab .our-best-offers .gallery {
  overflow: auto;
  display: grid;
  grid-template-columns: 2fr 357px 357px;
  gap: 40px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.overview-tab .our-best-offers .gallery .image {
  padding: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image {
    padding: 0px;
    border-radius: 8px;
    box-shadow: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
    background-image: none;
  }
}
.overview-tab .our-best-offers .gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overview-tab .our-best-offers .gallery .image.drop {
  display: flex;
  justify-content: center;
  align-items: center;
}
.overview-tab .our-best-offers .gallery .image.drop .figure {
  background-color: #ffe2a1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 0% 50% 50%;
  width: 268px;
  height: 268px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.drop .figure {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0px;
    background-color: #ffffff;
    overflow: hidden;
  }
}
.overview-tab .our-best-offers .gallery .image.drop .figure .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.drop .figure .text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: initial;
  }
}
.overview-tab .our-best-offers .gallery .image.drop .figure .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.drop .figure .text .title {
    margin-bottom: 0px;
    background: #ffe2a1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
.overview-tab .our-best-offers .gallery .image.drop .figure .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.drop .figure .text .description {
    margin-bottom: 0px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: 0.42px;
  }
}
.overview-tab .our-best-offers .gallery .image.semicircle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.overview-tab .our-best-offers .gallery .image.semicircle .figure {
  background-color: #d3dfff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% 50% 0% 0%;
  width: 268px;
  height: 268px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.semicircle .figure {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0px;
    background-color: #ffffff;
    overflow: hidden;
  }
}
.overview-tab .our-best-offers .gallery .image.semicircle .figure .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.semicircle .figure .text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: initial;
  }
}
.overview-tab .our-best-offers .gallery .image.semicircle .figure .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.semicircle .figure .text .title {
    margin-bottom: 0px;
    background: #d3dfff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
.overview-tab .our-best-offers .gallery .image.semicircle .figure .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.semicircle .figure .text .description {
    margin-bottom: 0px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: 0.42px;
  }
}
.overview-tab .our-best-offers .gallery .image.burger {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.overview-tab .our-best-offers .gallery .image.burger .figure {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 30px;
  border-radius: 50% 50% 0% 0%;
  width: 268px;
  height: 268px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    padding: 0px;
    background-color: #ffffff;
    overflow: hidden;
  }
}
.overview-tab .our-best-offers .gallery .image.burger .figure::before {
  top: 0px;
  left: 0px;
  position: absolute;
  content: "";
  background-color: #d7ffd0;
  height: 51%;
  width: 100%;
  border-radius: 100px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure::before {
    display: none;
  }
}
.overview-tab .our-best-offers .gallery .image.burger .figure::after {
  bottom: 0px;
  left: 0px;
  position: absolute;
  content: "";
  background-color: #d7ffd0;
  height: 51%;
  width: 100%;
  border-radius: 100px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure::after {
    display: none;
  }
}
.overview-tab .our-best-offers .gallery .image.burger .figure .text {
  text-align: center;
  color: #404040;
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  position: relative;
  z-index: 3;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure .text {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    text-align: initial;
  }
}
.overview-tab .our-best-offers .gallery .image.burger .figure .text .title {
  font-size: 17px;
  font-weight: 700;
  line-height: 22.1px;
  letter-spacing: 0.51px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure .text .title {
    margin-bottom: 0px;
    background: #d7ffd0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
.overview-tab .our-best-offers .gallery .image.burger .figure .text .description {
  font-size: 17px;
  font-weight: 500;
  line-height: 22.1px;
  letter-spacing: 0.51px;
}
@media (max-width: 767px) {
  .overview-tab .our-best-offers .gallery .image.burger .figure .text .description {
    margin-bottom: 0px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: 0.42px;
  }
}
.overview-tab .our-best-offers .button a {
  width: 278px;
  height: 60px;
}
.overview-tab .experience-the-joy {
  padding: 70px 0px;
  background-color: #045e51;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy {
    padding: 32px 0px;
  }
}
.overview-tab .experience-the-joy .heading {
  margin-bottom: 15px;
  color: #ffffff;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .heading h2 {
    font-size: 24px;
  }
}
.overview-tab .experience-the-joy .description {
  color: #ffffff;
  margin-bottom: 30px;
}
.overview-tab .experience-the-joy .description:empty {
  display: none;
}
.overview-tab .experience-the-joy .gallery {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 266px 266px;
  gap: 15px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    margin: 0;
  }
}
.overview-tab .experience-the-joy .gallery .image {
  position: relative;
}
.overview-tab .experience-the-joy .gallery .image h5 {
  position: absolute;
  left: 50%;
  bottom: 35px;
  transform: translateX(-50%);
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 3.927px 29.453px #000000;
  font-size: 25px;
  font-weight: 700;
  max-width: 150px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image h5 {
    bottom: 15px;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: 3;
  grid-column-end: 5;
}
.overview-tab .experience-the-joy .gallery .image:nth-child(1) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(1) img {
    object-position: top center;
    height: 200px;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 4;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(2) {
    order: 2;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(2) img {
  border-radius: 200px 200px 10px 10px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(2) img {
    height: 337px;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(3) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 3;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(3) {
    order: 5;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(3) h5 {
  bottom: 15px;
}
.overview-tab .experience-the-joy .gallery .image:nth-child(3) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(3) img {
    height: 200px;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(4) {
  grid-row-start: 2;
  grid-column-start: 2;
  grid-row-end: 3;
  grid-column-end: 3;
  mask-image: url(../images/masks/mask-image-burger.svg);
  mask-size: 100% 100%;
  mask-repeat: no-repeat;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(4) {
    order: 3;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(4) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(4) img {
    height: 266px;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(5) {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 2;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(5) {
    order: 4;
  }
}
.overview-tab .experience-the-joy .gallery .image:nth-child(5) img {
  border-radius: 10px;
}
@media (max-width: 767px) {
  .overview-tab .experience-the-joy .gallery .image:nth-child(5) img {
    height: 200px;
  }
}
.overview-tab .experience-the-joy .gallery .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.overview-tab .restaurants-and-shops {
  padding: 65px 0px;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops {
    padding: 40px 0px;
  }
}
.overview-tab .restaurants-and-shops .text {
  margin-bottom: 20px;
}
.overview-tab .restaurants-and-shops .text .title {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .text .title h2 {
    font-size: 24px;
  }
}
.overview-tab .restaurants-and-shops .text .desription {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: right;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .text .desription {
    font-size: 16px;
  }
}
.overview-tab .restaurants-and-shops .posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .posts {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.overview-tab .restaurants-and-shops .posts .post {
  position: relative;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .posts .post {
    width: 100%;
  }
}
.overview-tab .restaurants-and-shops .posts .post .image {
  position: relative;
  height: 100%;
}
.overview-tab .restaurants-and-shops .posts .post .image img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .posts .post .image img {
    height: 170px;
  }
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .posts .post .image {
    width: 100%;
  }
  .overview-tab .restaurants-and-shops .posts .post .image:before {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
.overview-tab .restaurants-and-shops .posts .post .image h5 {
  position: absolute;
  left: 50%;
  bottom: 27px;
  transform: translateX(-50%);
  color: #ffffff;
  text-align: center;
  text-shadow: 0px 3.927px 29.453px #000000;
  font-size: 25px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .posts .post .image h5 {
    font-size: 18px;
    bottom: 15px;
  }
}
@media (max-width: 767px) {
  .overview-tab .restaurants-and-shops .button {
    text-align: center;
  }
}
.overview-tab .restaurants-and-shops .button a {
  font-size: 20px;
  padding: 17px 30px;
  height: 60px;
}
.overview-tab .practical-information {
  margin-bottom: 70px;
}
.overview-tab .practical-information .heading h2 {
  color: #404040;
  text-align: center;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 75px;
  position: relative;
}
@media (max-width: 767px) {
  .overview-tab .practical-information .heading h2 {
    font-size: 24px;
    margin-bottom: 40px;
  }
}
.overview-tab .practical-information .heading h2::after {
  content: "";
  width: 34px;
  height: 1px;
  background-color: #abb5b7;
  position: absolute;
  bottom: -37px;
  left: 50%;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .overview-tab .practical-information .heading h2::after {
    bottom: -20px;
  }
}
.overview-tab .practical-information .map {
  width: 100%;
  height: 505px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 55px;
}
@media (max-width: 767px) {
  .overview-tab .practical-information .map {
    height: 400px;
    background-position: 21% 0%;
  }
  .overview-tab .practical-information .map img {
    height: 100%;
    object-fit: cover;
    object-position: 21% 0%;
  }
}
.overview-tab .practical-information .map .contacts {
  width: 210px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10%;
}
.overview-tab .practical-information .map .contacts .title {
  color: #404040;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 18px;
}
.overview-tab .practical-information .map .contacts .address {
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.48px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .overview-tab .practical-information .map .contacts .address {
    background: #ebf5f7;
  }
}
@media (max-width: 767px) {
  .overview-tab .practical-information .map + .button {
    display: none;
  }
}
.overview-tab .practical-information .map + .button a {
  height: 60px;
}
.overview-tab .practical-information .button {
  text-align: center;
}
.overview-tab .our-certificates {
  margin-bottom: 178px;
}
@media (max-width: 767px) {
  .overview-tab .our-certificates {
    padding: 0 10px;
    margin-bottom: 40px;
  }
}
.overview-tab .our-certificates .heading {
  margin-bottom: 40px;
}
.overview-tab .our-certificates .heading h2 {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .overview-tab .our-certificates .heading h2 {
    font-size: 24px;
    text-align: center;
  }
}
.overview-tab .our-certificates .list {
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
@media (max-width: 767px) {
  .overview-tab .our-certificates .list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.overview-tab .our-certificates .certificate-item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 20px 26px 20px;
  width: 100%;
  position: relative;
  height: 184px;
}
@media (max-width: 767px) {
  .overview-tab .our-certificates .certificate-item {
    padding: 10px;
    height: 100%;
  }
}
.overview-tab .our-certificates .certificate-item:hover {
  background: #f3f5f5;
}
.overview-tab .our-certificates .certificate-item:hover .title {
  color: #025f52;
}
.overview-tab .our-certificates .certificate-item:first-child:after {
  display: none;
}
.overview-tab .our-certificates .certificate-item:nth-child(6n):after {
  display: none;
}
.overview-tab .our-certificates .certificate-item:after {
  content: "";
  position: absolute;
  top: 20px;
  right: 0;
  bottom: 20px;
  display: block;
  width: 0;
  border-left: 1px solid #d2d3d3;
}
.overview-tab .our-certificates .certificate-item img {
  margin-bottom: 10px;
}
.overview-tab .our-certificates .certificate-item .title {
  color: #404040;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.overview-tab .our-certificates .certificate-item .description {
  color: #404040;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.overview-tab .discover-other-parks {
  margin-bottom: 80px;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks {
    margin-bottom: 40px;
  }
}
.overview-tab .discover-other-parks .heading {
  margin-bottom: 40px;
}
.overview-tab .discover-other-parks .heading h2 {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks .heading h2 {
    font-size: 24px;
    text-align: center;
  }
}
.overview-tab .discover-other-parks .slider {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .overview-tab .discover-other-parks .slider {
    margin-bottom: 30px;
  }
}
.overview-tab .discover-other-parks .slider .slide {
  overflow: hidden;
  height: auto;
  border-radius: 14px;
  background-color: #ffffff;
  display: flex !important;
  flex-direction: column;
  box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
  align-self: stretch;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks .slider .slide {
    margin-bottom: 12px;
  }
}
.overview-tab .discover-other-parks .slider .slide .image img {
  width: 100%;
  height: 206px;
  object-fit: cover;
}
.overview-tab .discover-other-parks .slider .slide .text {
  padding: 16px 32px;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks .slider .slide .text {
    font-size: 16px;
    padding: 12px;
  }
}
.overview-tab .discover-other-parks .slider .slide .text .title {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 575px) {
  .overview-tab .discover-other-parks .slider .slide .text .title {
    font-size: 18px;
  }
}
.overview-tab .discover-other-parks .slider .slide .text .title a {
  color: #404040;
}
.overview-tab .discover-other-parks .slider .slide .text .title .status {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.overview-tab .discover-other-parks .slider .slide .text .description {
  margin-bottom: 8px;
}
.overview-tab .discover-other-parks .slider .slide .text .description p {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
@media (max-width: 575px) {
  .overview-tab .discover-other-parks .slider .slide .text .description p {
    font-size: 14px;
  }
}
.overview-tab .discover-other-parks .slider .slide .text .date {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
}
@media (max-width: 575px) {
  .overview-tab .discover-other-parks .slider .slide .text .date {
    font-size: 16px;
  }
}
.overview-tab .discover-other-parks .slider .slide .text .cost {
  margin-left: 30px;
}
.overview-tab .discover-other-parks .slider .slide .text .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.overview-tab .discover-other-parks .slider .slide .text .cost .price .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
  margin: 0px;
}
.overview-tab .discover-other-parks .slider .slide .text .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
}
.overview-tab .discover-other-parks .slider .slide .text .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.overview-tab .discover-other-parks .slider .slide .text .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.overview-tab .discover-other-parks .slider .slide .button {
  margin-top: auto;
}
.overview-tab .discover-other-parks .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 575px) {
  .overview-tab .discover-other-parks .slider .slick-list {
    padding-left: 80px;
  }
}
.overview-tab .discover-other-parks .slider .slick-slide {
  margin: 0 15px;
}
.overview-tab .discover-other-parks .slider .slick-track {
  padding: 10px 0px;
  display: flex !important;
}
.overview-tab .discover-other-parks .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  left: -80px;
  margin-top: -24px;
}
.overview-tab .discover-other-parks .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  color: #045e51;
  font-weight: 400;
  font-size: 32px;
}
.overview-tab .discover-other-parks .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.overview-tab .discover-other-parks .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .discover-other-parks .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .discover-other-parks .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  right: -80px;
  margin-top: -24px;
}
.overview-tab .discover-other-parks .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  color: #045e51;
  font-weight: 400;
  font-size: 32px;
}
.overview-tab .discover-other-parks .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.overview-tab .discover-other-parks .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.overview-tab .discover-other-parks .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks .button {
    text-align: center;
  }
}
.overview-tab .discover-other-parks .button a {
  height: 60px;
}
@media (max-width: 767px) {
  .overview-tab .discover-other-parks .button a {
    padding: 17px 30px;
    font-size: 16px;
  }
}
.overview-tab .center-parcs-business .heading {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .overview-tab .center-parcs-business .heading h2 {
    font-size: 24px;
  }
}
.overview-tab .center-parcs-business .posts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media (max-width: 767px) {
  .overview-tab .center-parcs-business .posts {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
.overview-tab .center-parcs-business .posts .post {
  display: flex;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 13.34px 0px rgba(20, 6, 60, 0.15);
  overflow: hidden;
}
@media (max-width: 767px) {
  .overview-tab .center-parcs-business .posts .post {
    display: flex;
    flex-direction: column;
  }
}
.overview-tab .center-parcs-business .posts .post .thumbnail {
  width: 214px;
  flex-shrink: 0;
  position: relative;
}
@media (max-width: 767px) {
  .overview-tab .center-parcs-business .posts .post .thumbnail {
    height: 170px;
    width: 100%;
  }
}
.overview-tab .center-parcs-business .posts .post .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}
.overview-tab .center-parcs-business .posts .post .text {
  padding: 35px 54px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .overview-tab .center-parcs-business .posts .post .text {
    width: 100%;
    padding: 16px 20px;
  }
}
.overview-tab .center-parcs-business .posts .post .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 6px;
}
.overview-tab .center-parcs-business .posts .post .text .description {
  color: #abb5b7;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.54px;
  margin-bottom: 6px;
}
.overview-tab .center-parcs-business .posts .post .text .read-more {
  margin-top: auto;
}
.overview-tab .center-parcs-business .posts .post .text .read-more a {
  color: #4e944f;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}

.accomodations-tab > .posts .container-md {
  min-height: 150px;
}
.accomodations-tab .searchbox {
  margin-bottom: 20px;
  padding-bottom: 40px;
  background: rgb(250, 251, 251);
}
@media (max-width: 767px) {
  .accomodations-tab .searchbox {
    padding: 0 15px;
  }
}
.accomodations-tab .searchbox .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: center;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .accomodations-tab .searchbox .heading {
    font-size: 24px;
  }
}
.accomodations-tab .pre-filter {
  display: none;
}
@media (max-width: 767px) {
  .accomodations-tab .pre-filter {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    padding: 10px 16px;
    align-items: center;
  }
  .accomodations-tab .pre-filter .start-filter {
    border-radius: 8px;
    border: 1px solid #d0d0d0;
    height: 50px;
    width: 203px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
  }
  .accomodations-tab .pre-filter .map {
    color: #000000;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0.48px;
    text-decoration-line: underline;
  }
}
.accomodations-tab .filter {
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background: #ffffff;
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  height: 70px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter {
    height: fit-content;
  }
}
.accomodations-tab .filter .container-md {
  display: flex;
  align-items: center;
  position: relative;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .container-md {
    padding: 30px 20px 10px;
  }
}
.accomodations-tab .filter .sorting {
  display: flex;
  align-items: center;
  height: 70px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting {
    height: fit-content;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
  }
}
.accomodations-tab .filter .sorting .title {
  color: #d0d0d0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .title {
    font-size: 21px;
  }
}
.accomodations-tab .filter .sorting .select {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 114px;
  border-left: 1px solid #d0d0d0;
  border-bottom: 5px solid transparent;
  cursor: pointer;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select[data-select-name=budget] .nav {
    margin-top: 40px !important;
  }
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select {
    width: 100%;
    min-height: 60px;
    gap: 0;
    flex-direction: column;
    border-left: unset;
    border-bottom: 1px solid #d0d0d0;
    justify-content: center;
    padding: 15px 0;
  }
  .accomodations-tab .filter .sorting .select:last-child {
    border-bottom: none;
  }
}
.accomodations-tab .filter .sorting .select.open {
  border-bottom: 5px solid #045e51;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select.open {
    border-bottom: unset;
  }
}
.accomodations-tab .filter .sorting .select.open .label:before {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
  z-index: 4;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select.open .label:before {
    content: none;
  }
}
.accomodations-tab .filter .sorting .select.open .label .arrow {
  transform: rotate(180deg);
}
.accomodations-tab .filter .sorting .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0 30px;
  height: 100%;
  position: relative;
  z-index: 6;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .label {
    width: 100%;
    justify-content: space-between;
    padding: 0;
  }
}
.accomodations-tab .filter .sorting .select .label .arrow {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  transition: transform 0.3s ease;
}
.accomodations-tab .filter .sorting .select .label .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .label .text {
    font-size: 21px;
  }
}
.accomodations-tab .filter .sorting .select .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: calc(100% - 20px);
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 10px;
  z-index: 3;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  margin-top: 10px;
  padding: 25px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down {
    position: relative;
    box-shadow: none;
    padding: 0;
    width: 100%;
    left: 0;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down .close {
    display: none;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .content .checkboxes-rectangle {
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .checkboxes-rectangle {
    margin-bottom: 30px;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  border-bottom: 1px solid #d0d0d0;
  overflow: auto;
}
@media (max-width: 575px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav {
    padding: 0 50px;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 10px 50px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  text-align: center;
  position: relative;
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0;
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content {
  display: none;
}
.accomodations-tab .filter .sorting .select .drop-down .content .tabs .tabs-contents .tab-content.active {
  display: block;
}
.accomodations-tab .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 479px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal .checkbox-rectangle {
    padding: 10px;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .content .form .apartments {
  margin-bottom: 35px;
}
.accomodations-tab .filter .sorting .select .drop-down .content .form .apartments .cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.accomodations-tab .filter .sorting .select .drop-down .content .form .nav {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 50px;
}
@media (max-width: 767px) {
  .accomodations-tab .filter .sorting .select .drop-down .content .form .nav {
    margin-top: 0;
  }
}
.accomodations-tab .filter .sorting .select .drop-down .content .form .nav .reset {
  outline: none;
  background: none;
  border: none;
  color: #045e51;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
  text-decoration-line: underline;
}
.accomodations-tab .filter .sorting .range {
  direction: ltr;
}
.accomodations-tab .order-by {
  margin-bottom: 30px;
}
.accomodations-tab .order-by .container-md {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .accomodations-tab .order-by .container-md {
    flex-direction: column;
  }
}
.accomodations-tab .order-by .title {
  color: #404040;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  .accomodations-tab .order-by .title {
    width: 100%;
    margin-bottom: 24px;
  }
}
.accomodations-tab .order-by .select {
  display: flex;
  min-width: 300px;
  align-items: baseline;
}
@media (max-width: 767px) {
  .accomodations-tab .order-by .select {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.accomodations-tab .order-by .select select {
  border-radius: 5px;
  border: 1px solid #a4a4a4;
  flex: 1;
  height: 46px;
  padding: 0 13px;
}
.accomodations-tab .order-by .select label {
  color: #404040;
  font-size: 16px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}
.accomodations-tab .posts .container-md {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.accomodations-tab .posts .post {
  display: flex;
  border-radius: 9.065px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post {
    width: 100%;
    display: block;
    box-shadow: none;
    flex-direction: column;
  }
}
.accomodations-tab .posts .post .slider-container {
  position: relative;
  width: fit-content;
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .slider-container {
    width: 100%;
  }
}
.accomodations-tab .posts .post .slider-container .slider {
  width: 480px;
  flex-shrink: 0;
  position: relative;
  margin: 0px;
  overflow: hidden;
  border-radius: 0px 10px 10px 0px;
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .slider-container .slider {
    width: 100%;
    height: 178px;
    border-radius: 10px 10px 0px 0px;
  }
}
.accomodations-tab .posts .post .slider-container .slider .slick-slide {
  position: relative;
}
.accomodations-tab .posts .post .slider-container .slider .slick-slide::before {
  content: "";
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  border-radius: 0px 10px 10px 0px;
}
.accomodations-tab .posts .post .slider-container .slider .slick-slide img {
  width: 480px;
  height: 238px;
  object-fit: cover;
  object-position: 50% 50%;
}
@media (max-width: 1264px) {
  .accomodations-tab .posts .post .slider-container .slider .slick-slide img {
    height: 300px;
  }
}
.accomodations-tab .posts .post .slider-container .slider .slick-dots {
  bottom: 20px;
  position: absolute;
}
.accomodations-tab .posts .post .slider-container .slider .slick-dots li {
  margin: 0px;
}
.accomodations-tab .posts .post .slider-container .slider .slick-dots li button:before {
  font-size: 10px;
  color: #ffffff;
}
.accomodations-tab .posts .post .slider-container .slider .slick-dots li.slick-active button:before {
  color: #ffffff;
}
.accomodations-tab .posts .post .slider-container .slick-counter {
  position: absolute;
  bottom: 20px;
  right: 20px;
  padding: 10px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  gap: 6px;
  align-items: center;
}
.accomodations-tab .posts .post .text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  padding: 22px !important;
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .text {
    flex-direction: column;
    color: #404040;
    gap: 25px 0;
    padding: 20px 10px;
  }
}
.accomodations-tab .posts .post .text .info .title {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 5px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .title {
    display: block;
  }
}
.accomodations-tab .posts .post .text .info .title a {
  color: #000000;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .title a {
    color: #404040;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.72px;
  }
}
.accomodations-tab .posts .post .text .info .title .facilities {
  display: flex;
  align-items: center;
  gap: 8px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .title .facilities {
    gap: 20px;
    margin-top: 14px;
  }
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .title .facilities img {
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 50%;
  }
}
.accomodations-tab .posts .post .text .info .meta-data {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 16px;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .meta-data {
    font-size: 14px;
  }
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .info .meta-data i {
    color: #000000;
    font-size: 16px;
  }
}
.accomodations-tab .posts .post .text .info .meta-data .people {
  display: flex;
  align-items: center;
  gap: 6px;
}
.accomodations-tab .posts .post .text .info .meta-data .people .title {
  margin-bottom: 0px;
}
.accomodations-tab .posts .post .text .info .meta-data .bedroom {
  display: flex;
  align-items: center;
  gap: 6px;
}
.accomodations-tab .posts .post .text .info .meta-data .bedroom .title {
  margin-bottom: 0px;
}
.accomodations-tab .posts .post .text .info .meta-data .square-meters {
  display: flex;
  align-items: center;
  gap: 6px;
}
.accomodations-tab .posts .post .text .info .meta-data .square-meters .title {
  margin-bottom: 0px;
}
.accomodations-tab .posts .post .text .info .description p {
  margin-bottom: 12px;
}
.accomodations-tab .posts .post .text .info .description ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0;
  padding-inline-start: 20px;
}
.accomodations-tab .posts .post .text .cost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 8px;
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .text .cost {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
}
.accomodations-tab .posts .post .text .cost .duration-of-stay-and-number-of-people {
  color: #404040;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.36px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .cost .duration-of-stay-and-number-of-people {
    margin-top: 0;
    width: calc(50% - 10px);
  }
}
.accomodations-tab .posts .post .text .cost .price {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .cost .price {
    margin-top: 0;
    width: calc(50% - 10px);
    margin: 0;
  }
}
.accomodations-tab .posts .post .text .cost .price .summa {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .cost .price .summa {
    justify-content: flex-end;
  }
}
.accomodations-tab .posts .post .text .cost .price .summa .now {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e85481;
  text-align: center;
  font-size: 28.844px;
  font-weight: 700;
  line-height: 37.497px;
  letter-spacing: 0.865px;
}
.accomodations-tab .posts .post .text .cost .price .summa .now i {
  color: #abb5b7;
  font-size: 20px;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .cost .price .summa .now i {
    color: #7d7d7d;
    font-size: 16px;
  }
}
.accomodations-tab .posts .post .text .cost .price .summa .was {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
  text-decoration: line-through;
}
.accomodations-tab .posts .post .text .cost .price .summa .text {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
}
.accomodations-tab .posts .post .text .cost .price .price-per-stay {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .post .text .cost .price .price-per-stay {
    margin: 0;
  }
}
.accomodations-tab .posts .post .text .cost .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
@media (max-width: 1264px) {
  .accomodations-tab .posts .post .text .cost .nav {
    flex-direction: column;
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .text .cost .nav {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .text .cost .nav .btn-white {
    display: none;
  }
}
@media (max-width: 991px) {
  .accomodations-tab .posts .post .text .cost .nav .btn-pink {
    width: 100%;
    border-radius: 56px;
    height: 48px;
  }
}
.accomodations-tab .posts .load-more {
  margin-top: 40px;
  text-align: center;
}
@media (max-width: 767px) {
  .accomodations-tab .posts .load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.activities-tab {
  margin-top: -10px;
}
@media (max-width: 767px) {
  .activities-tab {
    margin-top: 0;
  }
}
.activities-tab .banner {
  height: 480px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .activities-tab .banner {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
  }
}
.activities-tab .banner .video {
  background-image: url("../images/search-park-general-page/main-banner.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 767px) {
  .activities-tab .banner .video {
    height: auto;
    background: none;
  }
}
.activities-tab .banner .video::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
@media (max-width: 767px) {
  .activities-tab .banner .video::before {
    display: none;
  }
}
.activities-tab .banner .video .book-now {
  border-radius: 15px 15px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  height: 88.775px;
  flex-shrink: 0;
  position: absolute;
  right: 10%;
  bottom: 0px;
  z-index: 6;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now {
    flex-wrap: wrap;
    position: relative;
    height: auto;
    right: 0;
    box-shadow: none;
    justify-content: space-between;
    padding: 12px;
    align-items: start;
  }
}
.activities-tab .banner .video .book-now .park {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .park {
    width: 50%;
  }
}
.activities-tab .banner .video .book-now .park .title {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .park .title {
    margin-bottom: 8px;
  }
}
.activities-tab .banner .video .book-now .park .title .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.activities-tab .banner .video .book-now .park .location {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #045e51;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: 0.28px;
}
.activities-tab .banner .video .book-now .park .location i {
  color: #045e51;
  font-size: 20px;
}
.activities-tab .banner .video .book-now .park-map {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 9.236px;
  background: #f4f5f5;
  color: #045e51;
  font-weight: 700;
  letter-spacing: 0.45px;
  width: 127px;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .park-map {
    width: 152px;
    height: 44px;
    font-size: 14px;
    padding: 10px;
    justify-content: center;
  }
}
.activities-tab .banner .video .book-now .park-map a {
  font-size: 16px;
  color: #045e51;
  word-break: break-all;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .park-map a {
    font-size: 14px;
  }
}
.activities-tab .banner .video .book-now .park-map i {
  color: #045e51;
  font-size: 28px;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .park-map i {
    font-size: 20px;
  }
}
.activities-tab .banner .video .book-now .sep {
  width: 1px;
  height: 59px;
  margin: 0px 15px;
  background-color: #d0d0d0;
}
@media (max-width: 767px) {
  .activities-tab .banner .video .book-now .sep {
    display: none;
  }
}
.activities-tab .banner .video .book-now .cost {
  margin-left: 30px;
}
.activities-tab .banner .video .book-now .cost .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
}
.activities-tab .banner .video .book-now .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.activities-tab .banner .video .book-now .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
}
.activities-tab .banner .video .book-now .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.activities-tab .banner .video .book-now .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.activities-tab .banner .sidebar {
  display: grid;
  grid-template-columns: 250px;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .activities-tab .banner .sidebar {
    display: flex;
  }
}
@media (max-width: 767px) {
  .activities-tab .banner .sidebar > div {
    height: 100px;
    background-size: cover;
    width: calc(33.3333333333% - 0px);
  }
  .activities-tab .banner .sidebar > div h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.activities-tab .banner .sidebar .photos-and-videos {
  background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.activities-tab .banner .sidebar .photos-and-videos h4 {
  z-index: 2;
}
.activities-tab .banner .sidebar .photos-and-videos img {
  z-index: 2;
}
.activities-tab .banner .sidebar .photos-and-videos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.activities-tab .banner .sidebar .customer-photos {
  background-image: url("../images/search-park-general-page/customer-photos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.activities-tab .banner .sidebar .customer-photos h4 {
  z-index: 2;
}
.activities-tab .banner .sidebar .customer-photos img {
  z-index: 2;
}
.activities-tab .banner .sidebar .customer-photos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.activities-tab .banner .sidebar .virtual-tour {
  background-image: url("../images/search-park-general-page/virtual-tour.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.activities-tab .banner .sidebar .virtual-tour h4 {
  z-index: 2;
}
.activities-tab .banner .sidebar .virtual-tour img {
  z-index: 2;
}
.activities-tab .banner .sidebar .virtual-tour::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.activities-tab .banner .thumbnail {
  display: none;
}
@media (max-width: 767px) {
  .activities-tab .banner .thumbnail {
    background-image: url("../images/search-park-general-page/main-banner.jpg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 250px;
    display: block;
  }
}
.activities-tab .navigation {
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 6;
  position: relative;
  overflow: auto;
  margin: 0px auto;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .activities-tab .navigation {
    padding: 0px;
  }
}
.activities-tab .navigation ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .activities-tab .navigation ul {
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .activities-tab .navigation ul li {
    display: block;
  }
}
.activities-tab .navigation ul li a {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 15px 25px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .activities-tab .navigation ul li a {
    font-size: 16px;
    padding: 10px 0;
    margin: 0;
    width: max-content;
  }
}
.activities-tab .navigation ul li a span {
  white-space: nowrap;
}
.activities-tab .navigation ul li a img {
  position: absolute;
  right: 0px;
}
.activities-tab .navigation ul li a.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
@media (max-width: 767px) {
  .activities-tab .navigation ul li a.active {
    border-bottom: 5px solid #045e51;
  }
}
.activities-tab .text {
  margin-bottom: 30px;
}
.activities-tab .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  .activities-tab .text .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 31.2px */
    letter-spacing: 0.72px;
    text-align: center;
  }
}
.activities-tab .text .description {
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.4px;
  letter-spacing: 0.48px;
}
@media (max-width: 767px) {
  .activities-tab .text .description {
    display: none;
  }
}
.activities-tab .main-tabs .main-tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  background-color: #f3f5f5;
  padding: 0px 30px;
  overflow: auto;
  border-radius: 8px;
}
@media (max-width: 575px) {
  .activities-tab .main-tabs .main-tabs-nav {
    padding: 0px 50px;
  }
}
.activities-tab .main-tabs .main-tabs-nav .main-tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  padding-right: 40px;
  position: relative;
}
@media (max-width: 767px) {
  .activities-tab .main-tabs .main-tabs-nav .main-tab {
    padding: 15px 40px 15px 10px;
    font-size: 16px;
  }
}
.activities-tab .main-tabs .main-tabs-nav .main-tab span {
  white-space: nowrap;
}
.activities-tab .main-tabs .main-tabs-nav .main-tab img {
  position: absolute;
  right: 0px;
  max-width: 24px;
  max-height: 24px;
}
.activities-tab .main-tabs .main-tabs-nav .main-tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.activities-tab .main-tabs .result {
  padding: 0 15px;
}
.activities-tab .main-tabs .main-tabs-contents .main-tab-content {
  display: none;
}
.activities-tab .main-tabs .main-tabs-contents .main-tab-content.active {
  display: block;
}
.activities-tab .filters {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  gap: 10px;
  position: relative;
  border-radius: 15px;
  background-color: #ffffff;
  padding: 12px;
}
@media (max-width: 767px) {
  .activities-tab .filters {
    flex-direction: column;
    gap: 16px 0;
  }
}
.activities-tab .filters .container-md {
  display: flex;
  align-items: center;
}
.activities-tab .filters .search {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 325px;
  height: 46px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .activities-tab .filters .search {
    width: 100%;
    justify-content: space-between;
  }
}
.activities-tab .filters .search:hover input {
  border-color: #045e51;
}
.activities-tab .filters .search:hover button i {
  color: #045e51;
}
.activities-tab .filters .search input {
  padding: 0px 20px;
  border: 1px solid #abb5b7;
  border-radius: 50px;
  padding-left: 55px;
  width: 100%;
  height: 46px;
  outline: none;
}
.activities-tab .filters .search button {
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
  position: absolute;
  border: none;
  padding: 4px 0px;
  outline: none;
  background: none;
  width: 45px;
  height: 100%;
  border-radius: 50px 0px 0px 50px;
  cursor: pointer;
}
.activities-tab .filters .search button::before {
  content: "";
  border-right: 1px solid #d0d0d0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 20px;
  right: 0px;
}
.activities-tab .filters .select {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 114px;
  cursor: pointer;
  height: 46px;
}
@media (max-width: 767px) {
  .activities-tab .filters .select {
    width: 100%;
    position: relative;
  }
}
.activities-tab .filters .select.open .label {
  border-color: #000000;
}
.activities-tab .filters .select.open .label .arrow {
  transform: rotate(180deg);
}
.activities-tab .filters .select .label {
  border-radius: 50px;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0px 30px;
  height: 100%;
  position: relative;
  z-index: 6;
  outline: none;
}
@media (max-width: 767px) {
  .activities-tab .filters .select .label {
    width: 100%;
    justify-content: space-between;
  }
}
.activities-tab .filters .select .label .number {
  opacity: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #045e51;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.activities-tab .filters .select .label:hover {
  border-color: #045e51;
}
.activities-tab .filters .select .label:hover .arrow i {
  color: #045e51;
}
.activities-tab .filters .select .label .arrow {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  transition: transform 0.3s ease;
}
.activities-tab .filters .select .label .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
  margin: 0px;
}
.activities-tab .filters .select .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: 557px;
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 3;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  margin-top: 10px;
  padding: 25px;
  padding-top: 50px;
}
@media (max-width: 767px) {
  .activities-tab .filters .select .drop-down {
    width: 100%;
    top: 50px;
    z-index: 10;
  }
}
.activities-tab .filters .select .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  border-bottom: 1px solid #d0d0d0;
  overflow: auto;
}
@media (max-width: 575px) {
  .activities-tab .filters .select .drop-down .content .tabs .tabs-nav {
    padding: 0px 50px;
  }
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 10px 50px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  text-align: center;
  position: relative;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-contents .tab-content {
  display: none;
}
.activities-tab .filters .select .drop-down .content .tabs .tabs-contents .tab-content.active {
  display: block;
}
.activities-tab .filters .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767px) {
  .activities-tab .filters .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .activities-tab .filters .select .drop-down .content .form .checkboxes-rectangle .checkboxes-horizontal .checkbox-rectangle-white {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .activities-tab .filters .select .drop-down .content .form .checkboxes-rectangle label {
    padding: 15px;
    border-radius: 8px;
    width: 100%;
  }
}
.activities-tab .filters .select .drop-down .content .form .apartments {
  margin-bottom: 35px;
}
.activities-tab .filters .select .drop-down .content .form .apartments .cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.activities-tab .filters .select .drop-down .content .form .nav {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
  margin-top: 50px;
}
.activities-tab .filters .select .drop-down .content .form .nav .reset {
  outline: none;
  background: none;
  border: none;
  color: #045e51;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
  text-decoration-line: underline;
}
.activities-tab .posts-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
}
.activities-tab .posts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  margin-bottom: 150px;
}
@media (max-width: 575px) {
  .activities-tab .posts {
    display: flex;
    flex-direction: column;
    gap: 24px 0;
    margin-bottom: 120px;
  }
}
.activities-tab .posts .post {
  position: relative;
  border-radius: 9.065px;
  overflow: hidden;
}
.activities-tab .posts .post a {
  display: flex;
  align-items: flex-end;
  gap: 40px;
  background-color: #d7ffd0;
  box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
  padding: 22px;
  height: 281px;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 575px) {
  .activities-tab .posts .post a {
    height: 264px;
  }
}
.activities-tab .posts .post a .title {
  color: #ffffff;
  text-shadow: 0px 3.833px 28.748px #000;
  font-size: 24px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: right;
  position: relative;
  z-index: 1;
}
.activities-tab .posts .post a:before {
  content: "";
  display: block;
  width: 100%;
  height: 75%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.79) 100%);
  position: absolute;
  left: 0;
  bottom: 0;
}
.activities-tab .posts .post a .more {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background: rgb(4, 94, 81);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: center;
  width: 182px;
  height: 60px;
  border-radius: 70px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  opacity: 0;
}
.activities-tab .posts .post a:hover .more {
  opacity: 1;
}
.activities-tab .posts .post .load-more {
  margin-top: 40px;
  text-align: center;
}
.activities-tab .content {
  padding: 0 0 27px 0;
  color: rgb(64, 64, 64);
}
.activities-tab .content .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: right;
  margin-bottom: 5px;
}
.activities-tab .content .description {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: right;
}
.activities-tab .discover-other-parks {
  margin-bottom: 80px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks {
    overflow: hidden;
  }
}
.activities-tab .discover-other-parks .heading {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .heading {
    margin-bottom: 48px;
  }
}
.activities-tab .discover-other-parks .heading h2 {
  color: #045e51;
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .heading h2 {
    font-size: 24px;
  }
}
.activities-tab .discover-other-parks .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  background-color: #f3f5f5;
  padding: 0px 30px;
  overflow: auto;
  border-radius: 8px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .tabs .tabs-nav {
    padding: 0px;
    justify-content: center;
    gap: 0 40px;
    border-radius: 8px;
  }
}
.activities-tab .discover-other-parks .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  position: relative;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .tabs .tabs-nav .tab {
    font-size: 20px;
    margin: 0;
    padding: 15px 10px;
  }
}
.activities-tab .discover-other-parks .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.activities-tab .discover-other-parks .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.activities-tab .discover-other-parks .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.activities-tab .discover-other-parks .tabs .tabs-contents .tab-content {
  display: none;
}
.activities-tab .discover-other-parks .tabs .tabs-contents .tab-content.active {
  display: block;
}
.activities-tab .discover-other-parks .slider {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .slider {
    margin-bottom: 30px;
  }
}
.activities-tab .discover-other-parks .slider .slide {
  overflow: hidden;
  height: auto;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
}
@media (max-width: 767px) {
  .activities-tab .discover-other-parks .slider .slide {
    margin-bottom: 12px;
  }
}
.activities-tab .discover-other-parks .slider .slide .image img {
  width: 100%;
  height: 206px;
  object-fit: cover;
}
.activities-tab .discover-other-parks .slider .slide .text {
  padding: 16px 32px;
}
@media (max-width: 767px) {
  .activities-tab .discover-other-parks .slider .slide .text {
    font-size: 16px;
    padding: 12px;
  }
}
.activities-tab .discover-other-parks .slider .slide .text .title {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .slider .slide .text .title {
    font-size: 18px;
  }
}
.activities-tab .discover-other-parks .slider .slide .text .title a {
  color: #404040;
}
.activities-tab .discover-other-parks .slider .slide .text .title .status {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.activities-tab .discover-other-parks .slider .slide .text .description {
  margin-bottom: 8px;
}
.activities-tab .discover-other-parks .slider .slide .text .description p {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .slider .slide .text .description p {
    font-size: 14px;
  }
}
.activities-tab .discover-other-parks .slider .slide .text .date {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .slider .slide .text .date {
    font-size: 16px;
  }
}
.activities-tab .discover-other-parks .slider .slide .text .cost {
  margin-left: 30px;
}
.activities-tab .discover-other-parks .slider .slide .text .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.activities-tab .discover-other-parks .slider .slide .text .cost .price .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
  margin: 0px;
}
.activities-tab .discover-other-parks .slider .slide .text .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
}
.activities-tab .discover-other-parks .slider .slide .text .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.activities-tab .discover-other-parks .slider .slide .text .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.activities-tab .discover-other-parks .slider .slide .button {
  margin-top: auto;
}
.activities-tab .discover-other-parks .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 575px) {
  .activities-tab .discover-other-parks .slider .slick-list {
    padding-left: 80px;
  }
}
.activities-tab .discover-other-parks .slider .slick-slide {
  margin: 0 15px;
}
.activities-tab .discover-other-parks .slider .slick-track {
  padding: 10px 0px;
  display: flex !important;
}
.activities-tab .discover-other-parks .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  left: -70px;
}
@media (max-width: 767px) {
  .activities-tab .discover-other-parks .slider .slick-next {
    display: none;
  }
}
.activities-tab .discover-other-parks .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.activities-tab .discover-other-parks .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.activities-tab .discover-other-parks .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.activities-tab .discover-other-parks .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.activities-tab .discover-other-parks .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  right: -70px;
}
@media (max-width: 767px) {
  .activities-tab .discover-other-parks .slider .slick-prev {
    display: none;
  }
}
.activities-tab .discover-other-parks .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.activities-tab .discover-other-parks .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.activities-tab .discover-other-parks .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.activities-tab .discover-other-parks .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .activities-tab .discover-other-parks .button {
    text-align: center;
  }
  .activities-tab .discover-other-parks .button a {
    padding: 17px 30px;
    font-size: 16px;
  }
}

.eat-and-shop-tab {
  transform: translateY(-7px);
}
@media (max-width: 767px) {
  .eat-and-shop-tab {
    transform: none;
  }
}
.eat-and-shop-tab .text {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .text {
    margin-bottom: 32px;
  }
}
.eat-and-shop-tab .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 8px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .text .title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
  }
}
.eat-and-shop-tab .text .description {
  color: #404040;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
  text-align: right;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .text .main-description {
    display: none;
  }
}
.eat-and-shop-tab .eat-and-shop-types {
  margin: 70px 0 60px 0;
}
.eat-and-shop-tab .eat-and-shop-types .container-md {
  display: flex;
  gap: 0 50px;
  max-width: 739px;
  margin: 0px auto;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop-types .container-md {
    padding: 0 16px;
  }
}
.eat-and-shop-tab .eat-and-shop-types .container-md .type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  flex: 1;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop-types .container-md .type {
    justify-content: normal;
  }
}
.eat-and-shop-tab .eat-and-shop-types .container-md .type .title {
  text-align: center;
  white-space: nowrap;
  margin-top: 19px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop-types .container-md .type .title {
    white-space: normal;
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md {
    padding: 0 16px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category-title {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category-title {
    margin-bottom: 40px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category-title h2 {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.eat-and-shop-tab .eat-and-shop .container-md .category {
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category {
    margin-bottom: 40px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 60px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .posts {
    grid-template-columns: none;
    gap: 22px;
    margin-bottom: 40px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts:last-child {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .posts:last-child {
    margin-bottom: 60px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post {
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .image img {
  width: 100%;
  height: 182px;
  object-fit: cover;
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text {
  padding: 20px 20px 33px 20px;
  margin: 0;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text {
    padding: 24px;
    margin-bottom: 0;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text .title {
  color: #404040;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0.48px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text .title {
    text-align: start;
    margin-bottom: 16px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text .description {
  color: #404040;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.03em;
  text-align: right;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.eat-and-shop-tab .eat-and-shop .container-md .category .posts .post .text .description p {
  font-size: 16px;
  font-weight: 300;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .load-more {
    text-align: center;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .load-more button {
  height: 60px;
  width: 305px;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: center;
  justify-content: space-between;
  padding-right: 50px;
}
@media (max-width: 767px) {
  .eat-and-shop-tab .eat-and-shop .container-md .category .load-more button {
    width: 220px;
    padding: 0 15px;
  }
}
.eat-and-shop-tab .eat-and-shop .container-md .category .load-more button:after {
  content: "\f078";
  font-family: Font Awesome\ 6 Pro;
  font-weight: 300;
  color: #fff;
}

.practical-information-tab .banner {
  height: 480px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner {
    display: flex;
    height: auto;
    flex-direction: column-reverse;
  }
}
.practical-information-tab .banner .video {
  background-image: url("../images/search-park-general-page/main-banner.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video {
    height: auto;
    background: none;
  }
}
.practical-information-tab .banner .video::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video::before {
    display: none;
  }
}
.practical-information-tab .banner .video .book-now {
  border-radius: 15px 15px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  height: 88.775px;
  flex-shrink: 0;
  position: absolute;
  right: 10%;
  bottom: 0px;
  z-index: 6;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now {
    flex-wrap: wrap;
    position: relative;
    height: auto;
    right: 0;
    box-shadow: none;
    justify-content: space-between;
    padding: 12px;
    align-items: start;
  }
}
.practical-information-tab .banner .video .book-now .park {
  margin-left: 10px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .park {
    width: 50%;
  }
}
.practical-information-tab .banner .video .book-now .park .title {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .park .title {
    margin-bottom: 8px;
  }
}
.practical-information-tab .banner .video .book-now .park .title .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.practical-information-tab .banner .video .book-now .park .location {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #045e51;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: 0.28px;
}
.practical-information-tab .banner .video .book-now .park .location i {
  color: #045e51;
  font-size: 20px;
}
.practical-information-tab .banner .video .book-now .park-map {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 9.236px;
  background: #f4f5f5;
  color: #045e51;
  font-weight: 700;
  letter-spacing: 0.45px;
  width: 127px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .park-map {
    width: 152px;
    height: 44px;
    font-size: 14px;
    padding: 10px;
    justify-content: center;
  }
}
.practical-information-tab .banner .video .book-now .park-map a {
  font-size: 16px;
  color: #045e51;
  word-break: break-all;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .park-map a {
    font-size: 14px;
  }
}
.practical-information-tab .banner .video .book-now .park-map i {
  color: #045e51;
  font-size: 28px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .park-map i {
    font-size: 20px;
  }
}
.practical-information-tab .banner .video .book-now .sep {
  width: 1px;
  height: 59px;
  margin: 0px 15px;
  background-color: #d0d0d0;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .video .book-now .sep {
    display: none;
  }
}
.practical-information-tab .banner .video .book-now .cost {
  margin-left: 30px;
}
.practical-information-tab .banner .video .book-now .cost .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
}
.practical-information-tab .banner .video .book-now .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.practical-information-tab .banner .video .book-now .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
}
.practical-information-tab .banner .video .book-now .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.practical-information-tab .banner .video .book-now .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.practical-information-tab .banner .sidebar {
  display: grid;
  grid-template-columns: 250px;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .sidebar {
    display: flex;
  }
}
@media (max-width: 767px) {
  .practical-information-tab .banner .sidebar > div {
    height: 100px;
    background-size: cover;
    width: calc(33.3333333333% - 0px);
  }
  .practical-information-tab .banner .sidebar > div h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.practical-information-tab .banner .sidebar .photos-and-videos {
  background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.practical-information-tab .banner .sidebar .photos-and-videos h4 {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .photos-and-videos img {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .photos-and-videos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.practical-information-tab .banner .sidebar .customer-photos {
  background-image: url("../images/search-park-general-page/customer-photos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.practical-information-tab .banner .sidebar .customer-photos h4 {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .customer-photos img {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .customer-photos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.practical-information-tab .banner .sidebar .virtual-tour {
  background-image: url("../images/search-park-general-page/virtual-tour.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.practical-information-tab .banner .sidebar .virtual-tour h4 {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .virtual-tour img {
  z-index: 2;
}
.practical-information-tab .banner .sidebar .virtual-tour::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.practical-information-tab .banner .thumbnail {
  display: none;
}
@media (max-width: 767px) {
  .practical-information-tab .banner .thumbnail {
    background-image: url("../images/search-park-general-page/main-banner.jpg");
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    height: 250px;
    display: block;
  }
}
.practical-information-tab .navigation {
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 6;
  position: relative;
  overflow: auto;
  margin: 0px auto;
  margin-bottom: 35px;
}
@media (max-width: 767px) {
  .practical-information-tab .navigation {
    padding: 0px;
  }
}
.practical-information-tab .navigation ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 767px) {
  .practical-information-tab .navigation ul {
    gap: 10px;
  }
}
@media (max-width: 767px) {
  .practical-information-tab .navigation ul li {
    display: block;
  }
}
.practical-information-tab .navigation ul li a {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 15px 25px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .practical-information-tab .navigation ul li a {
    font-size: 16px;
    padding: 10px 0;
    margin: 0;
    width: max-content;
  }
}
.practical-information-tab .navigation ul li a span {
  white-space: nowrap;
}
.practical-information-tab .navigation ul li a img {
  position: absolute;
  right: 0px;
}
.practical-information-tab .navigation ul li a.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
@media (max-width: 767px) {
  .practical-information-tab .navigation ul li a.active {
    border-bottom: 5px solid #045e51;
  }
}
.practical-information-tab .access-and-itinerary {
  margin-bottom: 168px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary {
    margin-bottom: 120px;
  }
}
.practical-information-tab .access-and-itinerary .heading .sub-title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .heading .sub-title {
    font-size: 16px;
    margin-bottom: 8px;
  }
}
.practical-information-tab .access-and-itinerary .heading .title {
  color: #404040;
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .heading .title {
    font-size: 24px;
  }
}
.practical-information-tab .access-and-itinerary .adresse {
  margin-bottom: 40px;
  display: flex;
  gap: 20px;
}
.practical-information-tab .access-and-itinerary .adresse .icon i {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .access-and-itinerary .adresse .text .title {
  color: #404040;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .adresse .text .title {
    margin: 5px 0 20px 0;
  }
}
.practical-information-tab .access-and-itinerary .adresse .street {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 14px;
  position: relative;
}
.practical-information-tab .access-and-itinerary .adresse .street i {
  font-size: 15px;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #d7ffd0;
  color: #045e51;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .adresse .street i {
    position: absolute;
    bottom: 100%;
    left: 0;
    margin-bottom: 15px;
  }
}
.practical-information-tab .access-and-itinerary .adresse .nav {
  display: flex;
  gap: 30px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .adresse .nav {
    flex-direction: column;
    gap: 23px;
    width: calc(100% + 60px);
    transform: translateX(60px);
  }
  .practical-information-tab .access-and-itinerary .adresse .nav a {
    width: 100%;
    font-size: 20px;
    line-height: 26px;
    display: flex;
    padding: 17px;
  }
}
.practical-information-tab .access-and-itinerary .information {
  display: flex;
  gap: 40px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information {
    flex-direction: column;
  }
}
.practical-information-tab .access-and-itinerary .information .indications {
  display: flex;
  justify-content: space-between;
  gap: 230px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications {
    flex-direction: column;
    gap: 40px 0;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .train-station {
  position: relative;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .train-station {
    padding-right: 50px;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .train-station .title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .train-station .title {
    margin-bottom: 0;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .train-station .title i {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .train-station .title i {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .train-station .description h5 {
  margin-bottom: 6px;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .train-station .description {
    padding-right: 32px;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .train-station .description p {
  margin-bottom: 5px;
}
.practical-information-tab .access-and-itinerary .information .indications .airport {
  position: relative;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .airport {
    padding-right: 50px;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .airport .title {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 14px;
}
.practical-information-tab .access-and-itinerary .information .indications .airport .title i {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
@media (max-width: 767px) {
  .practical-information-tab .access-and-itinerary .information .indications .airport .title i {
    position: absolute;
    right: 0;
    top: 0;
  }
}
.practical-information-tab .access-and-itinerary .information .indications .airport .description h5 {
  margin-bottom: 6px;
}
.practical-information-tab .access-and-itinerary .information .indications .airport .description p {
  margin-bottom: 5px;
}
.practical-information-tab .your-stay .container-md .heading {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .practical-information-tab .your-stay .container-md .heading {
    font-size: 24px;
  }
}
.practical-information-tab .your-stay .container-md .content {
  border-radius: 13.587px;
  border: 3px solid #E9E9E9;
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .practical-information-tab .your-stay .container-md .content {
    padding: 0;
    border: none;
  }
}
.practical-information-tab .your-stay .container-md .content .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  padding: 27px 40px;
}
@media (max-width: 767px) {
  .practical-information-tab .your-stay .container-md .content .grid {
    display: flex;
    flex-direction: column;
  }
}
.practical-information-tab .your-stay .container-md .content .grid:first-child {
  background-color: #f9fbfb;
}
@media (max-width: 767px) {
  .practical-information-tab .your-stay .container-md .content .grid .column:nth-child(1), .practical-information-tab .your-stay .container-md .content .grid .column:nth-child(2) {
    background: #F9FBFB;
    padding: 30px 15px;
    border: none;
    width: calc(100% + 20px);
    transform: translateX(10px);
    border-radius: 0;
  }
}
@media (max-width: 767px) {
  .practical-information-tab .your-stay .container-md .content .grid .column:nth-child(2) {
    margin-top: -30px;
  }
}
.practical-information-tab .your-stay .container-md .content .reception .head {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 12px;
}
.practical-information-tab .your-stay .container-md .content .reception .title {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
}
.practical-information-tab .your-stay .container-md .content .reception .phone {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .reception .phone .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.66px;
}
.practical-information-tab .your-stay .container-md .content .reception .phone .description .number {
  display: flex;
  align-items: center;
  gap: 14px;
}
.practical-information-tab .your-stay .container-md .content .reception .phone .description .number a {
  color: #404040;
  direction: ltr;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .reception .phone .description .number i {
  font-size: 15px;
  display: flex;
  width: 30px;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #d7ffd0;
  color: #045e51;
}
.practical-information-tab .your-stay .container-md .content .reception .phone .description .link a {
  color: #045e51;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .reception .flags {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .reception .flags .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.66px;
}
.practical-information-tab .your-stay .container-md .content .reception .flags .description .flags {
  display: flex;
  gap: 20px;
}
.practical-information-tab .your-stay .container-md .content .reception .flags .description .flags .flag {
  display: flex;
  gap: 10px;
}
.practical-information-tab .your-stay .container-md .content .reception .text {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .reception .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.66px;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .head {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 12px;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .title {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time {
  display: flex;
  gap: 10px;
  margin-bottom: 30px;
  position: relative;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time:last-child::before {
  display: none;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time::before {
  content: "";
  height: calc(100% + 30px);
  width: 1px;
  border: 1px dashed #045e51;
  position: absolute;
  right: 7px;
  top: 0;
  z-index: 1;
  transform: translateX(-50%);
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time .text-icon {
  flex-shrink: 0;
  position: relative;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time .text-icon img {
  background-color: #ffffff;
  position: relative;
  z-index: 2;
}
.practical-information-tab .your-stay .container-md .content .arrival-times .period-of-time .time .text .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.66px;
  margin-bottom: 6px;
}
.practical-information-tab .your-stay .container-md .content .car {
  display: flex;
  gap: 20px;
}
.practical-information-tab .your-stay .container-md .content .car .car-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .car .car-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .head {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 12px;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text .title {
  color: #404040;
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text .description {
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text-with-icon {
  display: flex;
  gap: 30px;
  margin-right: -60px;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text-with-icon .text-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .car .car-content .text-with-icon .text-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .your-stay .container-md .content .payment {
  display: flex;
  gap: 20px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .head {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 12px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text .title {
  color: #404040;
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text .description {
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text-with-icon {
  display: flex;
  gap: 20px;
  margin-right: -60px;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text-with-icon .text-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .payment .payment-content .text-with-icon .text-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .your-stay .container-md .content .pets {
  display: flex;
  gap: 20px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .head {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  margin-bottom: 12px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text {
  margin-bottom: 30px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text .title {
  color: #404040;
  display: flex;
  align-items: center;
  gap: 14px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text .description {
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
  letter-spacing: 0.54px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text-with-icon {
  display: flex;
  gap: 30px;
  margin-right: -60px;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text-with-icon .text-icon {
  flex-shrink: 0;
}
.practical-information-tab .your-stay .container-md .content .pets .pets-content .text-with-icon .text-icon img {
  font-size: 20px;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 20.002px;
  background: #f4f5f5;
}

.photos-and-videos-tab .filter {
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background: #ffffff;
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  height: 70px;
}
.photos-and-videos-tab .filter .container-md {
  display: flex;
  align-items: center;
  position: relative;
}
.photos-and-videos-tab .filter .sorting {
  display: flex;
  align-items: center;
  height: 70px;
}
.photos-and-videos-tab .filter .sorting .title {
  color: #d0d0d0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}
.photos-and-videos-tab .filter .sorting .select {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 114px;
  border-left: 1px solid #d0d0d0;
  position: relative;
  cursor: pointer;
}
.photos-and-videos-tab .filter .sorting .select.open .label .arrow {
  transform: rotate(180deg);
}
.photos-and-videos-tab .filter .sorting .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0px 30px;
  height: 100%;
  position: relative;
  z-index: 6;
}
.photos-and-videos-tab .filter .sorting .select .label .arrow {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  transition: transform 0.3s ease;
}
.photos-and-videos-tab .filter .sorting .select .label .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.photos-and-videos-tab .filter .sorting .select .drop-down {
  display: none;
  background: #ffffff;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 25px;
  padding-top: 50px;
  padding: 16px 26px;
}
.photos-and-videos-tab .filter .sorting .select .drop-down ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.photos-and-videos-tab .filter .sorting .select .drop-down ul li a {
  transition: all 0.3s linear;
}
.photos-and-videos-tab .filter .sorting .select .drop-down ul li a:hover {
  color: #025f52;
}
.photos-and-videos-tab .order-by {
  margin-bottom: 30px;
}
.photos-and-videos-tab .order-by .container-md {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.photos-and-videos-tab .order-by .title {
  color: #404040;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.photos-and-videos-tab .order-by .select label {
  color: #404040;
  font-size: 16px;
  margin-left: 20px;
}
.photos-and-videos-tab .photos-and-videos {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos {
    gap: 60px 0;
  }
}
.photos-and-videos-tab .photos-and-videos .video + * {
  margin-top: 60px;
}
.photos-and-videos-tab .photos-and-videos .video .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .video .title {
    text-align: center;
  }
}
.photos-and-videos-tab .photos-and-videos .video .video-container {
  position: relative;
}
.photos-and-videos-tab .photos-and-videos .video .video-container .play-button {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 100px;
  opacity: 0.8;
  background: #ffffff;
  border: 0px;
  padding: 16px 38px;
  color: #404040;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .video .video-container .play-button {
    font-size: 16px;
    width: 192px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 8px;
    padding: 0;
  }
}
.photos-and-videos-tab .photos-and-videos .video .video-container .play-button i {
  margin-right: 18px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .video .video-container .play-button i {
    font-size: 22px;
    color: #404040;
  }
}
.photos-and-videos-tab .photos-and-videos .video .video-container .pause-button {
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-radius: 100px;
  opacity: 0.8;
  background: #ffffff;
  border: 0px;
  padding: 16px 38px;
  color: #404040;
  font-size: 22px;
  line-height: 22px;
  font-weight: 600;
  cursor: pointer;
}
.photos-and-videos-tab .photos-and-videos .video .video-container .pause-button i {
  margin-right: 18px;
}
.photos-and-videos-tab .photos-and-videos .video .video-container .plyr {
  height: 799px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .video .video-container .plyr {
    height: auto;
  }
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type {
  margin-top: 60px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 30px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid {
  margin: 0 auto;
  display: grid;
  gap: 6px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid:after {
  content: "";
  display: block;
  clear: both;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(3) {
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(4) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 490px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(5) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 490px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(6) {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 490px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 7;
  height: 350px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(8) {
  grid-column-start: 4;
  grid-column-end: 7;
  grid-row-start: 5;
  grid-row-end: 7;
  height: 350px;
}
.photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(9) {
  grid-column-start: 7;
  grid-column-end: 12;
  grid-row-start: 5;
  grid-row-end: 7;
  height: 350px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .gallery-first-type .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2px;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item {
    width: calc(50% - 1px);
    height: auto !important;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item img {
    width: 100%;
    height: 136px;
    object-fit: cover;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(3n) {
    width: 100%;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-first-type .grid-item:nth-child(3n) img {
    height: 274px;
  }
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type {
  margin-top: 60px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .title {
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
  margin-bottom: 30px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid {
  display: grid;
  gap: 6px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(1) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412.19px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(2) {
  grid-column-start: 5;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412.19px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(3) {
  grid-column-start: 9;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 3;
  height: 412.19px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(4) {
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 497.89px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(5) {
  grid-column-start: 5;
  grid-column-end: 8;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 497.89px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(6) {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 3;
  grid-row-end: 5;
  height: 497.89px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 4;
  grid-row-start: 5;
  grid-row-end: 7;
  height: 353px;
}
.photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(8) {
  grid-column-start: 4;
  grid-column-end: 12;
  grid-row-start: 5;
  grid-row-end: 7;
  height: 353px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .photos-and-videos .gallery-second-type .grid {
    display: flex;
    flex-wrap: wrap;
    gap: 0 2px;
    height: auto;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image {
    width: calc(50% - 1px);
    height: auto !important;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image img {
    width: 100%;
    height: 136px;
    object-fit: cover;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(3n) {
    width: 100%;
  }
  .photos-and-videos-tab .photos-and-videos .gallery-second-type .grid .image:nth-child(3n) img {
    height: 274px;
  }
}
.photos-and-videos-tab .discover-other-parks {
  margin-bottom: 80px;
}
.photos-and-videos-tab .discover-other-parks .heading {
  margin-bottom: 30px;
}
.photos-and-videos-tab .discover-other-parks .heading h2 {
  color: #045e51;
  font-size: 35px;
  font-weight: 700;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .heading h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: right;
  }
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-nav {
  display: flex;
  gap: 0px;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  background-color: #f3f5f5;
  padding: 0px 30px;
  overflow: auto;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .tabs .tabs-nav {
    padding: 0px;
    justify-content: center;
    gap: 0 40px;
    border-radius: 8px;
  }
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 20px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  position: relative;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .tabs .tabs-nav .tab {
    font-size: 20px;
    margin: 0;
    padding: 15px 10px;
  }
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-nav .tab span {
  white-space: nowrap;
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-nav .tab img {
  position: absolute;
  right: 0px;
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-contents .tab-content {
  display: none;
}
.photos-and-videos-tab .discover-other-parks .tabs .tabs-contents .tab-content.active {
  display: block;
}
.photos-and-videos-tab .discover-other-parks .slider {
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .slider {
    margin-bottom: 30px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide {
  overflow: hidden;
  height: auto;
  border-radius: 14px;
  background-color: #ffffff;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide {
    margin-bottom: 12px;
    border-radius: 6.762px;
    height: auto;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .image img {
  width: 100%;
  height: 206px;
  object-fit: cover;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide .image img {
    height: 139px;
    border-radius: 6.762px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text {
  padding: 16px 32px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide .text {
    font-size: 16px;
    padding: 12px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .title {
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide .text .title {
    font-size: 18px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .title a {
  color: #404040;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .title .status {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .description {
  margin-bottom: 8px;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .description p {
  font-size: 16px;
  line-height: 20px;
  color: #404040;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide .text .description p {
    font-size: 14px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .date {
  display: flex;
  gap: 10px;
  margin-bottom: 8px;
  color: #404040;
  font-size: 18px;
  line-height: 23.4px;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .slider .slide .text .date {
    font-size: 16px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost {
  margin-left: 30px;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost .price .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
  margin: 0px;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .text .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.photos-and-videos-tab .discover-other-parks .slider .slide .button {
  margin-top: auto;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-list {
  margin: 0 -15px;
}
@media (max-width: 575px) {
  .photos-and-videos-tab .discover-other-parks .slider .slick-list {
    padding-left: 80px;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slick-slide {
  margin: 0 15px;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-track {
  padding: 10px 0px;
  display: flex !important;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  left: -70px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .slider .slick-next {
    display: none !important;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slick-next::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-next:hover {
  background-color: #d0d0d0;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-next:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-next:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-prev {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f5f5;
  background-color: #f3f5f5;
  transition: all 0.3s linear;
  z-index: 999;
  right: -70px;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .slider .slick-prev {
    display: none;
  }
}
.photos-and-videos-tab .discover-other-parks .slider .slick-prev::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: #045e51;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-prev:hover {
  background-color: #d0d0d0;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-prev:focus {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
.photos-and-videos-tab .discover-other-parks .slider .slick-prev:active {
  box-shadow: 0px 0px 10px 0px #abb5b7;
}
@media (max-width: 767px) {
  .photos-and-videos-tab .discover-other-parks .button {
    text-align: center;
  }
  .photos-and-videos-tab .discover-other-parks .button a {
    padding: 17px 30px;
    font-size: 16px;
  }
}

.park-page {
  overflow: hidden;
}
.park-page .banner {
  height: 480px;
  display: grid;
  grid-template-columns: 1fr 250px;
  grid-template-rows: 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .park-page .banner {
    display: flex;
    height: auto;
    flex-direction: column;
  }
}
.park-page .banner .video {
  background-image: url("../images/search-park-general-page/main-banner.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}
@media (max-width: 767px) {
  .park-page .banner .video {
    height: 250px;
  }
}
.park-page .banner .video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
}
.park-page .banner .video::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.park-page .banner .sidebar {
  display: grid;
  grid-template-columns: 250px;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 5px;
}
@media (max-width: 767px) {
  .park-page .banner .sidebar {
    display: flex;
  }
}
@media (max-width: 767px) {
  .park-page .banner .sidebar > div {
    height: 100px;
    background-size: cover;
    width: calc(33.3333333333% - 0px);
  }
  .park-page .banner .sidebar > div h4 {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.park-page .banner .sidebar .photos-and-videos {
  background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.park-page .banner .sidebar .photos-and-videos h4 {
  z-index: 2;
}
.park-page .banner .sidebar .photos-and-videos img {
  z-index: 2;
}
.park-page .banner .sidebar .photos-and-videos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.park-page .banner .sidebar .customer-photos {
  background-image: url("../images/search-park-general-page/customer-photos.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.park-page .banner .sidebar .customer-photos h4 {
  z-index: 2;
}
.park-page .banner .sidebar .customer-photos img {
  z-index: 2;
}
.park-page .banner .sidebar .customer-photos::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.park-page .banner .sidebar .virtual-tour {
  background-image: url("../images/search-park-general-page/virtual-tour.jpg");
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  position: relative;
}
.park-page .banner .sidebar .virtual-tour h4 {
  z-index: 2;
}
.park-page .banner .sidebar .virtual-tour img {
  z-index: 2;
}
.park-page .banner .sidebar .virtual-tour::before {
  z-index: 1;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
}
.park-page .park-book {
  position: relative;
  height: 1px;
  width: 100%;
}
@media (max-width: 767px) {
  .park-page .park-book {
    height: auto;
  }
}
.park-page .park-book .container-md {
  position: relative;
}
.park-page .park-book .book-now {
  right: 10px;
  border-radius: 15px 15px 0px 0px;
  background: #ffffff;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  height: 88.775px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0px;
  z-index: 6;
  display: flex;
  align-items: center;
  padding: 20px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now {
    flex-wrap: wrap;
    position: relative;
    height: auto;
    right: 0;
    box-shadow: none;
    justify-content: space-between;
    padding: 12px;
    align-items: start;
  }
}
.park-page .park-book .book-now .park {
  margin-left: 40px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .park {
    width: 151px;
  }
}
.park-page .park-book .book-now .park .title {
  color: #404040;
  font-size: 22px;
  font-weight: 700;
  line-height: 28.6px;
  letter-spacing: 0.66px;
  display: flex;
  align-items: center;
  gap: 15px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .park .title {
    margin-bottom: 8px;
  }
}
.park-page .park-book .book-now .park .title .tag {
  padding: 8px;
  border-radius: 3px;
  background: #d7ffd0;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  letter-spacing: 0.3px;
}
.park-page .park-book .book-now .park .location {
  display: flex;
  gap: 5px;
  align-items: center;
  color: #045e51;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: 0.28px;
}
.park-page .park-book .book-now .park .location i {
  color: #045e51;
  font-size: 20px;
}
.park-page .park-book .book-now .park-map {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 9.236px;
  background: #f4f5f5;
  color: #045e51;
  font-weight: 700;
  letter-spacing: 0.45px;
  width: 127px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .park-map {
    width: 152px;
    height: 44px;
    font-size: 14px;
    padding: 10px;
    justify-content: center;
  }
}
.park-page .park-book .book-now .park-map a {
  font-size: 16px;
  color: #045e51;
  word-break: break-all;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .park-map a {
    font-size: 14px;
  }
}
.park-page .park-book .book-now .park-map i {
  color: #045e51;
  font-size: 28px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .park-map i {
    font-size: 20px;
  }
}
.park-page .park-book .book-now .sep {
  width: 1px;
  height: 59px;
  margin: 0px 15px;
  background-color: #d0d0d0;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .sep {
    display: none;
  }
}
.park-page .park-book .book-now .cost {
  margin-left: 18px;
}
.park-page .park-book .book-now .cost .title {
  color: #d0d0d0;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  letter-spacing: -0.45px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .cost .title {
    display: none;
  }
}
.park-page .park-book .book-now .cost .price {
  display: flex;
  gap: 5px;
  align-items: flex-end;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .cost .price {
    display: none;
  }
}
.park-page .park-book .book-now .cost .price .info {
  color: #abb5b7;
  align-self: flex-start;
  font-size: 12px;
}
.park-page .park-book .book-now .cost .price .was {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17.357px;
  text-decoration: line-through;
}
.park-page .park-book .book-now .cost .price .now {
  display: flex;
  gap: 5px;
  direction: ltr;
  color: #e85481;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.6px;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .cost .nights {
    font-size: 12px;
    margin-top: 4px;
  }
}
.park-page .park-book .book-now .button a {
  width: 130px;
  height: 42px;
  font-size: 15px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  padding: 0;
}
@media (max-width: 767px) {
  .park-page .park-book .book-now .button a {
    width: 206px;
  }
}
@media (max-width: 767px) {
  .park-page .park-tabs {
    padding: 0px;
  }
}
.park-page .park-tabs .search-form-parks.without-parks, .park-page .park-tabs .search-form-parks.event-page {
  margin: 0 auto 60px;
}
.park-page .park-tabs .park-tabs-nav {
  padding: 0px;
  list-style: none;
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  background: #ffffff;
  margin-bottom: 35px;
}
.park-page .park-tabs .park-tabs-nav .container-md {
  display: flex;
  display: flex;
  gap: 0px;
  z-index: 6;
  position: relative;
  overflow: auto;
  margin: 0px auto;
  gap: 0px;
  transform: translateX(28px);
}
@media (max-width: 767px) {
  .park-page .park-tabs .park-tabs-nav .container-md {
    justify-content: flex-start;
    gap: 17px;
    white-space: nowrap;
    transform: none;
  }
}
.park-page .park-tabs .park-tabs-nav .park-tab {
  color: #404040;
  text-align: center;
  font-family: "Almoni", sans-serif;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 19px 28px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  position: relative;
  letter-spacing: 0.03em;
  position: relative;
}
@media (max-width: 767px) {
  .park-page .park-tabs .park-tabs-nav .park-tab {
    font-size: 16px;
    padding: 10px;
    margin: 0;
    width: max-content;
  }
}
.park-page .park-tabs .park-tabs-nav .park-tab:before {
  content: "";
  width: 3px;
  height: 3px;
  border-radius: 50%;
  display: block;
  background: rgb(64, 64, 64);
  position: absolute;
  left: 0;
  transform: translateY(-50%);
  top: 50%;
}
.park-page .park-tabs .park-tabs-nav .park-tab span {
  white-space: nowrap;
}
.park-page .park-tabs .park-tabs-nav .park-tab img {
  position: absolute;
  right: 0px;
}
.park-page .park-tabs .park-tabs-nav .park-tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
@media (max-width: 767px) {
  .park-page .park-tabs .park-tabs-nav .park-tab.active {
    border-bottom: 5px solid #045e51;
  }
}
.park-page .park-tabs .park-tabs-nav .park-tab:last-child:before {
  display: none;
}
.park-page .park-tabs .park-tabs-contents .park-tab-content {
  display: none;
}
.park-page .park-tabs .park-tabs-contents .park-tab-content.active {
  display: block;
}

.modal-accommodation {
  position: relative;
  background: #fff;
  width: auto;
  max-width: 1024px;
  margin: 0 auto;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 40px 10px 15px;
}
.modal-accommodation .accommodation-slider-galery .slick-arrow {
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3294117647);
  transition: all 0.3s linear;
  z-index: 9;
}
.modal-accommodation .accommodation-slider-galery .slick-arrow.slick-prev {
  right: unset;
  left: 5px;
}
.modal-accommodation .accommodation-slider-galery .slick-arrow.slick-prev::before {
  content: "\f053";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: white;
}
.modal-accommodation .accommodation-slider-galery .slick-arrow.slick-next {
  right: 5px;
}
.modal-accommodation .accommodation-slider-galery .slick-arrow.slick-next::before {
  content: "\f054";
  font-family: "Font Awesome 6 Pro";
  font-weight: 700;
  color: white;
}
.modal-accommodation .accommodation-slider-galery .slide {
  max-height: 450px;
  height: 450px;
}
.modal-accommodation .accommodation-slider-galery .slide img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  -o-object-position: 50% 50%;
  object-position: 50% 50%;
}
.modal-accommodation .mfp-close {
  font-size: 50px;
}

.accomodation-page .back {
  margin: 30px 0px;
  color: #000000;
  font-size: 30px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .accomodation-page .back {
    font-size: 20px;
    margin: 18px 0;
  }
}
.accomodation-page .back i {
  margin-left: 10px;
}
.accomodation-page .thumbnails {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  direction: ltr;
  border-radius: 30px;
  overflow: hidden;
  height: 660px;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails {
    grid-template-columns: repeat(3, 1fr);
    border-radius: 0;
    margin: 0 -10px;
  }
}
.accomodation-page .thumbnails .thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails .thumbnail {
    display: none;
  }
}
.accomodation-page .thumbnails .thumbnail:nth-child(1) {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 3;
  grid-column-end: 3;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails .thumbnail:nth-child(1) {
    display: block;
    grid-row-end: 1;
    grid-column-end: 4;
    max-height: 390px;
  }
}
.accomodation-page .thumbnails .thumbnail:nth-child(2) {
  grid-row-start: 1;
  grid-column-start: 3;
  grid-row-end: 2;
  grid-column-end: 4;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails .thumbnail:nth-child(2) {
    display: block;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-column-end: 2;
  }
}
.accomodation-page .thumbnails .thumbnail:nth-child(3) {
  grid-row-start: 1;
  grid-column-start: 4;
  grid-row-end: 2;
  grid-column-end: 5;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails .thumbnail:nth-child(3) {
    display: block;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-row-end: 2;
    grid-column-end: 3;
  }
}
.accomodation-page .thumbnails .thumbnail:nth-child(4) {
  grid-row-start: 2;
  grid-column-start: 3;
  grid-row-end: 3;
  grid-column-end: 4;
}
.accomodation-page .thumbnails .thumbnail:nth-child(5) {
  grid-row-start: 2;
  grid-column-start: 4;
  grid-row-end: 3;
  grid-column-end: 5;
  cursor: pointer;
}
@media (max-width: 767px) {
  .accomodation-page .thumbnails .thumbnail:nth-child(5) {
    display: block;
    grid-row-start: 2;
    grid-column-start: 3;
    grid-row-end: 2;
    grid-column-end: 4;
  }
}
.accomodation-page .reference {
  color: #7d7d7d;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .reference {
    font-size: 14px;
    margin-bottom: 5px;
    margin-top: 20px;
    padding: 0 5px;
  }
}
.accomodation-page .services {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30%;
  width: 100%;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .accomodation-page .services {
    flex-wrap: wrap;
    margin-bottom: 60px;
    padding: 0 5px;
  }
}
.accomodation-page .services .info .title {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .title {
    display: block;
    margin-bottom: 25px;
  }
}
.accomodation-page .services .info .title h4 {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
}
.accomodation-page .services .info .title .facilities {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .title .facilities {
    gap: 20px;
    margin-top: 14px;
  }
}
.accomodation-page .services .info .title .facilities img {
  width: 36px;
  height: 36px;
  object-fit: cover;
}
.accomodation-page .services .info .meta-data {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data {
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
}
.accomodation-page .services .info .meta-data .parameters {
  display: flex;
  gap: 20px;
  align-items: center;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data .parameters {
    gap: 6px;
  }
}
.accomodation-page .services .info .meta-data .parameters img {
  height: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data .parameters img {
    height: 16px;
  }
}
.accomodation-page .services .info .meta-data i {
  font-size: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data i {
    color: #000000;
    font-size: 16px;
  }
}
.accomodation-page .services .info .meta-data span {
  font-size: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data span {
    font-size: 14px;
  }
}
.accomodation-page .services .info .meta-data .people {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data .people {
    gap: 6px;
  }
}
.accomodation-page .services .info .meta-data .bedroom {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data .bedroom {
    gap: 6px;
  }
}
.accomodation-page .services .info .meta-data .square-meters {
  display: flex;
  align-items: center;
  gap: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .meta-data .square-meters {
    gap: 6px;
  }
}
.accomodation-page .services .info .description p {
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .accomodation-page .services .info .description p {
    font-size: 16px;
    margin-bottom: 30px;
  }
}
.accomodation-page .services .info .description ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.accomodation-page .services .cost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 8px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 40px;
  }
}
.accomodation-page .services .cost .duration-of-stay-and-number-of-people {
  color: #404040;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.36px;
  margin-top: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .duration-of-stay-and-number-of-people {
    margin-top: 0;
    width: calc(50% - 10px);
  }
}
.accomodation-page .services .cost .price {
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .price {
    margin-top: 0;
    width: calc(50% - 10px);
    margin: 0;
  }
}
.accomodation-page .services .cost .price .summa {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .price .summa {
    justify-content: end;
  }
}
.accomodation-page .services .cost .price .summa .now {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e85481;
  text-align: center;
  font-size: 28.844px;
  font-weight: 700;
  line-height: 37.497px;
  letter-spacing: 0.865px;
}
.accomodation-page .services .cost .price .summa .now i {
  color: #abb5b7;
  font-size: 20px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .price .summa .now i {
    color: #7d7d7d;
    font-size: 16px;
  }
}
.accomodation-page .services .cost .price .summa .was {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
  text-decoration: line-through;
}
.accomodation-page .services .cost .price .summa .text {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
}
.accomodation-page .services .cost .price .price-per-stay {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .price .price-per-stay {
    margin: 0;
  }
}
.accomodation-page .services .cost .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .nav {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .nav .btn-white {
    display: none;
  }
}
@media (max-width: 767px) {
  .accomodation-page .services .cost .nav .btn-pink {
    width: 100%;
    border-radius: 56px;
    height: 48px;
  }
}
.accomodation-page .service-and-equipment {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 62px;
  margin-bottom: 123px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 0 5px;
    margin-bottom: 60px;
  }
}
.accomodation-page .service-and-equipment .service {
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .service {
    height: auto;
    padding: 24px 16px;
  }
}
.accomodation-page .service-and-equipment .service .title {
  color: #000000;
  font-size: 45px;
  margin-bottom: 6px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .service .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
}
.accomodation-page .service-and-equipment .service .widget {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .service .widget {
    margin-bottom: 20px;
  }
}
.accomodation-page .service-and-equipment .service .widget .widget-title {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #d3dfff;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .service .widget .widget-title {
    font-size: 18px;
    padding: 6px 12px;
  }
}
.accomodation-page .service-and-equipment .service .widget .widget-list ul li {
  margin-bottom: 15px;
  color: #000000;
  font-size: 25px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .service .widget .widget-list ul li {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.accomodation-page .service-and-equipment .equipment {
  border-radius: 30px;
  background: #FFF;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
  padding: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .equipment {
    padding: 24px 16px;
  }
}
.accomodation-page .service-and-equipment .equipment .title {
  color: #000000;
  font-size: 45px;
  font-weight: 400;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .equipment .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.accomodation-page .service-and-equipment .equipment .equipment-types {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 60px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .equipment .equipment-types {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
.accomodation-page .service-and-equipment .equipment .equipment-types .equipment-item .title {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}
@media (max-width: 767px) {
  .accomodation-page .service-and-equipment .equipment .equipment-types .equipment-item .title h4 {
    font-size: 30px;
  }
}
.accomodation-page .information {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 62px;
}
@media (max-width: 767px) {
  .accomodation-page .information {
    grid-template-columns: 1fr;
    padding: 0 5px;
    gap: 30px;
  }
}
.accomodation-page .information .included {
  border-radius: 30px;
  background: #F9FBFB;
  padding: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .information .included {
    height: auto;
    padding: 24px 16px;
    margin-bottom: 25px;
  }
}
.accomodation-page .information .included .title {
  display: flex;
  gap: 20px;
  color: #000000;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .title {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .accomodation-page .information .included .title .icon {
    display: none;
  }
}
.accomodation-page .information .included .title .text {
  display: flex;
  flex-direction: column;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .title .text {
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
  }
}
.accomodation-page .information .included .title .text h4 {
  font-size: 30px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .title .text h4 {
    font-size: 24px;
  }
}
.accomodation-page .information .included .title .text p {
  font-size: 30px;
  font-weight: 400;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .title .text p {
    font-size: 24px;
  }
}
.accomodation-page .information .included .widget {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .widget {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .accomodation-page .information .included .widget:last-child {
    margin-bottom: 0;
  }
}
.accomodation-page .information .included .widget .widget-title {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #d3dfff;
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
}
.accomodation-page .information .included .widget .widget-list ul li {
  margin-bottom: 15px;
  color: #000000;
  font-size: 25px;
}
@media (max-width: 767px) {
  .accomodation-page .information .included .widget .widget-list ul li {
    font-size: 18px;
    margin-bottom: 10px;
  }
}
.accomodation-page .information .location {
  border-radius: 30px;
  background: #ffffff;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .information .location {
    grid-template-columns: 1fr;
    padding: 10px;
  }
}
@media (max-width: 767px) {
  .accomodation-page .information .location .practical-information {
    padding-top: 24px;
  }
}
.accomodation-page .information .location .practical-information h3 {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .accomodation-page .information .location .practical-information h3 {
    font-size: 24px;
    color: #404040;
    font-weight: 700;
    margin-bottom: 20px;
  }
}
.accomodation-page .information .location .practical-information .plane .plane-item {
  display: flex;
  gap: 10px;
  margin-bottom: 50px;
  position: relative;
}
@media (max-width: 767px) {
  .accomodation-page .information .location .practical-information .plane .plane-item {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .accomodation-page .information .location .practical-information .plane .plane-item:last-child {
    margin-bottom: 0;
  }
}
.accomodation-page .information .location .practical-information .plane .plane-item:last-child::before {
  display: none;
}
.accomodation-page .information .location .practical-information .plane .plane-item:nth-child(1) .icon img {
  transform: translateX(-12px);
}
.accomodation-page .information .location .practical-information .plane .plane-item:nth-child(3) .icon img {
  transform: translateX(6px);
}
.accomodation-page .information .location .practical-information .plane .plane-item:nth-child(4) .icon img {
  transform: translateX(6px);
}
.accomodation-page .information .location .practical-information .plane .plane-item::before {
  content: "";
  border: 1px dashed #045e51;
  position: absolute;
  top: 100%;
  height: calc(100% + 50px);
  right: 36px;
  transform: translate(0%, -50%);
}
.accomodation-page .information .location .practical-information .plane .plane-item .icon {
  width: 90px;
  flex-shrink: 0;
}
.accomodation-page .information .location .practical-information .plane .plane-item .icon img {
  position: relative;
  z-index: 2;
  background-color: #ffffff;
}
.accomodation-page .information .location .practical-information .plane .plane-item .text .sub-title {
  color: #045e51;
  font-size: 20px;
  font-weight: 500;
}
.accomodation-page .information .location .practical-information .plane .plane-item .text .title {
  color: #000000;
  font-size: 22px;
  font-weight: 500;
}
.accomodation-page .information .location .address .title h3 {
  color: #000000;
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 50px;
}
@media (max-width: 767px) {
  .accomodation-page .information .location .address .title h3 {
    margin-bottom: 24px;
  }
}
@media (max-width: 767px) {
  .accomodation-page .information .location .address .description {
    margin-bottom: 5px;
  }
}
.accomodation-page .information .location .address .description p {
  text-align: left;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .accomodation-page .information .location .address .description p {
    text-align: start;
    font-size: 18px;
    line-height: 23px;
    color: #404040;
    margin-bottom: 0;
  }
}
.accomodation-page .information .location .address .description p .phone {
  color: #000000;
  direction: ltr;
  display: inline-block;
}
@media (max-width: 767px) {
  .accomodation-page .information .location .address .button .btn-green {
    width: calc(100% - 30px);
    height: 60px;
    flex-direction: row-reverse;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .accomodation-page .information .location .address .button .btn-green span {
    font-size: 18px;
  }
}
@media (max-width: 767px) and (max-width: 767px) {
  .accomodation-page .information .location .address .button .btn-green i {
    font-size: 20px;
  }
}

.page-template-activities-for-the-children-page .hero .subtitle {
  width: 100%;
  height: fit-content;
}
.page-template-activities-for-the-children-page .hero .subtitle .wp-video {
  width: 100% !important;
  height: fit-content !important;
}
.page-template-activities-for-the-children-page .page-head {
  display: flex;
  justify-content: center;
  background: #d7ffd0;
  padding: 38px 0;
}
.page-template-activities-for-the-children-page .page-head h2 {
  color: #175f62;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .page-head h2 {
    font-size: 32px;
  }
}
.page-template-activities-for-the-children-page .secondary-menu {
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
}
.page-template-activities-for-the-children-page .secondary-menu .activities-menu {
  display: flex;
  list-style: none;
  margin: 0 -10px;
  padding: 0;
}
.page-template-activities-for-the-children-page .secondary-menu .activities-menu li {
  padding: 30px 20px;
  position: relative;
}
.page-template-activities-for-the-children-page .secondary-menu .activities-menu li.current-menu-item a {
  color: #045e51;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.page-template-activities-for-the-children-page .secondary-menu .activities-menu li:not(:last-child)::after {
  content: "";
  position: absolute;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none"><circle cx="4.5" cy="4.5" r="4.5" fill="black"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: -5px;
  width: 10px;
  height: 100%;
}
.page-template-activities-for-the-children-page .secondary-menu .activities-menu li a {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .secondary-menu .activities-menu {
    gap: 30px;
    overflow: auto;
  }
  .page-template-activities-for-the-children-page .secondary-menu .activities-menu li {
    text-wrap: nowrap;
    padding: 25px 10px;
  }
  .page-template-activities-for-the-children-page .secondary-menu .activities-menu li.current-menu-item a {
    font-size: 12px;
  }
  .page-template-activities-for-the-children-page .secondary-menu .activities-menu li:not(:last-child)::after {
    left: -20px;
  }
  .page-template-activities-for-the-children-page .secondary-menu .activities-menu li a {
    font-size: 12px;
  }
}
.page-template-activities-for-the-children-page .hero {
  padding: 50px 0 10px 0;
}
.page-template-activities-for-the-children-page .hero .container-md {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-template-activities-for-the-children-page .hero .container-md .banner-title-mobile {
  display: none;
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .hero .container-md .banner-title-mobile {
    display: block;
  }
}
.page-template-activities-for-the-children-page .hero h1 {
  color: #000000;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 35px;
  position: relative;
}
.page-template-activities-for-the-children-page .hero h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 2px;
  background: #000000;
}
.page-template-activities-for-the-children-page .hero .subtitle {
  padding: 20px 0 30px 0;
}
.page-template-activities-for-the-children-page .hero .subtitle p {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.page-template-activities-for-the-children-page .hero .banner-title {
  padding-bottom: 30px;
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .hero .banner-title {
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .hero {
    padding: 40px 0;
  }
  .page-template-activities-for-the-children-page .hero .banner-title-desktop {
    display: none;
  }
  .page-template-activities-for-the-children-page .hero h1 {
    text-align: center;
    font-size: 32px;
    padding-bottom: 16px;
  }
  .page-template-activities-for-the-children-page .hero .subtitle p {
    text-align: center;
    font-size: 16px;
  }
}
.page-template-activities-for-the-children-page .activities {
  padding-bottom: 100px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 40px;
  column-gap: 45px;
  row-gap: 30px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item {
  display: flex;
  justify-content: space-between;
  width: calc(50% - 22.5px);
  border-radius: 35px;
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-image {
  flex: 0 0 50%;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info {
  padding: 20px 30px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info h3 {
  color: #000000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 7px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info .description {
  padding-bottom: 15px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info .description p {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.111%;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info a {
  padding: 5px 23px;
  color: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 187.5%;
  border-radius: 25px;
  background: #045e51;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) {
  width: 100%;
  max-height: 570px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-image {
  flex: 0 0 70%;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info {
  padding: 40px 30px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info h3 {
  font-size: 25px;
  padding-bottom: 15px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info .description {
  padding-bottom: 45px;
}
.page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info .description p {
  line-height: 138.889%;
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-image {
    max-height: 180px;
  }
}
@media (max-width: 767px) {
  .page-template-activities-for-the-children-page .activities {
    padding-bottom: 78px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list {
    gap: 12px;
    padding: 0;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item {
    flex-direction: column-reverse;
    width: 100%;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info {
    padding: 16px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info h3 {
    padding-bottom: 4px;
    font-size: 24px;
    line-height: normal;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info .description {
    padding-bottom: 25px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info .description p {
    font-size: 16px;
    line-height: 156.25%;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item .activities-item-info a {
    padding: 5px 23px;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 187.5%;
    border-radius: 25px;
    background: #045e51;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) {
    width: 100%;
    max-height: 570px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info {
    padding: 16px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info h3 {
    padding-bottom: 4px;
    font-size: 24px;
    line-height: normal;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info .description {
    padding-bottom: 25px;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info .description p {
    font-size: 16px;
    line-height: 156.25%;
  }
  .page-template-activities-for-the-children-page .activities .container-md .activities-list .activities-item:nth-child(3n+1) .activities-item-info a {
    padding: 5px 23px;
    color: #ffffff;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 187.5%;
    border-radius: 25px;
    background: #045e51;
  }
}

.single-activities article {
  display: flex;
  flex-direction: column;
}
.single-activities article .activities-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 54px 0;
}
.single-activities article .activities-head h1 {
  color: #000000;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.single-activities article .activities-body {
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.single-activities article .activities-body .activities-image {
  width: 100%;
}
.single-activities article .activities-body .activities-image img {
  width: 100%;
  object-fit: cover;
}
.single-activities article .activities-body .activities-info {
  display: flex;
}
.single-activities article .activities-body .activities-info .activities-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 63px 35px;
  gap: 26px;
}
.single-activities article .activities-body .activities-info .activities-content h3 {
  color: #000000;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.single-activities article .activities-body .activities-info .activities-content p {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.single-activities article .activities-body .activities-info .activities-parks {
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #f9fbfb;
  padding: 63px 35px;
  gap: 60px;
}
.single-activities article .activities-body .activities-info .activities-parks .activities-parks-head h3 {
  color: #000000;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.single-activities article .activities-body .activities-info .activities-parks .activities-parks-body {
  display: flex;
  justify-content: space-between;
}
.single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country h4 {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country span {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 767px) {
  .single-activities .container-lg {
    padding: 0;
  }
  .single-activities article {
    padding-bottom: 40px;
  }
  .single-activities article .activities-head {
    padding: 35px 0;
  }
  .single-activities article .activities-head h1 {
    font-size: 32px;
    line-height: 43.52px;
    text-align: center;
  }
  .single-activities article .activities-body {
    border-radius: 0;
    box-shadow: unset;
  }
  .single-activities article .activities-body .activities-info {
    flex-direction: column;
  }
  .single-activities article .activities-body .activities-info .activities-content {
    padding: 20px 16px;
    gap: 6px;
  }
  .single-activities article .activities-body .activities-info .activities-content h3 {
    font-size: 24px;
    line-height: 32.64px;
  }
  .single-activities article .activities-body .activities-info .activities-content p {
    font-size: 16px;
    line-height: 21.76px;
  }
  .single-activities article .activities-body .activities-info .activities-parks {
    padding: 24px 16px;
    gap: 26px;
  }
  .single-activities article .activities-body .activities-info .activities-parks .activities-parks-head h3 {
    font-size: 24px;
    line-height: 32.64px;
  }
  .single-activities article .activities-body .activities-info .activities-parks .activities-parks-body {
    gap: 40px;
    flex-wrap: wrap;
  }
  .single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country {
    gap: 16px;
  }
  .single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country h4 {
    font-size: 20px;
    line-height: 27.2px;
  }
  .single-activities article .activities-body .activities-info .activities-parks .activities-parks-body .activities-parks-country span {
    font-size: 16px;
    line-height: 21.76px;
  }
}

.page-template-apartments-page .page-head {
  display: flex;
  justify-content: center;
  background: #d7ffd0;
  padding: 38px 0;
}
.page-template-apartments-page .page-head h2 {
  color: #175f62;
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 767px) {
  .page-template-apartments-page .page-head h2 {
    font-size: 32px;
  }
}
.page-template-apartments-page .secondary-menu {
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul li {
  padding: 30px 20px;
  position: relative;
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul li.current-menu-item {
  border-bottom: solid 5px #045e51;
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul li.current-menu-item a {
  color: #045e51;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul li:not(:last-child)::after {
  content: "";
  position: absolute;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none"><circle cx="4.5" cy="4.5" r="4.5" fill="black"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: -5px;
  width: 10px;
  height: 100%;
}
.page-template-apartments-page .secondary-menu .accommodation-menu ul li a {
  color: #000000;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media (max-width: 767px) {
  .page-template-apartments-page .secondary-menu .accommodation-menu ul {
    gap: 30px;
    flex-wrap: wrap;
  }
  .page-template-apartments-page .secondary-menu .accommodation-menu ul li {
    padding: 20px 20px;
  }
  .page-template-apartments-page .secondary-menu .accommodation-menu ul li.current-menu-item a {
    font-size: 12px;
  }
  .page-template-apartments-page .secondary-menu .accommodation-menu ul li:not(:last-child)::after {
    left: -20px;
  }
  .page-template-apartments-page .secondary-menu .accommodation-menu ul li a {
    font-size: 12px;
  }
}
.page-template-apartments-page .hero {
  padding: 80px 0 40px 0;
}
.page-template-apartments-page .hero .container-md {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.page-template-apartments-page .hero h1 {
  color: #000000;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 35px;
  position: relative;
}
.page-template-apartments-page .hero h1::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 2px;
  background: #000000;
}
.page-template-apartments-page .hero .subtitle {
  padding: 20px 0 30px 0;
}
.page-template-apartments-page .hero .subtitle p {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
@media (max-width: 767px) {
  .page-template-apartments-page .hero {
    padding: 40px 0;
  }
  .page-template-apartments-page .hero h1 {
    text-align: center;
    font-size: 32px;
    padding-bottom: 16px;
  }
  .page-template-apartments-page .hero .subtitle p {
    text-align: center;
    font-size: 16px;
  }
}
.page-template-apartments-page .apartments {
  padding: 35px 0 45px 0;
}
.page-template-apartments-page .apartments .apartments-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item {
  display: flex;
  border-radius: 11.292px;
  background: #ffffff;
  box-shadow: 0 0 18.83px 0 rgba(20, 6, 60, 0.15);
  min-height: 415px;
  overflow: hidden;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-image {
  width: 60%;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 42px 55px;
  background: #ffffff;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top h3 {
  color: #404040;
  font-size: 26.451px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.794px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top .subtitle p {
  color: #404040;
  font-size: 17.634px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.529px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top .description strong {
  color: #404040;
  font-size: 17.634px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.529px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top .description p {
  color: #404040;
  font-size: 17.634px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.529px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  background: #fafbfb;
  padding: 25px 55px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-people {
  display: flex;
  align-items: center;
  gap: 7px;
  grid-area: 1/1/3/2;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-people span {
  color: #404040;
  font-size: 15.43px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.463px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-parks {
  grid-area: 1/2/2/3;
  display: flex;
  justify-content: center;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-parks span {
  color: #404040;
  font-size: 15.43px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: 0.463px;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-link {
  grid-area: 2/2/3/3;
}
.page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-link a {
  display: flex;
  padding: 15.855px 78.49px 14.046px 78.895px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 61.718px;
  background: #045e51;
  color: #ffffff;
}
@media (max-width: 767px) {
  .page-template-apartments-page .apartments {
    padding: 0 0 75px 0;
  }
  .page-template-apartments-page .apartments .apartments-list {
    gap: 20px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item {
    flex-direction: column;
    min-height: auto;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-image {
    width: 100%;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top {
    gap: 8px;
    padding: 10px 14px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top h3 {
    font-size: 24px;
    letter-spacing: 0.72px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top .subtitle {
    padding-bottom: 10px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-top .subtitle p {
    font-size: 16px;
    letter-spacing: 0.48px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom {
    padding: 10px 20px 20px 20px;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-people {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/1/2/2;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-parks {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 1/2/2/3;
  }
  .page-template-apartments-page .apartments .apartments-list .apartments-item .apartments-item-info .apartments-item-info-bottom .apartments-item-info-link {
    grid-area: 2/1/3/3;
  }
}
.page-template-apartments-page .cta {
  padding-bottom: 50px;
}
.page-template-apartments-page .cta .container-md .cta-wrapper {
  display: flex;
  background: #045e51;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 30px 20px;
}
.page-template-apartments-page .cta .container-md .cta-wrapper .cta-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #ffffff;
}
.page-template-apartments-page .cta .container-md .cta-wrapper .cta-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #ffffff;
}
.page-template-apartments-page .cta .container-md .cta-wrapper .cta-link {
  padding: 15px 45px;
  background: #ffffff;
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: center;
  color: #045e51;
  border-radius: 62px;
}
@media (max-width: 767px) {
  .page-template-apartments-page .cta {
    display: none;
  }
}

.page-template-event-page #modal-search .item.parcs {
  display: none;
}
.page-template-event-page .banner {
  height: 480px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
.page-template-event-page .banner .banner-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-event-page .banner .banner-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-event-page .banner .banner-info-wrapper {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.page-template-event-page .banner .banner-info-wrapper .info {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 100px 30px 55px 30px;
  background: #d3dfff;
  border-top-left-radius: 135px;
  border-top-right-radius: 135px;
  max-width: 270px;
}
.page-template-event-page .banner .banner-info-wrapper .info .title {
  color: #404040;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.51px;
}
.page-template-event-page .banner .banner-info-wrapper .info .description {
  color: #404040;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.51px;
}
.page-template-event-page .banner .search {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
.page-template-event-page .search {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 70px;
  background: #ffffff;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
}
.page-template-event-page .search .arrival {
  display: flex;
  align-items: center;
  gap: 16px;
}
.page-template-event-page .search .arrival .arrival-date {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0 60px;
  color: #404040;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.641px;
}
.page-template-event-page .search .arrival .arrow-date {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: #d0d0d0;
}
.page-template-event-page .search .arrival .departure-date {
  display: flex;
  align-items: center;
  padding: 0;
  gap: 16px;
  height: 100%;
  color: #d0d0d0;
  font-size: 22px;
  font-weight: 500;
  line-height: 27.791px;
  letter-spacing: 0.641px;
}
.page-template-event-page .search .accommodation {
  border-right: 1px solid #d0d0d0;
}
.page-template-event-page .search .accommodation .select {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 114px;
  padding: 0px 60px 0px 30px;
  position: relative;
  position: relative;
}
.page-template-event-page .search .accommodation .select.open .label:before {
  content: "";
  position: absolute;
  transform: translateX(-50%);
  bottom: -15px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
  z-index: 4;
}
.page-template-event-page .search .accommodation .select.open .label .arrow {
  transform: rotate(180deg);
}
.page-template-event-page .search .accommodation .select .arrow {
  display: flex;
  align-items: center;
  gap: 22px;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.page-template-event-page .search .accommodation .select .label {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
}
.page-template-event-page .search .accommodation .drop-down {
  display: none;
  border-radius: 5px;
  background: #ffffff;
  width: 416px;
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 10px;
  z-index: 3;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  padding: 25px;
  padding-top: 50px;
}
.page-template-event-page .search .accommodation .drop-down:before {
  content: "";
  z-index: 3;
  position: absolute;
  transform: translateX(-50%);
  top: -10px;
  left: 50%;
  border-color: transparent transparent #fff;
  border-style: solid;
  border-width: 0 10px 10px;
}
.page-template-event-page .search .accommodation .drop-down .close {
  position: absolute;
  left: 10px;
  top: 10px;
  cursor: pointer;
}
.page-template-event-page .search .accommodation .drop-down .options {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.page-template-event-page .search .accommodation .drop-down .options .seniors {
  display: flex;
  justify-content: space-between;
}
.page-template-event-page .search .accommodation .drop-down .options .adults {
  display: flex;
  justify-content: space-between;
}
.page-template-event-page .search .accommodation .drop-down .options .children {
  display: flex;
  justify-content: space-between;
}
.page-template-event-page .search .accommodation .drop-down .options .pets {
  display: flex;
  justify-content: space-between;
}
.page-template-event-page .search .submit a {
  display: flex;
  width: 207px;
  height: 60px;
  padding: 10px 40px;
  justify-content: center;
  align-items: center;
  gap: 13.335px;
  flex-shrink: 0;
  border-radius: 70px 0px 0px 70px;
  background: #ff4380;
  color: #ffffff;
  font-size: 22px;
  font-weight: 600;
}
.page-template-event-page .countries-events {
  padding: 100px 0 50px 0;
}
.page-template-event-page .countries-events .countries-events-wrapper {
  display: flex;
  flex-direction: column;
  gap: 33px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-head {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-head h1 {
  color: #404040;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-head .description p {
  color: #404040;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.889%;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-head .description strong {
  font-weight: 700;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-head .description a {
  color: #045e51;
  font-weight: 700;
  text-decoration-line: underline;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 33px;
  row-gap: 53px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper {
  flex: 1 0 auto;
  max-width: calc(50% - 16.5px);
}
@media (max-width: 767px) {
  .page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper {
    max-width: unset;
    width: 100%;
  }
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper.show-more {
  display: none;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-image {
  width: 100%;
  max-height: 320px;
  object-fit: cover;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info {
  display: flex;
  flex-direction: column;
  gap: 33px;
  padding: 21px 33px 41px 33px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-head {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-head h3 {
  color: #000000;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-head .description {
  color: #404040;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.889%;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features .country-events-item-feature {
  padding-inline-start: 22px;
  position: relative;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features .country-events-item-feature:before {
  content: "";
  position: absolute;
  inset-inline-start: 0;
  top: 10px;
  width: 6px;
  height: 6px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="%23404040"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features .country-events-item-feature p {
  color: #404040;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 138.889%;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features .country-events-item-feature strong {
  font-weight: 700;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-features .country-events-item-feature a {
  color: #045e51;
  text-decoration-line: underline;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper .country-events-item .country-events-item-info .country-events-item-link {
  display: flex;
  width: fit-content;
  padding: 10.882px 33.168px;
  justify-content: center;
  align-items: center;
  border-radius: 93.275px;
  border: 1px solid #404040;
  color: #404040;
  font-size: 17.1px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper.horizontal {
  flex: 0 0 100%;
  max-width: 100%;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper.horizontal .country-events-item {
  flex-direction: row;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper.horizontal .country-events-item .country-events-item-image {
  width: 40%;
  max-height: 100%;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .country-events-item-wrapper.horizontal .country-events-item .country-events-item-info {
  gap: 13px;
  padding: 21px 33px 41px 56px;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow {
  display: inline-flex;
  padding: 17px 35px;
  justify-content: center;
  align-items: center;
  gap: 29px;
  border-radius: 70px;
  border: 1px solid #045e51;
  background: #045e51;
  color: #ffffff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.6px;
  cursor: pointer;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow .less {
  display: none;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow:after {
  content: "";
  height: 8.131px;
  width: 15px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none"><path d="M0.057152 0.646073L7.17972 7.87823L7.49978 8.18909L7.82456 7.88315L15.0567 0.760591L14.4168 0.110826L7.50952 6.91343L0.706916 0.00615487L0.057152 0.646073Z" fill="white"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow.active .less {
  display: flex;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow.active .more {
  display: none;
}
.page-template-event-page .countries-events .countries-events-wrapper .countries-events-list .btn-green-arrow.active:after {
  transform: rotate(180deg);
}
.page-template-event-page .filter {
  margin-bottom: 30px;
  width: 100%;
  height: 1px;
  background: #ffffff;
  box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
  height: 70px;
}
.page-template-event-page .filter .container-md {
  display: flex;
  align-items: center;
  position: relative;
}
.page-template-event-page .filter .sorting {
  display: flex;
  align-items: center;
  height: 70px;
}
.page-template-event-page .filter .sorting .title {
  color: #d0d0d0;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.48px;
}
.page-template-event-page .filter .sorting .select {
  height: 100%;
  display: inline-flex;
  align-items: center;
  gap: 114px;
  border-left: 1px solid #d0d0d0;
  position: relative;
  cursor: pointer;
}
.page-template-event-page .filter .sorting .select.open .label .arrow {
  transform: rotate(180deg);
}
.page-template-event-page .filter .sorting .select .label {
  display: flex;
  align-items: center;
  gap: 60px;
  padding: 0px 30px;
  height: 100%;
  position: relative;
  z-index: 6;
}
.page-template-event-page .filter .sorting .select .label .arrow {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  transition: transform 0.3s ease;
}
.page-template-event-page .filter .sorting .select .label .text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.page-template-event-page .filter .sorting .select .drop-down {
  display: none;
  background: #ffffff;
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 100%;
  left: 0px;
  z-index: 3;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 25px;
  padding-top: 50px;
  padding: 16px 26px;
}
.page-template-event-page .filter .sorting .select .drop-down ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.page-template-event-page .filter .sorting .select .drop-down ul li a {
  transition: all 0.3s linear;
}
.page-template-event-page .filter .sorting .select .drop-down ul li a:hover {
  color: #025f52;
}
.page-template-event-page .order-by {
  margin-bottom: 30px;
}
.page-template-event-page .order-by .container-md {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.page-template-event-page .order-by .title {
  color: #404040;
  text-align: right;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.6px;
}
.page-template-event-page .order-by .select label {
  color: #404040;
  font-size: 16px;
  margin-left: 20px;
}
.page-template-event-page .posts .container-md {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.page-template-event-page .posts .post {
  border-radius: 9.065px;
  background-color: #ffffff;
  box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
}
.page-template-event-page .posts .post .post-header {
  background-color: #FAFBFC;
  border-bottom: solid 1px #E9E8EA;
  border-radius: 9.065px 9.065px 0 0;
  padding: 14px;
}
.page-template-event-page .posts .post .post-content {
  display: flex;
  gap: 40px;
  padding-left: 22px;
}
.page-template-event-page .posts .post .thumbnail {
  width: 480px;
  height: 238px;
  flex-shrink: 0;
  overflow: hidden;
}
.page-template-event-page .posts .post .thumbnail img {
  border-radius: 0 0 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.page-template-event-page .posts .post .text {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
  padding: 22px 0;
}
.page-template-event-page .posts .post .text .info .title {
  display: flex;
  align-items: center;
  gap: 17px;
  margin-bottom: 5px;
}
.page-template-event-page .posts .post .text .info .title .facilities {
  display: flex;
  align-items: center;
  gap: 8px;
}
.page-template-event-page .posts .post .text .info .meta-data {
  display: flex;
  align-items: center;
  gap: 26px;
  margin-top: 16px;
  margin-bottom: 16px;
}
.page-template-event-page .posts .post .text .info .meta-data .people {
  display: flex;
  align-items: center;
  gap: 6px;
}
.page-template-event-page .posts .post .text .info .meta-data .people .title {
  margin-bottom: 0px;
}
.page-template-event-page .posts .post .text .info .meta-data .bedroom {
  display: flex;
  align-items: center;
  gap: 6px;
}
.page-template-event-page .posts .post .text .info .meta-data .bedroom .title {
  margin-bottom: 0px;
}
.page-template-event-page .posts .post .text .info .meta-data .square-meters {
  display: flex;
  align-items: center;
  gap: 6px;
}
.page-template-event-page .posts .post .text .info .meta-data .square-meters .title {
  margin-bottom: 0px;
}
.page-template-event-page .posts .post .text .info .description p {
  margin-bottom: 12px;
}
.page-template-event-page .posts .post .text .info .description ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.page-template-event-page .posts .post .cost {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border-radius: 8px;
}
.page-template-event-page .posts .post .cost .duration-of-stay-and-number-of-people {
  color: #404040;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  letter-spacing: 0.36px;
  margin-top: 20px;
}
.page-template-event-page .posts .post .cost .price {
  margin-bottom: 16px;
}
.page-template-event-page .posts .post .cost .price .summa {
  display: flex;
  align-items: flex-end;
  gap: 10px;
}
.page-template-event-page .posts .post .cost .price .summa .now {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #e85481;
  text-align: center;
  font-size: 28.844px;
  font-weight: 700;
  line-height: 37.497px;
  letter-spacing: 0.865px;
}
.page-template-event-page .posts .post .cost .price .summa .now i {
  color: #abb5b7;
  font-size: 20px;
}
.page-template-event-page .posts .post .cost .price .summa .was {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
  text-decoration: line-through;
}
.page-template-event-page .posts .post .cost .price .summa .text {
  display: flex;
  gap: 10px;
  color: #404040;
  font-size: 16.688px;
  font-weight: 400;
  line-height: 25.033px;
}
.page-template-event-page .posts .post .cost .price .price-per-stay {
  color: #404040;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 12px;
  text-align: left;
}
.page-template-event-page .posts .post .cost .nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;
}
.page-template-event-page .posts .load-more {
  margin-top: 40px;
  text-align: center;
}
.page-template-event-page .bottom {
  padding: 40px 0;
}
.page-template-event-page .bottom .container-md {
  display: flex;
  flex-direction: column;
  gap: 22px;
}
.page-template-event-page .bottom .container-md .description {
  color: #404040;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 155.556%;
}
.page-template-event-page .bottom .container-md .link-green-arrow {
  display: flex;
  width: fit-content;
  gap: 10px;
  align-items: center;
  color: #045e51;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
}
.page-template-event-page .bottom .container-md .link-green-arrow:before {
  content: "";
  width: 9px;
  height: 15px;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none"><path d="M0.189883 7.91162L7.89771 14.8304C8.15025 15.0569 8.55912 15.0565 8.81123 14.8292C9.06315 14.6019 9.0625 14.2337 8.80993 14.0069L1.56089 7.49997L8.81019 0.993044C9.06273 0.766287 9.06338 0.39829 8.81149 0.170947C8.68511 0.056983 8.51954 0 8.35397 0C8.18882 0 8.0239 0.0566015 7.89774 0.169775L0.189883 7.08835C0.0682516 7.19728 -9.53674e-07 7.34552 -9.53674e-07 7.49997C-9.53674e-07 7.65442 0.0684471 7.80249 0.189883 7.91162Z" fill="%23045E51"/></svg>');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.offers-page .banner {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .offers-page .banner {
    display: flex;
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin-bottom: 24px;
  }
}
.offers-page .banner img {
  width: 100%;
  height: 431px;
}
@media (max-width: 767px) {
  .offers-page .banner img {
    height: auto;
    width: auto;
    max-width: none;
  }
}
@media (max-width: 767px) {
  .offers-page .page-content {
    padding: 0 6px;
  }
}
.offers-page .page-content .breadcrumbs {
  text-align: center;
  margin-bottom: 43px;
  color: #404040;
  font-size: 14px;
}
.offers-page .page-content .breadcrumbs a {
  color: #404040;
}
@media (max-width: 767px) {
  .offers-page .page-content .breadcrumbs {
    margin-bottom: 12px;
  }
}
.offers-page .page-content .breadcrumbs .span {
  color: #045e51;
}
.offers-page .page-content .text {
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .offers-page .page-content .text {
    margin-bottom: 20px;
  }
}
.offers-page .page-content .text .title {
  text-align: center;
  position: relative;
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .offers-page .page-content .text .title {
    margin-bottom: 27px;
  }
}
.offers-page .page-content .text .title h1 {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
  padding-bottom: 25px;
  text-transform: none;
}
@media (max-width: 767px) {
  .offers-page .page-content .text .title h1 {
    margin-bottom: 17px;
    font-size: 32px;
  }
}
.offers-page .page-content .text .title h1::after {
  content: "";
  width: 32px;
  height: 1px;
  background-color: #9ea0a1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.offers-page .page-content .text .description {
  max-width: 1110px;
  margin: 0px auto;
  text-align: center;
  color: #404040;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.offers-page .page-content .text .description p {
  font-size: 16px;
}
.offers-page .page-content .offers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 146px;
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .offers-page .page-content .offers {
    grid-template-columns: 1fr;
    gap: 20px;
    margin-bottom: 40px;
  }
}
.offers-page .page-content .offers .offer {
  max-width: 406px;
  color: #404040;
  position: relative;
  padding: 0 35px 60px 35px;
  border-top: 11px solid #d7ffd0;
}
@media (max-width: 767px) {
  .offers-page .page-content .offers .offer {
    max-width: 100%;
    padding: 0 0px 60px 0px;
  }
}
.offers-page .page-content .offers .offer .title {
  margin-bottom: 20px;
}
.offers-page .page-content .offers .offer .title h3 {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  text-align: right;
  padding: 17px 0 15px 0;
}
@media (max-width: 767px) {
  .offers-page .page-content .offers .offer .title h3 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.offers-page .page-content .offers .offer .title h3::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #9ea0a1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.offers-page .page-content .offers .offer .description {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .offers-page .page-content .offers .offer .description {
    margin-bottom: 30px;
  }
}
.offers-page .page-content .offers .offer .description p {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
}
.offers-page .page-content .offers .offer .description ul {
  list-style: none;
  padding: 0px;
}
.offers-page .page-content .offers .offer .description ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.03em;
}
.offers-page .page-content .offers .offer .description ul li:before {
  content: "+";
  font-family: "Font Awesome 6 Pro";
  margin-left: 7px;
  font-size: 14px;
}
.offers-page .page-content .offers .offer .button {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0%;
  padding: 0 35px;
}
@media (max-width: 767px) {
  .offers-page .page-content .offers .offer .button {
    padding: 0;
  }
}
.offers-page .page-content .offers .offer .button a {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0;
  text-align: center;
  color: #404040;
  height: 46px;
  padding: 0 26px;
}
.offers-page .page-content .school-holidays {
  margin-bottom: 100px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays {
    margin-bottom: 40px;
  }
}
.offers-page .page-content .school-holidays .school-holidays-title {
  text-align: center;
  margin-bottom: 56px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .school-holidays-title {
    margin-bottom: 32px;
  }
}
.offers-page .page-content .school-holidays .school-holidays-title h2 {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .school-holidays-title h2 {
    font-size: 32px;
  }
}
.offers-page .page-content .school-holidays .holidays {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 146px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday {
  max-width: 406px;
  position: relative;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday {
    max-width: 100%;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .thumnbnail img {
  height: 170px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .thumnbnail img {
    height: auto;
    width: 100%;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content {
  padding: 0 35px;
  border-top: 11px solid #d7ffd0;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .content {
    padding: 0;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content .title {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .content .title {
    text-align: start;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content .title h3 {
  position: relative;
  padding: 15px 0 13px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: right;
  color: #404040;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .content .title h3 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content .title h3::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #9ea0a1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.offers-page .page-content .school-holidays .holidays .holiday .content .description {
  margin-bottom: 30px;
  color: #404040;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .content .description {
    margin-bottom: 20px;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content .description P {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.offers-page .page-content .school-holidays .holidays .holiday .content .description ul {
  list-style: none;
  padding: 0px;
}
.offers-page .page-content .school-holidays .holidays .holiday .content .description ul li:before {
  content: "+";
  font-family: "Font Awesome 6 Pro";
  margin-left: 7px;
  font-size: 14px;
}
.offers-page .page-content .school-holidays .holidays .holiday .content .button {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0%;
  padding: 0 35px;
}
@media (max-width: 767px) {
  .offers-page .page-content .school-holidays .holidays .holiday .content .button {
    padding: 0;
  }
}
.offers-page .page-content .school-holidays .holidays .holiday .content .button a {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0;
  text-align: center;
  color: #404040;
  height: 46px;
  padding: 0 26px;
}
.offers-page .page-content .additionals {
  margin-bottom: 60px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals {
    margin-bottom: 40px;
  }
}
.offers-page .page-content .additionals .additionals-title {
  text-align: center;
  margin-bottom: 56px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additionals-title {
    margin-bottom: 32px;
  }
}
.offers-page .page-content .additionals .additionals-title h2 {
  color: #404040;
  font-size: 35px;
  font-weight: 700;
  line-height: 1.4;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additionals-title h2 {
    font-size: 32px;
  }
}
.offers-page .page-content .additionals .additional-offers {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 146px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer {
  max-width: 406px;
  color: #404040;
  position: relative;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer {
    max-width: 100%;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .thumnbnail img {
  height: 170px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .thumnbnail img {
    height: auto;
    width: 100%;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content {
  border-top: 11px solid #d7ffd0;
  padding: 0 35px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .content {
    padding: 0;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .title {
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .content .title {
    text-align: start;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .title h3 {
  position: relative;
  padding: 15px 0 13px 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.03em;
  text-align: right;
  color: #404040;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .content .title h3 {
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .title h3::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #9ea0a1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .description {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .content .description {
    margin-bottom: 20px;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .description P {
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .description ul {
  list-style: none;
  padding: 0px;
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .description ul li:before {
  content: "+";
  font-family: "Font Awesome 6 Pro";
  margin-left: 7px;
  font-size: 14px;
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .button {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0%;
  padding: 0 35px;
}
@media (max-width: 767px) {
  .offers-page .page-content .additionals .additional-offers .additional-offer .content .button {
    padding: 0;
  }
}
.offers-page .page-content .additionals .additional-offers .additional-offer .content .button a {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.35;
  letter-spacing: 0;
  text-align: center;
  color: #404040;
  height: 46px;
  padding: 0 26px;
}
@media (max-width: 767px) {
  .offers-page .page-content .links {
    margin-bottom: 42px;
  }
}
.offers-page .page-content .links ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: center;
  gap: 32px;
}
@media (max-width: 767px) {
  .offers-page .page-content .links ul {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .offers-page .page-content .links ul li {
    width: 100%;
  }
}
.offers-page .page-content .links ul li a {
  color: #045e51;
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 0.48px;
}

.page-template-check-out-page .container-md .page-head {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}
.page-template-check-out-page .container-md .page-head .buttons {
  width: 350px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .page-head .buttons {
    display: none;
  }
}
.page-template-check-out-page .container-md .page-head .buttons .button {
  margin-bottom: 14px;
}
.page-template-check-out-page .container-md .page-head .buttons .button a {
  font-size: 17px;
  width: 100%;
}
.page-template-check-out-page .container-md .page-head .title {
  color: #404040;
}
.page-template-check-out-page .container-md .check-out {
  display: flex;
  justify-content: space-between;
  gap: 40px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out {
    flex-direction: column;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .btn-white {
  font-size: 17px;
  margin-bottom: 14px;
  width: 100%;
}
.page-template-check-out-page .container-md .check-out .check-out-info {
  width: calc(100% - 390px);
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info {
    width: 100%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps {
  padding: 11px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  display: flex;
  gap: 10px;
  margin-bottom: 40px;
  margin-top: 40px;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .steps {
    justify-content: flex-start;
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .steps .fa {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .step {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .step .title {
  color: #7D7D7D;
  font-size: 16px;
  font-weight: 500;
  align-self: center;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .steps .step:not(.active) .title {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .step .number {
  font-size: 18px;
  color: #7D7D7D;
  font-weight: 900;
  padding: 8px 17px;
  background-color: #DAE8DA;
  border-radius: 9px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .active {
  background-color: #045E51;
  border-radius: 9px;
  gap: 15px;
  padding: 8px 15px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .active .title {
  color: #FFFFFF;
  font-weight: 600;
}
.page-template-check-out-page .container-md .check-out .check-out-info .steps .active .number {
  font-size: 18px;
  color: #FFFFFF;
  padding: 0;
  background-color: transparent;
}
.page-template-check-out-page .container-md .check-out .check-out-info .messages {
  margin-bottom: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .messages .message {
  display: flex;
  gap: 15px;
  margin-bottom: 15px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .messages .message img {
  max-height: 20px;
  align-self: center;
}
.page-template-check-out-page .container-md .check-out .check-out-info .messages .message .text {
  align-self: center;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .nice-select .list {
  overflow-y: auto;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .check-out-results .ordering-info {
  max-width: 400px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .check-out-results .ordering-info .reference-number {
  display: flex;
  justify-content: space-between;
  background-color: #F3F5F5;
  border-radius: 8px;
  padding: 17px;
  color: #404040;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .check-out-results .ordering-info .order-remark {
  margin-bottom: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .form-section {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .form-section.current {
  display: block;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes {
  padding: 20px 20px 60px 20px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  margin-bottom: 50px;
  border-right: 7px solid #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes h3 {
  font-size: 24px;
  font-weight: 700;
  color: #045E51;
  line-height: 32px;
  margin-bottom: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-input {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-input {
    grid-template-columns: 1fr;
    width: 100%;
    margin-bottom: 0;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-input label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: #515358;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-input label input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #A4A4A4;
  padding: 18px 16px;
  font-size: 16px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-textarea {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-textarea {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-textarea label {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  color: #515358;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .representative-notes .representative-notes-textarea label textarea {
  width: 100%;
  border-radius: 5px;
  min-height: 100px;
  border: 1px solid #A4A4A4;
  padding: 18px 16px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences {
  padding: 20px 20px 60px 20px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  margin-bottom: 50px;
  border-right: 7px solid #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .nice-select.open .list {
  max-height: 40dvh;
  overflow-y: auto;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .nice-select.open .list::-webkit-scrollbar {
  width: 8px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .nice-select.open .list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .nice-select.open .list::-webkit-scrollbar-thumb {
  background-color: #abb8c3;
  outline: 1px solid slategrey;
  border-radius: 2px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .title {
  font-size: 24px;
  font-weight: 700;
  color: #045E51;
  line-height: 32px;
  margin-bottom: 5px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .text {
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .button {
  padding: 36px 20px;
  border-radius: 20px;
  background-color: rgba(217, 217, 217, 0.25);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .button {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .button a {
  font-size: 16px;
  font-weight: 500;
  color: #4E944F;
  line-height: 20px;
  padding: 2px 18px;
  border: 1px solid #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .head .button a:hover {
  border: 1px solid #4E944F;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  height: 100%;
}
@media (max-width: 1264px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    gap: 20px;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location {
  display: flex;
  align-items: center;
  gap: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .label {
  font-size: 20px;
  color: #404040;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .label {
    white-space: nowrap;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .select .chosen-container {
  width: 250px !important;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .select .chosen-container .chosen-single {
  border-radius: 70px !important;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .select .chosen-container .chosen-single {
    border: 1px solid #A4A4A4;
    box-shadow: none;
    border-radius: 5px !important;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .location-preferences .locations .location .select .chosen-container .chosen-results {
  max-height: 220px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .button.mobile {
  display: none;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .button.mobile {
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .button.mobile a {
  border-radius: 70px !important;
  border: none;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  font-size: 16px;
  min-width: 200px;
  max-width: 300px;
  width: 100%;
  color: #4E944F;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services {
  padding: 20px 20px 50px 20px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  margin-bottom: 50px;
  border-right: 7px solid #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .services-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .services-description {
  margin-bottom: 45px;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service {
  background-color: #F3F5F5;
  border-radius: 8px;
  margin-bottom: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service:last-child {
  margin-bottom: 0;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .head {
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .head i {
  font-size: 28px;
  color: #045E51;
  transition: all 0.3s;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .head.opened i {
  transform: rotate(180deg);
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .head .description {
  display: flex;
  align-items: center;
  gap: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .head .description .text {
  font-size: 24px;
  font-weight: 600;
  color: #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs {
  position: relative;
  display: none;
  padding: 0 25px 15px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs.opened {
  display: block;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-nav {
  display: flex;
  gap: 0;
  margin-bottom: 30px;
  z-index: 1;
  position: relative;
  overflow: auto;
  border-radius: 0 0 20px 20px;
  background: linear-gradient(0deg, rgb(255, 255, 255) 80%, rgba(208, 208, 208, 0.5) 100%);
  border: 1px solid #D0D0D0;
  border-top: none;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-nav .tab {
  margin-left: 50px;
  color: #404040;
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  padding: 18px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 6px solid transparent;
  padding-right: 25px;
  position: relative;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-nav .tab.active {
  border-bottom: 6px solid #045e51;
  color: #045e51;
  font-weight: 700;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-select {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content.active {
  display: block;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option {
  background: #fff;
  border-radius: 20px;
  border: 1px solid #D0D0D0;
  margin-bottom: 15px;
  overflow: hidden;
  height: 100px;
  max-height: 100px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option > a:first-child {
  height: 100%;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
  overflow: hidden;
  cursor: pointer;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description {
    gap: 3%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img {
  display: flex;
  gap: 40px;
  height: 100%;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img {
    min-width: 70px;
    max-width: 70px;
    width: 30%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img img {
  width: 156px;
  height: 100%;
  object-fit: cover;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img img {
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img .subtitle {
  align-self: center;
  font-size: 18px;
  font-weight: 700;
  color: #025F52;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .title-img .subtitle {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow {
  display: flex;
  padding-left: 35px;
  gap: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow.mobile {
  display: none;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow.mobile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
  }
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow.mobile .top {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow.mobile .top .subtitle {
    flex-grow: 1;
    align-self: center;
    font-size: 18px;
    font-weight: 700;
    color: #025f52;
  }
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow:not(.mobile) {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow .text {
  font-size: 18px;
  color: #FF4380;
  align-self: center;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description .toggle-and-arrow i {
  font-size: 28px;
  color: #045E51;
  transition: all 0.3s;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .description.opened .toggle-and-arrow i {
  transform: rotate(90deg);
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list.opened {
  display: block;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list .wrapper {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  padding: 40px;
  gap: 20px 30px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list .wrapper .checkbox-wrapper {
  display: flex;
  align-items: center;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list .wrapper .checkbox-wrapper .marker {
  display: flex;
  gap: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list .wrapper .checkbox-wrapper .marker .title {
  color: #025F52;
  font-size: 18px;
  font-weight: 400;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .services .service .tabs .tabs-contents .tab-content .service-option .price-list .wrapper .checkbox-wrapper .marker .subtitle {
  color: #FF4380;
  font-size: 18px;
  font-weight: 500;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .ordering-data-title {
  color: #045E51;
  margin-bottom: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info {
  border-radius: 0 0 8px 8px;
  background-color: #F3F5F5;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  margin-bottom: 30px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .input.select .nice-select {
    width: 100%;
    direction: rtl;
    display: flex;
    padding-inline: 18px 30px;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .input.select .nice-select::after {
  left: 12px;
  right: auto;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .input.select .nice-select .option {
  text-wrap: wrap;
  text-align: right;
  direction: rtl;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .input.select .nice-select .list {
  right: 0;
  left: auto;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .title {
  padding: 17px 30px;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper {
  background-color: white;
  padding: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 45px;
  margin-bottom: 45px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper {
    grid-template-columns: 1fr;
    padding: 10px;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .postal-code-wrap-mobile {
  display: none;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .postal-code-wrap-mobile {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.person1-house, .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.person1-postal-code {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.name {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.name {
    grid-template-columns: 1fr;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input input[type=text] {
  border-radius: 5px;
  border: 1px solid #A4A4A4;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input input[type=text].error {
  border-color: red;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input .parsley-errors-list {
  margin: 4px 0;
  color: red;
  font-weight: 700;
  list-style-type: none;
  padding: 0;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.select .chosen-container {
  width: 100% !important;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .info-wrapper .input.select .chosen-container .chosen-results {
  max-height: 350px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .total-info-wrapper {
  background-color: white;
  padding: 30px;
  margin-bottom: 45px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .total-info-wrapper .total-price {
  display: flex;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  margin-bottom: 40px;
  background-color: #F3F5F5;
  border-radius: 8px;
  padding-left: 17px;
  font-size: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .total-info-wrapper .total-price .price {
  color: #404040;
  font-weight: 700;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .customer-info .total-info-wrapper .btn-pink {
  width: 50%;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .checkboxes {
  margin-bottom: 10px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .checkboxes .checkbox-wrapper {
  display: flex;
  margin-bottom: 20px;
  align-items: flex-start;
  flex-direction: column;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .checkboxes .checkbox-wrapper .marker {
  display: flex;
  gap: 40px;
}
.page-template-check-out-page .container-md .check-out .check-out-info .check-out-main .checkboxes .checkbox-wrapper .marker .title {
  color: #025F52;
  font-size: 18px;
  font-weight: 400;
}
.page-template-check-out-page .container-md .check-out .check-out-info .form-navigation {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-info .form-navigation {
    display: none;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar {
  width: 350px;
  padding: 27px;
  background: #fff;
  box-shadow: 0 0 20px 0 rgba(20, 6, 60, 0.15);
  border-radius: 8px;
  height: fit-content;
}
@media (max-width: 991px) {
  .page-template-check-out-page .container-md .check-out .check-out-sidebar {
    width: 100%;
  }
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row:first-child {
  margin-top: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row .price {
  font-size: 20px;
  font-weight: 700;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row .title {
  font-size: 20px;
  font-weight: 400;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row.agent_commission_was {
  margin-top: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row.agent_commission_was .price {
  text-decoration: line-through;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .agent_commission_row.agent_commission_price .price {
  color: #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .sidebar-title {
  text-align: center;
  margin-bottom: 20px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .sidebar-img {
  border-radius: 8px;
  overflow: hidden;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .sidebar-img img {
  width: 100%;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block {
  padding: 20px 0;
  border-bottom: 1px solid #E0DFDF;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block.tax {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-title {
  font-size: 14px;
  font-weight: 700;
  color: #404040;
  margin-bottom: 12px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 8px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item.couponPrice {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item.couponPrice .price {
  color: #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item:last-child {
  margin-bottom: 0;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item .title {
  font-size: 14px;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item .value {
  font-size: 14px;
  font-weight: 700;
  color: #404040;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item .price {
  font-size: 14px;
  font-weight: 700;
  color: #404040;
  display: flex;
  gap: 5px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item .discount {
  color: #045E51;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .info-block .info-item .old-price {
  text-decoration: line-through;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .total {
  margin-top: 20px;
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
  gap: 30px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .total.agent {
  margin-top: 0;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .total .title {
  font-size: 20px;
  color: #404040;
  line-height: 26px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .total .price {
  font-size: 20px;
  color: #404040;
  line-height: 26px;
  font-weight: 700;
  display: flex;
  gap: 5px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .submit button {
  font-size: 16px;
  width: 100%;
  margin-bottom: 22px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .description .subtitle {
  font-family: Inter, sans-serif;
  font-size: 14px;
  text-align: left;
  font-weight: 200;
  margin-bottom: 14px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .description .text {
  font-family: Inter, sans-serif;
  font-size: 14px;
  text-align: left;
  font-weight: 200;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 26px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .clubs-wrapper .nice-select {
  width: 100%;
  margin-bottom: 10px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .clubs-wrapper .nice-select .list {
  width: 100%;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .coupon {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  pointer-events: none;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .coupon input {
  opacity: 0.5;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .coupon .button a {
  font-size: 16px;
  padding: 14px 25px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .coupon.active {
  pointer-events: painted;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-club-wrap .coupon.active input {
  opacity: 1;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-icon-section {
  background-color: #DAE8DA;
  border-radius: 3px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  gap: 10px;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-icon-section.hide {
  display: none;
}
.page-template-check-out-page .container-md .check-out .check-out-sidebar .coupon-icon-section .remove-coupon {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (max-width: 991px) {
  .page-template-check-out-page .mfp-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 991px) {
  .page-template-check-out-page .mfp-container .mfp-content {
    vertical-align: bottom;
  }
}

.contact-page .breadcrumbs {
  margin: 0 auto;
  width: max-content;
  display: none;
  font-size: 14px;
  line-height: normal;
  color: #285D51;
  margin-bottom: 21px;
}
.contact-page .breadcrumbs a {
  color: #515358;
}
@media (max-width: 767px) {
  .contact-page .breadcrumbs {
    display: block;
  }
}
.contact-page .banner {
  height: 480px;
  display: flex;
  justify-content: flex-start;
  position: relative;
}
@media (max-width: 767px) {
  .contact-page .banner {
    height: 197px;
  }
}
.contact-page .banner .banner-image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contact-page .banner .banner-info-wrapper {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.contact-page .banner .banner-info-wrapper .info {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 100px 30px 55px 30px;
  background: #d3dfff;
  border-top-left-radius: 135px;
  border-top-right-radius: 135px;
  max-width: 270px;
}
.contact-page .banner .banner-info-wrapper .info .title {
  color: #404040;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.51px;
}
.contact-page .banner .banner-info-wrapper .info .description {
  color: #404040;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 0.51px;
}
.contact-page .banner .search {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
.contact-page .page-content {
  padding: 62px 0 492px 0;
}
@media (max-width: 767px) {
  .contact-page .page-content {
    padding: 21px 0 0;
  }
}
.contact-page .page-content .page-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 63px;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container {
    padding: 0 16px;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 66px;
  flex: 1;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper {
    gap: 61px;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .contact-form-header {
  display: flex;
  flex-direction: column;
  gap: 35px;
}
.contact-page .page-content .page-content-container .contact-form-wrapper .contact-form-header h1 {
  font-size: 35px;
  font-weight: 700;
  line-height: 47.6px;
  color: #404040;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .contact-form-header h1 {
    font-size: 32px;
    text-align: center;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .contact-form-header p {
  font-size: 22px;
  font-weight: 300;
  line-height: 29.92px;
  color: #404040;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .contact-form-header p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    text-align: center;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .screen-reader-response {
  display: none;
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form {
    gap: 21px;
    flex-flow: column;
    padding: 0 16px;
  }
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form:not(.sent, .invalid) .wpcf7-response-output {
    display: none;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .form-row {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .form-row {
    gap: 21px;
    flex-flow: column;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form label {
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.76px;
  color: #515358;
  flex: 1;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form label {
    gap: 8px;
    line-height: normal;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form input {
  padding: 20px 16px 18px;
  border-radius: 5px;
  border: 1px solid #A4A4A4;
  color: #515358;
  font-size: 16px;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form input {
    padding: 13px 19px;
    width: 100%;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form input::placeholder {
  color: rgba(81, 83, 88, 0.4);
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form input.wpcf7-not-valid {
  border: 1px solid #D43C33 !important;
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .wpcf7-form-control-wrap {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .wpcf7-form-control-wrap .wpcf7-not-valid-tip {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  color: #D43C33;
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form textarea {
  width: 100%;
  padding: 20px 15px;
  border-radius: 5px;
  border: solid 1px #A4A4A4;
  resize: none;
  height: 234px;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form textarea {
    height: 184px;
  }
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 21.76px;
  color: rgba(81, 83, 88, 0.4);
}
.contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .wpcf7-submit {
  align-self: flex-end;
  width: fit-content;
  background: #045E51;
  border-radius: 93.27px;
  font-size: 17.1px;
  font-weight: 600;
  line-height: 23.26px;
  color: #ffffff;
  padding: 21px 69px;
  cursor: pointer;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .contact-form-wrapper .wpcf7 .wpcf7-form .wpcf7-submit {
    margin: 9px auto;
    padding: 21px;
    min-width: 238px;
    font-size: 16px;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: 0.48px;
    text-align: center;
  }
}
.contact-page .page-content .page-content-container .info-wrapper {
  width: 100%;
  max-width: 615px;
  display: flex;
  flex-direction: column;
  gap: 42px;
  padding: 42px 39px 42px 55px;
  box-shadow: 0 0 18.83px 0 rgba(20, 6, 60, 0.1490196078);
  border-radius: 11.29px;
  margin: 20px 0;
}
@media (max-width: 767px) {
  .contact-page .page-content .page-content-container .info-wrapper {
    display: none;
  }
}
.contact-page .page-content .page-content-container .info-wrapper .info-title {
  font-size: 35px;
  font-weight: 700;
  line-height: 47.6px;
  color: #404040;
  margin-bottom: -6px;
}
.contact-page .page-content .page-content-container .info-wrapper .info-item {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  letter-spacing: 0.03em;
  color: #404040;
}
.contact-page .page-content .page-content-container .info-wrapper .info-item .info-label {
  font-size: 18px;
  font-weight: 700;
  line-height: 23.4px;
  letter-spacing: 0.03em;
}
.contact-page .page-content .page-content-container .info-wrapper .info-item a {
  color: #404040;
}
.contact-page .page-content .page-content-container .info-wrapper .info-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #404040;
}

.page-default, .post-default {
  overflow-x: hidden;
}
.page-default .banner, .post-default .banner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 480px;
}
.page-default .default-page-container, .post-default .default-page-container {
  max-width: 1150px;
  margin: 50px auto 0;
}
.page-default .default-page-container a, .post-default .default-page-container a {
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #515358;
}
.page-default .default-page-container a:hover, .page-default .default-page-container a:focus, .page-default .default-page-container a:active, .post-default .default-page-container a:hover, .post-default .default-page-container a:focus, .post-default .default-page-container a:active {
  color: #175f62;
}
.page-default .default-page-container .page-breadcrumbs, .post-default .default-page-container .page-breadcrumbs {
  text-align: center;
  margin-bottom: 16px;
}
.page-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs, .post-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs {
  font-size: 14px;
  line-height: 19px;
}
.page-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs a, .post-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs a {
  font-size: 14px;
  transition: all 0.5s;
}
.page-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs a:hover, .post-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs a:hover {
  text-decoration: underline;
  font-weight: 500;
}
.page-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs .breadcrumb_last, .post-default .default-page-container .page-breadcrumbs .yoast-breadcrumbs .breadcrumb_last {
  color: #175f62;
  padding-right: 3px;
}
.page-default .default-page-container .page-title, .post-default .default-page-container .page-title {
  padding: 15px 0;
  text-align: center;
  line-height: 68px;
}
.page-default .default-page-container .decor-line, .post-default .default-page-container .decor-line {
  display: flex;
  justify-content: center;
}
.page-default .default-page-container .decor-line hr, .post-default .default-page-container .decor-line hr {
  margin: 0;
  padding: 0;
  width: 32px;
  border: none;
  border-bottom: solid 1px #9ea0a1;
}
.page-default .default-page-container .page-content, .post-default .default-page-container .page-content {
  background: #ffffff;
  padding: 50px 0 10px 0;
}
.page-default .default-page-container .page-content h1,
.page-default .default-page-container .page-content h2,
.page-default .default-page-container .page-content h3,
.page-default .default-page-container .page-content h4,
.page-default .default-page-container .page-content h5,
.page-default .default-page-container .page-content h6, .post-default .default-page-container .page-content h1,
.post-default .default-page-container .page-content h2,
.post-default .default-page-container .page-content h3,
.post-default .default-page-container .page-content h4,
.post-default .default-page-container .page-content h5,
.post-default .default-page-container .page-content h6 {
  margin-bottom: 8px;
  color: black;
}
.page-default .default-page-container .page-content h1:last-child,
.page-default .default-page-container .page-content h2:last-child,
.page-default .default-page-container .page-content h3:last-child,
.page-default .default-page-container .page-content h4:last-child,
.page-default .default-page-container .page-content h5:last-child,
.page-default .default-page-container .page-content h6:last-child, .post-default .default-page-container .page-content h1:last-child,
.post-default .default-page-container .page-content h2:last-child,
.post-default .default-page-container .page-content h3:last-child,
.post-default .default-page-container .page-content h4:last-child,
.post-default .default-page-container .page-content h5:last-child,
.post-default .default-page-container .page-content h6:last-child {
  margin-bottom: 0;
}
.page-default .default-page-container .page-content figure, .post-default .default-page-container .page-content figure {
  max-width: 100%;
}
.page-default .default-page-container .page-content figure video, .post-default .default-page-container .page-content figure video {
  max-width: 100%;
}
.page-default .default-page-container .page-content h4,
.page-default .default-page-container .page-content h5,
.page-default .default-page-container .page-content h6, .post-default .default-page-container .page-content h4,
.post-default .default-page-container .page-content h5,
.post-default .default-page-container .page-content h6 {
  line-height: 28px;
  text-transform: none;
}
.page-default .default-page-container .page-content p, .post-default .default-page-container .page-content p {
  margin-bottom: 23px;
  line-height: 25px;
  color: #404040;
}
.page-default .default-page-container .page-content p:last-child, .post-default .default-page-container .page-content p:last-child {
  margin-bottom: 0;
}
.page-default .default-page-container .page-content ul, .post-default .default-page-container .page-content ul {
  list-style-type: disc;
  padding: inherit;
  margin: inherit;
  margin-bottom: 25px;
}
.page-default .default-page-container .page-content ul:last-child, .post-default .default-page-container .page-content ul:last-child {
  margin-bottom: 0;
}
.page-default .default-page-container .page-content ul li, .post-default .default-page-container .page-content ul li {
  margin-bottom: 8px;
}

.not-found-page .form {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  flex-direction: column;
  gap: 20px;
}
.not-found-page .form a {
  color: black;
}

@media (max-width: 575px) {
  .flexible-contents .flexible-contents-banner {
    min-height: 200px;
    object-fit: cover;
    height: 100%;
    object-position: center center;
  }
}
.flexible-contents .container {
  max-width: 1530px;
  margin: 0 auto;
  padding: 0 20px;
}
.flexible-contents .head {
  text-align: center;
  margin: 50px 0 80px 0;
}
.flexible-contents .head .breadcrumb {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  margin-bottom: 38px;
}
.flexible-contents .head .breadcrumb a {
  color: rgb(81, 83, 88);
}
.flexible-contents .head .breadcrumb span {
  color: rgb(40, 93, 81);
}
.flexible-contents .head h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 68px;
  text-align: center;
  color: rgb(0, 0, 0);
  position: relative;
  padding-bottom: 20px;
}
.flexible-contents .head h1:after {
  content: "";
  width: 32px;
  height: 1px;
  background: rgb(158, 160, 161);
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}
.flexible-contents .text-content {
  margin: 60px 0;
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
.flexible-contents .text-content .container {
  max-width: 1190px;
}
.flexible-contents .text-content strong, .flexible-contents .text-content b {
  color: #000;
}
.flexible-contents .text-content a {
  color: #025F52;
  font-weight: bold;
  text-decoration: underline;
}
.flexible-contents .text-content a:hover {
  color: #000;
}
.flexible-contents .text-content p {
  margin-bottom: 23px;
}
.flexible-contents .text-content h2, .flexible-contents .text-content h3 {
  color: #000000;
  font-size: 32px;
  font-weight: 700;
  line-height: 43.52px;
  margin-bottom: 13px;
}
.flexible-contents .text-content h3 {
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 13px;
}
@media (max-width: 575px) {
  .flexible-contents .text-content h3 {
    text-align: unset !important;
  }
}
.flexible-contents .text-content ul {
  padding: 0;
}
.flexible-contents .text-content ul li {
  display: block;
  position: relative;
  padding-right: 20px;
}
.flexible-contents .text-content ul li:before {
  content: "";
  width: 5px;
  height: 5px;
  display: block;
  border-radius: 50%;
  background: #025F52;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.flexible-contents .image-carousel {
  margin: 70px 0;
  overflow: hidden;
}
.flexible-contents .image-carousel .carousel .slick-list {
  margin: 0 -20px;
}
@media (max-width: 575px) {
  .flexible-contents .image-carousel .carousel .slick-list {
    padding: 0 0 0 20% !important;
    margin: 0;
  }
}
.flexible-contents .image-carousel .carousel .slick-track {
  display: flex;
  gap: 0 30px;
}
.flexible-contents .image-carousel .carousel .slick-arrow {
  height: 70px;
  width: 70px;
  background: rgb(217, 217, 217);
  border-radius: 50%;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.flexible-contents .image-carousel .carousel .slick-arrow:before {
  display: none;
}
.flexible-contents .image-carousel .carousel .slick-arrow.slick-next {
  left: -90px;
}
.flexible-contents .image-carousel .carousel .slick-arrow.slick-prev {
  right: -90px;
}
.flexible-contents .image-carousel .carousel .item {
  border-radius: 16px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  width: 360px;
  height: 300px;
  overflow: hidden;
}
.flexible-contents .image-carousel .carousel .item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.flexible-contents .video-content {
  margin: 60px 0;
}
.flexible-contents .video-content .container {
  max-width: 1190px;
}
@media (max-width: 575px) {
  .flexible-contents .video-content .container {
    padding: 0;
  }
}
.flexible-contents .video-content .video-container {
  position: relative;
}
.flexible-contents .video-content .video-container iframe, .flexible-contents .video-content .video-container video {
  height: 690px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.flexible-contents .video-content .video-container .player-nav {
  z-index: 8;
  left: 15px;
  bottom: 18px;
  position: absolute;
  width: auto;
}
.flexible-contents .video-content .video-container .player-nav button {
  width: 68px;
  height: 42px;
  background: #000;
  color: #fff;
  font-size: 25px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.flexible-contents .image-content {
  margin: 60px 0;
}
.flexible-contents .image-content .container {
  max-width: 1190px;
}
@media (max-width: 575px) {
  .flexible-contents .image-content .container {
    padding: 0;
  }
}
.flexible-contents .image-content .image-container {
  position: relative;
}
.flexible-contents .image-content .image-container img {
  height: 690px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
@media (max-width: 575px) {
  .flexible-contents .image-content .image-container img {
    border-radius: 0;
    height: auto;
  }
}
.flexible-contents .quote-content {
  margin: 70px 0;
}
.flexible-contents .quote-content .box {
  background: #FAFCFC;
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
}
.flexible-contents .quote-content .box .content {
  background: #fff;
  border-radius: 30px;
  color: #404040;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
}
.flexible-contents .quote-content .box .content h2, .flexible-contents .quote-content .box .content h3 {
  color: #000000;
  font-size: 32px;
  font-weight: 700;
  line-height: 43.52px;
  margin-bottom: 18px;
}
.flexible-contents .cards-content {
  padding: 48px 0;
  background: rgb(243, 245, 245);
}
.flexible-contents .cards-content .heading {
  font-size: 32px;
  font-weight: 700;
  line-height: 43.52px;
  text-align: right;
  color: #000000;
  margin-bottom: 32px;
}
.flexible-contents .cards-content .description {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 24px;
}
.flexible-contents .cards-content .list {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}
.flexible-contents .cards-content .list .item {
  width: calc(25% - 24px);
}
.flexible-contents .cards-content .list .item .box {
  background: #fff;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 20px 0 #cdcdcd;
  height: 100%;
}
.flexible-contents .cards-content .list .item .box .image {
  border-radius: 16px 16px 8px 8px;
  overflow: hidden;
  height: 260px;
  background: #cdcdcd;
}
.flexible-contents .cards-content .list .item .box .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.flexible-contents .cards-content .list .item .box .content {
  padding: 24px 24px 90px 24px;
  color: #404040;
  position: relative;
  flex: 1;
}
.flexible-contents .cards-content .list .item .box .content .title {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  margin-bottom: 6px;
}
.flexible-contents .cards-content .list .item .box .content .desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.flexible-contents .cards-content .list .item .box .content .btns {
  position: absolute;
  bottom: 24px;
}
.flexible-contents .cards-content .list .item .box .content .btns a {
  height: 47px;
  padding: 13px 30px 13px 30px;
  gap: 0px;
  border-radius: 50px;
  border: 0.8px solid #404040;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.8px;
  letter-spacing: 0.03em;
  color: #404040;
  display: inline-flex;
}
.flexible-contents .cards-content .list .item .box .content .btns a:active {
  box-shadow: 0 0 10px 0 #025f52;
}
.flexible-contents .cards-content .list .item .box .content .btns a:hover {
  background-color: #003a32;
  color: #fff;
}
.flexible-contents .cards-content.carousel {
  background: transparent;
  padding: 0;
}
.flexible-contents .cards-content.carousel[data-carousel_arrow="1"] .container {
  max-width: 1190px;
}
.flexible-contents .cards-content.carousel .heading {
  margin-bottom: 16px;
}
.flexible-contents .cards-content.carousel .list .slick-list {
  width: 100%;
}
@media (max-width: 575px) {
  .flexible-contents .cards-content.carousel .list .slick-list {
    padding: 0 0 0 20% !important;
  }
}
.flexible-contents .cards-content.carousel .list .slick-arrow {
  height: 70px;
  width: 70px;
  background: rgb(217, 217, 217);
  border-radius: 50%;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.flexible-contents .cards-content.carousel .list .slick-arrow:before {
  display: none;
}
.flexible-contents .cards-content.carousel .list .slick-arrow.slick-next {
  left: -90px;
}
.flexible-contents .cards-content.carousel .list .slick-arrow.slick-prev {
  right: -90px;
}
.flexible-contents .cards-content.carousel .list .item {
  transition: all 0.3s;
  width: calc(33.3333333333% - 24px);
  min-width: 360px;
  padding: 16px;
}
@media (max-width: 575px) {
  .flexible-contents .cards-content.carousel .list .item {
    min-width: unset;
  }
}
.flexible-contents .form-content {
  margin: 70px 0;
}
.flexible-contents .form-content .container {
  max-width: 1190px;
}
.flexible-contents .form-content .box {
  background: #FAFCFC;
  padding: 40px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
}
.flexible-contents .form-content .box .heading {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.03em;
  color: #404040;
  margin-bottom: 24px;
}
.flexible-contents .form-content .box .content .screen-reader-response {
  display: none;
}
.flexible-contents .form-content .box .content form {
  display: flex;
  gap: 24px 32px;
  align-items: end;
}
@media (max-width: 991px) {
  .flexible-contents .form-content .box .content form {
    flex-wrap: wrap;
  }
}
@media (max-width: 767px) {
  .flexible-contents .form-content .box .content form {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .flexible-contents .form-content .box .content form > font {
    flex-basis: 47%;
  }
}
.flexible-contents .form-content .box .content form label {
  flex: 1;
  color: #515358;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.76px;
  position: relative;
}
@media (max-width: 991px) {
  .flexible-contents .form-content .box .content form label {
    flex-basis: 47%;
  }
}
@media (max-width: 767px) {
  .flexible-contents .form-content .box .content form label {
    width: 100%;
  }
}
.flexible-contents .form-content .box .content form label .wpcf7-not-valid-tip {
  position: absolute;
  font-size: 12px;
  top: 100%;
  right: 0;
}
.flexible-contents .form-content .box .content form label input {
  height: 46px;
  border-radius: 5px;
  border: 1px solid #A4A4A4;
  width: 100%;
  margin-top: 4px;
}
.flexible-contents .form-content .box .content form .wpcf7-submit {
  background: #045E51;
  color: #fff;
  font-size: 17.1px;
  font-weight: 600;
  line-height: 23.26px;
  height: 46px;
  border-radius: 50px;
  border: none;
  text-align: center;
  width: 140px;
  cursor: pointer;
}
.flexible-contents .form-content .box .content form .wpcf7-submit:hover {
  background-color: #003a32;
}
.flexible-contents .form-content .box .content form .wpcf7-submit:active {
  box-shadow: 0 0 10px 0 #025f52;
}

.video-page-wrqap > img {
  object-fit: contain;
  width: 100%;
}
@media (max-width: 575px) {
  .video-page-wrqap > img {
    object-fit: cover;
    height: 200px;
  }
}
.video-page-wrqap .container {
  max-width: 1650px;
  margin: 0 auto;
  padding: 0 20px;
}
.video-page-wrqap .head {
  text-align: center;
  margin: 50px 0 80px 0;
}
.video-page-wrqap .head .breadcrumb {
  font-size: 14px;
  font-weight: 400;
  line-height: 19.04px;
  margin-bottom: 38px;
}
.video-page-wrqap .head .breadcrumb a {
  color: rgb(81, 83, 88);
}
.video-page-wrqap .head .breadcrumb span {
  color: rgb(40, 93, 81);
}
.video-page-wrqap .head h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 68px;
  text-align: center;
  color: rgb(0, 0, 0);
  position: relative;
  padding-bottom: 20px;
}
@media (max-width: 575px) {
  .video-page-wrqap .head h1 {
    font-size: 32px;
  }
}
.video-page-wrqap .head h1:after {
  content: "";
  width: 32px;
  height: 1px;
  background: rgb(158, 160, 161);
  display: block;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
}
.video-page-wrqap .video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 75px;
}
@media (max-width: 1264px) {
  .video-page-wrqap .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767px) {
  .video-page-wrqap .video-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.video-page-wrqap .video-grid .video-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.video-page-wrqap .video-grid .video-item.hidden {
  display: none;
}
.video-page-wrqap .video-grid .video-item .img-wrap {
  position: relative;
}
.video-page-wrqap .video-grid .video-item .img-wrap svg {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 15%;
  max-width: 70px;
  cursor: pointer;
}
.video-page-wrqap .video-grid .video-item img {
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1.8/1;
  border-radius: 8px;
}
.video-page-wrqap .video-grid .video-item .img-title {
  font-weight: 700;
  color: #404040;
  font-size: 24px;
  line-height: 31.2px;
}
.video-page-wrqap .video-grid .video-item .img-text {
  font-weight: 400;
  color: #404040;
  font-size: 20px;
  line-height: 30px;
}
.video-page-wrqap .show-more-videos {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
}
.video-page-wrqap .show-more-videos.hidden {
  display: none;
}

.video-modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 16;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.568627451);
  z-index: 99999999;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms linear;
}
.video-modal-popup.active {
  pointer-events: painted;
  opacity: 1;
}
.video-modal-popup.active .video-container {
  margin-top: 0;
}
.video-modal-popup .video-container {
  margin-top: 50%;
  transition: all 500ms ease-in-out;
  width: 100%;
  position: relative;
  text-align: left;
}
.video-modal-popup .video-container .close-modal {
  opacity: 0.7;
  cursor: pointer;
  transition: all 200ms linear;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 31;
  transform: translateY(-100%);
  pointer-events: none;
}
.video-modal-popup .video-container .close-modal:hover {
  opacity: 1;
}
.video-modal-popup .video-container .plyr {
  height: 100%;
  border-radius: 8px;
}
.video-modal-popup .video-container .player-nav {
  z-index: 7;
  left: 15px;
  bottom: 18px;
  position: absolute;
  width: auto;
}
.video-modal-popup .video-container .player-nav button {
  width: 68px;
  height: 42px;
  background: #000;
  color: #fff;
  font-size: 25px;
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: pointer;
}
.video-modal-popup .video-container .player-vol {
  z-index: 7;
  right: 15px;
  bottom: 18px;
  position: absolute;
  width: auto;
}
.video-modal-popup .video-container .player-vol button {
  width: 50px;
  height: 42px;
  background: #000;
  color: #fff;
  font-size: 25px;
  border-radius: 4px;
  border: 1px solid #fff;
  cursor: pointer;
}
@media (min-width: 991px) {
  .video-modal-popup .video-container {
    width: 60%;
    height: 60%;
  }
}

.people-mobile {
  display: none;
  text-align: right;
}

.car-card-mobile {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px 30px;
  flex-direction: column;
}
.car-card-mobile .accommodation_images {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.car-card-mobile .car-bold-text {
  font-size: 14px;
  font-weight: 700;
  color: #404040;
}
.car-card-mobile .package-apartment-info {
  width: 100%;
}
.car-card-mobile .package-apartment-info .title h3 {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.car-card-mobile .package-apartment-info .title h3 span:first-child {
  font-size: 20px;
  font-weight: 700;
  color: #404040;
}
.car-card-mobile .package-apartment-info .title h3 span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}
.car-card-mobile .package-apartment-info .car-option ul {
  display: flex;
  gap: 8px;
  padding: 0;
  flex-direction: column;
}
.car-card-mobile .package-apartment-info .car-option ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  gap: 6px;
  justify-content: space-between;
}
.car-card-mobile .package-apartment-info .car-option ul li span:first-child {
  font-size: 14px;
}
.car-card-mobile .package-apartment-info .car-details {
  display: flex;
  gap: 100px;
}
.car-card-mobile .package-apartment-info .car-details h4 {
  font-size: 16px;
  font-weight: 700;
  color: #404040;
  margin-bottom: 20px;
}
.car-card-mobile .package-apartment-info .car-details .from-to .info-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-inline-start: 20px;
  position: relative;
}
.car-card-mobile .package-apartment-info .car-details .from-to .info-wrap:before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEwIiB2aWV3Qm94PSIwIDAgMjAgMTEwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMCAxMDkuNjY3QzExLjQ3MjggMTA5LjY2NyAxMi42NjY3IDEwOC40NzMgMTIuNjY2NyAxMDdDMTIuNjY2NyAxMDUuNTI3IDExLjQ3MjggMTA0LjMzMyAxMCAxMDQuMzMzQzguNTI3MjQgMTA0LjMzMyA3LjMzMzMzIDEwNS41MjcgNy4zMzMzMyAxMDdDNy4zMzMzMyAxMDguNDczIDguNTI3MjQgMTA5LjY2NyAxMCAxMDkuNjY3Wk0xMCAxMC42NjY3QzExLjQ3MjggMTAuNjY2NyAxMi42NjY3IDkuNDcyNzYgMTIuNjY2NyA4QzEyLjY2NjcgNi41MjcyNCAxMS40NzI4IDUuMzMzNCAxMCA1LjMzMzRDOC41MjcyNCA1LjMzMzQgNy4zMzMzMyA2LjUyNzI0IDcuMzMzMzMgOEM3LjMzMzMzIDkuNDcyNzYgOC41MjcyNCAxMC42NjY3IDEwIDEwLjY2NjdMMTAuNSAxMDdMMTAuNSA4SDkuNUw5LjUgMTA3SDEwLjVaIiBmaWxsPSIjRDdEN0Q3Ii8+PC9zdmc+");
  background-size: cover;
  position: absolute;
  width: 20px;
  height: 95%;
  right: 0;
}
.car-card-mobile .package-apartment-info .car-details .from-to .info-wrap .info > div:first-child {
  font-size: 12px;
  font-weight: 400;
  color: #404040;
  opacity: 0.5;
}
.car-card-mobile .package-apartment-info .car-details .from-to .info-wrap .info > div:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.car-card-mobile .package-apartment-info .car-details .rental_conditions ul {
  margin: 0;
  padding: 0;
}
.car-card-mobile .package-apartment-info .car-details .rental_conditions ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #7d7d7d;
}

.car-card {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px 30px;
}
.car-card .package-apartment-info .title h3 span:first-child {
  font-size: 20px;
  font-weight: 700;
  color: #404040;
}
.car-card .package-apartment-info .title h3 span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #7d7d7d;
}
.car-card .package-apartment-info .car-option ul {
  display: flex;
  gap: 26px;
  padding: 0;
}
.car-card .package-apartment-info .car-option ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 8px 16px;
  border-radius: 10px;
  gap: 6px;
  background-color: #f3f5f5;
}
.car-card .package-apartment-info .car-details {
  display: flex;
  gap: 100px;
}
.car-card .package-apartment-info .car-details h4 {
  font-size: 16px;
  font-weight: 700;
  color: #404040;
  margin-bottom: 20px;
}
.car-card .package-apartment-info .car-details .from-to .info-wrap {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-inline-start: 20px;
  position: relative;
}
.car-card .package-apartment-info .car-details .from-to .info-wrap:before {
  content: "";
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEwIiB2aWV3Qm94PSIwIDAgMjAgMTEwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMCAxMDkuNjY3QzExLjQ3MjggMTA5LjY2NyAxMi42NjY3IDEwOC40NzMgMTIuNjY2NyAxMDdDMTIuNjY2NyAxMDUuNTI3IDExLjQ3MjggMTA0LjMzMyAxMCAxMDQuMzMzQzguNTI3MjQgMTA0LjMzMyA3LjMzMzMzIDEwNS41MjcgNy4zMzMzMyAxMDdDNy4zMzMzMyAxMDguNDczIDguNTI3MjQgMTA5LjY2NyAxMCAxMDkuNjY3Wk0xMCAxMC42NjY3QzExLjQ3MjggMTAuNjY2NyAxMi42NjY3IDkuNDcyNzYgMTIuNjY2NyA4QzEyLjY2NjcgNi41MjcyNCAxMS40NzI4IDUuMzMzNCAxMCA1LjMzMzRDOC41MjcyNCA1LjMzMzQgNy4zMzMzMyA2LjUyNzI0IDcuMzMzMzMgOEM3LjMzMzMzIDkuNDcyNzYgOC41MjcyNCAxMC42NjY3IDEwIDEwLjY2NjdMMTAuNSAxMDdMMTAuNSA4SDkuNUw5LjUgMTA3SDEwLjVaIiBmaWxsPSIjRDdEN0Q3Ii8+PC9zdmc+");
  background-size: cover;
  position: absolute;
  width: 20px;
  height: 95%;
  right: 0;
}
.car-card .package-apartment-info .car-details .from-to .info-wrap .info > div:first-child {
  font-size: 12px;
  font-weight: 400;
  color: #404040;
  opacity: 0.5;
}
.car-card .package-apartment-info .car-details .from-to .info-wrap .info > div:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #404040;
}
.car-card .package-apartment-info .car-details .rental_conditions ul {
  margin: 0;
  padding: 0;
}
.car-card .package-apartment-info .car-details .rental_conditions ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  gap: 8px;
  font-size: 16px;
  font-weight: 400;
  color: #7d7d7d;
}

@media (max-width: 991px) {
  .page-template-search-packages .search-form-parks {
    border-radius: 12px !important;
  }
}
.page-template-search-packages #modal-search.modal-search .search-body > .item.packages {
  display: flex !important;
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap {
    padding: 0 !important;
    gap: 0 !important;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .thumbnail {
    max-height: 174px !important;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .thumbnail img {
    border-radius: 10px 10px 0 0;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .text {
    padding: 30px 16px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .text .title h4 {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .description {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-wrap .description p {
    font-size: 24px;
  }
}
.page-template-search-packages .post .cost {
  padding: 0 20px !important;
}
.page-template-search-packages .post .cost .people {
  text-align: end;
}
.page-template-search-packages .post .cost .duration-of-stay-and-number-of-people {
  text-align: end !important;
}
.page-template-search-packages .post .accommodation_short_description {
  max-height: 100px;
  overflow-y: hidden;
  padding-inline-start: 3px;
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div {
    gap: 0;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .package-apartment-info {
    order: 1;
  }
}
.page-template-search-packages .post .post-tabs-apartment > div .package-apartment-info h4 {
  font-size: 20px;
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .package-apartment-info .accommodation_parameters_row {
    flex-wrap: wrap;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .accommodation_images {
    order: 2;
    max-height: unset;
    padding: 0 20px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .accommodation_images img {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .price {
    order: 3;
  }
}
.page-template-search-packages .post .post-tabs-apartment > div .price .duration-of-stay-and-number-of-people {
  text-align: end;
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .price .cost {
    flex-direction: row !important;
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .price .cost .people {
    display: none;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .price .cost .price-per-stay {
    margin-bottom: 0;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-apartment > div .price .cost .people-mobile {
    display: block;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-plane {
    padding: 30px 20px;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .post-tabs-plane .plane-from,
  .page-template-search-packages .post .post-tabs-plane .plane-to {
    gap: 3px !important;
  }
}
@media (max-width: 991px) {
  .page-template-search-packages .post .mobile-price .price .people {
    display: none;
  }
}
.page-template-search-packages .post .mobile-price .price .people-mobile {
  display: block;
}

.search-packages .post-tabs > div,
.single-accommodation .post-tabs > div {
  display: none;
}
.search-packages .post-tabs > div.active,
.single-accommodation .post-tabs > div.active {
  display: flex;
}
@media (max-width: 991px) {
  .search-packages .post-tabs .price .duration-of-stay-and-number-of-people,
  .single-accommodation .post-tabs .price .duration-of-stay-and-number-of-people {
    text-align: start !important;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card,
  .single-accommodation .post-tabs .post-tabs-car .car-card {
    flex-direction: column-reverse;
    gap: 0;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card .car-details,
  .single-accommodation .post-tabs .post-tabs-car .car-card .car-details {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card .car-option ul,
  .single-accommodation .post-tabs .post-tabs-car .car-card .car-option ul {
    flex-wrap: wrap;
    gap: 8px;
    margin: 24px 0 20px;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card .car-option ul li,
  .single-accommodation .post-tabs .post-tabs-car .car-card .car-option ul li {
    font-size: 14px;
    font-weight: 400;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card .rental_conditions ul,
  .single-accommodation .post-tabs .post-tabs-car .car-card .rental_conditions ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs .post-tabs-car .car-card .rental_conditions ul li,
  .single-accommodation .post-tabs .post-tabs-car .car-card .rental_conditions ul li {
    font-size: 14px;
  }
}
.search-packages .post-tabs-plane,
.single-accommodation .post-tabs-plane {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 50px 100px;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane,
  .single-accommodation .post-tabs-plane {
    gap: 20px;
  }
}
.search-packages .post-tabs-plane .plane-company-mobile,
.single-accommodation .post-tabs-plane .plane-company-mobile {
  display: none;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .plane-company-mobile,
  .single-accommodation .post-tabs-plane .plane-company-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.search-packages .post-tabs-plane .duration-mobile,
.single-accommodation .post-tabs-plane .duration-mobile {
  display: none;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .duration-mobile,
  .single-accommodation .post-tabs-plane .duration-mobile {
    display: flex;
    justify-content: center;
    font-size: 14px;
    align-items: center;
    gap: 5px;
    color: #404040;
  }
}
.search-packages .post-tabs-plane .duration-mobile .dot,
.single-accommodation .post-tabs-plane .duration-mobile .dot {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane > div:nth-child(3),
  .single-accommodation .post-tabs-plane > div:nth-child(3) {
    padding-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }
}
.search-packages .post-tabs-plane .post-tabs-plane-row,
.single-accommodation .post-tabs-plane .post-tabs-plane-row {
  display: grid;
  grid-template-columns: 20% 15% 50% 15%;
  align-items: center;
  color: #404040;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .post-tabs-plane-row,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row {
    grid-template-columns: 45% 10% 45%;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .post-tabs-plane-row .plane-company,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-company {
    display: none;
  }
}
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-company .fly-checkout,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-company .fly-checkout {
  display: none;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-time,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-time {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.2em;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-day,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-day {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2em;
  opacity: 0.5;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-airport,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-airport {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2em;
  text-transform: uppercase;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-from,
.search-packages .post-tabs-plane .post-tabs-plane-row .plane-to,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-from,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-to {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .post-tabs-plane-row .plane-from,
  .search-packages .post-tabs-plane .post-tabs-plane-row .plane-to,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-from,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-to {
    flex-direction: row;
    gap: 10px;
  }
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration {
  text-align: center;
  line-height: 40px;
  padding: 5px;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .time-wrap,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .time-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .post-tabs-plane-row .duration .time-wrap,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .time-wrap {
    display: none;
  }
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .time-wrap .dot,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .time-wrap .dot {
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .luggage-wrap,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .luggage-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-plane .post-tabs-plane-row .duration .luggage-wrap,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .luggage-wrap {
    display: none;
  }
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot {
  border-bottom: 1px solid #d7d7d7;
  position: relative;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot:before,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #d7d7d7;
  left: -2px;
  top: -2px;
}
.search-packages .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot:after,
.single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #d7d7d7;
  right: -2px;
  top: -2px;
}
.search-packages .icon-type-row,
.single-accommodation .icon-type-row {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
.search-packages .icon-type-row > div,
.single-accommodation .icon-type-row > div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f5;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  border-radius: 24px;
  padding: 2px;
}
.search-packages .packages-result .post-wrap .price .nav,
.single-accommodation .packages-result .post-wrap .price .nav {
  display: flex;
  flex-direction: column-reverse;
  gap: 12px;
}
.search-packages .packages-result .post-wrap .price .nav a,
.single-accommodation .packages-result .post-wrap .price .nav a {
  margin-bottom: 0;
}
.search-packages .packages-result .load-more-packages,
.single-accommodation .packages-result .load-more-packages {
  display: none;
  padding: 30px;
}
.search-packages .packages-result .load-more-packages button,
.single-accommodation .packages-result .load-more-packages button {
  margin: 0 auto;
  display: block;
}
.search-packages .post,
.single-accommodation .post {
  flex-direction: column !important;
  padding: 0 !important;
  gap: 0 !important;
}
.search-packages .post .post-wrap,
.single-accommodation .post .post-wrap {
  display: grid !important;
  grid-template-columns: 2fr 2fr 1fr;
  padding: 20px;
  gap: 40px;
}
.search-packages .post .post-wrap .thumbnail,
.single-accommodation .post .post-wrap .thumbnail {
  height: auto !important;
  max-height: 255px;
}
.search-packages .post .post-wrap .text,
.single-accommodation .post .post-wrap .text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}
.search-packages .post .post-wrap .text .title h4,
.single-accommodation .post .post-wrap .text .title h4 {
  font-size: 24px;
}
.search-packages .post .post-wrap .text .description,
.single-accommodation .post .post-wrap .text .description {
  font-size: 20px;
}
.search-packages .post .post-wrap .text .description p,
.single-accommodation .post .post-wrap .text .description p {
  font-size: 20px;
}
.search-packages .post .post-wrap .price .cost,
.single-accommodation .post .post-wrap .price .cost {
  padding: 0;
  background: transparent;
  flex-direction: column;
}
.search-packages .post .post-wrap .price .nav a,
.single-accommodation .post .post-wrap .price .nav a {
  margin-bottom: 12px;
}
.search-packages .post .post-wrap .price .nav a,
.search-packages .post .post-wrap .price .nav button,
.single-accommodation .post .post-wrap .price .nav a,
.single-accommodation .post .post-wrap .price .nav button {
  width: 100%;
}
.search-packages .post .post-tabs-control,
.single-accommodation .post .post-tabs-control {
  display: flex;
  flex-direction: row;
  background: #f3f5f5;
}
.search-packages .post .post-tabs-control.mobile,
.single-accommodation .post .post-tabs-control.mobile {
  display: none;
}
@media (max-width: 991px) {
  .search-packages .post .post-tabs-control,
  .single-accommodation .post .post-tabs-control {
    display: none;
  }
}
@media (max-width: 991px) {
  .search-packages .post .post-tabs-control.mobile,
  .single-accommodation .post .post-tabs-control.mobile {
    display: flex;
  }
}
.search-packages .post .post-tabs-control .post-tabs-control-btn,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn {
  padding: 22px 50px;
  border-radius: 30px 30px 0 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  flex-direction: row-reverse;
}
@media (max-width: 991px) {
  .search-packages .post .post-tabs-control .post-tabs-control-btn,
  .single-accommodation .post .post-tabs-control .post-tabs-control-btn {
    padding: 16px;
  }
}
.search-packages .post .post-tabs-control .post-tabs-control-btn .icon-set-wrap img,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn .icon-set-wrap img {
  display: none;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn .icon-set-wrap img:first-child,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn .icon-set-wrap img:first-child {
  display: block;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn .plus,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn .plus {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5H3V13.5H21V10.5Z' fill='%23404040'/%3E%3Cpath d='M10.5 3L10.5 21H13.5V3H10.5Z' fill='%23404040'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center center;
  width: 20px;
  height: 20px;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn.active,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn.active {
  background-color: white;
  color: #045e51;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn.active .plus,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn.active .plus {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5H3V13.5H21V10.5Z' fill='%23045E51'/%3E%3C/svg%3E");
}
.search-packages .post .post-tabs-control .post-tabs-control-btn.active .icon-set-wrap img,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn.active .icon-set-wrap img {
  display: none;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn.active .icon-set-wrap img:last-child,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn.active .icon-set-wrap img:last-child {
  display: block;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn:first-child,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn:first-child {
  border-radius: 30px 0 0 0;
}
.search-packages .post .post-tabs-control .post-tabs-control-btn .plus,
.single-accommodation .post .post-tabs-control .post-tabs-control-btn .plus {
  font-size: 35px;
  margin-right: 20px;
}
.search-packages .accommodation_parameters_row,
.single-accommodation .accommodation_parameters_row {
  display: flex;
  flex-direction: row;
  gap: 25px;
}
.search-packages .accommodation_parameters_row .accommodation_parameters_section,
.single-accommodation .accommodation_parameters_row .accommodation_parameters_section {
  height: 18px;
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #404040;
  flex-direction: row-reverse;
}
.search-packages .accommodation_parameters_row .accommodation_parameters_section img,
.single-accommodation .accommodation_parameters_row .accommodation_parameters_section img {
  object-fit: contain;
  height: 100%;
}
.search-packages .post-tabs-apartment,
.single-accommodation .post-tabs-apartment {
  flex-direction: column;
}
.search-packages .post-tabs-apartment > div,
.single-accommodation .post-tabs-apartment > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 40px;
  align-items: flex-start;
  margin-bottom: 15px;
  box-shadow: 0px 4px 10px 0px rgba(20, 6, 60, 0.15);
  border-radius: 0 0 12px 0;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div,
  .single-accommodation .post-tabs-apartment > div {
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div > div,
  .single-accommodation .post-tabs-apartment > div > div {
    width: 100%;
  }
}
.search-packages .post-tabs-apartment > div:last-child,
.single-accommodation .post-tabs-apartment > div:last-child {
  box-shadow: none;
  margin-bottom: 0;
}
.search-packages .post-tabs-apartment > div .accommodation_images,
.single-accommodation .post-tabs-apartment > div .accommodation_images {
  max-height: 240px;
  height: 100%;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div .accommodation_images,
  .single-accommodation .post-tabs-apartment > div .accommodation_images {
    padding: 20px;
  }
}
.search-packages .post-tabs-apartment > div .accommodation_images img,
.single-accommodation .post-tabs-apartment > div .accommodation_images img {
  object-fit: contain;
  height: 100%;
  max-height: 240px;
  border-radius: 0 0 12px 0;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div .accommodation_images img,
  .single-accommodation .post-tabs-apartment > div .accommodation_images img {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    object-fit: cover;
    border-radius: 12px;
  }
}
.search-packages .post-tabs-apartment > div .package-apartment-info,
.single-accommodation .post-tabs-apartment > div .package-apartment-info {
  display: flex;
  flex-direction: column;
  gap: 18px;
  color: #404040;
  padding-top: 40px;
  flex-grow: 1;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div .package-apartment-info,
  .single-accommodation .post-tabs-apartment > div .package-apartment-info {
    padding: 20px;
  }
}
.search-packages .post-tabs-apartment > div .package-apartment-info h4,
.single-accommodation .post-tabs-apartment > div .package-apartment-info h4 {
  display: flex;
  align-items: center;
  gap: 10px;
}
.search-packages .post-tabs-apartment > div .package-apartment-info .accommodation_short_description ul,
.single-accommodation .post-tabs-apartment > div .package-apartment-info .accommodation_short_description ul {
  padding-inline-start: 16px;
  margin: 0;
}
.search-packages .post-tabs-apartment > div .package-apartment-info .accommodation_short_description ul li,
.single-accommodation .post-tabs-apartment > div .package-apartment-info .accommodation_short_description ul li {
  margin-bottom: 18px;
  font-size: 14px;
  color: #404040;
}
.search-packages .post-tabs-apartment > div .price,
.single-accommodation .post-tabs-apartment > div .price {
  padding: 20px;
}
@media (max-width: 991px) {
  .search-packages .post-tabs-apartment > div .price .nav,
  .single-accommodation .post-tabs-apartment > div .price .nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.single-accommodation .post-tabs-car {
  margin-top: 123px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1490196078);
  border-radius: 30px;
}
.single-accommodation .post-tabs-car > div {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;
  padding: 40px 40px 0 0;
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car > div {
    padding: 32px 16px 0 0;
  }
}
.single-accommodation .post-tabs-car .car-card {
  padding: 40px;
  flex-direction: row;
  justify-content: flex-start;
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card {
    padding: 32px 16px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card {
    flex-direction: column-reverse;
    gap: 0;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card .car-details {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card .car-option ul {
    flex-wrap: wrap;
    gap: 8px;
    margin: 24px 0 20px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card .car-option ul li {
    font-size: 14px;
    font-weight: 400;
    align-items: center;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card .rental_conditions ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-car .car-card .rental_conditions ul li {
    font-size: 14px;
  }
}
.single-accommodation .post-tabs-plane {
  padding: 40px;
  margin-top: 123px;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.1490196078);
  border-radius: 30px;
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane {
    padding: 32px 16px;
  }
}
.single-accommodation .post-tabs-plane > div:first-child {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 20px;
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane {
    margin-top: 60px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-from,
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .plane-to {
    gap: 5px !important;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane .post-tabs-plane-row .duration .line-with-dot {
    width: 80%;
    margin: 0 auto;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane h2 {
    font-size: 24px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane svg {
    width: 24px;
    height: 24px;
  }
}
@media (max-width: 991px) {
  .single-accommodation .post-tabs-plane > div:first-child {
    gap: 5px;
  }
}

@media (max-width: 991px) {
  .search-packages .post-wrap {
    grid-template-columns: 1fr !important;
  }
}
@media (max-width: 991px) {
  .search-packages .post-wrap .price {
    display: none;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-control {
    flex-direction: column !important;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-control > div {
    justify-content: space-between;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-control > div > div:nth-child(2) {
    flex-grow: 1;
  }
}
@media (max-width: 991px) {
  .search-packages .post-tabs-control .post-tabs-control-btn.active {
    background-color: #f3f5f5 !important;
  }
}
.search-packages .mobile-price {
  display: none;
  padding: 20px;
}
@media (max-width: 991px) {
  .search-packages .mobile-price {
    display: block;
  }
}
.search-packages .mobile-price .cost {
  background-color: white !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 20px;
  align-items: flex-start !important;
}
@media (max-width: 767px) {
  .search-packages .mobile-price .cost .duration-of-stay-and-number-of-people {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .search-packages .mobile-price .nav {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }
}

.info-block.fly {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.info-block.fly > p {
  font-size: 14px;
  color: #404040;
  font-weight: 400;
}
.info-block.fly > h3 {
  font-weight: 700;
  font-size: 16px;
  color: #404040;
}
.info-block.fly .plane-company-mobile {
  display: none;
}
.info-block.fly .plane-company > div:nth-child(1) {
  display: none;
}
.info-block.fly .plane-company .fly-checkout {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}
.info-block.fly .duration-mobile {
  display: none;
}
.info-block.fly > div:nth-child(1) {
  padding-bottom: 20px;
  border-bottom: 1px solid #d7d7d7;
}
.info-block.fly .fly-bottom-border {
  padding-bottom: 16px;
  border-bottom: 1px solid #d7d7d7;
}
.info-block.fly .post-tabs-plane-row {
  display: grid;
  grid-template-columns: 10% 15% 45% 15%;
  gap: 5%;
  align-items: center;
  color: #404040;
}
.info-block.fly .post-tabs-plane-row .plane-time {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2em;
  color: #404040;
}
.info-block.fly .post-tabs-plane-row .plane-day {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.2em;
  opacity: 0.5;
}
.info-block.fly .post-tabs-plane-row .plane-airport {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.2em;
  text-transform: uppercase;
}
.info-block.fly .post-tabs-plane-row .plane-from,
.info-block.fly .post-tabs-plane-row .plane-to {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 5px;
}
.info-block.fly .post-tabs-plane-row .duration {
  text-align: center;
  line-height: 40px;
}
.info-block.fly .post-tabs-plane-row .duration .line-with-dot {
  border-bottom: 1px solid #d7d7d7;
  position: relative;
}
.info-block.fly .post-tabs-plane-row .duration .line-with-dot:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #d7d7d7;
  left: -2px;
  top: -2px;
}
.info-block.fly .post-tabs-plane-row .duration .line-with-dot:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: #d7d7d7;
  right: -2px;
  top: -2px;
}

.page-template-check-out-page .time-wrap,
.page-template-check-out-page .luggage-wrap {
  display: none;
}

.banner-profile {
  height: 274px;
  position: relative;
}
@media (max-width: 767px) {
  .banner-profile {
    height: 120px;
  }
}
.banner-profile img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.banner-profile .banner-profile-title {
  position: absolute;
  width: 100%;
  bottom: 50%;
  transform: translateY(50%);
}
.banner-profile .banner-profile-title h2 {
  max-width: 1630px;
  margin: 0 auto;
  padding: 0 10px;
  font-weight: 700;
  color: #ffffff;
  font-size: calc(24px + 26 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .banner-profile .banner-profile-title h2 {
    font-size: 24px;
  }
}
@media screen and (min-width: 1200px) {
  .banner-profile .banner-profile-title h2 {
    font-size: 50px;
  }
}

.profile-page {
  max-width: 1600px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 0 0;
}
@media (max-width: 767px) {
  .profile-page .media-md-hide {
    display: none !important;
  }
}
.profile-page .media-md-show {
  display: none;
}
@media (max-width: 767px) {
  .profile-page .media-md-show {
    display: flex !important;
  }
}
.profile-page .control-header {
  flex-direction: column;
  margin-bottom: 30px;
}
.profile-page .control-header h4 {
  margin-bottom: 0 !important;
  font-size: 18px !important;
}
.profile-page .control-header .control-logout-md {
  display: flex;
  font-size: 16px;
  line-height: 1.5em;
  gap: 5px;
}
.profile-page .control-header .control-logout-md span {
  font-weight: 700;
}
.profile-page .control-header .control-logout-md a {
  color: #045e51;
  text-decoration: underline;
  font-weight: 700;
}
.profile-page > h3 {
  font-size: 14px;
  font-weight: 400;
  color: #515358;
  text-align: center;
  padding-inline-start: 25%;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .profile-page > h3 {
    padding-inline-start: 0;
    margin-bottom: 32px;
  }
}
.profile-page > h3 a {
  font-size: 14px;
  font-weight: 400;
  color: #515358;
  text-align: center;
  padding-inline-start: 25%;
  margin-bottom: 20px;
}
@media (max-width: 767px) {
  .profile-page > h3 a {
    padding-inline-start: 0;
    margin-bottom: 32px;
  }
}
.profile-page > h3 .current-tab-active {
  color: #285D51;
}
.profile-page > div {
  display: grid;
  gap: 5%;
  grid-template-columns: 20% 75%;
}
@media (max-width: 767px) {
  .profile-page > div {
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
  }
}
.profile-page > div .profile-tab {
  display: none;
}
.profile-page > div .profile-tab.active {
  display: flex;
  flex-direction: column;
}
.profile-page > div .sidebar-tab {
  list-style: none;
  padding: 0;
  margin: 0;
}
.profile-page > div .sidebar-tab li {
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1490196078);
  padding: 10px 20px;
  display: flex;
  max-width: 240px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 16px;
  color: #000000;
  font-weight: 600;
  cursor: pointer;
  transition: 200ms all linear;
}
@media (max-width: 767px) {
  .profile-page > div .sidebar-tab li {
    max-width: unset;
  }
}
.profile-page > div .sidebar-tab li.active {
  color: #045e51;
  background-color: #F9FBFB;
}
.profile-page > div .sidebar-tab li:hover {
  color: #045e51;
  background-color: #F9FBFB;
}
.profile-page > div .sidebar-tab li a {
  color: #000000;
  text-decoration: none;
  transition: 200ms all linear;
}
.profile-page > div .sidebar-tab li a:hover {
  color: #045e51;
}
.profile-page > div .sidebar-tab li > active {
  color: #045e51;
}
.profile-page > div .sidebar-tab li:not([data-sidebar-tab=logout])::after {
  content: "";
  display: inline-block;
  width: 9px;
  height: 15px;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 15"><path d="M7.64662 0L0.79443 6.85219L0.5 7.16L0.79443 7.46781L7.64662 14.32L8.26224 13.7044L1.71787 7.16L8.26224 0.615626L7.64662 0Z" fill="black"/></svg>') no-repeat;
  background-size: contain;
}
.profile-page > div .profile-user-info {
  position: relative;
}
@media (max-width: 767px) {
  .profile-page > div .profile-user-info {
    display: none;
    flex-direction: column;
  }
}
.profile-page > div .profile-user-info .profile-info-change {
  position: absolute;
  left: 0;
}
@media (max-width: 767px) {
  .profile-page > div .profile-user-info .profile-info-change {
    position: relative;
    left: auto;
    order: 2;
    margin-top: 24px;
  }
}
.profile-page > div .profile-user-info .profile-info-change button {
  font-size: 20px;
  font-weight: 700;
  color: #045e51;
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
}
.profile-page > div .profile-user-info h3 {
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 20px;
  color: #404040;
}
.profile-page > div .profile-user-info .profile-info-row {
  display: flex;
  margin-bottom: 8px;
}
.profile-page > div .profile-user-info .profile-info-row:nth-child(4) {
  margin-bottom: 40px;
}
.profile-page > div .profile-user-info .profile-info-row > div:first-child {
  min-width: 100px;
}
.profile-page > div .profile-user-info-edit h3 {
  font-weight: 700;
  color: #404040;
  margin-bottom: 24px;
  font-size: calc(18px + 2 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .profile-page > div .profile-user-info-edit h3 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .profile-page > div .profile-user-info-edit h3 {
    font-size: 20px;
  }
}
.profile-page > div .profile-user-info-edit form {
  max-width: 555px;
}
.profile-page > div .profile-user-info-edit .fr-fr {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
@media (max-width: 767px) {
  .profile-page > div .profile-user-info-edit .fr-fr {
    grid-template-columns: 1fr;
  }
}
.profile-page > div .profile-user-info-edit .fr-one {
  margin-bottom: 24px;
}
.profile-page > div .profile-user-info-edit .fr-one.email {
  opacity: 0.5;
}
.profile-page > div .profile-user-info-edit .fr-one.with-checkbox {
  position: relative;
}
.profile-page > div .profile-user-info-edit .fr-one.with-checkbox input[type=checkbox] {
  position: absolute;
  left: 24px;
  width: 24px;
  height: 24px;
  top: 39px;
  cursor: pointer;
  opacity: 0;
}
.profile-page > div .profile-user-info-edit .fr-one.with-checkbox input[type=checkbox] + div::after {
  content: "";
  width: 24px;
  height: 24px;
  top: 39px;
  left: 24px;
  z-index: 1;
  pointer-events: none;
  position: absolute;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 9C10.7554 9 9.80161 9.39509 9.09835 10.0984C8.39509 10.8016 8 11.7554 8 12.75C8 13.7446 8.39509 14.6984 9.09835 15.4016C9.80161 16.1049 10.7554 16.5 11.75 16.5C12.7446 16.5 13.6984 16.1049 14.4016 15.4016C15.1049 14.6984 15.5 13.7446 15.5 12.75C15.5 11.7554 15.1049 10.8016 14.4016 10.0984C13.6984 9.39509 12.7446 9 11.75 9ZM10.159 11.159C10.581 10.7371 11.1533 10.5 11.75 10.5C12.3467 10.5 12.919 10.7371 13.341 11.159C13.7629 11.581 14 12.1533 14 12.75C14 13.3467 13.7629 13.919 13.341 14.341C12.919 14.7629 12.3467 15 11.75 15C11.1533 15 10.581 14.7629 10.159 14.341C9.73705 13.919 9.5 13.3467 9.5 12.75C9.5 12.1533 9.73705 11.581 10.159 11.159Z' fill='%23515358'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 5C7.40833 5 3.86331 7.43675 1.10688 12.0308C0.964373 12.2683 0.964373 12.565 1.10688 12.8025C3.86331 17.3966 7.40833 19.8333 11.75 19.8333C16.0917 19.8333 19.6367 17.3966 22.3931 12.8025C22.5356 12.565 22.5356 12.2683 22.3931 12.0308C19.6367 7.43675 16.0917 5 11.75 5ZM11.75 18.3333C8.20304 18.3333 5.16058 16.446 2.62997 12.4167C5.16058 8.38733 8.20304 6.5 11.75 6.5C15.297 6.5 18.3394 8.38733 20.87 12.4167C18.3394 16.446 15.297 18.3333 11.75 18.3333Z' fill='%23515358'/%3E%3C/svg%3E");
}
.profile-page > div .profile-user-info-edit .fr-one.with-checkbox input[type=checkbox]:checked + div::after {
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 5C7.40833 5 3.86331 7.43675 1.10688 12.0308C0.964373 12.2683 0.964373 12.565 1.10688 12.8025C3.86331 17.3966 7.40833 19.8333 11.75 19.8333C16.0917 19.8333 19.6367 17.3966 22.3931 12.8025C22.5356 12.565 22.5356 12.2683 22.3931 12.0308C19.6367 7.43675 16.0917 5 11.75 5ZM11.75 18.3333C8.20304 18.3333 5.16058 16.446 2.62997 12.4167C5.16058 8.38733 8.20304 6.5 11.75 6.5C15.297 6.5 18.3394 8.38733 20.87 12.4167C18.3394 16.446 15.297 18.3333 11.75 18.3333Z' fill='%23515358'/%3E%3C/svg%3E");
}
.profile-page > div .profile-user-info-edit input {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4em;
  color: #515358;
  padding: 18px 16px;
  border-radius: 5px;
  border: 1px solid #7d7d7d;
}
.profile-page > div .profile-user-info-edit label {
  font-size: 14px;
  font-weight: 400;
  line-height: 2em;
  color: #515358;
}
.profile-page > div .no-orders {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-page > div .no-orders svg {
  margin-bottom: 24px;
}
.profile-page > div .no-orders h5 {
  font-weight: 400;
  color: #404040;
  margin-bottom: 8px;
  font-size: calc(18px + 2 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .profile-page > div .no-orders h5 {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .profile-page > div .no-orders h5 {
    font-size: 20px;
  }
}
.profile-page > div .no-orders h4 a {
  font-weight: 700;
  color: #045e51;
  text-decoration: underline;
  line-height: 1.5em;
  font-size: calc(18px + 2 * (100vw - 480px) / 720);
}
@media screen and (max-width: 480px) {
  .profile-page > div .no-orders h4 a {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .profile-page > div .no-orders h4 a {
    font-size: 20px;
  }
}
.profile-page .order-list .table-row-grid-5 {
  display: grid;
  grid-template-columns: 30% 20% 15% 15% 20%;
  background-color: #f3f5f5;
  margin-bottom: 10px;
  border-radius: 8px;
}
.profile-page .order-list .table-row-grid-5 > div {
  padding: 16px 24px;
  color: #000000;
  font-size: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.profile-page .order-list .table-row-grid-5:first-child {
  background-color: transparent;
}
.profile-page .order-list .table-row-grid-5:first-child > div {
  color: #404040;
  padding: 0 24px;
}
.profile-page .order-list .table-row-grid-5 button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  text-decoration: underline;
  color: #045e51;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5em;
}
.profile-page .order-list-mobile {
  flex-direction: column;
  gap: 30px;
  align-items: center;
}
.profile-page .order-list-mobile .single-order-mobile {
  max-width: 350px;
  background-color: #f3f5f5;
  padding: 20px 30px;
  border-radius: 8px;
}
.profile-page .order-list-mobile .single-order-mobile > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 30px;
}
.profile-page .order-list-mobile .single-order-mobile > div:first-child .table-row-2 {
  font-weight: 700;
}
.profile-page .order-list-mobile .single-order-mobile > div:first-child > div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.profile-page .order-list-mobile .single-order-mobile > div:last-child {
  display: flex;
  justify-content: center;
}
.profile-page .order-list-mobile .single-order-mobile > div:last-child button {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #045e51;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
}
.profile-page .profile-user-control {
  position: relative;
}
.profile-page .profile-user-control .control-logout {
  position: absolute;
  left: 0;
  font-size: 20px;
  line-height: 1.5em;
  display: flex;
  gap: 5px;
}
.profile-page .profile-user-control .control-logout span {
  font-weight: 700;
}
.profile-page .profile-user-control .control-logout a {
  color: #045e51;
  text-decoration: underline;
  font-weight: 700;
}
.profile-page .profile-user-control h4 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
.profile-page .profile-user-control h4 span {
  font-weight: 700;
}
.profile-page .profile-user-control p {
  font-size: calc(18px + 2 * (100vw - 480px) / 720);
  line-height: 1.5em;
}
@media screen and (max-width: 480px) {
  .profile-page .profile-user-control p {
    font-size: 18px;
  }
}
@media screen and (min-width: 1200px) {
  .profile-page .profile-user-control p {
    font-size: 20px;
  }
}
.profile-page .profile-user-control p span {
  cursor: pointer;
  color: #045e51;
  text-decoration: underline;
  font-weight: 700;
}

/* ___________  PAGE END ___________*/