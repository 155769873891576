@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

@import "./overview-tab";
@import "./accomodations-tab";
@import "./activities-tab";
@import "./eat-and-shop-tab";
@import "./practical-information-tab";
@import "./photos-and-videos-tab";


.park-page {
  overflow: hidden;

  .banner {
    height: 480px;
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-rows: 1fr;
    gap: 5px;

    @include media-breakpoint-down(md) {
      display: flex;
      height: auto;
      flex-direction: column;
    }

    .video {
      background-image: url("../images/search-park-general-page/main-banner.jpg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;
      overflow: hidden;

      @include media-breakpoint-down(md) {
        height: 250px;
      }

      video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 0;
      }

      &::before {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;

        //@include media-breakpoint-down(md) {
        //  display: none;
        //}
      }

    }

    .sidebar {
      display: grid;
      grid-template-columns: 250px;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 5px;

      @include media-breakpoint-down(md) {
        display: flex;
      }

      & > div {
        @include media-breakpoint-down(md) {
          height: 100px;
          background-size: cover;
          width: calc(100% / 3 - 0px);

          h4 {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .photos-and-videos {
        background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .customer-photos {
        background-image: url("../images/search-park-general-page/customer-photos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .virtual-tour {
        background-image: url("../images/search-park-general-page/virtual-tour.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }
    }

    //.thumbnail {
    //  display: none;
    //  @include media-breakpoint-down(md) {
    //    background-image: url("../images/search-park-general-page/main-banner.jpg");
    //    background-position: 50% 50%;
    //    background-size: cover;
    //    background-repeat: no-repeat;
    //    position: relative;
    //    height: 250px;
    //    display: block;
    //    img {
    //      height: 100%;
    //      width: 100%;
    //      object-fit: cover;
    //      display: block;
    //    }
    //  }
    //}
  }

  .park-book {
    position: relative;
    height: 1px;
    width: 100%;

    @include media-breakpoint-down(md) {
      height: auto;
    }

    .container-md {
      position: relative;
    }


    .book-now {
      right: 10px;
      border-radius: 15px 15px 0px 0px;
      background: $white;
      box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
      height: 88.775px;
      flex-shrink: 0;
      position: absolute;
      bottom: 0px;
      z-index: 6;
      display: flex;
      align-items: center;
      padding: 20px;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        position: relative;
        height: auto;
        right: 0;
        box-shadow: none;
        justify-content: space-between;
        padding: 12px;
        align-items: start;
      }

      .park {
        margin-left: 40px;

        @include media-breakpoint-down(md) {
          width: 151px;
        }

        .title {
          color: $grey-400;
          font-size: 22px;
          font-weight: 700;
          line-height: 28.6px;
          letter-spacing: 0.66px;
          display: flex;
          align-items: center;
          gap: 15px;

          @include media-breakpoint-down(md) {
            margin-bottom: 8px;
          }

          .tag {
            padding: 8px;
            border-radius: 3px;
            background: $green-100;
            color: $grey-400;
            text-align: center;
            font-size: 12px;
            font-weight: 700;
            line-height: 13px;
            letter-spacing: 0.3px;
          }
        }

        .location {
          display: flex;
          gap: 5px;
          align-items: center;
          color: $green-400;
          font-size: 14px;
          font-weight: 400;
          line-height: 18.2px;
          letter-spacing: 0.28px;

          i {
            color: $green-400;
            font-size: 20px;
          }
        }
      }

      .park-map {
        padding: 10px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        border-radius: 9.236px;
        background: $grey-50;
        color: $green-400;
        font-weight: 700;
        letter-spacing: 0.45px;
        width: 127px;

        @include media-breakpoint-down(md) {
          width: 152px;
          height: 44px;
          font-size: 14px;
          padding: 10px;
          justify-content: center;
        }

        a {
          font-size: 16px;
          color: $green-400;
          word-break: break-all;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }
        }

        i {
          color: $green-400;
          font-size: 28px;

          @include media-breakpoint-down(md) {
            font-size: 20px;
          }
        }
      }

      .sep {
        width: 1px;
        height: 59px;
        margin: 0px 15px;
        background-color: $grey-200;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .cost {
        margin-left: 18px;

        .title {
          color: $grey-200;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.5px;
          letter-spacing: -0.45px;
          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .price {
          display: flex;
          gap: 5px;
          align-items: flex-end;

          @include media-breakpoint-down(md) {
            display: none;
          }

          .info {
            color: $grey-300;
            align-self: flex-start;
            font-size: 12px;
          }

          .was {
            display: flex;
            gap: 5px;
            direction: ltr;
            color: $grey-400;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 17.357px;
            text-decoration: line-through;
          }

          .now {
            display: flex;
            gap: 5px;
            direction: ltr;
            color: #e85481;
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0.6px;
          }

        }

        .nights {
          @include media-breakpoint-down(md) {
            font-size: 12px;
            margin-top: 4px;
          }
        }
      }

      .button {
        a {
          width: 130px;
          height: 42px;
          font-size: 15px;
          font-weight: 600;
          line-height: 21px;
          letter-spacing: 0em;
          padding: 0;
          @include media-breakpoint-down(md) {
            width: 206px;
          }
        }
      }
    }
  }

  .park-tabs {
    @include media-breakpoint-down(md) {
      padding: 0px;
    }

    .search-form-parks.without-parks, .search-form-parks.event-page {
      margin: 0 auto 60px;
    }

    .park-tabs-nav {
      padding: 0px;
      list-style: none;
      box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
      background: $white;
      margin-bottom: 35px;

      .container-md {
        display: flex;
        display: flex;
        gap: 0px;
        z-index: 6;
        position: relative;
        overflow: auto;
        margin: 0px auto;
        gap: 0px;
        transform: translateX(28px);

        @include media-breakpoint-down(md) {
          justify-content: flex-start;
          gap: 17px;
          white-space: nowrap;
          transform: none;
        }
      }

      .park-tab {
        color: $grey-400;
        text-align: center;
        font-family: "Almoni", sans-serif;
        font-size: 22px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        padding: 19px 28px;
        align-items: center;
        gap: 10px;
        border-bottom: 6px solid transparent;
        position: relative;
        letter-spacing: 0.03em;
        position: relative;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          padding: 10px;
          margin: 0;
          width: max-content;
        }

        &:before {
          content: '';
          width: 3px;
          height: 3px;
          border-radius: 50%;
          display: block;
          background: rgba(64, 64, 64, 1);
          position: absolute;
          left: 0;
          transform: translateY(-50%);
          top: 50%;
        }

        span {
          white-space: nowrap;
        }

        img {
          position: absolute;
          right: 0px;
        }

        &.active {
          border-bottom: 6px solid $green-400;
          color: $green-400;
          font-weight: 700;

          @include media-breakpoint-down(md) {
            border-bottom: 5px solid $green-400;
          }
        }

        &:last-child {
          &:before {
            display: none;
          }
        }
      }
    }

    .park-tabs-contents {
      .park-tab-content {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}