.accommodation-box {

  border-radius: 10px;
  background-color: $white;
  height: 420px;
  display: flex !important;
  flex-direction: column;
  box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
  overflow: hidden;


  @include media-breakpoint-down(md) {
    width: 100%;
    display: block;
    margin-bottom: 20px;
    height: auto;
  }

  .image {
    position: relative;

    img {
      width: 100%;
      height: 206px;
      object-fit: cover;
      object-position: 50% 50%;
      border-radius: 10px 10px 0 0;
    }
  }

  .content {
    padding: 10px 30px;
    @include media-breakpoint-down(md) {
      padding: 10px 15px;
    }

    .info {
      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 0 15px;
        margin-bottom: 5px;

        a {
          color: $black;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0.03em;

          @include media-breakpoint-down(md) {
            color: $grey-400;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.72px;
          }
        }

        .people {
          display: flex;
          gap: 0 4px;
          align-items: center;
          @include media-breakpoint-down(md) {
            font-size: 14px;
            img {
              height: 16px;
            }
          }
        }
      }

      .description {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: right;
        height: 45px;
        overflow: hidden;

        p {
          margin-bottom: 12px;
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 0px;
          margin: 0;
        }
      }
    }

    .period {
      font-size: 18px;
      font-weight: 400;
      line-height: 23px;
      letter-spacing: 0.03em;
      margin-bottom: 2px;
    }

    .cost {
      display: flex;
      flex-direction: column;
      transform: translateY(-2px);

      .price {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 0 6px;

        @include media-breakpoint-down(md) {
          margin-top: 0;
          width: calc(50% - 10px);
          margin: 0;
        }

        .summa {
          display: flex;
          align-items: flex-end;
          gap: 0 5px;

          .now {
            display: flex;
            align-items: center;
            gap: 5px;
            color: rgba(232, 84, 129, 1);
            text-align: center;
            font-size: 28.844px;
            font-weight: 700;
            line-height: 37.497px;
            letter-spacing: 0.03em;

            i {
              color: $grey-600;
              font-size: 16px;
              transform: translate(-8px, -2px);
            }

            .currency {
              font-size: 25px;
              color: rgba(255, 67, 128, 1);
              transform: translateY(-2px);
              font-weight: 400;
            }

            .number {
              line-height: 1;
            }
          }

          .was {
            display: flex;
            gap: 5px;
            color: $grey-400;
            font-size: 16.688px;
            font-weight: 400;
            line-height: 25.033px;
            text-decoration: line-through;

            .currency {
              font-size: 15px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              transform: translateY(-2px);
            }

            .number {
            }
          }
        }

        .price-per-stay {
          color: $grey-400;
          font-size: 15px;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 0;
          transform: translateY(3px);
        }
      }
    }
  }
}