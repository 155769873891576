@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.practical-information-tab {
  .banner {
    height: 480px;
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-rows: 1fr;
    gap: 5px;

    @include media-breakpoint-down(md) {
      display: flex;
      height: auto;
      flex-direction: column-reverse;
    }

    .video {
      background-image: url("../images/search-park-general-page/main-banner.jpg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      @include media-breakpoint-down(md) {
        height: auto;
        background: none;
      }

      &::before {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .book-now {
        border-radius: 15px 15px 0px 0px;
        background: $white;
        box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
        height: 88.775px;
        flex-shrink: 0;
        position: absolute;
        right: 10%;
        bottom: 0px;
        z-index: 6;
        display: flex;
        align-items: center;
        padding: 20px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          position: relative;
          height: auto;
          right: 0;
          box-shadow: none;
          justify-content: space-between;
          padding: 12px;
          align-items: start;
        }

        .park {
          margin-left: 10px;

          @include media-breakpoint-down(md) {
            width: 50%;
          }

          .title {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
            display: flex;
            align-items: center;
            gap: 15px;

            @include media-breakpoint-down(md) {
              margin-bottom: 8px;
            }

            .tag {
              padding: 8px;
              border-radius: 3px;
              background: $green-100;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 13px;
              letter-spacing: 0.3px;
            }
          }

          .location {
            display: flex;
            gap: 5px;
            align-items: center;
            color: $green-400;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.2px;
            letter-spacing: 0.28px;

            i {
              color: $green-400;
              font-size: 20px;
            }
          }
        }

        .park-map {
          padding: 10px 20px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 9.236px;
          background: $grey-50;
          color: $green-400;
          font-weight: 700;
          letter-spacing: 0.45px;
          width: 127px;

          @include media-breakpoint-down(md) {
            width: 152px;
            height: 44px;
            font-size: 14px;
            padding: 10px;
            justify-content: center;
          }

          a {
            font-size: 16px;
            color: $green-400;
            word-break: break-all;

            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }

          i {
            color: $green-400;
            font-size: 28px;

            @include media-breakpoint-down(md) {
              font-size: 20px;
            }
          }
        }

        .sep {
          width: 1px;
          height: 59px;
          margin: 0px 15px;
          background-color: $grey-200;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .cost {
          margin-left: 30px;

          .title {
            color: $grey-200;
            font-size: 15px;
            font-weight: 400;
            line-height: 22.5px;
            letter-spacing: -0.45px;
          }

          .price {
            display: flex;
            gap: 5px;
            align-items: flex-end;

            .info {
              color: $grey-300;
              align-self: flex-start;
            }

            .was {
              display: flex;
              gap: 5px;
              direction: ltr;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              line-height: 17.357px;
              text-decoration: line-through;
            }

            .now {
              display: flex;
              gap: 5px;
              direction: ltr;
              color: #e85481;
              text-align: center;
              font-size: 20px;
              font-weight: 600;
              line-height: 26px;
              letter-spacing: 0.6px;
            }
          }
        }
      }
    }

    .sidebar {
      display: grid;
      grid-template-columns: 250px;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 5px;

      @include media-breakpoint-down(md) {
        display: flex;
      }

      &>div {
        @include media-breakpoint-down(md) {
          height: 100px;
          background-size: cover;
          width: calc(100% / 3 - 0px);

          h4 {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .photos-and-videos {
        background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .customer-photos {
        background-image: url("../images/search-park-general-page/customer-photos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .virtual-tour {
        background-image: url("../images/search-park-general-page/virtual-tour.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }
    }

    .thumbnail {
      display: none;

      @include media-breakpoint-down(md) {
        background-image: url("../images/search-park-general-page/main-banner.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 250px;
        display: block;
      }
    }
  }

  .navigation {
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    background: $white;
    display: flex;
    gap: 0px;
    margin-bottom: 30px;
    z-index: 6;
    position: relative;
    overflow: auto;
    margin: 0px auto;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      padding: 0px;
    }

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        gap: 10px;
      }

      li {
        @include media-breakpoint-down(md) {
          display: block;
        }

        a {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 15px 25px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;
          text-align: center;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 10px 0;
            margin: 0;
            width: max-content;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;

            @include media-breakpoint-down(md) {
              border-bottom: 5px solid $green-400;
            }
          }
        }
      }
    }
  }

  .access-and-itinerary {
    margin-bottom: 168px;

    @include media-breakpoint-down(md) {
      margin-bottom: 120px;
    }

    .heading {
      .sub-title {
        color: $grey-400;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.6px;
        margin-bottom: 15px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          margin-bottom: 8px;
        }
      }

      .title {
        color: $grey-400;
        font-weight: 700;
        font-size: 35px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }
    }

    .adresse {
      margin-bottom: 40px;
      display: flex;
      gap: 20px;

      .icon {
        i {
          font-size: 20px;
          display: flex;
          width: 40px;
          height: 40px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 20.002px;
          background: $grey-50;
        }
      }

      .text {
        .title {
          color: $grey-400;
          margin-bottom: 10px;
          display: flex;
          align-items: center;
          gap: 14px;
          text-align: center;
          font-size: 18px;
          font-weight: 700;
          line-height: 23.4px;
          letter-spacing: 0.54px;

          @include media-breakpoint-down(md) {
            margin: 5px 0 20px 0;
          }
        }
      }

      .street {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 14px;
        position: relative;

        i {
          font-size: 15px;
          display: flex;
          width: 30px;
          height: 30px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: 20.002px;
          background: $green-100;
          color: $green-400;

          @include media-breakpoint-down(md) {
            position: absolute;
            bottom: 100%;
            left: 0;
            margin-bottom: 15px;
          }
        }
      }

      .nav {
        display: flex;
        gap: 30px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          gap: 23px;
          width: calc(100% + 60px);
          transform: translateX(60px);

          a {
            width: 100%;
            font-size: 20px;
            line-height: 26px;
            display: flex;
            padding: 17px;
          }

        }
      }
    }

    .information {
      display: flex;
      gap: 40px;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }

      .indications {
        display: flex;
        justify-content: space-between;
        gap: 230px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          gap: 40px 0;
        }

        .train-station {
          position: relative;

          @include media-breakpoint-down(md) {
            padding-right: 50px;
          }

          .title {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 14px;

            @include media-breakpoint-down(md) {
              margin-bottom: 0;
            }

            i {
              font-size: 20px;
              display: flex;
              width: 40px;
              height: 40px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 20.002px;
              background: $grey-50;

              @include media-breakpoint-down(md) {
                position: absolute;
                right: 0;
                top: 0
              }
            }
          }

          .description {
            h5 {
              margin-bottom: 6px;
            }

            @include media-breakpoint-down(md) {
              padding-right: 32px;
            }

            p {
              margin-bottom: 5px;
            }
          }
        }

        .airport {
          position: relative;

          @include media-breakpoint-down(md) {
            padding-right: 50px;
          }

          .title {
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            gap: 14px;


            i {
              font-size: 20px;
              display: flex;
              width: 40px;
              height: 40px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 20.002px;
              background: $grey-50;

              @include media-breakpoint-down(md) {
                position: absolute;
                right: 0;
                top: 0
              }
            }
          }

          .description {
            h5 {
              margin-bottom: 6px;
            }

            p {
              margin-bottom: 5px;
            }
          }
        }

      }
    }
  }

  .your-stay {
    .container-md {
      .heading {
        color: $grey-400;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }

      .content {
        border-radius: 13.587px;
        border: 3px solid #E9E9E9;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
          padding: 0;
          border: none;
        }

        .grid {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
          padding: 27px 40px;

          @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
          }

          &:first-child {
            background-color: $blue-100;
          }

          .column {

            &:nth-child(1),
            &:nth-child(2) {
              @include media-breakpoint-down(md) {
                background: #F9FBFB;
                padding: 30px 15px;
                border: none;
                width: calc(100% + 20px);
                transform: translateX(10px);
                border-radius: 0;
              }
            }

            &:nth-child(2) {
              @include media-breakpoint-down(md) {
                margin-top: -30px;
              }
            }
          }
        }

        .reception {
          .head {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
            margin-bottom: 12px;

          }

          .title {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
          }


          .phone {
            margin-bottom: 30px;

            .title {
              color: $grey-400;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0.66px;
            }

            .description {
              .number {
                display: flex;
                align-items: center;
                gap: 14px;

                a {
                  color: $grey-400;
                  direction: ltr;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 23.4px;
                  letter-spacing: 0.54px;
                }

                i {
                  font-size: 15px;
                  display: flex;
                  width: 30px;
                  height: 30px;
                  padding: 10px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 20.002px;
                  background: $green-100;
                  color: $green-400;
                }
              }

              .link {
                a {
                  color: $green-400;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 23.4px;
                  letter-spacing: 0.54px;
                }
              }
            }
          }

          .flags {
            margin-bottom: 30px;

            .title {
              color: $grey-400;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0.66px;
            }

            .description {
              .flags {
                display: flex;
                gap: 20px;

                .flag {
                  display: flex;
                  gap: 10px;
                }
              }
            }
          }

          .text {
            margin-bottom: 30px;

            .title {
              color: $grey-400;
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0.66px;
            }
          }
        }

        .arrival-times {
          .head {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
            margin-bottom: 12px;
          }

          .title {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
          }

          .period-of-time {
            .time {
              display: flex;
              gap: 10px;
              margin-bottom: 30px;
              position: relative;


              &:last-child {
                &::before {
                  display: none;
                }
              }

              &::before {
                content: "";
                height: calc(100% + 30px);
                width: 1px;
                border: 1px dashed $green-400;
                position: absolute;
                right: 7px;
                top: 0;
                z-index: 1;
                transform: translateX(-50%);
              }

              .text-icon {
                flex-shrink: 0;
                position: relative;

                img {
                  background-color: $white;
                  position: relative;
                  z-index: 2;
                }
              }

              .text {
                .title {
                  color: $grey-400;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 14px;
                  letter-spacing: 0.66px;
                  margin-bottom: 6px;
                }
              }
            }
          }
        }

        .car {
          display: flex;
          gap: 20px;

          .car-icon {
            flex-shrink: 0;

            img {
              font-size: 20px;
              display: flex;
              width: 40px;
              height: 40px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 20.002px;
              background: $grey-50;
            }
          }

          .car-content {
            .head {
              color: $grey-400;
              font-size: 22px;
              font-weight: 700;
              line-height: 28.6px;
              letter-spacing: 0.66px;
              margin-bottom: 12px;
            }

            .text {
              margin-bottom: 30px;

              .title {
                color: $grey-400;
                display: flex;
                align-items: center;
                gap: 14px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }

              .description {
                color: $grey-400;
                font-size: 18px;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }
            }

            .text-with-icon {
              display: flex;
              gap: 30px;
              margin-right: -60px;

              .text-icon {
                flex-shrink: 0;

                img {
                  font-size: 20px;
                  display: flex;
                  width: 40px;
                  height: 40px;
                  padding: 10px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 20.002px;
                  background: $grey-50;
                }
              }
            }
          }
        }

        .payment {
          display: flex;
          gap: 20px;

          .payment-icon {
            flex-shrink: 0;

            img {
              font-size: 20px;
              display: flex;
              width: 40px;
              height: 40px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 20.002px;
              background: $grey-50;
            }
          }

          .payment-content {
            .head {
              color: $grey-400;
              font-size: 22px;
              font-weight: 700;
              line-height: 28.6px;
              letter-spacing: 0.66px;
              margin-bottom: 12px;
            }

            .text {
              margin-bottom: 30px;

              .title {
                color: $grey-400;
                display: flex;
                align-items: center;
                gap: 14px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }

              .description {
                color: $grey-400;
                font-size: 18px;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }
            }

            .text-with-icon {
              display: flex;
              gap: 20px;
              margin-right: -60px;

              .text-icon {
                flex-shrink: 0;

                img {
                  font-size: 20px;
                  display: flex;
                  width: 40px;
                  height: 40px;
                  padding: 10px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 20.002px;
                  background: $grey-50;
                }
              }
            }
          }
        }

        .pets {
          display: flex;
          gap: 20px;

          .pets-icon {
            flex-shrink: 0;

            img {
              font-size: 20px;
              display: flex;
              width: 40px;
              height: 40px;
              padding: 10px;
              justify-content: center;
              align-items: center;
              flex-shrink: 0;
              border-radius: 20.002px;
              background: $grey-50;
            }
          }

          .pets-content {
            .head {
              color: $grey-400;
              font-size: 22px;
              font-weight: 700;
              line-height: 28.6px;
              letter-spacing: 0.66px;
              margin-bottom: 12px;
            }

            .text {
              margin-bottom: 30px;

              .title {
                color: $grey-400;
                display: flex;
                align-items: center;
                gap: 14px;
                text-align: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }

              .description {
                color: $grey-400;
                font-size: 18px;
                line-height: 23.4px;
                letter-spacing: 0.54px;
              }
            }

            .text-with-icon {
              display: flex;
              gap: 30px;
              margin-right: -60px;

              .text-icon {
                flex-shrink: 0;

                img {
                  font-size: 20px;
                  display: flex;
                  width: 40px;
                  height: 40px;
                  padding: 10px;
                  justify-content: center;
                  align-items: center;
                  flex-shrink: 0;
                  border-radius: 20.002px;
                  background: $grey-50;
                }
              }
            }
          }
        }
      }
    }
  }
}