@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-template-search-packages {
  #modal-search.modal-search {
    .number-of-nights{
      display: none !important;
    }
  }
}

#modal-search.modal-search {
  display: none;
  z-index: 15;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100dvh;
  background: $white;
  color: $text-color;
  flex-direction: column;

  &.active {
    display: flex;
  }

  &.without-parks, &.event-page {
    .search-body {
      .item.parcs {
        display: none;
      }
    }
  }

  .search-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100px;
    border-bottom: 1px solid $grey-150;

    .title {
      font-size: 25px;
      font-weight: 700;
      line-height: 34px;
    }

    .close-popup {
      position: absolute;
      transform: translate(0, -50%);
      top: 50%;
      left: 25px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40765 0L0.0449251 1.36614L7.63727 8.97748L0 16.6489L1.34775 18L9 10.3436L16.6373 18L18 16.6339L10.3627 8.97748L17.9551 1.36614L16.5923 0L9 7.61134L1.40765 0Z' fill='%23404040'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      width: 18px;
      height: 18px;
      cursor: pointer;
    }

    .back-arrow {
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.418 14L18.5391 19.8789L17.3086 18.6211L21.0547 14.875L3.5 14.875L3.5 13.125L21.0547 13.125L17.3086 9.37891L18.5391 8.12109L24.418 14Z' fill='%23404040'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translate(0, -50%);
      top: 50%;
      right: 25px;
      cursor: pointer;
    }
  }

  .search-body {
    display: flex;
    flex-flow: column;
    gap: 20px;
    width: 100%;
    padding: 25px 10px;
    overflow-x: hidden;
    overflow-y: auto;
    flex-grow: 1;

    > .item {
      display: flex;
      width: 100%;
      box-shadow: $box-shadow;
      padding: 15px;
      height: 86px;
      border-radius: 8px;
      cursor: pointer;

      &.packages {
        display: none;

        &.active {
          display: flex;
        }
      }

      &.packages,
      &.parcs,
      &.accommodation {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          transform: translate(0, -50%);
          top: 50%;
          left: 35px;
          background-image: url("data:image/svg+xml,%3Csvg width='9' height='18' viewBox='0 0 9 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.189884 9.49395L7.89771 17.7964C8.15025 18.0683 8.55912 18.0679 8.81123 17.795C9.06315 17.5223 9.0625 17.0804 8.80993 16.8083L1.56089 8.99997L8.81019 1.19165C9.06273 0.919544 9.06338 0.477949 8.81149 0.205137C8.68511 0.0683804 8.51954 0 8.35397 0C8.18882 0 8.0239 0.0679226 7.89775 0.20373L0.189884 8.50602C0.0682526 8.63673 0 8.81462 0 8.99997C0 9.18531 0.0684481 9.36299 0.189884 9.49395Z' fill='%23404040'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          width: 9px;
          height: 18px;
        }
      }

      .text-info {
        display: flex;
        flex-flow: column;
        row-gap: 5px;
        width: 100%;

        .title {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }

        .text {
          font-size: 18px;
          font-weight: 700;
          line-height: 27px;

          &.calendar-dates {
            display: flex;
            align-items: center;
            justify-content: space-between;
            max-width: 500px;
            gap: 24px;

            .arrival-date {
              width: 120px;
              margin-inline-end: 5vw;
            }

            .departure-date {
              width: 120px;
              font-size: 18px;
              font-weight: 700;
              line-height: 27px;
              color: $grey-200;

              &.active {
                color: $text-color;
              }
            }

            .arrow {
              width: 28px;
              height: 28px;
              background-image: url("data:image/svg+xml,%3Csvg width='28' height='28' viewBox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.58203 14L9.46094 19.8789L10.6914 18.6211L6.94531 14.875L24.5 14.875L24.5 13.125L6.94531 13.125L10.6914 9.37891L9.46094 8.12109L3.58203 14Z' fill='%23D0D0D0'/%3E%3C/svg%3E%0A");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
            }
          }
        }
      }
    }
  }

  .search-footer {
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 3.728569746017456px 27.960784912109375px 0px #00000026;
    background-color: $white;
    height: 85px;
    min-height: 85px;
    width: 100%;
    z-index: 1;
    position: relative;

    .submit-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      font-size: 22px;
      font-weight: 600;
      line-height: 30px;
      color: $white;
      appearance: none;
      width: 100%;
      cursor: pointer;
      background-color: $pink-400;
      border: none;
    }
  }

  > .search-section {
    position: absolute;
    background-color: $white;
    top: 0;
    left: 100%;
    width: 100%;
    transition: left 0.3s;

    display: flex;
    flex-direction: column;
    height: 100dvh !important;

    .search-body{
      flex-grow: 1;
    }

    &.active {
      left: 0;
    }

    &.parcs {
      z-index: 2;
      height: 100%;

      .search-body {
        gap: 15px;

        .accordion {
          display: flex;
          flex-flow: column;
          background-color: $white;
          border: 1px solid $grey-100;
          border-radius: 7px;
          width: 100%;

          .parc-title {
            position: relative;
            padding: 14px 16px 15px;
            padding-inline-end: 50px;
            background-color: $grey-100;
            font-size: 17px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: 0.03em;
            cursor: pointer;

            .arrow {
              position: absolute;
              translate: 0 -50%;
              top: 50%;
              left: 22px;
              background-image: url("data:image/svg+xml,%3Csvg width='17' height='8' viewBox='0 0 17 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.0335 7.83121L0.192242 0.979811C-0.064517 0.755333 -0.0640854 0.391897 0.19357 0.167795C0.451192 -0.0561333 0.868521 -0.0555547 1.12548 0.168953L8.50003 6.61254L15.8745 0.168721C16.1315 -0.0557573 16.5486 -0.0563359 16.8063 0.167564C16.9354 0.279904 17 0.427078 17 0.574252C17 0.721049 16.9359 0.867644 16.8076 0.979782L8.96653 7.83121C8.84308 7.93933 8.67508 8 8.50003 8C8.32499 8 8.15718 7.93916 8.0335 7.83121Z' fill='%23404040'/%3E%3C/svg%3E%0A");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              width: 17px;
              height: 8px;
              transition: all 0.3s;
            }
          }

          .locations {
            padding: 25px 30px;

            > a {
              color: $black;
              font-size: 17px;
              font-weight: 700;
              line-height: 24px;
              //display: flex;
              align-items: center;
              direction: ltr;
              text-align: left;
              gap: 10px;
              margin-bottom: 22px;
              display: none;

              &:hover {
                color: $green-400;

                .checkbox > label::before {
                  border: 2px solid $green-400;
                }
              }
            }

            ul {
              padding: 0px;
              margin: 0px;
              list-style: none;
              padding-inline-end: 14px;

              li {
                line-height: normal;
                margin-bottom: 19px;

                .tag {
                  padding: 8px;
                  border-radius: 3px;
                  background: $green-100;
                  color: $grey-400;
                  text-align: center;
                  font-size: 12px;
                  font-weight: 700;
                  line-height: 13px;
                  letter-spacing: 0.3px;
                }

                &:last-child {
                  margin-bottom: 0px;
                }

                a {
                  color: $black;
                  font-size: 17px;
                  font-weight: 500;
                  line-height: 24px;
                  display: flex;
                  align-items: center;
                  direction: ltr;
                  text-align: left;
                  gap: 10px;

                  &:not(.disabled):hover {
                    color: $green-400;

                    .checkbox > label::before {
                      border: 2px solid $green-400;
                    }
                  }

                  &.disabled {
                    pointer-events: none;
                    color: $grey-300;

                    label::before {
                      border: 2px solid $grey-200;
                    }
                  }
                }
              }
            }
          }

          &.active {
            .parc-title {
              .arrow {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }

    &.accommodation {
      z-index: 2;
      height: 100%;

      .search-body {
        padding: 20px 10px;
        gap: 29px;

        .seniors,
        .adults,
        .children,
        .pets {
          display: flex;
          justify-content: space-between;
        }

        .children {
          flex-wrap: wrap;
          gap: 10px;

          .children-ages {
            display: flex;
            justify-content: space-between;
            gap: 6px;
            width: 100%;
            flex-direction: column;

            .tip {
              display: block;
              min-width: 100px;
              white-space: normal;
              font-size: 14px;
              font-weight: 500;
              color: $red-300;
            }

            .ages {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              gap: 10px;

              .chosen-container {
                width: 100% !important;
              }

              select {
                width: 70px;
                height: 46px;
                font-size: 14px;
                border-radius: 6px;
                border: 1px solid #aaa;
              }

              .chosen-single {
                span {
                  padding: 10px 8px;
                }
              }

              .chosen-results {
                max-height: 180px;

                .active-result {
                  padding: 10px 15px;
                }
              }
            }
          }
        }
      }
    }

    &.calendar {
      z-index: 2;
      height: 100%;

      .search-body {
        align-items: center;
        gap: 0;

        .number-of-nights {
          margin-bottom: 30px;
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          max-width: max-content;
          gap: 8px;
          width: 100%;

          span {
            color: $grey-400;
            font-size: 14px;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            width: 100%;
          }

          ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 5px;

            li {
              span {
                display: flex;
                width: 49px;
                height: 39px;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 100px;
                background-color: $white;
                border: 1px solid $grey-200;
                font-size: 14px;
                font-weight: 500;
                line-height: 19px;

                &.disabled {
                  pointer-events: none;
                  color: $grey-600;
                  opacity: 0.3;
                }

                &:hover {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }

                &.active {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }
              }
            }
          }
        }

        .dates {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin-bottom: 42px;

          .calendar-clear {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            width: 30px;
            height: 100%;
            cursor: pointer;
          }

          .arrow {
            font-size: 26px;
            color: $grey-200;
          }

          .arrival-date,
          .departure-date {
            position: relative;
            cursor: pointer;

            &.active {
              input,
              input::placeholder {
                color: $green-400;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                height: 4px;
                background-color: $green-400;
                cursor: default;
              }
            }
          }

          .arrival-date {
            input {
              border: none;
              font-size: 20px;
              font-weight: 500;
              line-height: 1.26;
              color: $black;
              outline: none;
              width: 100%;
              height: 100%;
              text-align: center;
              pointer-events: none;
            }
          }

          .departure-date {
            &.disabled {
              cursor: not-allowed;

              input,
              input::placeholder {
                color: $grey-200;
              }
            }

            input {
              border: none;
              font-size: 20px;
              font-weight: 500;
              line-height: 1.26;
              color: $black;
              outline: none;
              width: 100%;
              height: 100%;
              text-align: center;
              pointer-events: none;
            }
          }
        }

        #mobile-calendar-container-start-date,
        #mobile-calendar-container-end-date {
          width: 100%;

          .pika-single {
            margin: 0 -140px;
          }
        }

        #mobile-calendar-container-start-date {
          .error-message {
            display: block;
            width: 100%;
            text-align: center;
            color: $red-400;
            font-size: 18px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
