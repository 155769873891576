@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.people-mobile {
  display: none;
  text-align: right;
}

.car-card-mobile {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px 30px;
  flex-direction: column;

  .accommodation_images {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .car-bold-text {
    font-size: 14px;
    font-weight: 700;
    color: $grey-400;
  }

  .package-apartment-info {
    width: 100%;

    .title {
      h3 {
        display: flex;
        flex-direction: column;
        align-items: center;

        span:first-child {
          font-size: 20px;
          font-weight: 700;
          color: $grey-400;
        }

        span:last-child {
          font-size: 14px;
          font-weight: 400;
          color: $grey-600;
        }
      }
    }

    .car-option {
      ul {
        display: flex;
        gap: 8px;
        padding: 0;
        flex-direction: column;

        li {
          list-style: none;
          display: flex;
          flex-direction: row;
          border-radius: 10px;
          gap: 6px;
          justify-content: space-between;

          span:first-child {
            font-size: 14px;
          }
        }
      }
    }

    .car-details {
      display: flex;
      gap: 100px;

      h4 {
        font-size: 16px;
        font-weight: 700;
        color: $grey-400;
        margin-bottom: 20px;
      }

      .from-to {
        .info-wrap {
          display: flex;
          flex-direction: column;
          gap: 60px;
          padding-inline-start: 20px;
          position: relative;

          &:before {
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEwIiB2aWV3Qm94PSIwIDAgMjAgMTEwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMCAxMDkuNjY3QzExLjQ3MjggMTA5LjY2NyAxMi42NjY3IDEwOC40NzMgMTIuNjY2NyAxMDdDMTIuNjY2NyAxMDUuNTI3IDExLjQ3MjggMTA0LjMzMyAxMCAxMDQuMzMzQzguNTI3MjQgMTA0LjMzMyA3LjMzMzMzIDEwNS41MjcgNy4zMzMzMyAxMDdDNy4zMzMzMyAxMDguNDczIDguNTI3MjQgMTA5LjY2NyAxMCAxMDkuNjY3Wk0xMCAxMC42NjY3QzExLjQ3MjggMTAuNjY2NyAxMi42NjY3IDkuNDcyNzYgMTIuNjY2NyA4QzEyLjY2NjcgNi41MjcyNCAxMS40NzI4IDUuMzMzNCAxMCA1LjMzMzRDOC41MjcyNCA1LjMzMzQgNy4zMzMzMyA2LjUyNzI0IDcuMzMzMzMgOEM3LjMzMzMzIDkuNDcyNzYgOC41MjcyNCAxMC42NjY3IDEwIDEwLjY2NjdMMTAuNSAxMDdMMTAuNSA4SDkuNUw5LjUgMTA3SDEwLjVaIiBmaWxsPSIjRDdEN0Q3Ii8+PC9zdmc+");
            background-size: cover;
            position: absolute;
            width: 20px;
            height: 95%;
            right: 0;
          }

          .info {
            & > div:first-child {
              font-size: 12px;
              font-weight: 400;
              color: $grey-400;
              opacity: 0.5;
            }

            & > div:last-child {
              font-size: 14px;
              font-weight: 400;
              color: $grey-400;
            }
          }
        }
      }

      .rental_conditions {
        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            display: flex;
            flex-direction: row;
            gap: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $grey-600;
          }
        }
      }
    }
  }
}

.car-card {
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 40px 30px;

  .package-apartment-info {
    .title {
      h3 {
        span:first-child {
          font-size: 20px;
          font-weight: 700;
          color: $grey-400;
        }

        span:last-child {
          font-size: 14px;
          font-weight: 400;
          color: $grey-600;
        }
      }
    }

    .car-option {
      ul {
        display: flex;
        gap: 26px;
        padding: 0;

        li {
          list-style: none;
          display: flex;
          flex-direction: row;
          padding: 8px 16px;
          border-radius: 10px;
          gap: 6px;
          background-color: $grey-100;
        }
      }
    }

    .car-details {
      display: flex;
      gap: 100px;

      h4 {
        font-size: 16px;
        font-weight: 700;
        color: $grey-400;
        margin-bottom: 20px;
      }

      .from-to {
        .info-wrap {
          display: flex;
          flex-direction: column;
          gap: 60px;
          padding-inline-start: 20px;
          position: relative;

          &:before {
            content: "";
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTEwIiB2aWV3Qm94PSIwIDAgMjAgMTEwIiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0xMCAxMDkuNjY3QzExLjQ3MjggMTA5LjY2NyAxMi42NjY3IDEwOC40NzMgMTIuNjY2NyAxMDdDMTIuNjY2NyAxMDUuNTI3IDExLjQ3MjggMTA0LjMzMyAxMCAxMDQuMzMzQzguNTI3MjQgMTA0LjMzMyA3LjMzMzMzIDEwNS41MjcgNy4zMzMzMyAxMDdDNy4zMzMzMyAxMDguNDczIDguNTI3MjQgMTA5LjY2NyAxMCAxMDkuNjY3Wk0xMCAxMC42NjY3QzExLjQ3MjggMTAuNjY2NyAxMi42NjY3IDkuNDcyNzYgMTIuNjY2NyA4QzEyLjY2NjcgNi41MjcyNCAxMS40NzI4IDUuMzMzNCAxMCA1LjMzMzRDOC41MjcyNCA1LjMzMzQgNy4zMzMzMyA2LjUyNzI0IDcuMzMzMzMgOEM3LjMzMzMzIDkuNDcyNzYgOC41MjcyNCAxMC42NjY3IDEwIDEwLjY2NjdMMTAuNSAxMDdMMTAuNSA4SDkuNUw5LjUgMTA3SDEwLjVaIiBmaWxsPSIjRDdEN0Q3Ii8+PC9zdmc+");
            background-size: cover;
            position: absolute;
            width: 20px;
            height: 95%;
            right: 0;
          }

          .info {
            & > div:first-child {
              font-size: 12px;
              font-weight: 400;
              color: $grey-400;
              opacity: 0.5;
            }

            & > div:last-child {
              font-size: 14px;
              font-weight: 400;
              color: $grey-400;
            }
          }
        }
      }

      .rental_conditions {
        ul {
          margin: 0;
          padding: 0;

          li {
            list-style: none;
            display: flex;
            flex-direction: row;
            gap: 8px;
            font-size: 16px;
            font-weight: 400;
            color: $grey-600;
          }
        }
      }
    }
  }
}

.page-template-search-packages {
  .search-form-parks {
    @include media-breakpoint-down(lg) {
      border-radius: 12px !important;
    }
  }

  #modal-search.modal-search .search-body > .item.packages {
    display: flex !important;
  }

  .post {
    .post-wrap {
      @include media-breakpoint-down(lg) {
        padding: 0 !important;
        gap: 0 !important;
      }

      .thumbnail {
        @include media-breakpoint-down(lg) {
          max-height: 174px !important;
        }

        img {
          @include media-breakpoint-down(lg) {
            border-radius: 10px 10px 0 0;
          }
        }
      }

      .text {
        @include media-breakpoint-down(lg) {
          padding: 30px 16px;
        }

        .title h4 {
          @include media-breakpoint-down(lg) {
            font-size: 24px;
          }
        }
      }

      .description {
        @include media-breakpoint-down(lg) {
          font-size: 24px;
        }

        p {
          @include media-breakpoint-down(lg) {
            font-size: 24px;
          }
        }
      }
    }

    .cost {
      padding: 0 20px !important;

      .people {
        text-align: end;
      }

      .duration-of-stay-and-number-of-people {
        text-align: end !important;
      }
    }

    .accommodation_short_description {
      max-height: 100px;
      overflow-y: hidden;
      padding-inline-start: 3px;
    }

    .post-tabs-apartment > div {
      @include media-breakpoint-down(lg) {
        gap: 0;
      }

      .package-apartment-info {
        @include media-breakpoint-down(lg) {
          order: 1;
        }

        h4 {
          font-size: 20px;
        }

        .accommodation_parameters_row {
          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
          }
        }
      }

      .accommodation_images {
        @include media-breakpoint-down(lg) {
          order: 2;
          max-height: unset;
          padding: 0 20px;
        }

        img {
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
      }

      .price {
        @include media-breakpoint-down(lg) {
          order: 3;
        }

        .duration-of-stay-and-number-of-people {
          text-align: end;
        }

        .cost {
          @include media-breakpoint-down(lg) {
            flex-direction: row !important;
            justify-content: space-between;
          }

          .people {
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }

          .price-per-stay {
            @include media-breakpoint-down(lg) {
              margin-bottom: 0;
            }
          }

          .people-mobile {
            @include media-breakpoint-down(lg) {
              display: block;
            }
          }
        }
      }
    }

    @include media-breakpoint-down(lg) {
      font-size: 24px;
    }

    .post-tabs-plane {
      @include media-breakpoint-down(lg) {
        padding: 30px 20px;
      }

      .plane-from,
      .plane-to {
        @include media-breakpoint-down(lg) {
          gap: 3px !important;
        }
      }
    }

    .mobile-price {
      .price {
        .people {
          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        .people-mobile {
          display: block;
        }
      }
    }
  }
}

.search-packages,
.single-accommodation {
  .post-tabs {
    & > div {
      display: none;

      &.active {
        display: flex;
      }
    }

    .price {
      .duration-of-stay-and-number-of-people {
        @include media-breakpoint-down(lg) {
          text-align: start !important;
        }
      }
    }

    .post-tabs-car {
      .car-card {
        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
          gap: 0;
        }

        .car-details {
          @include media-breakpoint-down(lg) {
            flex-direction: column;
            gap: 20px;
          }
        }

        .car-option {
          ul {
            @include media-breakpoint-down(lg) {
              flex-wrap: wrap;
              gap: 8px;
              margin: 24px 0 20px;
            }
            li {
              @include media-breakpoint-down(lg) {
                font-size: 14px;
                font-weight: 400;
                align-items: center;
              }
            }
          }
        }

        .rental_conditions {
          ul {
            @include media-breakpoint-down(lg) {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              gap: 10px;
            }
            li {
              @include media-breakpoint-down(lg) {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }

  .post-tabs-plane {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 50px 100px;

    @include media-breakpoint-down(lg) {
      gap: 20px;
    }

    .plane-company-mobile {
      display: none;

      @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .duration-mobile {
      display: none;

      @include media-breakpoint-down(lg) {
        display: flex;
        justify-content: center;
        font-size: 14px;
        align-items: center;
        gap: 5px;
        color: $text-color;
      }

      .dot {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & > div:nth-child(3) {
      @include media-breakpoint-down(lg) {
        padding-bottom: 20px;
        border-bottom: 1px solid #d7d7d7;
      }
    }

    .post-tabs-plane-row {
      display: grid;
      grid-template-columns: 20% 15% 50% 15%;
      align-items: center;
      color: $grey-400;

      @include media-breakpoint-down(lg) {
        grid-template-columns: 45% 10% 45%;
      }

      .plane-company {
        @include media-breakpoint-down(lg) {
          display: none;
        }

        .fly-checkout {
          display: none;
        }
      }

      .plane-time {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.2em;
      }

      .plane-day {
        font-weight: 400;
        font-size: 12px;
        line-height: 1.2em;
        opacity: 0.5;
      }

      .plane-airport {
        font-weight: 400;
        font-size: 14px;
        line-height: 1.2em;
        text-transform: uppercase;
      }

      .plane-from,
      .plane-to {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
        gap: 5px;

        @include media-breakpoint-down(lg) {
          flex-direction: row;
          gap: 10px;
        }
      }

      .duration {
        text-align: center;
        line-height: 40px;
        padding: 5px;

        .time-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          @include media-breakpoint-down(lg) {
            display: none;
          }

          .dot {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        .luggage-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        .line-with-dot {
          border-bottom: 1px solid #d7d7d7;
          position: relative;

          &:before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #d7d7d7;
            left: -2px;
            top: -2px;
          }

          &:after {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            border-radius: 5px;
            background-color: #d7d7d7;
            right: -2px;
            top: -2px;
          }
        }
      }
    }
  }

  .icon-type-row {
    display: flex;
    flex-direction: row;
    gap: 16px;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $grey-100;
      width: 24px;
      min-width: 24px;
      min-height: 24px;
      height: 24px;
      border-radius: 24px;
      padding: 2px;
    }
  }

  .packages-result {
    .post-wrap {
      .price {
        .nav {
          display: flex;
          flex-direction: column-reverse;
          gap: 12px;

          a {
            margin-bottom: 0;
          }
        }
      }
    }
    .load-more-packages {
      display: none;
      padding: 30px;
      button {
        margin: 0 auto;
        display: block;
      }
    }
  }

  .post {
    flex-direction: column !important;
    padding: 0 !important;
    gap: 0 !important;

    .post-wrap {
      display: grid !important;
      grid-template-columns: 2fr 2fr 1fr;
      padding: 20px;
      gap: 40px;

      .thumbnail {
        height: auto !important;
        max-height: 255px;
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;

        .title {
          h4 {
            font-size: 24px;
          }
        }

        .description {
          font-size: 20px;

          p {
            font-size: 20px;
          }
        }
      }

      .price {
        .cost {
          padding: 0;
          background: transparent;
          flex-direction: column;
        }

        .nav {
          a {
            margin-bottom: 12px;
          }

          a,
          button {
            width: 100%;
          }
        }
      }
    }

    .post-tabs-control {
      display: flex;
      flex-direction: row;
      background: $grey-100;

      &.mobile {
        display: none;
      }
      @include media-breakpoint-down(lg) {
        display: none;
      }
      &.mobile {
        @include media-breakpoint-down(lg) {
          display: flex;
        }
      }

      .post-tabs-control-btn {
        padding: 22px 50px;
        border-radius: 30px 30px 0 0;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
        flex-direction: row-reverse;

        @include media-breakpoint-down(lg) {
          padding: 16px;
        }

        .icon-set-wrap {
          img {
            display: none;

            &:first-child {
              display: block;
            }
          }
        }

        .plus {
          background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5H3V13.5H21V10.5Z' fill='%23404040'/%3E%3Cpath d='M10.5 3L10.5 21H13.5V3H10.5Z' fill='%23404040'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center center;
          width: 20px;
          height: 20px;
        }

        &.active {
          background-color: white;
          color: $green-400;

          .plus {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M21 10.5H3V13.5H21V10.5Z' fill='%23045E51'/%3E%3C/svg%3E");
          }

          .icon-set-wrap {
            img {
              display: none;

              &:last-child {
                display: block;
              }
            }
          }
        }

        &:first-child {
          border-radius: 30px 0 0 0;
        }

        .plus {
          font-size: 35px;
          margin-right: 20px;
        }
      }
    }
  }

  .accommodation_parameters_row {
    display: flex;
    flex-direction: row;
    gap: 25px;

    .accommodation_parameters_section {
      height: 18px;
      display: flex;
      gap: 5px;
      font-size: 14px;
      color: $grey-400;
      flex-direction: row-reverse;

      img {
        object-fit: contain;
        height: 100%;
      }
    }
  }

  .post-tabs-apartment {
    flex-direction: column;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 40px;
      align-items: flex-start;
      margin-bottom: 15px;
      box-shadow: 0px 4px 10px 0px rgba(20, 6, 60, 0.15);
      border-radius: 0 0 12px 0;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      & > div {
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }

      &:last-child {
        box-shadow: none;
        margin-bottom: 0;
      }

      .accommodation_images {
        max-height: 240px;
        height: 100%;

        @include media-breakpoint-down(lg) {
          padding: 20px;
        }

        img {
          object-fit: contain;
          height: 100%;
          max-height: 240px;
          border-radius: 0 0 12px 0;

          @include media-breakpoint-down(lg) {
            width: calc(100% - 40px);
            height: calc(100% - 40px);
            object-fit: cover;
            border-radius: 12px;
          }
        }
      }

      .package-apartment-info {
        display: flex;
        flex-direction: column;
        gap: 18px;
        color: $grey-400;
        padding-top: 40px;
        flex-grow: 1;

        @include media-breakpoint-down(lg) {
          padding: 20px;
        }

        h4 {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .accommodation_short_description {
          ul {
            padding-inline-start: 16px;
            margin: 0;

            li {
              margin-bottom: 18px;
              font-size: 14px;
              color: $grey-400;
            }
          }
        }
      }

      .price {
        padding: 20px;

        .nav {
          @include media-breakpoint-down(lg) {
            display: flex;
            flex-direction: column;
            gap: 20px;
          }
        }
      }
    }
  }
}

.single-accommodation {
  .post-tabs-car {
    margin-top: 123px;
    box-shadow: 0px 4px 30px 0px #00000026;
    border-radius: 30px;

    & > div {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 20px;
      padding: 40px 40px 0 0;

      @include media-breakpoint-down(lg) {
        padding: 32px 16px 0 0;
      }
    }

    .car-card {
      padding: 40px;
      flex-direction: row;
      justify-content: flex-start;

      @include media-breakpoint-down(lg) {
        padding: 32px 16px;
      }
      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
        gap: 0;
      }

      .car-details {
        @include media-breakpoint-down(lg) {
          flex-direction: column;
          gap: 20px;
        }
      }

      .car-option {
        ul {
          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            gap: 8px;
            margin: 24px 0 20px;
          }
          li {
            @include media-breakpoint-down(lg) {
              font-size: 14px;
              font-weight: 400;
              align-items: center;
            }
          }
        }
      }

      .rental_conditions {
        ul {
          @include media-breakpoint-down(lg) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
          }
          li {
            @include media-breakpoint-down(lg) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .post-tabs-plane {
    padding: 40px;
    margin-top: 123px;
    box-shadow: 0px 4px 30px 0px #00000026;
    border-radius: 30px;

    @include media-breakpoint-down(lg) {
      padding: 32px 16px;
    }

    & > div:first-child {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: flex-end;
      gap: 20px;
    }

    @include media-breakpoint-down(lg) {
      margin-top: 60px;
    }

    .post-tabs-plane-row .plane-from,
    .post-tabs-plane-row .plane-to {
      @include media-breakpoint-down(lg) {
        gap: 5px !important;
      }
    }

    .post-tabs-plane-row .duration .line-with-dot {
      @include media-breakpoint-down(lg) {
        width: 80%;
        margin: 0 auto;
      }
    }

    h2 {
      @include media-breakpoint-down(lg) {
        font-size: 24px;
      }
    }

    svg {
      @include media-breakpoint-down(lg) {
        width: 24px;
        height: 24px;
      }
    }

    & > div:first-child {
      @include media-breakpoint-down(lg) {
        gap: 5px;
      }
    }
  }
}

.search-packages {
  .post-wrap {
    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr !important;
    }

    .price {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }
  }

  .post-tabs-control {
    @include media-breakpoint-down(lg) {
      flex-direction: column !important;
    }

    & > div {
      @include media-breakpoint-down(lg) {
        justify-content: space-between;
      }

      & > div:nth-child(2) {
        @include media-breakpoint-down(lg) {
          flex-grow: 1;
        }
      }
    }

    .post-tabs-control-btn.active {
      @include media-breakpoint-down(lg) {
        background-color: $grey-100 !important;
      }
    }
  }

  .mobile-price {
    display: none;
    padding: 20px;

    @include media-breakpoint-down(lg) {
      display: block;
    }

    .cost {
      background-color: white !important;
      flex-direction: row !important;
      justify-content: space-between !important;
      padding: 20px;
      align-items: flex-start !important;

      .duration-of-stay-and-number-of-people {
        @include media-breakpoint-down(md) {
          margin-top: 0;
        }
      }
    }

    .nav {
      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 20px;
      }
    }
  }
}

.info-block.fly {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > p {
    font-size: 14px;
    color: $grey-400;
    font-weight: 400;
  }

  & > h3 {
    font-weight: 700;
    font-size: 16px;
    color: $grey-400;
  }

  .plane-company-mobile {
    display: none;
  }

  .plane-company {
    & > div:nth-child(1) {
      display: none;
    }

    .fly-checkout {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 10px;
    }
  }

  .duration-mobile {
    display: none;
  }

  & > div:nth-child(1) {
    padding-bottom: 20px;
    border-bottom: 1px solid #d7d7d7;
  }

  .fly-bottom-border {
    padding-bottom: 16px;
    border-bottom: 1px solid #d7d7d7;
  }

  .post-tabs-plane-row {
    display: grid;
    grid-template-columns: 10% 15% 45% 15%;
    gap: 5%;
    align-items: center;
    color: $grey-400;

    .plane-time {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2em;
      color: $grey-400;
    }

    .plane-day {
      font-weight: 400;
      font-size: 12px;
      line-height: 1.2em;
      opacity: 0.5;
    }

    .plane-airport {
      font-weight: 400;
      font-size: 14px;
      line-height: 1.2em;
      text-transform: uppercase;
    }

    .plane-from,
    .plane-to {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
      gap: 5px;
    }

    .duration {
      text-align: center;
      line-height: 40px;

      .line-with-dot {
        border-bottom: 1px solid #d7d7d7;
        position: relative;

        &:before {
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: #d7d7d7;
          left: -2px;
          top: -2px;
        }

        &:after {
          position: absolute;
          content: "";
          width: 5px;
          height: 5px;
          border-radius: 5px;
          background-color: #d7d7d7;
          right: -2px;
          top: -2px;
        }
      }
    }
  }
}

.page-template-check-out-page {
  .time-wrap,
  .luggage-wrap {
    display: none;
  }
}
