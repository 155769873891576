@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.accomodations-tab {
  > .posts {
    .container-md {
      min-height: 150px;
    }
  }

  .searchbox {
    margin-bottom: 20px;
    padding-bottom: 40px;
    background: rgba(250, 251, 251, 1);

    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }

    .heading {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: 0.03em;
      text-align: center;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
      }
    }
  }

  .pre-filter {
    display: none;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      background: $white;
      box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
      padding: 10px 16px;
      align-items: center;

      .start-filter {
        border-radius: 8px;
        border: 1px solid $grey-200;
        height: 50px;
        width: 203px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 8px;
      }

      .map {
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.48px;
        text-decoration-line: underline;
      }
    }
  }

  .filter {
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    background: $white;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    height: 70px;

    @include media-breakpoint-down(md) {
      height: fit-content;
    }

    .container-md {
      display: flex;
      align-items: center;
      position: relative;

      @include media-breakpoint-down(md) {
        padding: 30px 20px 10px;
      }
    }

    .sorting {
      display: flex;
      align-items: center;
      height: 70px;

      @include media-breakpoint-down(md) {
        height: fit-content;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
      }

      .title {
        color: $grey-200;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;

        @include media-breakpoint-down(md) {
          font-size: 21px;
        }
      }

      .select {
        height: 100%;
        display: inline-flex;
        align-items: center;
        gap: 114px;
        border-left: 1px solid $grey-200;
        border-bottom: 5px solid transparent;
        cursor: pointer;

        &[data-select-name="budget"] {
          .nav {
            @include media-breakpoint-down(md) {
              margin-top: 40px !important;
            }
          }
        }

        @include media-breakpoint-down(md) {
          width: 100%;
          min-height: 60px;
          gap: 0;
          flex-direction: column;
          border-left: unset;
          border-bottom: 1px solid $grey-200;
          justify-content: center;
          padding: 15px 0;

          &:last-child {
            border-bottom: none;
          }
        }

        &.open {
          border-bottom: 5px solid $green-400;

          @include media-breakpoint-down(md) {
            border-bottom: unset;
          }

          .label {
            &:before {
              content: "";
              position: absolute;
              transform: translateX(-50%);
              bottom: -15px;
              left: 50%;
              border-color: transparent transparent #fff;
              border-style: solid;
              border-width: 0 10px 10px;
              z-index: 4;

              @include media-breakpoint-down(md) {
                content: none;
              }
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .label {
          display: flex;
          align-items: center;
          gap: 60px;
          padding: 0 30px;
          height: 100%;
          position: relative;
          z-index: 6;

          @include media-breakpoint-down(md) {
            width: 100%;
            justify-content: space-between;
            padding: 0;
          }

          .arrow {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            transition: transform 0.3s ease;
          }

          .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            letter-spacing: 0.48px;

            @include media-breakpoint-down(md) {
              font-size: 21px;
            }
          }
        }

        .drop-down {
          display: none;
          border-radius: 5px;
          background: $white;
          width: calc(100% - 20px);
          flex-shrink: 0;
          position: absolute;
          top: 100%;
          left: 10px;
          z-index: 3;
          box-shadow: $box-shadow;
          margin-top: 10px;
          padding: 25px;
          padding-top: 50px;

          @include media-breakpoint-down(md) {
            position: relative;
            box-shadow: none;
            padding: 0;
            width: 100%;
            left: 0;
          }

          .close {
            position: absolute;
            left: 10px;
            top: 10px;
            cursor: pointer;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }

          .content {
            .checkboxes-rectangle {
              margin-bottom: 50px;

              @include media-breakpoint-down(md) {
                margin-bottom: 30px;
              }
            }

            .tabs {
              .tabs-nav {
                display: flex;
                gap: 0px;
                margin-bottom: 30px;
                z-index: 1;
                position: relative;
                border-bottom: 1px solid $grey-200;
                overflow: auto;

                @include media-breakpoint-down(sm) {
                  padding: 0 50px;
                }

                .tab {
                  margin-left: 50px;
                  color: $grey-400;
                  text-align: center;
                  font-family: "Almoni", sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  cursor: pointer;
                  display: flex;
                  padding: 10px 50px;
                  align-items: center;
                  gap: 10px;
                  border-bottom: 6px solid transparent;
                  text-align: center;

                  span {
                    white-space: nowrap;
                  }

                  position: relative;

                  img {
                    position: absolute;
                    right: 0;
                  }

                  &.active {
                    border-bottom: 6px solid $green-400;
                    color: $green-400;
                    font-weight: 700;
                  }
                }
              }

              .tabs-contents {
                .tab-content {
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }

            .form {
              .checkboxes-rectangle {
                .checkboxes-horizontal {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);

                  @include media-breakpoint-down(md) {
                    grid-template-columns: repeat(2, 1fr);
                  }

                  @include media-breakpoint-down(xs) {
                    grid-template-columns: repeat(1, 1fr);
                  }

                  .checkbox-rectangle {
                    @include media-breakpoint-down(md) {
                      padding: 10px;
                    }
                  }
                }
              }

              .apartments {
                margin-bottom: 35px;

                .cards {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);
                  gap: 20px;
                }
              }

              .nav {
                display: flex;
                justify-content: flex-end;
                gap: 30px;
                margin-top: 50px;

                @include media-breakpoint-down(md) {
                  margin-top: 0;
                }

                .reset {
                  outline: none;
                  background: none;
                  border: none;
                  color: $green-400;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20.8px;
                  letter-spacing: 0.48px;
                  text-decoration-line: underline;
                }
              }
            }
          }
        }
      }

      .range {
        direction: ltr;
      }
    }
  }

  .order-by {
    margin-bottom: 30px;

    .container-md {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    .title {
      color: $grey-400;
      text-align: right;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.6px;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 24px;
      }
    }

    .select {
      display: flex;
      min-width: 300px;
      align-items: baseline;

      @include media-breakpoint-down(md) {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      select {
        border-radius: 5px;
        border: 1px solid #a4a4a4;
        flex: 1;
        height: 46px;
        padding: 0 13px;
      }

      label {
        color: $grey-400;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  .posts {
    @include media-breakpoint-down(md) {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
    }

    .container-md {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    .post {
      display: flex;
      border-radius: 9.065px;
      background-color: $white;
      box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);

      @include media-breakpoint-down(lg) {
        width: 100%;
        display: block;
        box-shadow: none;
        flex-direction: column;
      }

      .slider-container {
        position: relative;
        width: fit-content;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        .slider {
          width: 480px;
          flex-shrink: 0;
          position: relative;
          margin: 0px;
          overflow: hidden;
          border-radius: 0px 10px 10px 0px;

          @include media-breakpoint-down(lg) {
            width: 100%;
            height: 178px;
            border-radius: 10px 10px 0px 0px;
          }

          .slick-slide {
            position: relative;

            &::before {
              content: "";
              left: 0px;
              right: 0px;
              top: 0px;
              bottom: 0px;
              background-color: rgba(0, 0, 0, 0.3);
              position: absolute;
              border-radius: 0px 10px 10px 0px;
            }

            img {
              width: 480px;
              height: 238px;
              object-fit: cover;
              object-position: 50% 50%;
              @include media-breakpoint-down(xl) {
                height: 300px;
              }
            }
          }

          .slick-dots {
            bottom: 20px;
            position: absolute;

            li {
              margin: 0px;
            }

            li button:before {
              font-size: 10px;
              color: $white;
            }

            li.slick-active button:before {
              color: $white;
            }
          }
        }

        .slick-counter {
          position: absolute;
          bottom: 20px;
          right: 20px;
          padding: 10px;
          color: $white;
          background-color: rgba(0, 0, 0, 0.5);
          border-radius: 5px;
          display: flex;
          gap: 6px;
          align-items: center;
        }
      }

      .text {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 50px;
        width: 100%;
        padding: 22px !important;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          color: $grey-400;
          gap: 25px 0;
          padding: 20px 10px;
        }

        .info {
          .title {
            display: flex;
            align-items: center;
            gap: 17px;
            margin-bottom: 5px;

            @include media-breakpoint-down(md) {
              display: block;
            }

            a {
              color: $black;

              @include media-breakpoint-down(md) {
                color: $grey-400;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: 130%;
                letter-spacing: 0.72px;
              }
            }

            .facilities {
              display: flex;
              align-items: center;
              gap: 8px;

              @include media-breakpoint-down(md) {
                gap: 20px;
                margin-top: 14px;
              }

              img {
                @include media-breakpoint-down(md) {
                  height: 36px;
                  width: 36px;
                  object-fit: cover;
                  border-radius: 50%;
                }
              }
            }
          }

          .meta-data {
            display: flex;
            align-items: center;
            gap: 26px;
            margin-top: 16px;
            margin-bottom: 16px;

            @include media-breakpoint-down(md) {
              font-size: 14px;
            }

            i {
              @include media-breakpoint-down(md) {
                color: $black;
                font-size: 16px;
              }
            }

            .people {
              display: flex;
              align-items: center;
              gap: 6px;

              .title {
                margin-bottom: 0px;
              }
            }

            .bedroom {
              display: flex;
              align-items: center;
              gap: 6px;

              .title {
                margin-bottom: 0px;
              }
            }

            .square-meters {
              display: flex;
              align-items: center;
              gap: 6px;

              .title {
                margin-bottom: 0px;
              }
            }
          }

          .description {
            p {
              margin-bottom: 12px;
            }

            ul {
              display: flex;
              flex-direction: column;
              gap: 10px;
              margin: 0;
              padding-inline-start: 20px;
            }
          }
        }

        .cost {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;
          border-radius: 8px;

          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            width: 100%;
          }

          .duration-of-stay-and-number-of-people {
            color: $grey-400;
            text-align: right;
            font-size: 12px;
            font-weight: 400;
            line-height: 15.6px;
            letter-spacing: 0.36px;
            margin-top: 20px;

            @include media-breakpoint-down(md) {
              margin-top: 0;
              width: calc(50% - 10px);
            }
          }

          .price {
            margin-bottom: 16px;

            @include media-breakpoint-down(md) {
              margin-top: 0;
              width: calc(50% - 10px);
              margin: 0;
            }

            .summa {
              display: flex;
              align-items: flex-end;
              gap: 10px;

              @include media-breakpoint-down(md) {
                justify-content: flex-end;
              }

              .now {
                display: flex;
                align-items: center;
                gap: 10px;

                color: #e85481;
                text-align: center;
                font-size: 28.844px;
                font-weight: 700;
                line-height: 37.497px;
                letter-spacing: 0.865px;

                i {
                  color: $grey-300;
                  font-size: 20px;

                  @include media-breakpoint-down(md) {
                    color: $grey-600;
                    font-size: 16px;
                  }
                }
              }

              .was {
                display: flex;
                gap: 10px;
                color: $grey-400;
                font-size: 16.688px;
                font-weight: 400;
                line-height: 25.033px;
                text-decoration: line-through;
              }

              .text {
                display: flex;
                gap: 10px;
                color: $grey-400;
                font-size: 16.688px;
                font-weight: 400;
                line-height: 25.033px;
              }
            }

            .price-per-stay {
              color: $grey-400;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              margin-bottom: 12px;
              text-align: left;

              @include media-breakpoint-down(md) {
                margin: 0;
              }
            }
          }

          .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 25px;

            @include media-breakpoint-down(xl) {
              flex-direction: column;
              gap: 10px;
            }

            @include media-breakpoint-down(lg) {
              width: 100%;
            }

            .btn-white {
              @include media-breakpoint-down(lg) {
                display: none;
              }
            }

            .btn-pink {
              @include media-breakpoint-down(lg) {
                width: 100%;
                border-radius: 56px;
                height: 48px;
              }
            }
          }
        }
      }
    }

    .load-more {
      margin-top: 40px;
      text-align: center;

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}
