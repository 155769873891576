@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/colors.scss";

.checkbox-rectangle-white {

  &:hover {
    border-color: $green-400 !important;

    label {
      &::before {
        border-color: $green-400 !important;
      }
    }
  }

  label {
    display: flex;
    align-items: center;
    border-radius: 15px;
    background: $white;
    border: 2px solid #f4f5f5;
    padding: 25px;

    margin: 0px;
    font-size: 18px;
    line-height: 133%;
    font-weight: 400;
    display: flex;
    align-items: center;
    color: $black;
    cursor: pointer;
    position: relative;

    &::before {
      content: "";
      display: inline-block;
      width: 24px;
      height: 24px;
      border: 2px solid $grey-300;
      margin-left: 14px;
      border-radius: 5px;
      flex-shrink: 0;


    }
  }

  input[type="checkbox"] {
    display: none;

    &:disabled {
      +label {
        color: $grey-200 !important;

        &::before {
          background-color: $grey-200 !important;
          border: 2px solid $grey-300 !important;


        }

        &::after {
          color: $grey-400 !important;


        }
      }
    }

    &:checked+label {
      &::before {
        background-color: transparent;
      }

      &::after {
        position: absolute;
        width: 24px;
        padding: 4px;
        font-weight: 700;
        color: #045e51;
        font-size: 16px;
        content: "\f00c";
        font-family: "Font Awesome 6 Pro";
        width: 24px;
        height: 24px;
        line-height: 1;
        text-align: center;

      }
    }
  }
}