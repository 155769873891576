@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";


.map-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: $grey-400;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &.hide {
    display: none;
  }

  .home-map {
    height: 90%;
    width: 100%;
    max-height: 932px;
    background: $white;
    padding: 22px;

    @include media-breakpoint-down(lg) {
      padding: 0;
      max-height: unset;
      height: 100dvh;
    }

    .map-wrapper {
      display: flex;
      flex-direction: row-reverse;
      height: 100%;
      position: relative;
    }

    .control-search-form-mobile {
      display: none;
      transition: all 200ms linear;
      cursor: pointer;

      &.active {
        transform: rotateX(180deg);
      }

      @include media-breakpoint-down(lg) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .map-section {
      flex-grow: 1;

      #map{
        @include media-breakpoint-down(lg){
          height: 100dvh !important;
        }
      }

      .cluster {
        display: flex;
        align-items: center;
        justify-content: center;

        & > div {
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .gm-style-iw.gm-style-iw-c {
        padding: 0 !important;

        .gm-style-iw-d {
          overflow: auto !important;
        }

        .gm-style-iw-ch {
          position: absolute !important;
        }

        button.gm-ui-hover-effect {
          position: absolute !important;
          left: 0;
          top: 0;
        }
      }

      .info-window-content-map {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        padding: 10px;
        min-height: 140px;
        min-width: 290px;

        & > div:first-child {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        img {
          height: 100% !important;
          object-fit: cover;
          object-position: center center;
        }

        .info-window-btn-map {
          border: 1px solid #000;
          padding: 5px 30px;
          border-radius: 30px;
          color: $grey-400;
          font-size: 14px;
          font-weight: 500;
        }

        h5 {
          font-size: 12px;
          font-weight: 500;
        }

        h4 {
          font-size: 14px;
          font-weight: 300;
          margin-bottom: 5px;
        }
      }
    }

    .locations-list {
      max-width: 650px;
      width: 40%;
      height: 100%;

      @include media-breakpoint-down(lg) {
        position: absolute;
        left: 0;
        width: 100%;
        max-width: unset;
        z-index: 1;
        height: fit-content;
      }

      .locations-list-search {
        width: 100%;
        height: 360px;
        background: $green-500;
        position: relative;
        padding-top: 15%;
        padding-inline: 15%;

        @include media-breakpoint-down(laptop) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          justify-content: center;
          height: auto;
          padding: 25px 5%;
        }

        & > div {
          @include media-breakpoint-down(lg) {
            width: 100%;
            position: relative;
            padding-bottom: 15px;
          }
        }

        .close-home-map {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;

          @include media-breakpoint-down(lg) {
            top: 0;
          }

          svg {
            @include media-breakpoint-down(lg) {
              width: 24px;
              height: 24px;
            }
          }
        }

        h2 {
          color: $white;
          padding-bottom: 30px;

          @include media-breakpoint-down(lg) {
            margin: 0 auto 15px;
            text-align: center;
            padding-bottom: 0;
            width: 90%;
            font-size: 21px;
          }
        }

        form {
          @include media-breakpoint-down(lg) {
            display: none;
          }
          border: 1px solid $white;
          border-radius: 50px;
          height: 46px;
          display: flex;
          flex-direction: row-reverse;
          background: $green-500;
          position: relative;
          width: 100%;

          input {
            height: 100%;
            border: none;
            outline: none;
            background: #025F52;
            flex-grow: 1;
            border-radius: 0 50px 50px 0;
            padding: 12px;
            color: #fff;
            font-weight: 500;
            font-size: 14px;

            &::placeholder {
              color: #fff;
              font-weight: 500;
              font-size: 14px;
            }
          }

          button {
            height: 100%;
            background: #fff;
            border-radius: 50px;
            width: 100px;
            border: 1px solid #fff;
            color: $grey-400;
            cursor: pointer;
          }

          .search-list-result {
            position: absolute;
            width: calc(100% - 30px);
            background: $white;
            bottom: -1px;
            left: 15px;
            transform: translateY(100%);
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid $green-500;

            .list-search {
              padding: 10px;
              margin: 0;

              li {
                font-size: 14px;
                font-weight: 300;
                list-style: none;
                color: $grey-400;
                cursor: pointer;
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      h3 {
        padding: 10px 20px 10px 50px;
        height: 50px;
        font-size: 21px;
        @include media-breakpoint-down(laptop) {
          height: 70px;
        }
        @include media-breakpoint-down(lg) {
          display: none;
        }

      }

      .locations-list-result {
        overflow-y: auto;
        height: calc(100% - 410px);
        padding: 20px 20px 20px 50px;
        @include media-breakpoint-down(laptop) {
          height: calc(100% - 330px);
        }
        @include media-breakpoint-down(lg) {
          pointer-events: none;
          opacity: 0;
          position: absolute;
          left: -9999999px;
        }

        .list-location-item {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 0 12px 12px;
          gap: 58px;
          border-bottom: 1px solid $grey-200;
          cursor: pointer;

          &.active {
            background: $green-100;
          }

          &:hover {
            background: $green-100;
          }

          .thumbnail_map_wrap {
            width: 40%;


            img {
              height: 100px;
              width: 100%;
              object-fit: cover;
              object-position: center;
            }
          }

          .map-park-title {
            color: $grey-400;
            font-size: 16px;
            font-weight: 500;

          }

          .map-park-location {
            color: $grey-400;
            font-size: 16px;
            font-weight: 300;

          }


        }
      }


    }
  }
}