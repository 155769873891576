@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/colors.scss";

.chosen-container {
  width: 255px !important;
  &.chosen-with-drop {
    .chosen-single {
      div {
        b {
          &::before {
            transform: translate(-50%, -50%) rotate(180deg);
          }
        }
      }
    }
  }
  .chosen-single {
    height: auto;
    padding: 0px;
    border-radius: 5px !important;
    background-image: none !important;
    border: 1px solid $grey-300;
    background: $white;
    span {
      padding: 10px 20px;
    }
    div {
      b {
        background: none;
        &::before {
          content: "\f078";
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translate(-50%, -50%);
          font-family: "Font Awesome 6 Pro";
          font-weight: 900;
          color: $grey-400;
          transition: $transition-all-linear;
        }
      }
    }
  }
  .chosen-drop {
    border-radius: 5px;
    border: 1px solid $grey-300;
    background: $white;
    margin-top: 2px;
    overflow: hidden;
    box-shadow: none;
    .chosen-results {
      max-height: 600px;
      padding: 0px;
      margin: 0px;
      li {
        padding: 10px 20px;
        color: $grey-400;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        &.result-selected {
          color: $green-300;
          font-size: 16px;
          font-weight: 700;
        }
        &.highlighted {
          background-color: $grey-100 !important;
          background-image: none !important;
        }
      }
    }
  }
}
