@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";


.mfp-container,
.mfp-content {
  direction: rtl;
  text-align: right;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close,
.mfp-ajax-holder .mfp-close {
  right: auto;
  left: 0;
}

.mfp-image-holder .mfp-figure {
  padding-right: 0;
  padding-left: 40px; /* Adjust as needed */
}

.mfp-figure figcaption {
  text-align: right;
}

.mfp-bottom-bar {
  text-align: right;
}

.mfp-counter {
  margin-right: 10px;
}

.mfp-title {
  text-align: right;
}

#addon-popup {
  padding: 20px 20px 20px 40px;
  max-width: 900px;
  width: 100%;
  background: $white;
  align-self: center;
  border-radius: 9px;
  position: relative;
  margin: 40px auto;

  @include media-breakpoint-down(lg) {
    margin: 40px auto 0;
    border-radius: 9px 9px 0 0;
  }

  .mfp-close {
    display: inline-flex;
    position: absolute;
    top: 22px;
    inset-inline-end: 22px;
    width: 20px;
    height: 20px;
  }

  .addon-popup-container {
    display: flex;
    gap: 30px;

    .addon-popup-image {
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(lg) {
        display: none;
      }

      img {
        border-radius: 10px;
        width: 500px;
        object-fit: cover;
      }
    }

    .addon-popup-info {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      flex: 1;

      .addon-popup-info-header {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 12px;

        .addon-popup-title {
          font-size: 24px;
          font-weight: 700;
          line-height: 31.2px;
          letter-spacing: 0.03em;
          color: $text-color;
        }

        .addon-popup-description {
          font-size: 18px;
          font-weight: 400;
          line-height: 23.4px;
          letter-spacing: 0.03em;
          color: $text-color;
        }
      }

      .addon-popup-info-add-to-cart {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .variations-list {
          display: flex;
          flex-direction: column;
          gap: 32px;

          .variations-item {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .price-container {
              display: flex;
              flex-direction: column;

              .variations-item-name {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.04px;
                color: $text-color;
              }

              .variations-item-price {
                font-size: 16px;
                font-weight: 700;
                line-height: 21.76px;
                color: $text-color;
              }
            }

            .quantity-container {
              position: relative;

              .price-result {
                position: absolute;
                inset-inline-end: 0;
                bottom: calc(100% + 5px);
                font-size: 14px;
                font-weight: 400;
                line-height: 19.04px;
                color: $green-400;
              }

              .quantity {
                display: flex;
                align-items: center;
                gap: 12px;

                input {
                  width: min-content;
                  border: unset;
                  font-size: 20px;
                  font-weight: 500;
                  line-height: 27.2px;
                }

                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 36px;
                  height: 36px;
                  background: transparent;
                  border-radius: 18px;
                  border: 1px solid #404040;
                  color: $text-color;
                  cursor: pointer;

                  &:disabled {
                    border: 1px solid #D0D0D0;
                    color: #D0D0D0;
                    fill: #D0D0D0;

                    img{
                      opacity: .5;
                    }
                    svg path {
                      fill: #D0D0D0;
                    }
                  }
                }

                .quantity-error {
                  display: none;
                  position: absolute;
                  top: calc(100% + 4px);
                  left: 50%;
                  width: max-content;
                  transform: translateX(-50%);
                  padding: 4px 8px;
                  background: #FAFCFC;
                  border-radius: 4px;
                  box-shadow: 0px 0px 10px 0px #00000026;

                  &:before {
                    position: absolute;
                    content: '';
                    top: -5px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 11px;
                    height: 5px;
                    background-image: url('data:image/svg+xml,<svg width="11" height="5" viewBox="0 0 11 5" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5.5 0.000488281L0 5.00049L11 5.00049L5.5 0.000488281Z" fill="%23FAFCFC"/></svg>');
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                  }

                  .quantity-error-message {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 22.4px;
                    letter-spacing: -0.017em;
                    text-align: center;
                    color: #D43C33;
                  }
                }
              }
            }
          }
        }

        .add-to-cart-button {
          font-size: 16px;
          font-weight: 700;
          line-height: 20.8px;
          letter-spacing: 0.03em;

          &:disabled {
            border: unset;
            background: $grey-150;
            color: #9EA0A2;
          }
        }
      }
    }
  }
}