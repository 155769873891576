@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/_colors.scss";

.single-park, .page-template-event-page {
  .search-form-parks {
    @include media-breakpoint-down(lg) {
      border-radius: 12px !important;
      padding-top: 0 !important;
    }
    .arrival {
      &::before {
        @include media-breakpoint-down(lg) {
          content: none !important;
        } 
      }
    }
  }
}

.search-form-tabs {
  background-color: white;
  color: $grey-600;
  font-size: 18px;
  font-weight: 400;
  padding: 22px 44px 0 44px;
  border-radius: 20px 28px 0 0;
  text-align: center;
  display: none;
  box-shadow: 0 3.729px 27.961px 0 rgba(0, 0, 0, 0.15);

  //opacity: 0;
  //pointer-events: none;

  @include media-breakpoint-down(lg) {
    width: 100%;
    max-width: 400px;
  }

  ul {
    display: flex;
    gap: 20px;
    list-style: none;
    text-transform: uppercase;
    margin: 0;
    text-align: center;
    padding: 0;
    @include media-breakpoint-down(lg) {
      justify-content: center;
    }
  }

  li {
    border-bottom: 5px solid transparent;
    padding-bottom: 17px;
    cursor: pointer;
    font-weight: 700;

    @include media-breakpoint-down(md) {
      padding-bottom: 7px;
    }

    &[data-tab-type="flights"] {
      display: none;
    }

    &.active {
      color: $green-400;
      border-bottom: 5px solid $green-400;
    }
  }
}

.home .search-form-tabs {
  display: block;
}

.page-template-search-packages {
  form {
    .number-of-nights {
      display: none !important;
    }

    .packages {
      display: block !important;

      @include media-breakpoint-down(md) {
        .select .drop-down {
          position: relative !important;
          transform: none !important;
          padding: 0 !important;
          border-radius: 0 !important;
          min-width: unset !important;

          .packages-drop-down-row {
            background-color: transparent !important;
            justify-content: center;
          }

          .close {
            display: none;
          }
        }
      }
    }

    .parks .select {
      border-radius: 0;
      border-right: 1px solid $grey-200;
    }
  }
}

form.search.search-parks {
  &[selected-tab="packages"] {
    .search-form {
      padding-inline: 30px;
      @include media-breakpoint-down(md) {
        padding-inline: 0;
      }
    }

    .accommodation .select .label {
      padding: 0 30px 0 0;
      gap: 40px;
      @include media-breakpoint-down(md) {
        padding: 0 30px 0 15px;
      }
    }

    .arrival {
      gap: 20px;
      @include media-breakpoint-down(md) {
        gap: 5px;
      }

      .arrival-date {
        padding: 0;
      }

      .departure-date {
        padding: 0;
      }
    }

    .packages {
      display: block;
    }

    .parks .select {
      border-radius: 0;
      border-right: 1px solid $grey-200;
    }

    .number-of-nights {
      display: none !important;
    }
  }

  .packages {
    position: relative;
    display: none;

    @include media-breakpoint-down(lg) {
      padding: 0;
      margin-bottom: 20px;
    }

    .select {
      height: 100%;
      @include media-breakpoint-down(lg) {
        position: relative;
        padding-bottom: 20px;
      }

      &::after {
        @include media-breakpoint-down(lg) {
          content: "";
          z-index: 3;
          position: absolute;
          width: calc(100% - 32px);
          transform: translateX(-50%);
          bottom: 0;
          left: 50%;
          background: #25368e;
          opacity: 0.1;
          display: block;
          height: 1px;
        }
      }

      .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        padding: 0 30px 0 15px;
        min-width: 260px;
        gap: 15px;
        border-radius: 0 0 30px 0;
        cursor: pointer;
        justify-content: space-between;

        &:hover {
          box-shadow: inset 0 -5px 0 $green-400;
        }

        @include media-breakpoint-down(md) {
          height: auto;
          &:hover {
            box-shadow: unset;
          }
        }

        .text {
          font-size: 22px;
          font-weight: 500;
          line-height: 1.4;
          overflow: hidden;
          white-space: nowrap;

          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }
        }

        .arrow {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          @include media-breakpoint-down(md) {
            pointer-events: none;
          }

          img {
            height: 30px;
          }

          i {
            font-size: 25px;
          }
        }
      }

      &.open {
        .arrow i {
          transform: rotate(180deg);
        }

        .packages-drop-down {
          display: flex;
        }
      }

      .drop-down {
        position: absolute;
        bottom: 0;
        right: 0;
        transform: translateY(calc(100% + 20px));
        background-color: white;
        min-width: 430px;
        border-radius: 5px;
        padding: 40px;
        display: none;
        flex-direction: column;
        gap: 18px;
        z-index: 1;

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
          font-size: 16px;
          color: $grey-400;
        }

        &::before {
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAyNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzIDBMIDI1LjEyNDQgMTQuMjVIMC44NzU2NDVMMTMgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPg==");
          position: absolute;
          top: -10px;
          left: 75%;
          width: 28px;
          height: 19px;
        }
      }
    }
  }
}

.packages-drop-down-row {
  padding: 25px;
  border-radius: 5px;
  background-color: $grey-50;
  display: flex;
  align-items: center;
  gap: 10px;

  .icon-package-wrap {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    background: #e9e9e9;
  }

  .packages-drop-down-checkbox {
    border: 2px solid $grey-600;
    width: 25px;
    height: 25px;
    min-width: 25px;
    min-height: 25px;
    border-radius: 4px;
    position: relative;
    overflow: hidden;

    input {
      cursor: pointer;
      width: 100%;
      height: 100%;
    }

    .drop-down-checkbox-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: white;
      top: 0;
      left: 0;
      pointer-events: none;
    }

    input[type="radio"]:checked + .drop-down-checkbox-wrapper::after {
      content: "";
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE0LjU4NyAwLjYzMTMzN0MxNC4zMjg4IDAuNjQwNDUxIDE0LjA5MTggMC43NzcxIDEzLjk1NTEgMC45OTU5Mkw2LjYzOTExIDEyLjE4ODZMMS45OTUyIDguMDkzMTRDMS42OTU5NiA3Ljg4MTkgMS4zOTA2MyA3LjgwMjk5IDEuMTA5NTkgNy44OTExQzAuODMwNzk5IDcuOTc3NjkgMC42MjE5NjMgOC4yMTQ2NyAwLjU3MzUyMiA4LjUwMzMwQzAuNTI0NzQxIDguNzkzNDUgMC42NDE3MTEgOS4wODUxMSAwLjg3ODY5IDkuMjU5ODFMNi4yOTg4MyAxMy45NTA4QzYuNDY4OTcgMTQuMDk1MSA2LjY5Mzc5IDE0LjE1ODkgNi45MTQwNiAxNC4xMjdDNy4xMzU4NSAxNC4wOTM2IDcuMzMxODIgMTMuOTY3NSA3LjQ1MzM0IDEzLjc4MDZMMTUuMjY3NiAxLjg0NjYxQzE1LjQzNDcgMS42MDIwNCAxNS40NDg0IDEuMjg2MDcgMTUuMzA0IDEuMDI3ODJDMTUuMTU5NyAwLjc3MTA5NCAxNC44ODE3IDAuNjE3NjY1IDE0LjU4NyAwLjYzMTMzN1oiIGZpbGw9IiMwNDVFNTIiIHN0cm9rZT0iIzA0NUU1MiIgc3Ryb2tlLXdpZHRoPSIwLjgzMzMzMyIvPgo8L3N2Zz4=");
      position: absolute;
      background-repeat: no-repeat;
      background-position: center center;
      pointer-events: none;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    font-size: 18px;
    font-weight: 500;
  }
}

form.search.search-parks {
  display: flex;
  justify-content: center;
  width: 100%;
}

.event-page form.search.search-parks {
  width: fit-content;
  margin: 0 auto;
}

.search-form-parks {
  max-width: 1600px;
  border-radius: 70px;
  background: $white;
  display: flex;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  @include media-breakpoint-down(lg) {
    height: 50px;
  }
  @include media-breakpoint-down(lg) {
    height: unset;
  }

  .calendar-clear {
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    @include media-breakpoint-down(lg) {
      padding: 0;
      width: 50px;
      justify-content: center;
    }
  }

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    padding-top: 20px;
    max-width: 400px;
    width: 100%;
    border-radius: 0 0 12px 12px;
    box-shadow: 0 20px 28px 0 rgba(0, 0, 0, 0.15);
  }

  .parks {
    border-left: 1px solid $grey-200;
    max-width: 270px;
    width: 100%;
    @include media-breakpoint-down(lg) {
      border-left: unset;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
      cursor: pointer;
      max-width: unset;
    }

    .select {
      height: 100%;
      display: flex;
      align-items: center;
      border-radius: 0 70px 70px 0;
      gap: 114px;
      width: 100%;
      @include media-breakpoint-down(lg) {
        pointer-events: none;
        min-width: 231px;
      }

      &.open,
      &:hover {
        box-shadow: inset 0 -5px 0 $green-400;
      }

      &.open {
        .label {
          .fa-chevron-down {
            rotate: 180deg;
          }
        }
      }

      .label {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0px 30px 0px 15px;
        height: 100%;
        width: 100%;
        justify-content: space-evenly;
        cursor: pointer;

        &::after {
          content: attr(data-tooltip);
          padding: 5px 10px;
          border-radius: 10px;
          background-color: #000000b5;
          color: $white;
          pointer-events: none;
          opacity: 0;
          position: absolute;
          width: max-content;
          transform: translate(0, 0);
          top: -40px;
          right: 5px;
          transition: all 0.3s ease-in-out;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }

        &[data-show-tooltip="1"]:hover {
          &::after {
            opacity: 1;
          }
        }

        @include media-breakpoint-down(lg) {
          gap: 8px;
          justify-content: space-between;
          width: 100%;
        }

        .arrow {
          display: flex;
          align-items: center;
          gap: 22px;
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
        }

        .text {
          font-size: 20px;
          font-weight: 500;
          line-height: 28px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 1.2;
          }
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.03em;
          }
          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }
        }
      }

      .drop-down {
        display: none;
        max-width: 1000px;
        border-radius: 5px;
        background: $white;
        width: 100%;
        flex-shrink: 0;
        position: absolute;
        top: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
        z-index: 9;
        box-shadow: $box-shadow;

        &:before {
          content: "";
          z-index: 3;
          position: absolute;
          transform: translateX(-50%);
          top: -15px;
          right: 10%;
          border-color: transparent transparent #fff;
          border-style: solid;
          border-width: 0px 15px 15px;
        }

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
          font-size: 16px;
          color: $grey-400;
          z-index: 999;
        }

        .content {
          .tabs {
            display: flex;
            z-index: 999;
            height: 400px;
            width: 100%;
            height: 100%;
            background-color: $white;

            .tabs-nav {
              width: 295px;
              background-color: $grey-150;
              padding: 30px 30px 30px 0px;
              @include media-breakpoint-down(lg) {
                width: 240px;
              }

              .tab {
                color: $black;
                text-align: right;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                padding: 10px 30px;
                border-radius: 0px 18px 18px 0px;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                > div {
                  display: flex;
                  align-items: center;
                  column-gap: 12px;

                  .item-counter {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 7px;
                    border-radius: 100px;
                    background-color: $green-400;
                    color: $white;
                    font-weight: 600;
                    font-size: 12px;
                    height: 22px;
                    min-width: 22px;
                    width: max-content;
                  }
                }

                &:last-child {
                  margin-bottom: 0px;
                }

                &::after {
                  content: "\f104";
                  font-family: "Font Awesome 6 Pro";
                  font-weight: 300;
                  font-size: 24px;
                }

                &.active {
                  background-color: $white;
                  color: $green-400;
                }
              }
            }

            .tabs-contents {
              flex: 1;
              width: calc(100% - 295px);

              .tab-content {
                display: none;
                padding: 15px;
                position: relative;
                height: 100%;
                padding: 15px 70px;
                @include media-breakpoint-down(lg) {
                  padding: 15px 35px;
                }

                &.active {
                  display: flex;
                }

                .locations {
                  flex-shrink: 0;

                  > a {
                    color: $black;
                    font-size: 14px;
                    font-weight: 500;
                    display: none;
                    align-items: center;
                    direction: ltr;
                    text-align: left;
                    gap: 10px;
                    margin-bottom: 16px;

                    &:hover {
                      color: $green-400;

                      .checkbox > label::before {
                        border: 2px solid $green-400;
                      }
                    }
                  }

                  ul {
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    padding-inline-end: 14px;

                    .marker_item {
                      position: relative;

                      .marker_item_discount {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        background: #ff4380;
                        padding: 6px;
                        transform: translate(-100%, -50%);
                        border-radius: 3px;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 700;
                        display: flex;

                        & > span:last-child {
                          margin-right: 7px;
                        }
                      }
                    }

                    li {
                      line-height: normal;
                      margin-bottom: 14px;

                      .tag {
                        padding: 8px;
                        border-radius: 3px;
                        background: $green-100;
                        color: $grey-400;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 13px;
                        letter-spacing: 0.3px;
                      }

                      &:last-child {
                        margin-bottom: 0px;
                      }

                      a {
                        color: $black;
                        font-size: 14px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        direction: ltr;
                        text-align: left;
                        gap: 10px;

                        &:not(.disabled):hover {
                          color: $green-400;

                          .checkbox > label::before {
                            border: 2px solid $green-400;
                          }
                        }

                        &.disabled {
                          pointer-events: none;
                          color: $grey-300;

                          label::before {
                            border: 2px solid $grey-200;
                          }
                        }
                      }
                    }
                  }
                }

                .maps {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: end;
                  align-items: center;

                  .map {
                    display: inline-block;
                    position: relative;
                    margin: 0px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }

                    .marker {
                      line-height: 1;
                      transform: translate(-50%, -22.5px);

                      &::before {
                        content: "\f3c5";
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 900;
                        display: inline-block;
                        font-size: 30px;
                        color: $green-400;
                        line-height: 1;
                      }

                      &.active {
                        &::before {
                          color: $green-hover;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .search-form {
    display: flex;
    align-items: center;
    position: relative;
    @include media-breakpoint-down(lg) {
      flex-flow: column;
      cursor: pointer;
    }

    .arrival {
      display: flex;
      align-items: center;
      height: 100%;

      #calendar-container-start-date {
        top: calc(100% + 15px);
        right: 0;
        position: absolute;
        width: 100%;
        display: none;
        background-color: $white;
        padding: 50px 80px;
        z-index: 9;

        &:before {
          content: "";
          position: absolute;
          top: -15px;
          right: 12%;
          transform: translateX(-50%);
          border-width: 0px 15px 15px;
          border-style: solid;
          border-color: transparent transparent #fff transparent;
        }

        .error-message {
          display: none;
          position: absolute;
          transform: translate(-50%, 0);
          left: 50%;
          top: 15px;
          color: $red-400;
          font-size: 18px;
        }

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
          font-size: 16px;
          color: $grey-400;
        }

        .number-of-nights {
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $grey-400;
            font-size: 14px;
            font-weight: 600;
            width: 100px;
          }

          ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: flex;
            gap: 5px;

            li {
              span {
                display: flex;
                width: 49px;
                height: 39px;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 100px;
                background-color: $white;
                border: 1px solid $grey-200;

                &.disabled {
                  pointer-events: none;
                  color: $grey-600;
                  opacity: 0.3;
                }

                &:hover {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }

                &.active {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }
              }
            }
          }
        }
      }

      #calendar-container-end-date {
        top: calc(100% + 15px);
        right: 0;
        position: absolute;
        width: 100%;
        display: none;
        background-color: $white;
        padding: 50px 80px;
        z-index: 999;

        &:before {
          content: "";
          position: absolute;
          top: -15px;
          left: 48%;
          transform: translateX(-50%);
          border-width: 0px 15px 15px;
          border-style: solid;
          border-color: transparent transparent #fff transparent;
        }

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
          font-size: 16px;
          color: $grey-400;
        }

        .number-of-nights {
          margin-bottom: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $grey-400;
            font-size: 14px;
            font-weight: 600;
            width: 100px;
          }

          ul {
            list-style: none;
            padding: 0px;
            margin: 0px;
            display: flex;
            gap: 5px;

            li {
              span {
                display: flex;
                width: 49px;
                height: 39px;
                padding: 10px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 100px;
                background-color: $white;
                border: 1px solid $grey-200;

                &:hover {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }

                &.active {
                  cursor: pointer;
                  color: $white;
                  background-color: $green-400;
                }
              }
            }
          }
        }
      }

      @include media-breakpoint-down(lg) {
        padding: 20px 16px;
        position: relative;
        width: 100%;
        gap: 5px;
        margin-bottom: 20px;

        &:before,
        &:after {
          content: "";
          z-index: 3;
          position: absolute;
          width: calc(100% - 32px);
          transform: translateX(-50%);
          top: 0px;
          left: 50%;
          background: $blue-400;
          opacity: 0.1;
          display: block;
          height: 1px;
        }

        &:after {
          top: 100%;
        }
      }

      .arrival-date {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 100%;
        padding: 0px 30px;
        color: $grey-400;
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.641px;
        @include media-breakpoint-down(xl) {
          padding: 0 20px;
        }
        @include media-breakpoint-down(lg) {
          padding: 0 10px;
        }
        @include media-breakpoint-down(lg) {
          gap: 5px;
          padding: 0 !important;
        }

        input {
          border: none;
          font-size: 22px;
          font-weight: 500;
          line-height: 1.27;
          color: $black;
          outline: none;
          width: 100%;
          height: 100%;
          cursor: pointer;

          i {
            @include media-breakpoint-down(lg) {
              font-size: 15px;
            }
          }

          &[disabled] {
            cursor: not-allowed;
            opacity: 0.5;
            pointer-events: none;
          }

          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 1.2;
            text-align: center;
          }
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.03em;
            width: 130px;
            pointer-events: none;
          }
          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }

          &:not([disabled]):hover,
          &:not([disabled]):focus {
            box-shadow: inset 0 -0.25rem 0 #00806c;
            color: #00806c;
          }

          box-shadow ::placeholder {
            color: $black;
          }
        }
      }

      .arrow-date {
        font-size: 22px;
        font-weight: 500;
        line-height: 28px;
        color: $grey-200;
        @include media-breakpoint-down(lg) {
          font-size: 14px;
        }
      }

      .departure-date {
        display: flex;
        align-items: center;
        gap: 16px;
        height: 100%;
        padding: 0px 30px;
        color: $grey-200;
        font-size: 22px;
        font-weight: 500;
        line-height: 27.791px;
        letter-spacing: 0.641px;
        @include media-breakpoint-down(xl) {
          padding: 0px 20px;
        }
        @include media-breakpoint-down(lg) {
          padding: 0px 10px;
        }
        @include media-breakpoint-down(lg) {
          padding: 0px;
        }

        input {
          border: none;
          font-size: 22px;
          font-weight: 500;
          line-height: 1.27;
          color: $black;
          outline: none;
          width: 100%;
          height: 100%;
          cursor: pointer;
          @include media-breakpoint-down(xl) {
            font-size: 18px;
            line-height: 1.2;
            text-align: center;
          }
          @include media-breakpoint-down(lg) {
            font-size: 16px;
          }
          @include media-breakpoint-down(lg) {
            font-size: 22px;
            line-height: 28px;
            letter-spacing: 0.03em;
            width: 130px;
          }
          @include media-breakpoint-down(xs) {
            font-size: 18px;
          }

          &:disabled {
            color: $black;
            cursor: not-allowed;

            &::placeholder {
              color: $grey-200;
            }
          }

          &:not([disabled]):hover,
          &:not([disabled]):focus {
            box-shadow: inset 0 -0.25rem 0 #00806c;
            color: #00806c;
          }

          box-shadow ::placeholder {
            color: $black;
          }
        }
      }
    }

    .accommodation {
      border-right: 1px solid $grey-200;
      height: 100%;
      @include media-breakpoint-down(xl) {
        border-right: unset;
      }
      @include media-breakpoint-down(lg) {
        width: 100%;
      }

      .select {
        height: 100%;
        display: flex;
        align-items: center;
        gap: 114px;
        position: relative;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
          justify-content: center;
          pointer-events: none;
          width: 100%;
        }

        &.open,
        &:hover {
          box-shadow: inset 0 -5px 0 $green-400;
        }

        &.open {
          .label {
            .fa-chevron-down {
              rotate: 180deg;
            }
          }
        }

        .label {
          display: flex;
          align-items: center;
          gap: 60px;
          padding: 0px 60px 0px 30px;
          height: 100%;
          cursor: pointer;
          @include media-breakpoint-down(xl) {
            padding: 0 30px 0 15px;
            gap: 40px;
          }
          @include media-breakpoint-down(lg) {
            margin-bottom: 20px;
            gap: 100px;
            width: 100%;
            justify-content: space-between;
          }

          .arrow {
            display: flex;
            align-items: center;
            gap: 22px;
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
          }

          .text {
            font-size: 22px;
            font-weight: 500;
            line-height: 28px;
            @include media-breakpoint-down(xl) {
              font-size: 18px;
              line-height: 1.2;
            }
            @include media-breakpoint-down(md) {
              font-size: 22px;
            }
            @include media-breakpoint-down(xs) {
              font-size: 18px;
            }
          }
        }

        .drop-down {
          display: none;
          border-radius: 5px;
          background: $white;
          width: 416px;
          flex-shrink: 0;
          position: absolute;
          top: calc(100% + 15px);
          left: 50%;
          transform: translateX(-50%);
          z-index: 9;
          box-shadow: $box-shadow;
          padding: 25px;
          padding-top: 50px;

          @include media-breakpoint-down(md) {
            width: 100%;
          }

          &:before {
            content: "";
            z-index: 3;
            position: absolute;
            transform: translateX(-50%);
            top: -15px;
            left: 50%;
            border-color: transparent transparent #fff;
            border-style: solid;
            border-width: 0px 15px 15px;
          }

          .close {
            position: absolute;
            left: 10px;
            top: 10px;
            cursor: pointer;
            font-size: 16px;
            color: $grey-400;
          }

          .options {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .seniors,
            .adults,
            .children,
            .pets {
              display: flex;
              justify-content: space-between;
            }

            .children {
              flex-wrap: wrap;
              gap: 12px;

              .children-ages {
                display: flex;
                justify-content: space-between;
                gap: 6px;
                width: 100%;
                flex-direction: column;

                .tip {
                  display: block;
                  min-width: 100px;
                  white-space: normal;
                  font-size: 14px;
                  font-weight: 500;
                  color: $red-300;
                }

                .ages {
                  display: grid;
                  grid-template-columns: repeat(3, 1fr);
                  gap: 10px;

                  select {
                    height: 46px;
                    font-size: 14px;
                    border-radius: 6px;
                    border: 1px solid #aaa;
                  }

                  select.error-val + .chosen-container {
                    .chosen-single {
                      border: 1px solid $red-300;
                    }
                  }

                  .chosen-container {
                    width: 100% !important;
                  }

                  .chosen-single {
                    span {
                      padding: 10px 8px;
                    }
                  }

                  .chosen-results {
                    max-height: 180px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .submit {
    button {
      display: flex;
      width: 207px;
      height: 60px;
      padding: 10px 40px;
      justify-content: center;
      align-items: center;
      gap: 13.335px;
      flex-shrink: 0;
      border-radius: 70px 0px 0px 70px;
      background: $pink-400;
      color: $white;
      font-size: 22px;
      font-weight: 600;
      outline: none;
      border: 0px;
      cursor: pointer;
      @include media-breakpoint-down(xl) {
        width: 180px;
        font-size: 18px;
        line-height: 1.2;
      }

      @include media-breakpoint-down(lg) {
        font-size: 16px;
        width: 100%;
        border-radius: 0px 0px 12px 12px;
        height: 70px;
      }
    }
  }

  &.without-parks,
  &.event-page {
    max-width: 1005px;

    .search-form {
      .arrival {
        .arrival-date {
          padding: 0 35px;
        }
      }
    }
  }
}
