@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/_colors.scss";

.search-form-accommodations {
  border-radius: 70px;
  background: $white;
  display: inline-flex;
  box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
  position: relative;

  @include media-breakpoint-down(md) {
    flex-direction: column;
    border-radius: 12px;
    gap: 20px 0;
    padding-top: 20px;
  }



  .arrival {
    display: flex;
    align-items: center;
    gap: 16px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      padding: 20px 0;
      position: relative;

      &:before,
      &:after {
        content: "";
        z-index: 3;
        position: absolute;
        width: calc(100% - 32px);
        transform: translateX(-50%);
        top: 0px;
        left: 50%;
        background: $blue-400;
        opacity: 0.1;
        display: block;
        height: 1px;
      }

      &:after {
        top: 100%;
      }

    }

    .arrival-date {
      display: flex;
      align-items: center;
      gap: 16px;
      height: 100%;
      padding: 0px 60px;
      color: $grey-400;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.641px;
    }

    .arrow-date {
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: $grey-200;

      @include media-breakpoint-down(md) {
        transform: rotate(-90deg);
      }
    }

    .departure-date {
      display: flex;
      align-items: center;
      gap: 16px;
      height: 100%;
      padding: 0px 60px;
      color: $grey-200;
      font-size: 22px;
      font-weight: 500;
      line-height: 27.791px;
      letter-spacing: 0.641px;
    }
  }

  .accommodation {
    border-right: 1px solid $grey-200;

    .select {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 114px;
      padding: 0px 60px 0px 30px;
      position: relative;

      @include media-breakpoint-down(md) {
        justify-content: center;
      }

      .label {
        display: flex;
        align-items: center;
        gap: 60px;
        cursor: pointer;

        .arrow {
          display: flex;
          align-items: center;
          gap: 22px;
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
        }

        .text {
          font-size: 22px;
          font-weight: 500;
          line-height: 28px;
        }
      }

      .drop-down {
        display: none;
        border-radius: 5px;
        background: $white;
        width: 416px;
        flex-shrink: 0;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 999;
        box-shadow: $box-shadow;
        margin-top: 10px;
        padding: 25px;
        padding-top: 50px;

        @include media-breakpoint-down(md) {
          width: 100%;
        }

        &:before {
          content: "";
          z-index: 3;
          position: absolute;
          transform: translateX(-50%);
          top: -10px;
          left: 50%;
          border-color: transparent transparent #fff;
          border-style: solid;
          border-width: 0 10px 10px;
        }

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
        }

        .options {
          display: flex;
          flex-direction: column;
          gap: 40px;

          .seniors {
            display: flex;
            justify-content: space-between;
          }

          .adults {
            display: flex;
            justify-content: space-between;
          }

          .children {
            display: flex;
            justify-content: space-between;
          }

          .pets {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .submit {
    button {
      display: flex;
      width: 207px;
      height: 60px;
      padding: 10px 40px;
      justify-content: center;
      align-items: center;
      gap: 13.335px;
      flex-shrink: 0;
      border-radius: 70px 0px 0px 70px;
      background: $pink-400;
      color: $white;
      font-size: 22px;
      font-weight: 600;
      outline: none;
      border: 0px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        border-radius: 0px 0px 12px 12px;
        height: 70px;
      }
    }
  }
}