@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";


.open-floating-form-modal {
  position: fixed;
  top: 80%;
  left: 0;
  transform: translate(-40%, -50%) rotate(270deg);
  cursor: pointer;
  background-color: #ff4380;
  z-index: 8;
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  color: white;
  border-radius: 0 0 15px 15px;
  flex-direction: row;
  font-size: 18px;
  font-weight: 600;
  opacity: 1;
  pointer-events: painted;
  transition: 500ms;

  svg {
    transform: rotate(90deg);
  }

  &.active {
    opacity: 0;
    pointer-events: none;
    transform: translate(-400%, -50%) rotate(270deg);
  }
}

.modal-floating-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  background: #000000b6;
  z-index: 10;
  opacity: 0;
  pointer-events: none;
  transition: 200ms;
  padding: 40px 0;
  overflow-y: auto;

  .wpcf7-response-output{
    margin-top: 10px;
    border: none !important;
  }

  &.active {
    opacity: 1;
    pointer-events: painted;
    z-index: 10;

    .floating_form {
      transform: scale(1) !important;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .floating_form {
    transform: scale(0.6);
    height: auto;
    width: 90%;
    max-width: 580px;
    background: $white;
    padding: 20px 40px;
    border-radius: 9px;
    position: relative;
    transition: 200ms;
    margin: auto;


    .close-floating_form {
      position: absolute;
      left: 10px;
      top: 10px;
      cursor: pointer;
    }

    .form-row {
      display: flex;
      width: 100%;
      gap: 16px;
      margin-bottom: 20px;
      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      &.form-header {
        flex-direction: column;
        gap: 12px;

        h3 {
          font-size: 24px;
          font-weight: 700;
          color: $grey-400;
        }

        p {
          font-size: 18px;
          color: $grey-400;
        }
      }

      label {
        flex-basis: 50%;
        font-size: 16px;
        line-height: 2em;
        color: #515358;
      }

      input {
        padding: 18px 16px;
        width: 100%;
        border-radius: 5px;
        border: 1px solid $grey-320;
        color: #51535866;
        font-size: 16px;
      }
    }

    button[type="submit"] {
      background: $green-400;
      border: none;
      width: 100%;
      text-align: center;
      height: 64px;
      border-radius: 64px;
      color: $white;

      &.block {
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    @include media-breakpoint-down(lg) {
      padding: 24px 20px;
    }
  }
}