.checkboxes-rectangle {
  .checkboxes-horizontal {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 18px;
  }

  .checkboxes-vertical {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 18px;
  }
}
