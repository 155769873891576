@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-template-check-out-page {
  .container-md {
    .page-head {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 40px;


      .buttons {
        width: 350px;
        @include media-breakpoint-down(lg) {
          display: none;
        }

        .button {
          margin-bottom: 14px;

          a {
            font-size: 17px;
            width: 100%;
          }
        }
      }

      .title {
        color: #404040;
      }
    }

    .check-out {
      display: flex;
      justify-content: space-between;
      gap: 40px;

      @include media-breakpoint-down(lg) {
        flex-direction: column;
      }

      .check-out-sidebar {
        .btn-white {
          font-size: 17px;
          margin-bottom: 14px;
          width: 100%;
        }
      }


      .check-out-info {
        width: calc(100% - 390px);
        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        .steps {
          padding: 11px;
          background: #fff;
          box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
          border-radius: 8px;
          display: flex;
          gap: 10px;
          margin-bottom: 40px;
          margin-top: 40px;
          justify-content: space-around;
          align-items: center;
          @include media-breakpoint-down(lg) {
            justify-content: flex-start;
            margin-top: 0;
          }

          .fa {
            @include media-breakpoint-down(lg) {
              display: none;
            }
          }

          .step {
            display: flex;
            gap: 20px;
            justify-content: center;

            .title {
              color: #7D7D7D;
              font-size: 16px;
              font-weight: 500;
              align-self: center;
            }

            &:not(.active) {
              .title {
                @include media-breakpoint-down(lg) {
                  display: none;
                }
              }
            }

            .number {
              font-size: 18px;
              color: #7D7D7D;
              font-weight: 900;
              padding: 8px 17px;
              background-color: #DAE8DA;
              border-radius: 9px;
            }
          }


          .active {
            background-color: #045E51;
            border-radius: 9px;
            gap: 15px;
            padding: 8px 15px;

            .title {
              color: #FFFFFF;
              font-weight: 600;
            }

            .number {
              font-size: 18px;
              color: #FFFFFF;
              padding: 0;
              background-color: transparent;
            }
          }
        }

        .messages {
          margin-bottom: 40px;

          .message {
            display: flex;
            gap: 15px;
            margin-bottom: 15px;

            img {
              max-height: 20px;
              align-self: center;
            }

            .text {
              align-self: center;
              color: #404040;
            }
          }
        }

        .check-out-main {

          .customer-info{
            .nice-select{
              .list{
                overflow-y: auto;
              }
            }
          }

          .check-out-results {
            .ordering-info {
              max-width: 400px;

              .reference-number {
                display: flex;
                justify-content: space-between;
                background-color: #F3F5F5;
                border-radius: 8px;
                padding: 17px;
                color: #404040;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 40px;
              }

              .order-remark {
                margin-bottom: 40px;
              }
            }
          }

          .form-section {
            display: none;

            &.current {
              display: block;
            }
          }

          .representative-notes {
            padding: 20px 20px 60px 20px;
            background: #fff;
            box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
            border-radius: 8px;
            margin-bottom: 50px;
            border-right: 7px solid #045E51;

            h3 {
              font-size: 24px;
              font-weight: 700;
              color: #045E51;
              line-height: 32px;
              margin-bottom: 20px;
            }

            .representative-notes-input {
              width: 90%;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 30px;
              margin-bottom: 20px;
              @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr;
                width: 100%;
                margin-bottom: 0;
              }

              label {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 16px;
                color: $grey-370;

                input {
                  width: 100%;
                  border-radius: 5px;
                  border: 1px solid #A4A4A4;
                  padding: 18px 16px;
                  font-size: 16px;
                }
              }
            }

            .representative-notes-textarea {
              width: 90%;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 30px;
              @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr;
                width: 100%;
              }

              label {
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 16px;
                color: $grey-370;

                textarea {
                  width: 100%;
                  border-radius: 5px;
                  min-height: 100px;
                  border: 1px solid #A4A4A4;
                  padding: 18px 16px;
                }
              }
            }
          }

          .location-preferences {

            .nice-select.open .list {
              max-height: 40dvh;
              overflow-y: auto;

              &::-webkit-scrollbar {
                width: 8px;
              }

              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
              }

              &::-webkit-scrollbar-thumb {
                background-color: #abb8c3;
                outline: 1px solid slategrey;
                border-radius: 2px;
              }
            }

            padding: 20px 20px 60px 20px;
            background: #fff;
            box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
            border-radius: 8px;
            margin-bottom: 50px;
            border-right: 7px solid #045E51;

            .head {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 20px;

              .title {
                font-size: 24px;
                font-weight: 700;
                color: #045E51;
                line-height: 32px;
                margin-bottom: 5px;
              }

              .text {
                color: #404040;
              }

              .button {
                padding: 36px 20px;
                border-radius: 20px;
                background-color: rgba(217, 217, 217, 0.25);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;

                @include media-breakpoint-down(lg) {
                  display: none;
                }

                a {
                  font-size: 16px;
                  font-weight: 500;
                  color: #4E944F;
                  line-height: 20px;
                  padding: 2px 18px;
                  border: 1px solid #fff;
                  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

                  &:hover {
                    border: 1px solid #4E944F;
                  }
                }
              }
            }

            .locations {
              display: grid;
              grid-template-rows: 1fr 1fr;
              grid-template-columns: 1fr 1fr;
              gap: 40px;
              height: 100%;

              @include media-breakpoint-down(xl) {
                grid-template-rows: 1fr;
                grid-template-columns: 1fr;
                gap: 20px;
              }

              .location {
                display: flex;
                align-items: center;
                gap: 20px;

                .label {
                  font-size: 20px;
                  color: #404040;
                  @include media-breakpoint-down(lg) {
                    white-space: nowrap;
                  }
                }

                .select {
                  .chosen-container {
                    width: 250px !important;

                    .chosen-single {
                      border-radius: 70px !important;
                      border: none;
                      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
                      font-size: 16px;

                      @include media-breakpoint-down(lg) {
                        border: 1px solid #A4A4A4;
                        box-shadow: none;
                        border-radius: 5px !important;
                      }
                    }

                    .chosen-results {
                      max-height: 220px;
                    }
                  }
                }
              }
            }
          }

          .button.mobile {
            display: none;

            @include media-breakpoint-down(lg) {
              display: flex;
              justify-content: center;
              padding-top: 40px;
            }

            a {
              border-radius: 70px !important;
              border: none;
              box-shadow: 0 0 15px 0 rgba(0, 0, 0, .15);
              font-size: 16px;
              min-width: 200px;
              max-width: 300px;
              width: 100%;
              color: #4E944F;
            }
          }

          .services {
            padding: 20px 20px 50px 20px;
            background: #fff;
            box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
            border-radius: 8px;
            margin-bottom: 50px;
            border-right: 7px solid #045E51;

            .services-title {
              font-size: 24px;
              font-weight: 700;
              margin-bottom: 10px;
              color: #045E51;
            }

            .services-description {
              margin-bottom: 45px;
              color: #404040;
            }

            .service {
              background-color: #F3F5F5;
              border-radius: 8px;
              margin-bottom: 40px;

              &:last-child {
                margin-bottom: 0;
              }

              .head {
                background-color: #fff;
                border-radius: 10px 10px 0 0;
                box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
                padding: 20px 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;

                i {
                  font-size: 28px;
                  color: #045E51;
                  transition: all 0.3s;
                }

                &.opened {
                  i {
                    transform: rotate(180deg);
                  }
                }

                .description {
                  display: flex;
                  align-items: center;
                  gap: 20px;

                  .text {
                    font-size: 24px;
                    font-weight: 600;
                    color: #045E51;
                  }
                }
              }

              .tabs {
                position: relative;
                display: none;
                padding: 0 25px 15px;

                &.opened {
                  display: block;
                }

                .tabs-nav {
                  display: flex;
                  gap: 0;
                  margin-bottom: 30px;
                  z-index: 1;
                  position: relative;
                  overflow: auto;
                  border-radius: 0 0 20px 20px;
                  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 80%, rgba(208, 208, 208, 0.5) 100%);
                  border: 1px solid #D0D0D0;
                  border-top: none;

                  .tab {
                    margin-left: 50px;
                    color: $grey-400;
                    text-align: center;
                    font-size: 18px;
                    font-weight: 400;
                    cursor: pointer;
                    display: flex;
                    padding: 18px 10px;
                    align-items: center;
                    gap: 10px;
                    border-bottom: 6px solid transparent;
                    padding-right: 25px;
                    position: relative;

                    &.active {
                      border-bottom: 6px solid $green-400;
                      color: $green-400;
                      font-weight: 700;
                    }
                  }
                }

                .tabs-select {
                  display: none;
                }

                .tabs-contents {
                  .tab-content {
                    display: none;

                    &.active {
                      display: block;
                    }

                    .service-option {
                      background: #fff;
                      border-radius: 20px;
                      border: 1px solid #D0D0D0;
                      margin-bottom: 15px;
                      overflow: hidden;
                      height: 100px;
                      max-height: 100px;

                      & > a:first-child {
                        height: 100%;
                      }

                      .description {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        max-height: 100px;
                        overflow: hidden;
                        cursor: pointer;

                        @include media-breakpoint-down(lg) {
                          gap: 3%;
                        }

                        .title-img {
                          display: flex;
                          gap: 40px;
                          height: 100%;

                          @include media-breakpoint-down(lg) {
                            min-width: 70px;
                            max-width: 70px;
                            width: 30%;
                          }

                          img {
                            width: 156px;
                            height: 100%;
                            object-fit: cover;


                            @include media-breakpoint-down(lg) {
                              object-fit: cover;
                              object-position: center center;
                              width: 100%;
                            }
                          }

                          .subtitle {
                            align-self: center;
                            font-size: 18px;
                            font-weight: 700;
                            color: #025F52;
                            @include media-breakpoint-down(lg) {
                              display: none;
                            }
                          }
                        }

                        .toggle-and-arrow {
                          display: flex;
                          padding-left: 35px;
                          gap: 40px;

                          &.mobile {
                            display: none;
                            @include media-breakpoint-down(lg) {
                              display: flex;
                              flex-direction: column;
                              flex-grow: 1;
                              gap: 20px;

                              .top {
                                display: flex;
                                flex-direction: row-reverse;
                                align-items: center;

                                .subtitle {
                                  flex-grow: 1;
                                  align-self: center;
                                  font-size: 18px;
                                  font-weight: 700;
                                  color: #025f52;
                                }
                              }
                            }
                          }

                          &:not(.mobile) {
                            @include media-breakpoint-down(lg) {
                              display: none;
                            }
                          }

                          .text {
                            font-size: 18px;
                            color: #FF4380;
                            align-self: center;
                          }

                          i {
                            font-size: 28px;
                            color: #045E51;
                            transition: all 0.3s;
                          }
                        }

                        &.opened {
                          .toggle-and-arrow {
                            i {
                              transform: rotate(90deg);
                            }
                          }
                        }
                      }

                      .price-list {
                        display: none;

                        &.opened {
                          display: block;
                        }

                        .wrapper {
                          display: grid;
                          grid-template-rows: 1fr 1fr;
                          grid-template-columns: 1fr 1fr;
                          padding: 40px;
                          gap: 20px 30px;

                          .checkbox-wrapper {
                            display: flex;
                            align-items: center;

                            .marker {
                              display: flex;
                              gap: 40px;

                              .title {
                                color: #025F52;
                                font-size: 18px;
                                font-weight: 400;
                              }

                              .subtitle {
                                color: #FF4380;
                                font-size: 18px;
                                font-weight: 500;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .ordering-data-title {
            color: #045E51;
            margin-bottom: 20px;
          }

          .customer-info {
            border-radius: 0 0 8px 8px;
            background-color: #F3F5F5;
            box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
            margin-bottom: 30px;

            .input.select .nice-select {
              @include media-breakpoint-down(lg) {
                width: 100%;
                direction: rtl;
                display: flex;
                padding-inline: 18px 30px;
              }

              &::after {
                left: 12px;
                right: auto;
              }

              .option {
                text-wrap: wrap;
                text-align: right;
                direction: rtl;
              }

              .list {
                right: 0;
                left: auto;
              }
            }

            .title {
              padding: 17px 30px;
              color: #404040;
            }

            .info-wrapper {
              background-color: white;
              padding: 30px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              gap: 45px;
              margin-bottom: 45px;

              @include media-breakpoint-down(lg) {
                grid-template-columns: 1fr;
                padding: 10px;
              }

              .postal-code-wrap-mobile {
                display: none;
                @include media-breakpoint-down(lg) {
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 16px;
                }
              }

              .input {
                &.person1-house, &.person1-postal-code {
                  @include media-breakpoint-down(lg) {
                    display: none;
                  }
                }

                &.name {
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  gap: 30px;

                  @include media-breakpoint-down(lg) {
                    grid-template-columns: 1fr;
                  }
                }

                input[type="text"] {
                  border-radius: 5px;
                  border: 1px solid #A4A4A4;

                  &.error {
                    border-color: red;
                  }
                }

                .parsley-errors-list {
                  margin: 4px 0;
                  color: red;
                  font-weight: 700;
                  list-style-type: none;
                  padding: 0;
                }

                &.select {
                  .chosen-container {
                    width: 100% !important;

                    .chosen-results {
                      max-height: 350px;
                    }
                  }
                }
              }
            }

            .total-info-wrapper {
              background-color: white;
              padding: 30px;
              margin-bottom: 45px;

              .total-price {
                display: flex;
                align-items: center;
                width: 50%;
                justify-content: space-between;
                margin-bottom: 40px;
                background-color: #F3F5F5;
                border-radius: 8px;
                padding-left: 17px;
                font-size: 20px;

                .price {
                  color: #404040;
                  font-weight: 700;
                }
              }

              .btn-pink {
                width: 50%;
              }
            }
          }

          .checkboxes {
            margin-bottom: 10px;

            .checkbox-wrapper {
              display: flex;
              margin-bottom: 20px;
              align-items: flex-start;
              flex-direction: column;

              .marker {
                display: flex;
                gap: 40px;

                .title {
                  color: #025F52;
                  font-size: 18px;
                  font-weight: 400;
                }
              }
            }
          }
        }

        .form-navigation {
          display: flex;
          justify-content: space-between;

          @include media-breakpoint-down(lg) {
            display: none;
          }
        }
      }

      .check-out-sidebar {
        width: 350px;
        padding: 27px;
        background: #fff;
        box-shadow: 0 0 20px 0 rgba(20, 6, 60, .15);
        border-radius: 8px;
        height: fit-content;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }

        .agent_commission_row {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;

          &:first-child{
            margin-top: 20px;
          }

          .price {
            font-size: 20px;
            font-weight: 700;
            color: #404040;
          }

          .title {
            font-size: 20px;
            font-weight: 400;
            color: #404040;
          }

          &.agent_commission_was{
            margin-top: 20px;
            .price {
              text-decoration: line-through;
            }
          }
          &.agent_commission_price{
            .price {
              color: #045E51;
            }
          }
        }

        .sidebar-title {
          text-align: center;
          margin-bottom: 20px;
        }

        .sidebar-img {
          border-radius: 8px;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .info-block {
          padding: 20px 0;
          border-bottom: 1px solid #E0DFDF;

          &.tax {
            display: none;
          }

          .info-title {
            font-size: 14px;
            font-weight: 700;
            color: #404040;
            margin-bottom: 12px;
          }

          .info-item {
            &.couponPrice {
              display: none;

              .price {
                color: #045E51;
              }
            }

            display: flex;
            justify-content: space-between;
            gap: 30px;
            margin-bottom: 8px;

            &:last-child {
              margin-bottom: 0;
            }

            .title {
              font-size: 14px;
              color: #404040;
            }

            .value {
              font-size: 14px;
              font-weight: 700;
              color: #404040;
            }

            .price {
              font-size: 14px;
              font-weight: 700;
              color: #404040;
              display: flex;
              gap: 5px;
            }

            .discount {
              color: #045E51;
            }

            .old-price {
              text-decoration: line-through;
            }
          }
        }

        .total {
          margin-top: 20px;
          margin-bottom: 26px;
          display: flex;
          justify-content: space-between;
          gap: 30px;

          &.agent {
            margin-top: 0;
          }

          .title {
            font-size: 20px;
            color: #404040;
            line-height: 26px;
          }

          .price {
            font-size: 20px;
            color: #404040;
            line-height: 26px;
            font-weight: 700;
            display: flex;
            gap: 5px;
          }
        }

        .submit {
          button {
            font-size: 16px;
            width: 100%;
            margin-bottom: 22px;
          }
        }

        .description {
          .subtitle {
            font-family: Inter, sans-serif;
            font-size: 14px;
            text-align: left;
            font-weight: 200;
            margin-bottom: 14px;
          }

          .text {
            font-family: Inter, sans-serif;
            font-size: 14px;
            text-align: left;
            font-weight: 200;
          }
        }

        .coupon-club-wrap {
          display: flex;
          flex-direction: column;
          gap: 15px;
          padding-bottom: 26px;

          .clubs-wrapper {
            .nice-select {
              width: 100%;
              margin-bottom: 10px;

              .list {
                width: 100%;
              }
            }
          }

          .coupon {
            display: flex;
            justify-content: space-between;
            gap: 15px;
            pointer-events: none;

            input {
              opacity: .5;
            }


            .button {
              a {
                font-size: 16px;
                padding: 14px 25px;
              }
            }

            &.active {
              pointer-events: painted;

              input {
                opacity: 1;
              }
            }
          }
        }

        .coupon-icon-section {
          background-color: $greenDAE8DA;
          border-radius: 3px;
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: fit-content;
          gap: 10px;

          &.hide {
            display: none;
          }

          .remove-coupon {
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }
  }

  .mfp-container {
    @include media-breakpoint-down(lg) {
      padding-left: 0;
      padding-right: 0;
    }

    .mfp-content {
      @include media-breakpoint-down(lg) {
        vertical-align: bottom;
      }
    }
  }


}