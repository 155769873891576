@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.overview-tab {
  .searchbox {
    margin-bottom: 20px;
    padding-bottom: 40px;
    background: rgba(250, 251, 251, 1);
    @include media-breakpoint-down(md) {
      padding: 0 15px;
    }

    .heading {
      h3 {
        font-size: 20px;
        font-weight: 700;
        line-height: 1.3;
        letter-spacing: .03em;
        text-align: center;
        margin-bottom: 10px;
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }

      & > div {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3;
        letter-spacing: .03em;
        text-align: center;
        color: $grey-400;

        p, a, li {
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          letter-spacing: .03em;
          text-align: center;
          color: $grey-400;
        }
      }

    }
  }

  .park {
    .container-md {
      display: flex;
      gap: 135px;
      margin-bottom: 10px;

      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        gap: 12px;
      }
    }

    .thumbnails {
      margin-top: 40px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 10px;
      flex-shrink: 0;
      flex: 1.1;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: row-reverse;
      }

      .image {
        &:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 1;

          grid-row-end: 3;
          grid-column-end: 3;

          @include media-breakpoint-down(md) {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            height: 430px;
          }
        }

        &:nth-child(2) {
          grid-row-start: 3;
          grid-column-start: 3;

          grid-row-end: 3;
          grid-column-end: 3;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            height: 211px;
          }
        }

        &:nth-child(3) {
          grid-row-start: 1;
          grid-column-start: 3;

          grid-row-end: 2;
          grid-column-end: 3;
          mask-image: url(../images/masks/mask-image-burger.svg);
          mask-size: 100% 100%;
          mask-repeat: no-repeat;

          img {
            width: 100%;
            height: 209px;
          }
        }
      }

      .image {
        &:nth-child(2) {
          grid-row-start: 2;
          grid-column-start: 3;

          grid-row-end: 3;
          grid-column-end: 3;

          img {
            border-radius: 0px 0px 0px 10px;
            object-fit: cover;
          }
        }
      }
    }

    .text {
      flex: 1;

      .sub-title {
        color: $grey-300;
        text-align: right;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;
        margin-bottom: 8px;

        @include media-breakpoint-down(md) {
          width: 100%;
          font-size: 14px;
        }
      }

      .title {
        width: 663px;
        color: $grey-400;
        font-size: 35px;
        font-weight: 700;
        line-height: 40px;
        margin-bottom: 8px;

        @include media-breakpoint-down(md) {
          width: 100%;
          font-size: 24px;
        }
      }

      .description {
        color: $grey-400;
        font-style: normal;
        font-weight: 500;
        line-height: 23.4px;
        letter-spacing: 0.54px;
        margin-bottom: 20px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 130%;
          letter-spacing: 0.48px;
        }
      }
    }
  }

  .advice {
    .container-md {
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 5px;
        justify-content: center;
      }
    }

    .advice-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      width: 100%;
      height: 100%;
      position: relative;

      @include media-breakpoint-down(md) {
        width: calc(50% - 10px);
        padding: 0;
      }

      &:hover {
        background: $grey-100;

        .title {
          color: $green-500;
        }
      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      &:nth-child(6n) {
        &:after {
          display: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        display: block;
        width: 0;
        border-left: 1px solid #d2d3d3;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      img {
        margin-bottom: 16px;
      }

      .title {
        color: $grey-400;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
        letter-spacing: 0.54px;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }

      .description {
        color: $grey-400;
        text-align: center;
        font-weight: 500;
        line-height: 23.4px;
        letter-spacing: 0.54px;

        p {
          font-size: 18px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .accommodations {
    margin: 86px 0 40px 0;
    background-color: $white;

    .title {
      margin-bottom: 13px;
      @include media-breakpoint-down(md) {
        font-size: 24px;
      }
    }

    .description {
      margin-bottom: 37px;
      font-size: 18px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0.03em;
      text-align: right;
      @include media-breakpoint-down(md) {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.03em;
        text-align: right;
        color: rgba(64, 64, 64, 1);
      }
    }

    .tabs {
      position: relative;

      .tabs-nav {
        display: flex;
        gap: 0px;
        margin-bottom: 10px;
        z-index: 1;
        position: relative;
        background-color: $grey-100;
        padding: 0px 50px;
        overflow: auto;

        @include media-breakpoint-down(md) {
          position: absolute;
          left: 0;
          width: 100%;
          z-index: 2;
          display: none;
          top: 44px;
        }

        @include media-breakpoint-down(sm) {
        }

        .tab {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 15px 5px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;
          padding-right: 40px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            padding: 10px 40px 10px 10px;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      .tabs-select {
        display: none;

        @include media-breakpoint-down(md) {
          border-radius: 8px;
          background: #f3f5f5;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          &:after {
            content: "\f078";
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            color: $green-400;
            transition: all 0.3s;
          }

          &.open {
            &:after {
              transform: rotate(180deg);
            }
          }

          .tab {
            display: flex;
            align-items: center;
            gap: 0 12px;

            span {
              color: $green-400;
              font-size: 14px;
              font-weight: 700;
            }

            img {
              height: 24px;
              width: auto;
            }
          }
        }
      }

      .tabs-contents {
        .tab-content {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    .slider {
      margin-bottom: 0px;

      .post {
      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
          padding-left: 80px;
        }
      }

      .slick-slide {
        margin: 0 15px;
      }

      .slick-track {
        padding: 30px 0px;
        display: flex !important;
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        left: -70px;

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        right: -70px;

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      a {
        width: 278px;
        height: 60px;
        transform: translateY(-10px);
        @include media-breakpoint-down(md) {
          transform: none;
        }
      }
    }
  }

  .do-not-miss {
    margin-bottom: 77px;

    .title {
      margin-bottom: 18px;

      h2 {
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }
    }

    .list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 40px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      .item {
        border-radius: 8px;
        background: $white;
        box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);

        .thumbnail {
          img {
            border-radius: 8px;
            width: 100%;
            height: 153px;
            object-fit: cover;

            @include media-breakpoint-down(md) {
              border-radius: 8px 8px 0 0;
            }
          }
        }

        .text {
          padding: 13px 18px;

          .title {
            color: $grey-400;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.6px;
            margin-bottom: 6px;
          }

          .description {
            color: $grey-400;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.03em;
            text-align: right;

          }

          .read-more {
            a {
              color: $green-300;
              font-size: 16px;
              font-weight: 700;
              line-height: 20.8px;
              letter-spacing: 0.48px;
            }
          }
        }
      }
    }
  }

  .our-best-offers {
    margin-bottom: 84px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .heading {
      margin-bottom: 16px;
    }

    .gallery {
      overflow: auto;
      display: grid;
      grid-template-columns: 2fr 357px 357px;
      gap: 40px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      .image {
        padding: 20px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(md) {
          padding: 0px;
          border-radius: 8px;
          box-shadow: $box-shadow-small;
          background-image: none;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.drop {
          display: flex;
          justify-content: center;
          align-items: center;

          .figure {
            background-color: $yellow-400;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50% 0% 50% 50%;
            width: 268px;
            height: 268px;

            @include media-breakpoint-down(md) {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              padding: 0px;
              background-color: $white;
              overflow: hidden;
            }

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;

              @include media-breakpoint-down(md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                text-align: initial;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  background: $yellow-400;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 15px;
                  width: 100%;
                  text-align: center;
                }
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  padding: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18.2px;
                  letter-spacing: 0.42px;
                }
              }
            }
          }
        }

        &.semicircle {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .figure {
            background-color: $violet-400;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50% 50% 0% 0%;
            width: 268px;
            height: 268px;

            @include media-breakpoint-down(md) {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              padding: 0px;
              background-color: $white;
              overflow: hidden;
            }

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;

              @include media-breakpoint-down(md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                text-align: initial;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  background: $violet-400;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 15px;
                  width: 100%;
                  text-align: center;
                }
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  padding: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18.2px;
                  letter-spacing: 0.42px;
                }
              }
            }
          }
        }

        &.burger {
          display: flex;
          justify-content: flex-end;
          align-items: center;

          .figure {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 30px;
            border-radius: 50% 50% 0% 0%;
            width: 268px;
            height: 268px;

            @include media-breakpoint-down(md) {
              width: 100%;
              height: 100%;
              border-radius: 8px;
              padding: 0px;
              background-color: $white;
              overflow: hidden;
            }

            &::before {
              top: 0px;
              left: 0px;
              position: absolute;
              content: "";
              background-color: $green-100;
              height: 51%;
              width: 100%;
              border-radius: 100px;

              @include media-breakpoint-down(md) {
                display: none;
              }
            }

            &::after {
              bottom: 0px;
              left: 0px;
              position: absolute;
              content: "";
              background-color: $green-100;
              height: 51%;
              width: 100%;
              border-radius: 100px;

              @include media-breakpoint-down(md) {
                display: none;
              }
            }

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;
              position: relative;
              z-index: 3;

              @include media-breakpoint-down(md) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                text-align: initial;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  background: $green-100;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 15px;
                  width: 100%;
                  text-align: center;
                }
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;

                @include media-breakpoint-down(md) {
                  margin-bottom: 0px;
                  padding: 15px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  text-align: center;
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 18.2px;
                  letter-spacing: 0.42px;
                }
              }
            }
          }
        }
      }
    }

    .button {
      a {
        width: 278px;
        height: 60px;
      }
    }
  }

  .experience-the-joy {
    padding: 70px 0px;
    background-color: $green-400;

    @include media-breakpoint-down(md) {
      padding: 32px 0px;
    }

    .heading {
      margin-bottom: 15px;
      color: $white;
      @include media-breakpoint-down(md) {
        h2 {
          font-size: 24px;
        }
      }
    }

    .description {
      color: $white;
      margin-bottom: 30px;

      &:empty {
        display: none;
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 266px 266px;
      gap: 15px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 24px 0;
        margin: 0;
      }

      .image {
        position: relative;

        h5 {
          position: absolute;
          left: 50%;
          bottom: 35px;
          transform: translateX(-50%);
          color: $white;
          text-align: center;
          text-shadow: 0px 3.927px 29.453px $black;
          font-size: 25px;
          font-weight: 700;
          max-width: 150px;
          @include media-breakpoint-down(md) {
            bottom: 15px;
          }
        }

        &:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 4;

          grid-row-end: 3;
          grid-column-end: 5;

          @include media-breakpoint-down(md) {
            //display: none;
          }

          img {
            border-radius: 10px;
            @include media-breakpoint-down(md) {
              object-position: top center;
              height: 200px;
            }
          }
        }

        &:nth-child(2) {
          grid-row-start: 1;
          grid-column-start: 3;

          grid-row-end: 3;
          grid-column-end: 4;

          @include media-breakpoint-down(md) {
            order: 2;
          }

          img {
            border-radius: 200px 200px 10px 10px;

            @include media-breakpoint-down(md) {
              height: 337px;
            }
          }
        }

        &:nth-child(3) {
          grid-row-start: 1;
          grid-column-start: 1;

          grid-row-end: 2;
          grid-column-end: 3;

          @include media-breakpoint-down(md) {
            order: 5;
          }

          h5 {
            bottom: 15px;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 200px;
            }
          }
        }

        &:nth-child(4) {
          grid-row-start: 2;
          grid-column-start: 2;
          grid-row-end: 3;
          grid-column-end: 3;
          mask-image: url(../images/masks/mask-image-burger.svg);
          mask-size: 100% 100%;
          mask-repeat: no-repeat;

          @include media-breakpoint-down(md) {
            order: 3;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 266px;
            }
          }
        }

        &:nth-child(5) {
          grid-row-start: 2;
          grid-column-start: 1;

          grid-row-end: 3;
          grid-column-end: 2;

          @include media-breakpoint-down(md) {
            order: 4;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 200px;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }
      }
    }
  }

  .restaurants-and-shops {
    padding: 65px 0px;

    @include media-breakpoint-down(md) {
      padding: 40px 0px;
    }

    .text {
      margin-bottom: 20px;

      .title {
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          h2 {
            font-size: 24px;
          }
        }
      }

      .desription {
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.03em;
        text-align: right;

        @include media-breakpoint-down(md) {
          font-size: 16px;
        }
      }
    }

    .posts {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 50px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
      }

      .post {
        position: relative;
        @include media-breakpoint-down(md) {
          width: 100%;
        }

        .image {
          position: relative;
          height: 100%;

          img {
            border-radius: 10px;
            width: 100%;
            object-fit: cover;
            height: 100%;

            @include media-breakpoint-down(md) {
              height: 170px;
            }
          }

          @include media-breakpoint-down(md) {
            width: 100%;
            &:before {
              content: '';
              display: block;
              width: 100%;
              height: 50%;
              background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
              position: absolute;
              left: 0;
              bottom: 0;
            }
          }

          h5 {
            position: absolute;
            left: 50%;
            bottom: 27px;
            transform: translateX(-50%);
            color: $white;
            text-align: center;
            text-shadow: 0px 3.927px 29.453px $black;
            font-size: 25px;
            font-weight: 700;

            @include media-breakpoint-down(md) {
              font-size: 18px;
              bottom: 15px;
            }
          }
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      a {
        font-size: 20px;
        padding: 17px 30px;
        height: 60px;
      }
    }
  }

  .practical-information {
    margin-bottom: 70px;

    .heading {
      h2 {
        color: $grey-400;
        text-align: center;
        font-size: 35px;
        font-weight: 700;
        margin-bottom: 75px;
        position: relative;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          margin-bottom: 40px;
        }

        &::after {
          content: "";
          width: 34px;
          height: 1px;
          background-color: $grey-300;
          position: absolute;
          bottom: -37px;
          left: 50%;
          transform: translateX(-50%);

          @include media-breakpoint-down(md) {
            bottom: -20px;
          }
        }
      }
    }

    .map {
      width: 100%;
      height: 505px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      margin-bottom: 55px;

      @include media-breakpoint-down(md) {
        height: 400px;
        background-position: 21% 0%;
        img {
          height: 100%;
          object-fit: cover;
          object-position: 21% 0%;
        }
      }

      .contacts {
        width: 210px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 10%;

        .title {
          color: $grey-400;
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 28.6px;
          letter-spacing: 0.66px;
          margin-bottom: 18px;
        }

        .address {
          color: $grey-400;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.8px;
          letter-spacing: 0.48px;
          margin-bottom: 30px;

          @include media-breakpoint-down(md) {
            background: #ebf5f7;
          }
        }
      }

      & + .button {
        @include media-breakpoint-down(md) {
          display: none;
        }

        a {
          height: 60px;
        }
      }
    }

    .button {
      text-align: center;
    }
  }

  .our-certificates {
    margin-bottom: 178px;

    @include media-breakpoint-down(md) {
      padding: 0 10px;
      margin-bottom: 40px;
    }

    .heading {
      margin-bottom: 40px;

      h2 {
        color: $grey-400;
        font-size: 35px;
        font-weight: 700;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          text-align: center;
        }
      }
    }

    .list {
      border-radius: 8px;
      background: $white;
      box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
      display: grid;
      grid-template-columns: repeat(3, 1fr);

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .certificate-item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 26px 20px 26px 20px;
      width: 100%;
      position: relative;
      height: 184px;

      @include media-breakpoint-down(md) {
        padding: 10px;
        height: 100%;
      }

      &:hover {
        background: $grey-100;

        .title {
          color: $green-500;
        }
      }

      &:first-child {
        &:after {
          display: none;
        }
      }

      &:nth-child(6n) {
        &:after {
          display: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        display: block;
        width: 0;
        border-left: 1px solid #d2d3d3;
      }

      img {
        margin-bottom: 10px;
      }

      .title {
        color: $grey-400;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 23.4px;
        letter-spacing: 0.54px;
      }

      .description {
        color: $grey-400;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        line-height: 23.4px;
        letter-spacing: 0.54px;
      }
    }
  }

  .discover-other-parks {
    margin-bottom: 80px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .heading {
      margin-bottom: 40px;

      h2 {
        color: $grey-400;
        font-size: 35px;
        font-weight: 700;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          text-align: center;
        }
      }
    }

    .slider {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      .slide {
        overflow: hidden;
        height: auto;
        border-radius: 14px;
        background-color: $white;
        display: flex !important;
        flex-direction: column;
        box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);
        align-self: stretch;

        @include media-breakpoint-down(md) {
          margin-bottom: 12px;
        }

        .image {
          img {
            width: 100%;
            height: 206px;
            object-fit: cover;
          }
        }

        .text {
          padding: 16px 32px;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 12px;
          }

          .title {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 10px;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }

            a {
              color: $grey-400;
            }

            .status {
              padding: 8px;
              border-radius: 3px;
              background: $green-100;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 13px;
              letter-spacing: 0.3px;
            }
          }

          .description {
            margin-bottom: 8px;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey-400;

              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
          }

          .date {
            display: flex;
            gap: 10px;
            margin-bottom: 8px;
            color: $grey-400;
            font-size: 18px;
            line-height: 23.4px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          .cost {
            margin-left: 30px;

            .price {
              display: flex;
              gap: 5px;
              align-items: flex-end;

              .title {
                color: $grey-200;
                font-size: 15px;
                font-weight: 400;
                line-height: 22.5px;
                letter-spacing: -0.45px;
                margin: 0px;
              }

              .info {
                color: $grey-300;
                align-self: flex-start;
              }

              .was {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: $grey-400;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 17.357px;
                text-decoration: line-through;
              }

              .now {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: #e85481;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0.6px;
              }
            }
          }
        }

        .button {
          margin-top: auto;
        }
      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
          padding-left: 80px;
        }
      }

      .slick-slide {
        margin: 0 15px;
      }

      .slick-track {
        padding: 10px 0px;
        display: flex !important;
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        left: -80px;
        margin-top: -24px;

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          color: $green-400;
          font-weight: 400;
          font-size: 32px;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        right: -80px;
        margin-top: -24px;

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          color: $green-400;
          font-weight: 400;
          font-size: 32px;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
      }

      a {
        height: 60px;
        @include media-breakpoint-down(md) {
          padding: 17px 30px;
          font-size: 16px;
        }
      }
    }
  }

  .center-parcs-business {
    .heading {
      margin-bottom: 30px;

      h2 {
        @include media-breakpoint-down(md) {
          font-size: 24px;
        }
      }
    }

    .posts {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .post {
        display: flex;
        border-radius: 8px;
        background: $white;
        box-shadow: 0px 0px 13.34px 0px rgba(20, 6, 60, 0.15);
        overflow: hidden;

        @include media-breakpoint-down(md) {
          display: flex;
          flex-direction: column;
        }

        .thumbnail {
          width: 214px;
          flex-shrink: 0;
          position: relative;

          @include media-breakpoint-down(md) {
            height: 170px;
            width: 100%;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
          }
        }

        .text {
          padding: 35px 54px;
          display: flex;
          flex-direction: column;

          @include media-breakpoint-down(md) {
            width: 100%;
            padding: 16px 20px;
          }

          .title {
            color: $grey-400;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.6px;
            margin-bottom: 6px;
          }

          .description {
            color: $grey-300;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0.54px;
            margin-bottom: 6px;
          }

          .read-more {
            margin-top: auto;

            a {
              color: $green-300;
              font-size: 18px;
              font-weight: 700;
              line-height: 23.4px;
              letter-spacing: 0.54px;
            }
          }
        }
      }
    }
  }
}
