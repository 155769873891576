@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.modal-activities {
  display: grid;
  grid-template-columns: 2fr 1.3fr;
  gap: 30px;
  direction: rtl;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &.white-popup {
    position: relative;
    background: $white;
    padding: 22px;
    width: auto;
    max-width: 1130px;
    margin: 0 auto;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }


  .text {
    margin: 20px 0px;
    text-align: right;

    .title {
      margin-bottom: 14px;
    }

    .tags {
      margin-bottom: 14px;

      ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 14px;

        li {
          padding: 10px 20px;
          border-radius: 100px;
          background: $grey-100;
          direction: ltr;
        }
      }
    }

    .price {
      margin-bottom: 14px;
      display: flex;
      gap: 5px;
      align-items: flex-end;

      .title {
        color: $grey-400;
        font-size: 15px;
        font-weight: 400;
        line-height: 22.5px;
        letter-spacing: -0.45px;
        margin: 0px;
      }

      .now {
        display: flex;
        gap: 5px;
        direction: ltr;
        color: #e85481;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.6px;
      }

      .description {
        color: $grey-400;
      }
    }
  }

  .thumbnail {
    img {
      object-fit: cover;
      width: 100%;
      height: 100;
    }
  }
}

.mfp-close {
  left: 0;
  right: inherit;
  font-size: 30px;
}