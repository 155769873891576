$transition-all-linear: all 0.3s linear;
$transition-all-easy-in-out: all 0.3s linear;
$box-shadow: 0px 0px 40px 0px rgba(20, 6, 60, 0.15);
$box-shadow-small: 0px 0px 20px 0px rgba(20, 6, 60, 0.15);
$Inter: "Inter", sans-serif;
$Poppins: "Poppins", sans-serif;


$size-20: 20px;
$size-24: 24px;
$size-32: 32px;
$size-50: 50px;
