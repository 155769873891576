@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.eat-and-shop-tab {
  transform: translateY(-7px);
  @include media-breakpoint-down(md) {
    transform: none;
  }

  .text {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }

    .title {
      color: $grey-400;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.6px;
      margin-bottom: 8px;

      @include media-breakpoint-down(md) {
        text-align: center;
        font-size: 24px;
        line-height: 32px;
      }
    }

    .description {
      color: $grey-400;
      font-size: 16px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0.03em;
      text-align: right;
    }

    .main-description {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .eat-and-shop-types {
    margin: 70px 0 60px 0;

    .container-md {
      display: flex;
      gap: 0 50px;
      max-width: 739px;
      margin: 0px auto;

      @include media-breakpoint-down(md) {
        padding: 0 16px;
      }

      .type {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        flex: 1;

        @include media-breakpoint-down(md) {
          justify-content: normal;
        }

        .title {
          text-align: center;
          white-space: nowrap;
          margin-top: 19px;

          @include media-breakpoint-down(md) {
            white-space: normal;
            margin-top: 0;
          }
        }
      }
    }
  }

  .eat-and-shop {
    .container-md {
      @include media-breakpoint-down(md) {
        padding: 0 16px;
      }

      .category-title {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 40px;
        }

        h2 {
          color: $grey-400;
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0.6px;
        }
      }

      .category {
        margin-bottom: 100px;
        @include media-breakpoint-down(md) {
          margin-bottom: 40px;
        }

        .posts {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 40px 60px;
          margin-bottom: 30px;

          @include media-breakpoint-down(md) {
            grid-template-columns: none;
            gap: 22px;
            margin-bottom: 40px;
          }

          &:last-child {
            margin-bottom: 0;

            @include media-breakpoint-down(md) {
              margin-bottom: 60px;
            }
          }

          .post {
            border-radius: 20px;
            background: #FFF;
            box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
            overflow: hidden;

            .image {
              img {
                width: 100%;
                height: 182px;
                object-fit: cover;
              }
            }

            .text {
              padding: 20px 20px 33px 20px;
              margin: 0;

              @include media-breakpoint-down(md) {
                padding: 24px;
                margin-bottom: 0;
              }

              .title {
                color: $grey-400;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 15px;
                letter-spacing: 0.48px;
                margin-bottom: 12px;

                @include media-breakpoint-down(md) {
                  text-align: start;
                  margin-bottom: 16px;
                }
              }

              .description {
                color: $grey-400;
                font-size: 16px;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: 0.03em;
                text-align: right;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                  font-size: 16px;
                  font-weight: 300;
                }
              }
            }
          }
        }

        .load-more {
          @include media-breakpoint-down(md) {
            text-align: center;
          }

          button {
            height: 60px;
            width: 305px;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.03em;
            text-align: center;
            justify-content: space-between;
            padding-right: 50px;
            @include media-breakpoint-down(md) {
              width: 220px;
              padding: 0 15px;
            }

            &:after {
              content: "\f078";
              font-family: Font Awesome\ 6 Pro;
              font-weight: 300;
              color: #fff;
            }
          }
        }
      }
    }
  }
}