@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

#pdf-container {
  .check-out {
    @include media-breakpoint-down(lg) {
      flex-direction: row;
    }

    .button.agent-btn{
      display: none;
    }
    .check-out-sidebar {
      box-shadow: none;
      border-bottom: 1px solid black;
      @include media-breakpoint-down(lg) {
        width: 350px;
      }
    }

    .location-preferences, .services {
      box-shadow: none;
      border-bottom: 1px solid black;
    }

    .steps {
      box-shadow: none;
      border-bottom: 1px solid black;
      @include media-breakpoint-down(lg) {
        justify-content: space-around;
      }

      .title {
        @include media-breakpoint-down(lg) {
          display: block;
        }
      }

      .fa {
        @include media-breakpoint-down(lg) {
          display: block;
        }
      }
    }

    .button.mobile {
      @include media-breakpoint-down(lg) {
        display: none;
      }
    }

    .locations {
      @include media-breakpoint-down(xl) {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 40px;


      }
    }

    .head {
      .button {
        @include media-breakpoint-down(lg) {
          display: block;
        }
      }
    }

    .chosen-container {
      .chosen-single {
        box-shadow: none;
        border: 1px solid black;
        @include media-breakpoint-down(lg) {
          border-radius: 70px !important;
          border: 1px solid black;
        }
      }
    }

    .service {
      .head {
        box-shadow: none;
        border: 1px solid black;
      }
    }
  }

  #footer {
    display: none;
    .footer-top {
      & > .container-md {
        display: none;
      }
    }

    .navigation {
      @include media-breakpoint-down(xl) {
        grid-template-columns: repeat(6, 1fr);
      }
      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    .content {
      @include media-breakpoint-down(md) {
        padding: 40px 46px 40px 13px;
      }
    }

  }
}
