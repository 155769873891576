@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-default, .post-default {
  overflow-x: hidden;

  .banner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 480px;
  }

  .default-page-container {
    max-width: 1150px;
    margin: 50px auto 0;

    a {
      text-decoration: none;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      color: #515358;

      &:hover, &:focus, &:active {
        color: $green-350;
      }
    }

    .page-breadcrumbs {
      text-align: center;
      margin-bottom: 16px;

      .yoast-breadcrumbs {
        font-size: 14px;
        line-height: 19px;

        a {
          font-size: 14px;
          transition: all 0.5s;

          &:hover {
            text-decoration: underline;
            font-weight: 500;
          }
        }

        .breadcrumb_last {
          color: $green-350;
          padding-right: 3px;
        }
      }
    }

    .page-title {
      padding: 15px 0;
      text-align: center;
      line-height: 68px;
    }

    .decor-line {
      display: flex;
      justify-content: center;

      hr {
        margin: 0;
        padding: 0;
        width: 32px;
        border: none;
        border-bottom: solid 1px #9ea0a1;
      }
    }

    .page-content {
      background: $white;
      padding: 50px 0 10px 0;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 8px;
        color: black;

        &:last-child {
          margin-bottom: 0;
        }
      }

      figure {
        max-width: 100%;

        video {
          max-width: 100%;
        }
      }

      h4,
      h5,
      h6 {
        line-height: 28px;
        text-transform: none;
      }

      p {
        margin-bottom: 23px;
        line-height: 25px;
        color: $text-color;

        &:last-child {
          margin-bottom: 0;
        }
      }

      ul {
        list-style-type: disc;
        padding: inherit;
        margin: inherit;
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }

        li {
          margin-bottom: 8px;
        }
      }
    }
  }
}


.not-found-page {
  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
    flex-direction: column;
    gap: 20px;

    a {
      color: black;
    }
  }
}