@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.offers-page {
  .banner {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
      display: flex;
      width: 100%;
      height: 200px;
      overflow: hidden;
      margin-bottom: 24px;
    }

    img {
      width: 100%;
      height: 431px;

      @include media-breakpoint-down(md) {
        height: auto;
        width: auto;
        max-width: none;
      }
    }
  }


  .page-content {
    @include media-breakpoint-down(md) {
      padding: 0 6px;
    }

    .breadcrumbs {
      text-align: center;
      margin-bottom: 43px;
      color: $grey-400;
      font-size: 14px;


      a {
        color: $grey-400;
      }

      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }

      .span {
        color: $green-400;
      }
    }

    .text {
      margin-bottom: 40px;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
      }

      .title {
        text-align: center;
        position: relative;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 27px;
        }


        h1 {
          color: $grey-400;
          font-size: 35px;
          font-weight: 700;
          padding-bottom: 25px;
          text-transform: none;

          @include media-breakpoint-down(md) {
            margin-bottom: 17px;
            font-size: 32px;
          }

          &::after {
            content: "";
            width: 32px;
            height: 1px;
            background-color: $grey-350;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .description {
        max-width: 1110px;
        margin: 0px auto;
        text-align: center;
        color: $grey-400;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 20.8px;
        letter-spacing: 0.48px;

        p {
          font-size: 16px;
        }
      }
    }


    .offers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 146px;
      margin-bottom: 100px;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: 20px;
        margin-bottom: 40px;
      }

      .offer {
        max-width: 406px;
        color: #404040;
        position: relative;
        padding: 0 35px 60px 35px;
        border-top: 11px solid $green-100;

        @include media-breakpoint-down(md) {
          max-width: 100%;
          padding: 0 0px 60px 0px;
        }

        .title {
          margin-bottom: 20px;

          h3 {
            position: relative;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            letter-spacing: 0.03em;
            text-align: right;
            padding: 17px 0 15px 0;

            @include media-breakpoint-down(md) {
              padding-top: 14px;
              padding-bottom: 14px;
            }

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background-color: $grey-350;
              position: absolute;
              top: 100%;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .description {
          margin-bottom: 30px;

          @include media-breakpoint-down(md) {
            margin-bottom: 30px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0.03em;
          }

          ul {
            list-style: none;
            padding: 0px;

            li {
              font-size: 16px;
              font-weight: 400;
              line-height: 21px;
              letter-spacing: 0.03em;

              &:before {
                content: "\2b";
                font-family: "Font Awesome 6 Pro";
                margin-left: 7px;
                font-size: 14px;
              }
            }
          }
        }

        .button {
          position: absolute;
          bottom: 0;
          width: 100%;
          left: 0%;
          padding: 0 35px;
          @include media-breakpoint-down(md) {
            padding: 0;
          }

          a {
            font-size: 17px;
            font-weight: 600;
            line-height: 1.35;
            letter-spacing: 0;
            text-align: center;
            color: #404040;
            height: 46px;
            padding: 0 26px;
          }
        }
      }
    }

    .school-holidays {
      margin-bottom: 100px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      .school-holidays-title {
        text-align: center;
        margin-bottom: 56px;

        @include media-breakpoint-down(md) {
          margin-bottom: 32px;
        }

        h2 {
          color: $grey-400;
          font-size: 35px;
          font-weight: 700;
          line-height: 1.4;

          @include media-breakpoint-down(md) {
            font-size: 32px;
          }
        }
      }

      .holidays {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 146px;

        @include media-breakpoint-down(md) {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        .holiday {
          max-width: 406px;
          position: relative;
          padding-bottom: 60px;

          @include media-breakpoint-down(md) {
            max-width: 100%;
          }

          .thumnbnail {
            img {
              height: 170px;

              @include media-breakpoint-down(md) {
                height: auto;
                width: 100%;
              }
            }
          }

          .content {
            padding: 0 35px;
            border-top: 11px solid $green-100;
            @include media-breakpoint-down(md) {
              padding: 0;
            }
            .title {
              margin-bottom: 20px;

              @include media-breakpoint-down(md) {
                text-align: start;
              }

              h3 {
                position: relative;
                padding: 15px 0 13px 0;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.3;
                letter-spacing: .03em;
                text-align: right;
                color: #404040;

                @include media-breakpoint-down(md) {
                  padding-top: 14px;
                  padding-bottom: 14px;
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 1px;
                  background-color: $grey-350;
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }

            .description {
              margin-bottom: 30px;
              color: #404040;

              @include media-breakpoint-down(md) {
                margin-bottom: 20px;
              }

              P {
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                letter-spacing: 0.48px;
              }

              ul {
                list-style: none;
                padding: 0px;

                li {
                  &:before {
                    content: "\2b";
                    font-family: "Font Awesome 6 Pro";
                    margin-left: 7px;
                    font-size: 14px;
                  }
                }
              }
            }

            .button {
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0%;
              padding: 0 35px;
              @include media-breakpoint-down(md) {
                padding: 0;
              }

              a {
                font-size: 17px;
                font-weight: 600;
                line-height: 1.35;
                letter-spacing: 0;
                text-align: center;
                color: #404040;
                height: 46px;
                padding: 0 26px;
              }
            }
          }
        }
      }
    }

    .additionals {
      margin-bottom: 60px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      .additionals-title {
        text-align: center;
        margin-bottom: 56px;

        @include media-breakpoint-down(md) {
          margin-bottom: 32px;
        }

        h2 {
          color: $grey-400;
          font-size: 35px;
          font-weight: 700;
          line-height: 1.4;

          @include media-breakpoint-down(md) {
            font-size: 32px;
          }
        }
      }

      .additional-offers {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 146px;

        @include media-breakpoint-down(md) {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        .additional-offer {
          max-width: 406px;
          color: #404040;
          position: relative;
          padding-bottom: 60px;

          @include media-breakpoint-down(md) {
            max-width: 100%;
          }

          .thumnbnail {
            img {
              height: 170px;

              @include media-breakpoint-down(md) {
                height: auto;
                width: 100%;
              }
            }
          }

          .content {
            border-top: 11px solid $green-100;
            padding: 0 35px;
            @include media-breakpoint-down(md) {
              padding: 0;
            }

            .title {
              margin-bottom: 20px;

              @include media-breakpoint-down(md) {
                text-align: start;
              }

              h3 {
                position: relative;
                padding: 15px 0 13px 0;
                font-size: 20px;
                font-weight: 700;
                line-height: 1.3;
                letter-spacing: .03em;
                text-align: right;
                color: #404040;

                @include media-breakpoint-down(md) {
                  padding-top: 14px;
                  padding-bottom: 14px;
                }

                &::after {
                  content: "";
                  width: 100%;
                  height: 1px;
                  background-color: $grey-350;
                  position: absolute;
                  top: 100%;
                  left: 50%;
                  transform: translateX(-50%);
                }
              }
            }

            .description {
              margin-bottom: 30px;

              @include media-breakpoint-down(md) {
                margin-bottom: 20px;
              }

              P {
                font-size: 16px;
                font-weight: 400;
                line-height: 20.8px;
                letter-spacing: 0.48px;
              }

              ul {
                list-style: none;
                padding: 0px;

                li {
                  &:before {
                    content: "\2b";
                    font-family: "Font Awesome 6 Pro";
                    margin-left: 7px;
                    font-size: 14px;
                  }
                }
              }
            }

            .button {
              position: absolute;
              bottom: 0;
              width: 100%;
              left: 0%;
              padding: 0 35px;
              @include media-breakpoint-down(md) {
                padding: 0;
              }

              a {
                font-size: 17px;
                font-weight: 600;
                line-height: 1.35;
                letter-spacing: 0;
                text-align: center;
                color: #404040;
                height: 46px;
                padding: 0 26px;
              }
            }
          }
        }
      }
    }

    .links {
      @include media-breakpoint-down(md) {
        margin-bottom: 42px;
      }

      ul {
        list-style: none;
        padding: 0px;
        margin: 0px;
        display: flex;
        align-items: center;
        gap: 32px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }

        li {

          @include media-breakpoint-down(md) {
            width: 100%;
          }

          a {
            color: $green-400;
            font-size: 16px;
            font-weight: 600;
            line-height: 20.8px;
            letter-spacing: 0.48px;
          }
        }
      }
    }
  }
}