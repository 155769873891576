@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.banner-profile {
  height: 274px;
  position: relative;
  @include media-breakpoint-down(md) {
    height: 120px;
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .banner-profile-title {
    position: absolute;
    width: 100%;
    bottom: 50%;
    transform: translateY(50%);

    h2 {
      max-width: 1630px;
      margin: 0 auto;
      padding: 0 10px;
      font-weight: 700;
      color: $white;
      @include font-size-responsive(50px, 24px);
    }
  }
}

.profile-page {
  max-width: 1600px;
  width: 90%;
  margin: 0 auto;
  padding: 40px 0 0 0;

  .media-md-hide {
    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .media-md-show {
    display: none;
    @include media-breakpoint-down(md) {
      display: flex !important;
    }
  }

  .control-header {
    flex-direction: column;
    margin-bottom: 30px;

    h4 {
      margin-bottom: 0 !important;
      font-size: 18px !important;
    }

    .control-logout-md {
      display: flex;
      font-size: 16px;
      line-height: 1.5em;
      gap: 5px;

      span {
        font-weight: 700;
      }

      a {
        color: $green-400;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }

  & > h3 {
    font-size: 14px;
    font-weight: 400;
    color: $grey-370;
    text-align: center;
    padding-inline-start: 25%;
    margin-bottom: 20px;
    @include media-breakpoint-down(md) {
      padding-inline-start: 0;
      margin-bottom: 32px;
    }
    a{
      font-size: 14px;
      font-weight: 400;
      color: $grey-370;
      text-align: center;
      padding-inline-start: 25%;
      margin-bottom: 20px;
      @include media-breakpoint-down(md) {
        padding-inline-start: 0;
        margin-bottom: 32px;
      }
    }

    .current-tab-active {
      color: $green-510;
    }
  }

  & > div {
    display: grid;
    gap: 5%;
    grid-template-columns: 20% 75%;
    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column-reverse;
      gap: 30px;
    }

    .profile-tab {
      display: none;
    }

    .profile-tab.active {
      display: flex;
      flex-direction: column;
    }

    .sidebar-tab {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        border-radius: 10px;
        box-shadow: 0 0 4px 0 #00000026;
        padding: 10px 20px;
        display: flex;
        max-width: 240px;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 16px;
        color: $black;
        font-weight: 600;
        cursor: pointer;
        transition: 200ms all linear;
        @include media-breakpoint-down(md) {
          max-width: unset;
        }

        &.active {
          color: $green-400;
          background-color: #F9FBFB;
        }

        &:hover {
          color: $green-400;
          background-color: #F9FBFB;
        }

        a {
          color: $black;
          text-decoration: none;
          transition: 200ms all linear;

          &:hover {
            color: $green-400;
          }
        }

        & > active {
          color: $green-400;
        }

        &:not([data-sidebar-tab="logout"])::after {
          content: "";
          display: inline-block;
          width: 9px;
          height: 15px;
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 15"><path d="M7.64662 0L0.79443 6.85219L0.5 7.16L0.79443 7.46781L7.64662 14.32L8.26224 13.7044L1.71787 7.16L8.26224 0.615626L7.64662 0Z" fill="black"/></svg>') no-repeat;
          background-size: contain;
        }
      }
    }


    .profile-user-info {
      position: relative;

      @include media-breakpoint-down(md) {
        display: none;
        flex-direction: column;
      }

      .profile-info-change {
        position: absolute;
        left: 0;

        @include media-breakpoint-down(md) {
          position: relative;
          left: auto;
          order: 2;
          margin-top: 24px;
        }

        button {
          font-size: 20px;
          font-weight: 700;
          color: $green-400;
          border: none;
          background-color: transparent;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      h3 {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 20px;
        color: $grey-400;
      }

      .profile-info-row {
        display: flex;
        margin-bottom: 8px;

        &:nth-child(4) {
          margin-bottom: 40px;
        }

        & > div:first-child {
          min-width: 100px;
        }
      }
    }

    .profile-user-info-edit {
      h3 {
        font-weight: 700;
        color: $grey-400;
        margin-bottom: 24px;
        @include font-size-responsive(20px, 18px);
      }

      form {
        max-width: 555px;
      }

      .fr-fr {
        margin-bottom: 24px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
        @include media-breakpoint-down(md) {
          grid-template-columns: 1fr;
        }
      }

      .fr-one {
        margin-bottom: 24px;

        &.email {
          opacity: .5;
        }

        &.with-checkbox {
          position: relative;

          input[type="checkbox"] {
            position: absolute;
            left: 24px;
            width: 24px;
            height: 24px;
            top: 39px;
            cursor: pointer;
            opacity: 0;
          }

          input[type="checkbox"] + div::after {
            content: "";
            width: 24px;
            height: 24px;
            top: 39px;
            left: 24px;
            z-index: 1;
            pointer-events: none;
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 9C10.7554 9 9.80161 9.39509 9.09835 10.0984C8.39509 10.8016 8 11.7554 8 12.75C8 13.7446 8.39509 14.6984 9.09835 15.4016C9.80161 16.1049 10.7554 16.5 11.75 16.5C12.7446 16.5 13.6984 16.1049 14.4016 15.4016C15.1049 14.6984 15.5 13.7446 15.5 12.75C15.5 11.7554 15.1049 10.8016 14.4016 10.0984C13.6984 9.39509 12.7446 9 11.75 9ZM10.159 11.159C10.581 10.7371 11.1533 10.5 11.75 10.5C12.3467 10.5 12.919 10.7371 13.341 11.159C13.7629 11.581 14 12.1533 14 12.75C14 13.3467 13.7629 13.919 13.341 14.341C12.919 14.7629 12.3467 15 11.75 15C11.1533 15 10.581 14.7629 10.159 14.341C9.73705 13.919 9.5 13.3467 9.5 12.75C9.5 12.1533 9.73705 11.581 10.159 11.159Z' fill='%23515358'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 5C7.40833 5 3.86331 7.43675 1.10688 12.0308C0.964373 12.2683 0.964373 12.565 1.10688 12.8025C3.86331 17.3966 7.40833 19.8333 11.75 19.8333C16.0917 19.8333 19.6367 17.3966 22.3931 12.8025C22.5356 12.565 22.5356 12.2683 22.3931 12.0308C19.6367 7.43675 16.0917 5 11.75 5ZM11.75 18.3333C8.20304 18.3333 5.16058 16.446 2.62997 12.4167C5.16058 8.38733 8.20304 6.5 11.75 6.5C15.297 6.5 18.3394 8.38733 20.87 12.4167C18.3394 16.446 15.297 18.3333 11.75 18.3333Z' fill='%23515358'/%3E%3C/svg%3E");
          }

          input[type="checkbox"]:checked + div::after {
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.75 5C7.40833 5 3.86331 7.43675 1.10688 12.0308C0.964373 12.2683 0.964373 12.565 1.10688 12.8025C3.86331 17.3966 7.40833 19.8333 11.75 19.8333C16.0917 19.8333 19.6367 17.3966 22.3931 12.8025C22.5356 12.565 22.5356 12.2683 22.3931 12.0308C19.6367 7.43675 16.0917 5 11.75 5ZM11.75 18.3333C8.20304 18.3333 5.16058 16.446 2.62997 12.4167C5.16058 8.38733 8.20304 6.5 11.75 6.5C15.297 6.5 18.3394 8.38733 20.87 12.4167C18.3394 16.446 15.297 18.3333 11.75 18.3333Z' fill='%23515358'/%3E%3C/svg%3E");
          }
        }
      }

      input {
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4em;
        color: $grey-370;
        padding: 18px 16px;
        border-radius: 5px;
        border: 1px solid $grey-600;
      }

      label {
        font-size: 14px;
        font-weight: 400;
        line-height: 2em;
        color: $grey-370;
      }
    }

    .no-orders {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        margin-bottom: 24px;
      }

      h5 {
        font-weight: 400;
        color: $grey-400;
        margin-bottom: 8px;
        @include font-size-responsive(20px, 18px);
      }

      h4 {
        a {
          font-weight: 700;
          color: $green-400;
          text-decoration: underline;
          line-height: 1.5em;
          @include font-size-responsive(20px, 18px);
        }
      }
    }
  }

  .order-list {
    .table-row-grid-5 {
      display: grid;
      grid-template-columns: 30% 20% 15% 15% 20%;
      background-color: #f3f5f5;
      margin-bottom: 10px;
      border-radius: 8px;

      & > div {
        padding: 16px 24px;
        color: $black;
        font-size: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:first-child {
        background-color: transparent;

        & > div {
          color: $grey-400;
          padding: 0 24px;
        }
      }

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        text-decoration: underline;
        color: $green-400;
        font-size: 20px;
        font-weight: 700;
        line-height: 1.5em;
      }
    }
  }

  .order-list-mobile {
    flex-direction: column;
    gap: 30px;
    align-items: center;

    .single-order-mobile {
      max-width: 350px;
      background-color: $grey-100;
      padding: 20px 30px;
      border-radius: 8px;

      & > div:first-child {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 30px;

        .table-row-2 {
          font-weight: 700;
        }

        & > div {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }

      & > div:last-child {
        display: flex;
        justify-content: center;

        button {
          background-color: transparent;
          border: none;
          text-decoration: underline;
          color: $green-400;
          font-size: 18px;
          font-weight: 700;
          cursor: pointer;
        }

      }

    }
  }

  .profile-user-control {
    position: relative;

    .control-logout {
      position: absolute;
      left: 0;
      font-size: 20px;
      line-height: 1.5em;
      display: flex;
      gap: 5px;

      span {
        font-weight: 700;
      }

      a {
        color: $green-400;
        text-decoration: underline;
        font-weight: 700;
      }
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 20px;

      span {
        font-weight: 700;
      }
    }

    p {
      @include font-size-responsive(20px, 18px);
      line-height: 1.5em;

      span {
        cursor: pointer;
        color: $green-400;
        text-decoration: underline;
        font-weight: 700;
      }
    }
  }
}