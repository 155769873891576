@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.home-page {
  overflow: hidden;

  .banner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 540px;
    position: relative;

    .video{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      video{
        width: 100%;
        height: 100%;
        object-position: center;
        object-fit: cover;
      }

    }

    @include media-breakpoint-down(md) {
      padding: 0 16px;
      background-size: auto 600px;
      background-position: 25% -117px;
      padding-top: 205px;
      height: auto;
    }

    .container-lg {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: relative;
      z-index: 1;

      @include media-breakpoint-down(md) {
        padding: 0;
        flex-flow: unset;
        flex-direction: column;
      }
    }

    .banner-text {
      font-size: 35px;
      line-height: 47px;
      color: #fff;
      margin-bottom: 38px;
    }
  }

  .map {
    margin-top: -60px;
    margin-bottom: 175px;

    @include media-breakpoint-down(md) {
      margin-top: 50px;
      margin-bottom: 32px;
    }

    .open-map {
      margin-bottom: 60px;
      display: flex;
      align-items: center;
      border-radius: 15px;
      padding: 33px 40px;
      padding-right: calc(25% + 30px);
      border-radius: 15px;
      overflow: hidden;
      background: $white;
      box-shadow: $box-shadow;
      position: relative;



      @include media-breakpoint-down(lg) {
        box-shadow: none;
        padding: 0px;
        margin-bottom: 32px;
        border-radius: 15px;
        background: $white;
        box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
        flex-direction: column;
      }

      .text {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;
        font-size: 22px;
        line-height: 28.6px;
        letter-spacing: 0.66px;

        @include media-breakpoint-down(lg) {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          gap: 25px;
          padding: 16px;
        }

        .description {
          width: 100%;
          font-size: 22px;
          line-height: 28.6px;
          letter-spacing: 0.66px;

          p {
            font-size: 22px;
            font-weight: 400;
            line-height: 28.6px;
            letter-spacing: 0.66px;

            @include media-breakpoint-down(md) {
              font-size: 18px;
              line-height: 23.4px;
              letter-spacing: 0.54px;
            }
          }
        }
      }

      .image {
        position: relative;
        width: 25%;
        height: 100%;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;

        @include media-breakpoint-down(lg) {
          position: relative;
          width: 100%;
          height: 127px;
          border-radius: 15px 15px 0px 0px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .button {
        flex-shrink: 0;

        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;

          a {
            width: 100%;
            height: 60px;
            font-size: 18px;
          }
        }
      }
    }

    .our-values {
      display: grid;
      grid-template-columns: repeat(3, 3fr);
      gap: 22px;

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(1, 1fr);
        place-items: center;
        gap: 12px;
      }

      .value {
        display: flex;
        align-items: center;
        gap: 16px;
        background: $white;
        box-shadow: $box-shadow;
        padding: 26px 30px;
        border-radius: 15px;

        @include media-breakpoint-down(lg) {
          padding: 16px 60px 16px 16px;
          gap: 10px;
          width: 100%;
        }

        .image {
          flex-shrink: 0;
        }

        .title {
          margin-bottom: 10px;

          @include media-breakpoint-down(sm) {
            h5 {
              font-size: 18px;
            }
          }
        }

        .description {
          p {
            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .our-goals {
    margin-bottom: 77px;
    background-color: $white;

    .heading {
      margin-bottom: 30px;
      color: #404040;

      @include media-breakpoint-down(md) {
        margin-bottom: 20px;
        text-align: center;
      }
    }

    .container-md {
      @include media-breakpoint-down(md) {
        padding: 0px;
      }
    }

    .slider {
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        margin-bottom: 12px;
      }

      .slide {
        overflow: hidden;
        height: auto;
        border-radius: 14px;
        background-color: $white;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);

        .image {
          @include media-breakpoint-down(sm) {
            height: 206px;
          }

          img {
            width: 100%;

            @include media-breakpoint-down(sm) {
              object-fit: cover;
              height: 100%;
            }
          }
        }

        .text {
          padding: 16px 32px;

          @include media-breakpoint-down(sm) {
            font-size: 12px 28px;
          }

          .title {
            margin-bottom: 8px;
            color: $black;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }
          }

          .description {
            margin-bottom: 8px;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey-400;

              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
          }

          .date {
            margin-bottom: 8px;
            color: $grey-400;
            font-size: 18px;
            line-height: 23.4px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          .price {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0.6px;
            color: $black;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .button {
          margin-top: auto;
        }

        @include media-breakpoint-down(md) {
          &.slick-cloned {
            display: none !important;
          }

          &:nth-child(6n) {
            display: none !important;
          }
        }

      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(md) {
          margin: 0;
          padding: 0;
        }
      }

      .slick-slide {
        margin: 0 15px;

        .image {
          height: 206px;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .slick-track {
        padding: 10px 0px;
        display: flex !important;

        @include media-breakpoint-down(md) {
          width: 100% !important;
          transform: none !important;
          flex-direction: column;
          gap: 12px;
        }
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        left: -70px;

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        right: -70px;

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        width: 100%;

        a {
          width: 100%;
          height: 60px;
          font-size: 18px;
          max-width: 280px;
        }
      }
    }
  }

  .our-best-offers {
    margin-bottom: 77px;

    @include media-breakpoint-down(md) {
      margin-bottom: 40px;
    }

    .heading {
      margin-bottom: 30px;
      color: #404040;

      @include media-breakpoint-down(md) {
        h2 {
          font-size: 32px;
          text-align: center;
          padding: 0 16px;
        }
      }
    }

    .container-md {
      padding: 0px;
    }

    .gallery {
      display: flex;
      gap: 40px;
      margin-bottom: 30px;

      @include media-breakpoint-down(xl) {
        flex-wrap: wrap;
      }

      @include media-breakpoint-down(md) {
        padding: 10px;
        gap: 15px;
      }

      > :first-child {
        flex: 0 1 705px;

        @include media-breakpoint-down(md) {
          flex: 1 1 100%;
        }
      }

      > * {
        flex: 0 0 361px;

        @include media-breakpoint-down(md) {
          flex: 1 1 100%;
        }
      }

      .image {
        padding: 20px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &.drop {
          display: flex;
          justify-content: center;
          align-items: center;

          .figura {
            background-color: $yellow-400;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50% 0% 50% 50%;
            width: 268px;
            height: 268px;

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;

              @include media-breakpoint-down(md) {
                padding: 0 20px;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;
              }
            }
          }
        }

        &.semicircle {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background-color: #f5f5f5;

          @include media-breakpoint-down(md) {
            justify-content: center;
          }

          .figura {
            background-color: $violet-400;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50% 50% 0% 0%;
            width: 268px;
            height: 268px;

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;

              @include media-breakpoint-down(md) {
                padding: 0 30px;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;
              }
            }
          }
        }

        &.burger {
          display: flex;
          justify-content: center;
          align-items: center;

          .figura {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 60px 30px;
            border-radius: 50% 50% 0% 0%;
            width: 100%;
            height: 268px;


            &::before {
              top: 0px;
              left: 0px;
              position: absolute;
              content: "";
              background-color: $green-100;
              height: 51%;
              width: 100%;
              border-radius: 100px;

            }

            &::after {
              bottom: 0px;
              left: 0px;
              position: absolute;
              content: "";
              background-color: $green-100;
              height: 51%;
              width: 100%;
              border-radius: 100px;


            }

            .text {
              text-align: center;
              color: $grey-400;
              font-size: 17px;
              font-weight: 500;
              line-height: 22.1px;
              letter-spacing: 0.51px;
              position: relative;
              z-index: 3;

              @include media-breakpoint-down(md) {
                padding: 0 20px;
              }

              .title {
                font-size: 17px;
                font-weight: 700;
                line-height: 22.1px;
                letter-spacing: 0.51px;
                margin-bottom: 8px;
              }

              .description {
                font-size: 17px;
                font-weight: 500;
                line-height: 22.1px;
                letter-spacing: 0.51px;
              }
            }
          }
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        width: 100%;

        a {
          width: 100%;
          height: 60px;
          font-size: 18px;
          max-width: 280px;
        }
      }
    }
  }

  .experience-the-joy {
    padding: 60px 0px;
    background-color: $green-400;

    @include media-breakpoint-down(md) {
      padding: 32px 0;
    }

    .heading {
      margin-bottom: 30px;
      color: $white;
      margin-bottom: 10px;
    }

    .description {
      color: $white;
      margin-bottom: 30px;
    }

    .gallery {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 266px 266px;
      gap: 40px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 24px 0;
        margin: 0;
      }

      .image {
        position: relative;

        h5 {
          position: absolute;
          left: 50%;
          bottom: 20px;
          transform: translateX(-50%);
          color: $white;
          text-align: center;
          text-shadow: 0px 3.927px 29.453px $black;
          font-size: 25px;
          font-weight: 700;
        }

        &:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 4;

          grid-row-end: 3;
          grid-column-end: 5;

          @include media-breakpoint-down(md) {
            display: none;
          }

          img {
            border-radius: 10px;
          }
        }

        &:nth-child(2) {
          grid-row-start: 1;
          grid-column-start: 3;

          grid-row-end: 3;
          grid-column-end: 4;

          @include media-breakpoint-down(md) {
            order: 2;
          }

          img {
            border-radius: 200px 200px 10px 10px;

            @include media-breakpoint-down(md) {
              height: 337px;
            }
          }
        }

        &:nth-child(3) {
          grid-row-start: 1;
          grid-column-start: 1;

          grid-row-end: 2;
          grid-column-end: 3;

          @include media-breakpoint-down(md) {
            order: 1;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 230px;
            }
          }
        }

        &:nth-child(4) {
          grid-row-start: 2;
          grid-column-start: 2;
          grid-row-end: 3;
          grid-column-end: 3;
          mask-image: url(../images/masks/mask-image-burger.svg);
          mask-size: 100% 100%;
          mask-repeat: no-repeat;

          @include media-breakpoint-down(md) {
            order: 3;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 266px;
            }
          }
        }

        &:nth-child(5) {
          grid-row-start: 2;
          grid-column-start: 1;

          grid-row-end: 3;
          grid-column-end: 2;

          @include media-breakpoint-down(md) {
            order: 4;
          }

          img {
            border-radius: 10px;

            @include media-breakpoint-down(md) {
              height: 266px;
            }
          }
        }

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }
      }
    }
  }

  .accommodations {
    margin: 60px 0px;
    background-color: $white;

    @include media-breakpoint-down(md) {
      margin: 20px 0;
    }

    .tabs {
      position: relative;

      .tabs-nav {
        display: flex;
        gap: 0px;
        margin-bottom: 30px;
        z-index: 1;
        position: relative;
        background-color: $grey-100;
        padding: 0px 30px;
        overflow: auto;

        @include media-breakpoint-down(md) {
          position: absolute;
          left: 0;
          width: 100%;
          z-index: 2;
          display: none;
          top: 44px;
        }

        @include media-breakpoint-down(sm) {
        }

        .tab {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 20px 10px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;
          padding-right: 40px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
            padding: 10px 40px 10px 10px;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;

            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
      }

      .tabs-select {
        display: none;

        @include media-breakpoint-down(md) {
          border-radius: 8px;
          background: #F3F5F5;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:after {
            content: "\f078";
            font-family: "Font Awesome 6 Pro";
            font-weight: 400;
            color: $green-400;
            transition: all 0.3s;
          }

          &.open {
            &:after {
              transform: rotate(180deg);
            }
          }

          .tab {
            display: flex;
            align-items: center;
            gap: 0 12px;

            span {
              color: $green-400;
              font-size: 14px;
              font-weight: 700;
            }

            img {
              height: 24px;
              width: auto;
            }
          }
        }
      }

      .tabs-contents {
        .tab-content {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    .title, .heading {
      margin-bottom: 30px;
      margin-bottom: 10px;
      color: #404040;
    }

    .description {
      margin-bottom: 30px;
    }

    .slider {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      .slide {
        overflow: hidden;
        height: auto;
        border-radius: 14px;
        background-color: $white;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);

        .image {
          img {
            width: 100%;

            @include media-breakpoint-down(md) {
              height: 160px;
            }
          }
        }

        .text {
          padding: 16px 32px;

          @include media-breakpoint-down(sm) {
            font-size: 12px 28px;
          }

          .title {
            margin-bottom: 8px;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }
          }

          .description {
            margin-bottom: 8px;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey-400;

              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
          }

          .date {
            margin-bottom: 8px;
            color: $grey-400;
            font-size: 18px;
            line-height: 23.4px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          .price {
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0.6px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }

        .button {
          margin-top: auto;
        }
      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
          margin: 0;
        }
      }

      .slick-slide {
        margin: 0 15px;
        @include media-breakpoint-down(md) {
          margin: 0;
          width: 100% !important;
        }

        &.slick-cloned {
          @include media-breakpoint-down(md) {
            display: none !important;
          }
        }
      }

      .slick-track {
        padding: 10px 0px;
        display: flex !important;
        @include media-breakpoint-down(md) {
          display: flex !important;
          transform: none !important;
          width: 100% !important;
          flex-direction: column;
          gap: 12px 0;
        }
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 9;
        left: -70px;

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 9;
        right: -70px;

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        width: 100%;

        a {
          width: 100%;
          height: 60px;
          font-size: 18px;
          max-width: 280px;
        }
      }
    }
  }

  .recommended-parks {
    margin: 60px 0px;

    .heading {
      margin-bottom: 30px;
      color: #404040;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .cards {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 45px 16px;
      margin-bottom: 30px;

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .card {
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 8px;
        background: $white;
        box-shadow: $box-shadow-small;

        @include media-breakpoint-down(md) {
          flex-direction: column;
        }

        .text {
          padding: 35px 60px;

          @include media-breakpoint-down(xl) {
            padding: 30px;
          }

          @include media-breakpoint-down(md) {
            padding: 20px;
            width: 100%;
          }

          .title {
            margin-bottom: 8px;
          }

          .description {
            margin-bottom: 8px;
            color: $grey-300;
            font-size: 18px;
            line-height: 27px;
            letter-spacing: 0.54px;
            overflow: hidden;
          }

          .description p {
            display: -webkit-box;
            /* Это позволит использовать -webkit-line-clamp для обрезки текста */
            -webkit-line-clamp: 2;
            /* Это ограничивает текст двумя строками */
            -webkit-box-orient: vertical;
            /* Необходимо для работы -webkit-line-clamp */
            overflow: hidden;
            /* Обрезает лишний текст */
            text-overflow: ellipsis;
            /* Это, на самом деле, не будет нужно с -webkit-line-clamp, но хорошо иметь на всякий случай */
            max-width: 250px;
          }
        }

        .image {
          width: 214px;
          height: 100%;
          flex-shrink: 0;

          @include media-breakpoint-down(md) {
            width: 100%;
            height: 180px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .link {
          a {
            color: $green-300;
            font-size: 18px;
            font-weight: 700;
            line-height: 23.4px;
            letter-spacing: 0.54px;
          }
        }
      }
    }


    .button {
      @include media-breakpoint-down(md) {
        text-align: center;
        display: block;
        width: 100%;

        a {
          width: 100%;
          height: 60px;
          font-size: 18px;
          max-width: 280px;
        }
      }
    }
  }

  .vacation-for-everyone {
    margin: 60px 0px;

    .heading {
      margin-bottom: 30px;
      color: #404040;
      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }

    .vacations {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;

      @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-direction: column;
        gap: 15px;
      }

      @include media-breakpoint-down(sm) {
        gap: 10px;
      }

      .vacation {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        border-radius: 8px;
        box-shadow: $box-shadow-small;
        height: 188px;
        padding: 30px;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        text-align: center;
        cursor: pointer;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;


        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 50%;
          background: linear-gradient(180deg, transparent, rgba(0, 0, 0, .7));
          position: absolute;
          left: 0;
          bottom: 0;
        }

        @include media-breakpoint-down(md) {
          padding: 15px;
          height: 187px;
        }

        &:hover {
          background-size: 125% 125%;
        }

        h4 {
          color: $white;
          font-size: 25px;
          font-weight: 700;
          position: relative;
          z-index: 5;

          @include media-breakpoint-down(ld) {
            font-size: 22px;
          }

          @include media-breakpoint-down(md) {
            font-size: 20px;
          }

          @include media-breakpoint-down(sm) {
            font-size: 18px;
          }
        }

        .image {
          width: 214px;
          height: 210px;
          flex-shrink: 0;


          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .link {
          a {
            color: $green-300;
            font-size: 18px;
            font-weight: 700;
            line-height: 23.4px;
            letter-spacing: 0.54px;
          }
        }
      }
    }
  }
}