@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-template-activities-for-the-children-page {
	.hero{
		.subtitle{
			width: 100%;
			height: fit-content;

			.wp-video{
				width: 100% !important;
				height: fit-content !important;
			}
		}
	}

	.page-head {
		display: flex;
		justify-content: center;
		background: $green-100;
		padding: 38px 0;
		
		h2 {
			color: $green-350;
			font-size: 35px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		
		@include media-breakpoint-down(md) {
			
			h2 {
				font-size: 32px;
			}
		}
	}
	
	.secondary-menu {
		background: $white;
		box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
		
		.activities-menu {
			display: flex;
			list-style: none;
			margin: 0 -10px;
			padding: 0;
			
			li {
				padding: 30px 20px;
				position: relative;
				
				&.current-menu-item {
					a {
						color: $green-400;
						font-size: 18px;
						font-style: normal;
						font-weight: 500;
						line-height: normal;
					}
				}
				
				&:not(:last-child)::after {
					content: '';
					position: absolute;
					background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none"><circle cx="4.5" cy="4.5" r="4.5" fill="black"/></svg>');
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
					top: 0;
					left: -5px;
					width: 10px;
					height: 100%;
				}
				
				a {
					color: $black;
					font-size: 18px;
					font-style: normal;
					font-weight: 300;
					line-height: normal;
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			
			.activities-menu {
				gap: 30px;
				overflow: auto;
				
				li {
					text-wrap: nowrap;
					padding:25px 10px;

					&.current-menu-item {
						
						a {
							font-size: 12px;
						}
					}
					
					&:not(:last-child)::after {
						left: -20px;
					}
					
					a {
						font-size: 12px;
					}
				}
			}
		}
	}
	
	.hero {
		padding: 50px 0 10px 0;
		
		.container-md {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.banner-title-mobile {
				display: none;

				@include media-breakpoint-down(md) {
					display: block;
				}
			}
		}
		
		h1 {
			color: $black;
			font-size: 50px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding-bottom: 35px;
			position: relative;
			
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100px;
				height: 2px;
				background: $black;
			}
		}
		
		.subtitle {
			padding: 20px 0 30px 0;
			
			p {
				color: $black;
				font-size: 25px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}
		}

		.banner-title {
			padding-bottom: 30px;

			@include media-breakpoint-down(md) {
				padding-bottom: 0;
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: 40px 0;

			.banner-title-desktop {
				display: none;
			}

			h1 {
				text-align: center;
				font-size: 32px;
				padding-bottom: 16px;
			}
			
			.subtitle {
				
				p {
					text-align: center;
					font-size: 16px;
				}
			}
		}
	}
	
	.activities {
		padding-bottom: 100px;
		
		.container-md {
			.activities-list {
				display: flex;
				flex-wrap: wrap;
				padding: 0 40px;
				column-gap: 45px;
				row-gap: 30px;
				
				.activities-item {
					display: flex;
					justify-content: space-between;
					width: calc(50% - (30px - 30px / 4));
					border-radius: 35px;
					background: $white;
					box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
					overflow: hidden;
					
					.activities-item-image {
						flex: 0 0 50%;
						
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					
					.activities-item-info {
						padding: 20px 30px;
						
						h3 {
							color: $black;
							font-size: 20px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
							padding-bottom: 7px;
						}
						
						.description {
							padding-bottom: 15px;
							
							p {
								color: $black;
								font-size: 18px;
								font-style: normal;
								font-weight: 400;
								line-height: 111.111%;
							}
						}
						
						a {
							padding: 5px 23px;
							color: $white;
							font-size: 16px;
							font-style: normal;
							font-weight: 400;
							line-height: 187.5%;
							border-radius: 25px;
							background: $green-400;
						}
					}
					
					&:nth-child(3n+1) {
						width: 100%;
						max-height: 570px;
						
						.activities-item-image {
							flex: 0 0 70%;
						}
						
						.activities-item-info {
							padding: 40px 30px;
							
							h3 {
								font-size: 25px;
								padding-bottom: 15px;
							}
							
							.description {
								padding-bottom: 45px;
								
								p {
									line-height: 138.889%;
								}
							}
						}
					}

					@include media-breakpoint-down(md) {
						.activities-item-image {
							max-height: 180px;
						}
					}
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			padding-bottom: 78px;
			
			.container-md {
				.activities-list {
					gap: 12px;
					padding: 0;
					
					.activities-item {
						flex-direction: column-reverse;
						width: 100%;
						
						.activities-item-info {
							padding: 16px;
							
							h3 {
								padding-bottom: 4px;
								font-size: 24px;
								line-height: normal;
							}
							
							.description {
								padding-bottom: 25px;
								
								p {
									font-size: 16px;
									line-height: 156.25%;
								}
							}
							
							a {
								padding: 5px 23px;
								color: $white;
								font-size: 16px;
								font-style: normal;
								font-weight: 400;
								line-height: 187.5%;
								border-radius: 25px;
								background: $green-400;
							}
						}
						
						&:nth-child(3n+1) {
							width: 100%;
							max-height: 570px;
							
							.activities-item-info {
								padding: 16px;
								
								h3 {
									padding-bottom: 4px;
									font-size: 24px;
									line-height: normal;
								}
								
								.description {
									padding-bottom: 25px;
									
									p {
										font-size: 16px;
										line-height: 156.25%;
									}
								}
								
								a {
									padding: 5px 23px;
									color: $white;
									font-size: 16px;
									font-style: normal;
									font-weight: 400;
									line-height: 187.5%;
									border-radius: 25px;
									background: $green-400;
								}
							}
						}
					}
				}
			}
		}
	}
}

.single-activities {
	article {
		display: flex;
		flex-direction: column;
		
		.activities-head {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 54px 0;
			
			h1 {
				color: $black;
				font-size: 50px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
			}
		}
		
		.activities-body {
			display: flex;
			flex-direction: column;
			border-radius: 30px;
			background: $white;
			box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
			overflow: hidden;
			
			.activities-image {
				width: 100%;
				
				img {
					width: 100%;
					object-fit: cover;
				}
			}
			
			.activities-info {
				display: flex;
				
				.activities-content {
					display: flex;
					flex-direction: column;
					flex: 1;
					padding: 63px 35px;
					gap: 26px;
					
					h3 {
						color: $black;
						font-size: 35px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
					}
					
					p {
						color: $black;
						font-size: 25px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
				}
				
				.activities-parks {
					display: flex;
					flex-direction: column;
					flex: 1;
					background: $blue-100;
					padding: 63px 35px;
					gap: 60px;
					
					.activities-parks-head {
						
						h3 {
							color: $black;
							font-size: 35px;
							font-style: normal;
							font-weight: 700;
							line-height: normal;
						}
					}
					
					.activities-parks-body {
						display: flex;
						justify-content: space-between;
						
						.activities-parks-country {
							display: flex;
							flex-direction: column;
							gap: 20px;
							
							h4 {
								color: $black;
								font-size: 25px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}
							
							span {
								color: $black;
								font-size: 18px;
								font-style: normal;
								font-weight: 400;
								line-height: normal;
							}
						}
					}
				}
			}
		}
	}
	
	@include media-breakpoint-down(md) {
		.container-lg {
			padding: 0;
		}
		
		article {
			padding-bottom: 40px;
			
			.activities-head {
				padding: 35px 0;
				
				h1 {
					font-size: 32px;
					line-height: 43.52px;
					text-align: center;
				}
			}
			
			.activities-body {
				border-radius: 0;
				box-shadow: unset;
				
				.activities-info {
					flex-direction: column;
					
					.activities-content {
						padding: 20px 16px;
						gap: 6px;
						
						h3 {
							font-size: 24px;
							line-height: 32.64px;
						}
						
						p {
							font-size: 16px;
							line-height: 21.76px;
						}
					}
					
					.activities-parks {
						padding: 24px 16px;
						gap: 26px;
						
						.activities-parks-head {
							
							h3 {
								font-size: 24px;
								line-height: 32.64px;
							}
						}
						
						.activities-parks-body {
							gap: 40px;
							flex-wrap: wrap;
							
							.activities-parks-country {
								gap: 16px;
								
								h4 {
									font-size: 20px;
									line-height: 27.2px;
								}
								
								span {
									font-size: 16px;
									line-height: 21.76px;
								}
							}
						}
					}
				}
			}
		}
	}
}