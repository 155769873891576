@import "../base/_mixins.scss";
@import "../base/_variables.scss";
@import "../base/_colors.scss";

.lock {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: none;
}

#header {
  position: sticky;
  top: 0;
  z-index: 10;

  .representative_note_error {
    background: #CE2116;
    color: white;
    padding: 10px;
    font-size: 24px;
    font-weight: 700;
    text-align: center;
    display: none;
  }

  .header-top {
    background-color: $green-100;

    .container-lg {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 52px;
    }

    .connect-with-us {
      display: flex;
      align-items: center;
      gap: 20px;

      a {
        color: $grey-400;
        font-family: Inter;
        font-size: 20px;
      }
    }

    .info {
      font-family: $Poppins;
      font-size: 20px;
      @include media-breakpoint-down(md) {
        font-size: 14px;
        text-align: center;
      }

      a {
        color: #000;
        transition: all .3s;

        &:hover {
          color: #045e51;
        }
      }
    }
  }

  .header-bottom {
    height: 90px;
    background-color: $green-400;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(laptop) {
      height: 180px;
      display: block;
    }

    @include media-breakpoint-down(md) {
      position: relative;
      height: 70px;
      display: flex;
    }

    .container-lg {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .main-container {
      @include media-breakpoint-down(laptop) {
        padding: 15px 10px;
      }
      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }
    }

    .sub-container {
      display: none;

      @include media-breakpoint-down(laptop) {
        display: flex;
        justify-content: center;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .user {
      display: flex;
      align-items: center;
      gap: 20px;

      .logo {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .profile {
        //pointer-events: none;
      }

      .cart {
        position: relative;
        display: inline-block;
        cursor: pointer;
        //pointer-events: none;

        .tooltip-content {
          display: none;
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: black;
          color: white;
          text-align: center;
          padding: 5px;
          border-radius: 6px;
          width: 130px;
          font-size: 14px;
          opacity: 0;
          transition: opacity 0.3s;
        }

        &:hover {
          .tooltip-content {
            display: inline-block;
            opacity: 1;
          }
        }
      }
    }

    .navigation {
      display: flex;
      align-items: center;
      gap: 45px;
      @include media-breakpoint-down(md) {
        gap: 20px;
      }

      .burger {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          z-index: 11;
          position: relative;
          svg {
            display: block;

            & + svg {
              display: none;
            }
          }

          &.active {
            margin-left: 30px;

            svg {
              display: none;

              & + svg {
                display: block;
              }
            }
          }
        }
      }

      .logo {
        @include media-breakpoint-down(md) {
          z-index: 11;
          position: relative;
        }

        img {
          @include media-breakpoint-down(md) {
            height: 36px;
            width: auto;
          }
        }
      }

      .sub-menu {
        display: none;

        @include media-breakpoint-down(laptop) {
          display: block;
        }

        @include media-breakpoint-down(lg) {
          > ul {
            gap: 13px !important;

            > li {
              > a {
                font-size: 16px !important;
              }
            }
          }
        }
      }

      .menu {
        @include media-breakpoint-down(laptop) {
          display: none;
        }

        @media only screen and (max-width: 1605px) {
          > ul {
            gap: 10px !important;
          }
        }
      }

      @media only screen and (max-width: 1605px) {
        .menu {
          > ul {
            gap: 10px !important;
          }
        }
      }

      @media only screen and (max-width: 1490px) {
        .menu {
          > ul {
            gap: 13px !important;

            > li {
              > a {
                font-size: 16px !important;
              }
            }
          }
        }
      }

      .menu, .sub-menu {
        @include media-breakpoint-down(md) {
          display: none;
        }

        > ul {
          padding: 0px;
          margin: 0px;
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 30px;

          > li {
            height: 90px;
            line-height: 90px;
            cursor: pointer;
            position: relative;

            &:hover {
              > a {
                background: $green-hover;
              }
            }

            &:hover {
              .sub-menu {
                display: block;
              }
            }

            > a {
              color: $green-100;
              font-size: 18px;
              transition: $transition-all-linear;
              padding: 4px 12px;
              border-radius: 20px;
            }
          }
        }

        .sub-menu-map {
          display: none;
          z-index: 999;
          height: 400px;
          width: 950px;
          position: absolute;
          top: 100%;
          right: 0;
          //left: 50%;
          //transform: translateX(-50%);
          background-color: $white;

          &::after {
            content: "";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $white;
            position: absolute;
            top: 0%;
            //left: 50%;
            transform: translate(-50%, -100%);
            right: 30px;
            z-index: 999;
          }

          .tabs {
            display: flex;
            height: 100%;

            .tabs-nav {
              width: 295px;
              background-color: $grey-150;
              padding: 30px 30px 30px 0px;

              .tab {
                color: $black;
                text-align: right;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                padding: 10px 30px;
                border-radius: 0px 18px 18px 0px;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                &:last-child {
                  margin-bottom: 0px;
                }

                &::after {
                  content: "\f104";
                  font-family: "Font Awesome 6 Pro";
                  font-weight: 300;
                  font-size: 24px;
                }

                &.active {
                  background-color: $white;
                }
              }
            }

            .tabs-contents {
              flex: 1;
              width: calc(100% - 295px);

              .tab-heading {
                display: none;
              }

              .tab-content {
                display: none;
                padding: 15px;
                position: relative;
                height: 100%;
                padding: 15px 30px;

                &.active {
                  display: flex;
                }

                .locations {
                  flex-shrink: 0;

                  ul {
                    padding: 0px;
                    margin: 0px;
                    list-style: none;
                    @include media-breakpoint-down(md) {
                      padding-left: 40px;
                    }

                    .marker_item {
                      position: relative;

                      .marker_item_discount {
                        background: #FF4380;
                        padding: 6px;
                        border-radius: 3px;
                        color: #fff;
                        font-size: 10px;
                        font-weight: 700;
                        display: flex;
                        align-items: center;

                        & > span:first-child {
                          margin-right: 7px;
                        }
                      }
                    }

                    li {
                      line-height: normal;
                      margin-bottom: 10px;

                      .tag {
                        padding: 8px;
                        border-radius: 3px;
                        background: $green-100;
                        color: $grey-400;
                        text-align: center;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 13px;
                        letter-spacing: 0.3px;
                      }

                      &:last-child {
                        margin-bottom: 0px;

                        a {
                          color: $black;
                        }
                      }

                      a {
                        color: $black;
                        font-size: 16px;
                        font-weight: 500;
                        width: 240px;
                        display: flex;
                        align-items: center;
                        direction: ltr;
                        text-align: left;
                        gap: 10px;
                        display: grid;
                        grid-template-columns: 1fr 1fr;

                        .marker.marker_discount {
                          display: flex;
                          justify-content: flex-start;
                          gap: 10px;
                          flex-direction: row-reverse;
                        }

                        &:hover {
                          color: $green-400;
                        }
                      }
                    }
                  }
                }

                .maps {
                  width: 100%;
                  height: 100%;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  .map {
                    display: inline-block;
                    position: relative;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }

                    .marker {
                      line-height: 1;
                      transform: translate(-50%, -22.5px);

                      &::before {
                        content: "\f3c5";
                        font-family: "Font Awesome 6 Pro";
                        font-weight: 900;
                        display: inline-block;
                        font-size: 30px;
                        color: $green-400;
                        line-height: 1;
                      }

                      &.active {
                        &::before {
                          color: $green-hover;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .sub-menu-cells {
          display: none;
          z-index: 999;
          width: 950px;
          position: absolute;
          top: 100%;
          //right: 0;
          left: 50%;
          transform: translateX(-50%);
          background-color: $white;
          padding: 60px 30px;
          z-index: 999;

          &::after {
            content: "";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $white;
            position: absolute;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -100%);
            z-index: 999;
          }

          .cells {
            padding: 0px;
            margin: 0px;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .cell {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              &:first-child {
                a:after {
                  display: none;
                }
              }

              &:nth-child(5n) {
                a:after {
                  display: none;
                }
              }

              a {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 10px 20px;
                width: 100%;
                height: 100%;
                position: relative;

                .menu-discount {
                  background: #FF4380;
                  border-radius: 3px;
                  padding: 6px;
                  color: #FFF;
                  font-size: 10px;
                  font-weight: 700;
                }

                &:hover {
                  background: $grey-100;

                  h5 {
                    color: $green-500;
                  }
                }

                &:after {
                  content: "";
                  position: absolute;
                  top: 20px;
                  right: 0;
                  bottom: 20px;
                  display: block;
                  width: 0;
                  border-left: 1px solid #d2d3d3;
                }

                img {
                  margin-bottom: 16px;
                }

                h5 {
                  color: $black;
                  text-align: center;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: normal;
                  white-space: nowrap;
                  @include media-breakpoint-down(sm) {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    white-space: wrap;
                  }
                }
              }
            }
          }


          &.elements_1 {
            width: auto;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            .cells {
              display: block;

              .cell {
                width: 200px;

                a {
                  padding: 0;
                }
              }
            }
          }

          &.elements_2, &.elements_3 {
            width: auto;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            .cells {
              display: flex;

              .cell {
                width: 200px;

                a {
                  padding: 0;
                }
              }
            }
          }
        }

        .sub-menu-columns {
          display: none;
          z-index: 999;
          width: 950px;
          position: absolute;
          top: 100%;
          left: 50%;
          transform: translateX(-50%);
          background-color: $white;

          &::after {
            content: "";
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid $white;
            position: absolute;
            top: 0%;
            left: 50%;
            transform: translate(-50%, -100%);
            z-index: 999;
          }

          .columns {
            padding: 0px;
            margin: 0px;
            list-style: none;
            display: grid;
            grid-template-columns: repeat(4, 1fr);

            .column {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: flex-start;
              padding: 60px 0px 20px 0px;
              width: 100%;
              height: 100%;
              position: relative;

              &:hover {
                background: $grey-100;
              }

              &:hover {
                h5 {
                  color: $green-hover;
                }

                svg path {
                  fill: $green-hover;
                  transition: all 0.1s ease;
                }
              }

              a {
                display: inline-flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
              }

              img,
              svg {
                height: 68px;
                width: 68px;
                margin-bottom: 16px;
              }

              h5 {
                color: $black;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: normal;
                margin-bottom: 30px;
              }

              ul {
                padding: 0px;
                margin: 0px;
                list-style: none;
                text-align: center;

                li {
                  line-height: normal;
                  margin-bottom: 10px;

                  a {
                    color: $black;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;

                    &:hover {
                      color: $green-500;
                    }
                  }
                }
              }

              &:first-child {
                &:after {
                  display: none;
                }
              }

              &:nth-child(5n) {
                &:after {
                  display: none;
                }
              }

              &:after {
                content: "";
                position: absolute;
                top: 60px;
                right: 0;
                bottom: 20px;
                display: block;
                width: 0;
                border-left: 1px solid #d2d3d3;
              }
            }
          }
        }

        &.active {
          @include media-breakpoint-down(md) {
            display: block;
            position: fixed;
            z-index: 5;
            height: 100vh;
            width: 100%;
            left: 0;
            top: 0;
            background: #045E51;
            padding-top: 100px;

            > ul {
              display: flex;
              flex-direction: column;
              overflow-y: auto;
              align-items: start;
              justify-content: flex-start;
              gap: 43px 0;
              height: 100%;
              overflow-y: auto;

              & > li {
                line-height: normal;
                height: auto;
                width: 100%;
              }
            }

            .sub-menu-cells {
              width: 100%;
              position: relative;
              top: 0%;
              left: 0%;
              transform: none;
              margin-top: 30px;
              padding: 50px 24px;

              &:after {
                left: 90%;
              }

              .cells {
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

                .cell {
                  width: calc(50% - 10px);

                  &:nth-child(odd) {
                    a {
                      &:after {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            .sub-menu-map {
              width: 100%;
              position: relative;
              top: 0%;
              left: 0%;
              transform: none;
              margin-top: 30px;
              height: auto;
              min-height: 100px;

              &:after {
                left: 90%;
              }

              .tabs {
                display: block;

                .tabs-nav {
                  display: none;
                }

                .tabs-contents {
                  width: 100%;

                  .tab-content {
                    display: none !important;

                    .locations {
                      ul {
                        li {
                          margin-bottom: 20px;

                          a {
                            width: 100%;
                            font-size: 18px;
                            grid-template-columns: 110px auto;
                          }

                          .tag {
                            font-size: 18px;
                            padding: 12px;
                            border-radius: 5.294px;
                          }
                        }
                      }
                    }
                  }

                  .tab-heading {
                    display: block;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    color: $grey-400;
                    padding: 12px 20px;
                    background: $grey-150;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    &:after {
                      content: "\f078";
                      font-family: Font Awesome\ 6 Pro;
                      font-weight: 400;
                      transition: all .3s;
                      color: $grey-400;
                      transform: rotate(90deg);
                    }

                    &.open {
                      background: $white;

                      &:after {
                        transform: rotate(180deg);
                      }

                      & + .tab-content {
                        display: block !important;
                      }
                    }
                  }

                }
              }
            }
            .sub-menu-columns {
              width: 100%;
              position: relative;
              top: 0%;
              left: 0%;
              transform: none;
              margin-top: 30px;

              &:after {
                left: 90%;
              }

              .columns {
                display: flex;
                flex-direction: column-reverse;

                .column {
                  h5 {
                    a {
                      color: $black;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .promotions-header {
    background: #FF4380;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      @include font-size-responsive(32px, 18px);
      font-weight: 700;
      color: #FFF;
    }

    p {
      @include font-size-responsive(20px, 13px);
      font-weight: 200;
      color: #FFF;
    }

    .banner-promo {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      position: relative;

      input {
        visibility: hidden;
        max-height: 0;
        outline: none;
        position: absolute;
        z-index: -1;
      }

      .tooltip {
        visibility: hidden;
        width: 140px;
        background-color: white;
        color: #045e51;
        text-align: center;
        border: 1px solid #045e51;
        border-radius: 5px;
        padding: 5px 10px;
        position: absolute;
        z-index: 1;
        bottom: 0%;
        left: 50%;
        transform: translate(-50%, 110%);
        opacity: 0;
        transition: opacity .3s;
      }

      .tooltip::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -105%) rotate(180deg);
        border-width: 5px;
        border-style: solid;
        border-color: #045e51 #ff4380 #ff4380;
      }

      &:hover {
        .tooltip {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}
