@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.contact-page {
	.breadcrumbs {
		margin: 0 auto;
		width: max-content;
		display: none;
		font-size: 14px;
		line-height: normal;
		color: #285D51;
		margin-bottom: 21px;

		a {
			color: $color-515358;
		}

		@include media-breakpoint-down(md) {
			display: block;
		}
	}

	.banner {
		height: 480px;
		display: flex;
		justify-content: flex-start;
		position: relative;
		@include media-breakpoint-down(md) {
			height: 197px;
		}
		
		.banner-image {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
		
		.banner-info-wrapper {
			position: relative;
			z-index: 5;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			
			.info {
				position: relative;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 8px;
				padding: 100px 30px 55px 30px;
				background: $violet-400;
				border-top-left-radius: 135px;
				border-top-right-radius: 135px;
				max-width: 270px;
				
				.title {
					color: $text-color;
					text-align: center;
					font-size: 17px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%;
					letter-spacing: 0.51px;
				}
				
				.description {
					color: $text-color;
					text-align: center;
					font-size: 17px;
					font-style: normal;
					font-weight: 500;
					line-height: 130%;
					letter-spacing: 0.51px;
				}
			}
		}
		
		.search {
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: max-content;
		}
	}
	
	.page-content {
		padding: 62px 0 492px 0;
		@include media-breakpoint-down(md) {
			padding: 21px 0 0;
		}
		
		.page-content-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 63px;
			@include media-breakpoint-down(md) {
				padding: 0 16px;
			}
			
			.contact-form-wrapper {
				display: flex;
				flex-direction: column;
				gap: 66px;
				flex: 1;
				@include media-breakpoint-down(md) {
					gap: 61px;
				}
				
				.contact-form-header {
					display: flex;
					flex-direction: column;
					gap: 35px;
					
					h1 {
						font-size: 35px;
						font-weight: 700;
						line-height: 47.6px;
						color: $text-color;
						@include media-breakpoint-down(md) {
							font-size: 32px;
							text-align: center;
						}
					}
					
					p {
						font-size: 22px;
						font-weight: 300;
						line-height: 29.92px;
						color: $text-color;
						@include media-breakpoint-down(md) {
							font-size: 20px;
							line-height: 25px;
							font-weight: 400;
							text-align: center;
						}
					}
				}
				
				.wpcf7 {
					.screen-reader-response {
						display: none;
					}
					
					.wpcf7-form {
						display: flex;
						flex-direction: column;
						gap: 36px;
						@include media-breakpoint-down(md) {
							gap: 21px;
							flex-flow: column;
							padding: 0 16px;
						}

						&:not(.sent, .invalid) .wpcf7-response-output {
							@include media-breakpoint-down(md) {
								display: none;
							}
						}
						
						.form-row {
							display: flex;
							justify-content: space-between;
							gap: 24px;
							flex-wrap: wrap;
							@include media-breakpoint-down(md) {
								gap: 21px;
								flex-flow: column;
							}
						}
						
						label {
							display: flex;
							flex-direction: column;
							gap: 6px;
							font-size: 16px;
							font-weight: 400;
							line-height: 21.76px;
							color: #515358;
							flex: 1;
							@include media-breakpoint-down(md) {
								gap: 8px;
								line-height: normal;
							}
						}
						
						input {
							padding: 20px 16px 18px;
							border-radius: 5px;
							border: 1px solid #A4A4A4;
							color: #515358;
							font-size: 16px;
							@include media-breakpoint-down(md) {
								padding: 13px 19px;
								width: 100%;
							}
							
							&::placeholder {
								color: #51535866;
							}
							
							&.wpcf7-not-valid {
								border: 1px solid #D43C33 !important;
							}
						}
						
						.wpcf7-form-control-wrap {
							display: flex;
							flex-direction: column;
							gap: 6px;
							
							.wpcf7-not-valid-tip {
								font-size: 14px;
								font-weight: 400;
								line-height: 19.04px;
								color: #D43C33;
							}
						}
						
						textarea {
							width: 100%;
							padding: 20px 15px;
							border-radius: 5px;
							border: solid 1px #A4A4A4;
							resize: none;
							height: 234px;
							@include media-breakpoint-down(md) {
								height: 184px;
							}
							
							&::placeholder {
								font-size: 16px;
								font-weight: 400;
								line-height: 21.76px;
								color: #51535866;
							}
						}
						
						.wpcf7-submit {
							align-self: flex-end;
							width: fit-content;
							background: #045E51;
							border-radius: 93.27px;
							font-size: 17.1px;
							font-weight: 600;
							line-height: 23.26px;
							color: $white;
							padding: 21px 69px;
							cursor: pointer;
							@include media-breakpoint-down(md) {
								margin: 9px auto;
								padding: 21px;
								min-width: 238px;
								font-size: 16px;
								font-weight: 700;
								line-height: 130%;
								letter-spacing: 0.48px;
								text-align: center;
							}
						}
					}
				}
			}
			
			.info-wrapper {
				width: 100%;
				max-width: 615px;
				display: flex;
				flex-direction: column;
				gap: 42px;
				padding: 42px 39px 42px 55px;
				box-shadow: 0 0 18.83px 0 #14063C26;
				border-radius: 11.29px;
				margin: 20px 0;
				@include media-breakpoint-down(md) {
					display: none;
				}
				
				.info-title {
					font-size: 35px;
					font-weight: 700;
					line-height: 47.6px;
					color: $text-color;
					margin-bottom: -6px;
				}
				
				.info-item {
					display: flex;
					flex-direction: column;
					font-size: 18px;
					font-weight: 400;
					line-height: 23.4px;
					letter-spacing: 0.03em;
					color: $text-color;
					
					.info-label {
						font-size: 18px;
						font-weight: 700;
						line-height: 23.4px;
						letter-spacing: 0.03em;
					}
					
					a {
						color: $text-color;
					}
				}
				
				.info-description {
					font-size: 18px;
					font-weight: 400;
					line-height: 25px;
					color: $text-color;
				}
			}
		}
	}
}