@import "../base/_mixins.scss";
@import "../base/_variables.scss";
@import "../base/_colors.scss";

#footer {
  margin-top: 200px;
  @include media-breakpoint-down(lg) {
    margin-top: 0px;
  }
  @include media-breakpoint-down(lg) {
    margin-top: 100px;
  }

  .footer-top {
    background: $green-400;
    @include media-breakpoint-down(lg) {
      padding: 30px 0px;
    }

    @include media-breakpoint-down(md) {
      padding-top: 0;
    }

    > .container-md {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 65px;
      margin-bottom: -70px;
      @include media-breakpoint-down(md) {
        gap: 0px;
        height: 420px;
        margin-top: 280px;
        margin-bottom: -350px;
        transform: translateY(-360px);
        align-items: end;
      }

      .our-app {
        display: flex;
        transform: translateY(-50%);
        border-radius: 8px;
        background: $white;
        box-shadow: $box-shadow-small;
        border-radius: 8px;
        overflow: hidden;
        min-height: 218px;
        max-width: 588px;
        width: 100%;
        @include media-breakpoint-down(md) {
          min-height: unset;
          max-width: unset;
          width: max-content;
          transform: translateY(0);
        }

        .image {
          width: 256px;
          flex-shrink: 0;
          position: relative;
          @include media-breakpoint-down(md) {
            width: 131px;
            border-radius: 10px;
            overflow: hidden;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
          }
        }

        .content {
          display: flex;
          flex-flow: column;
          width: 100%;
          padding: 40px 46px 40px 13px;
          @include media-breakpoint-down(md) {
            padding: 25px 25px 17px 17px;
          }

          .title {
            display: flex;
            align-items: center;
            gap: 14px;
            margin-bottom: 17px;
            @include media-breakpoint-down(md) {
              margin-bottom: unset;

              img {
                display: none;
              }
            }

            h5 {
              font-size: 24px;
              line-height: 125%;
              @include media-breakpoint-down(md) {
                font-size: 17px;
                line-height: 18px;
                letter-spacing: 0.03em;
                font-weight: normal;
              }
            }
          }

          .text {
            font-size: 18px;
            line-height: normal;
            letter-spacing: 0.03em;
            margin-bottom: 25px;
            @include media-breakpoint-down(md) {
              font-size: 17px;
            }
          }

          .app-btn {
            font-size: 18px;
            padding: 11px 20px;
            width: max-content;
            @include media-breakpoint-down(md) {
              font-size: 16px;
            }
          }
        }
      }

      .contact-form-7 {
        display: flex;
        transform: translateY(-50%);
        border-radius: 8px;
        background: $white;
        box-shadow: $box-shadow-small;
        border-radius: 8px;
        overflow: hidden;
        min-height: 218px;
        max-width: 840px;
        width: 100%;

        @include media-breakpoint-down(md) {
          padding: 18px 12px;
          transform: translateY(0);
          min-height: unset;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 14px;
          margin-bottom: 30px;
          @include media-breakpoint-down(md) {
            justify-content: center;
            margin-bottom: 24px;
            h5 {
              text-align: center;
            }
          }

          h5 {
            font-size: 24px;
            line-height: 125%;
          }
        }

        .image {
          width: 365px;
          flex-shrink: 0;
          position: relative;
          @include media-breakpoint-down(lg) {
            display: none;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
          }
        }

        .form {
          display: flex;
          flex-flow: column;
          padding: 20px 47px 20px 36px;
          width: 100%;
          @include media-breakpoint-down(md) {
            padding: 0;
          }

          .screen-reader-response {
            display: none;
          }

          form {
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1000px;
            @include media-breakpoint-down(lg) {
              align-items: flex-start;
              flex-direction: column;
              gap: 20px;
            }
            @include media-breakpoint-down(md) {
              gap: 0;
            }

            &.invalid {
              .wpcf7-response-output {
                color: $red-400;
              }
            }

            &.sent {
              .wpcf7-response-output {
                color: $green-300;
              }
            }

            .wpcf7-not-valid-tip {
              display: none;
            }

            .wpcf7-response-output {
              font-size: 16px;
              line-height: normal;
              margin-top: 15px;
            }

            .form-group {
              display: flex;
              width: 100%;
              gap: 30px;
              position: relative;
            }

            .input {
              width: 100%;

              input {
                color: $black;

                @include media-breakpoint-down(md) {
                  font-size: 14px;
                  padding-inline-end: 160px;
                  border-radius: 100px;
                  &::placeholder {
                    color: #c4c4c4;
                  }
                }
              }
            }

            .submit {
              @include media-breakpoint-down(md) {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
              }

              input {
                padding: 10.882px 33.168px;
                font-size: 18px;
                @include media-breakpoint-down(md) {
                  font-size: 16px;
                  height: 45px;
                }
              }
            }

            .agreement {
              margin-top: 30px;
              @include media-breakpoint-down(md) {
                margin-top: 16px;
              }

              .checkbox {
                .wpcf7-list-item-label {
                  @include media-breakpoint-down(md) {
                    font-size: 14px;
                  }

                  &::before {
                    border-radius: 0px !important;
                  }
                }
              }
            }
          }
        }
      }
    }

    .entertainment {
      padding-top: 70px;
      @include media-breakpoint-down(md) {
        padding-top: 50px;
      }

      .heading {
        text-align: center;
        margin-bottom: 36px;

        h2 {
          color: $white;
          @include media-breakpoint-down(md) {
            font-size: 24px;
          }
        }
      }

      .cards {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(5, 1fr);
        margin-bottom: 50px;
        @include media-breakpoint-down(md) {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }

        .card {
          width: 170px;
          margin: 0px auto;
          text-align: center;
          @include media-breakpoint-down(md) {
            width: calc(50% - 10px);
          }

          .image {
            display: inline-block;
            margin-bottom: 12px;

            img {
              @include media-breakpoint-down(md) {
                height: 42px;
              }
            }
          }

          .title {
            h5 {
              color: $white;
              @include media-breakpoint-down(md) {
                font-size: 14px;
              }
            }
          }
        }
      }

      .navigation {
        display: grid;
        justify-content: center;
        grid-template-columns: repeat(6, 1fr);
        border-top: 1px solid $white;
        padding-top: 50px;
        padding-bottom: 60px;
        gap: 10px;
        @include media-breakpoint-down(xl) {
          grid-template-columns: repeat(4, 1fr);
          place-self: center;
        }
        @include media-breakpoint-down(lg) {
          grid-template-columns: repeat(3, 1fr);
        }
        @include media-breakpoint-down(md) {
          padding-top: 20px;
          padding-bottom: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
        }

        .menu {
          width: 170px;
          margin: 0px auto;

          @include media-breakpoint-down(xl) {
            margin-bottom: 30px;
            &:nth-child(5) {
              grid-column: 2/3;
            }
            &:nth-child(6) {
              grid-column: 3/4;
            }
          }
          @include media-breakpoint-down(lg) {
            &:nth-child(5) {
              grid-column: inherit;
            }
            &:nth-child(6) {
              grid-column: inherit;
            }
          }
          @include media-breakpoint-down(md) {
            &:first-child {
              order: 1;
            }
          }

          h5 {
            color: $white;
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
          }

          ul {
            list-style: none;
            padding: 0px;
            margin: 0px;

            li {
              a {
                color: $white;
                text-align: right;
                font-family: $Inter;
                font-size: 16px;
                font-weight: 400;
                line-height: 29px;

                img {
                  height: 38px;
                  object-fit: contain;
                }
              }
            }
          }
        }
      }
    }
  }

  .footer-bottom {
    background-color: $grey-500;
    padding: 20px;
    @include media-breakpoint-down(md) {
      padding: 16px 6px;
    }

    .container-md {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 30px;

      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
      }
      @include media-breakpoint-down(md) {
        flex-direction: column-reverse;
        flex-wrap: wrap;
      }
    }

    .logos {
      display: flex;
      align-items: center;
      gap: 30px;
      max-width: 1000px;
      grid-row: 1/3;
      @include media-breakpoint-down(lg) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(3, 1fr);
        place-items: center;
        width: 100%;
        gap: 20px;
      }

      @include media-breakpoint-down(md) {
        display: flex;
        flex-wrap: wrap-reverse;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 10px 5px;
      }

      .logo {
        text-align: center;
        width: 100%;

        &.madebyomnis-logo {
          a {
            width: max-content;
          }

          img {
            height: 35px;
            @include media-breakpoint-down(lg) {
              height: inherit;
            }
          }

          @include media-breakpoint-down(md) {
            flex-basis: 100%;
          }
        }

        img {
          width: 100%;
          height: 60px;
          object-fit: contain;
          @include media-breakpoint-down(lg) {
            grid-row: 3/3;
            height: inherit;
          }
          @include media-breakpoint-down(md) {
            max-height: 32px;
            width: auto;
          }
        }

        @include media-breakpoint-down(lg) {
          grid-row: 3/3;
          &:nth-child(4) {
            grid-row: 2/3;
            grid-column: 2/4;
          }
          &:nth-child(5) {
            grid-row: 1/2;
            grid-column: 1/3;
          }
          &:nth-child(6) {
            grid-row: 1/2;
            grid-column: 3/3;
          }
        }

        @include media-breakpoint-down(md) {
          width: calc(100% / 3 - 10px);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .cards {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-shrink: 0;

      @include media-breakpoint-down(lg) {
        display: grid;
        gap: 20px;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        grid-template-columns: repeat(4, 1fr);
      }

      .card {
        a {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
