@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.photos-and-videos-tab {
  .filter {
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    background: $white;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    height: 70px;

    .container-md {
      display: flex;
      align-items: center;
      position: relative;
    }

    .sorting {
      display: flex;
      align-items: center;
      height: 70px;

      .title {
        color: $grey-200;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;
      }

      .select {
        height: 100%;
        display: inline-flex;
        align-items: center;
        gap: 114px;
        border-left: 1px solid $grey-200;
        position: relative;
        cursor: pointer;

        &.open {
          .label {
            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .label {
          display: flex;
          align-items: center;
          gap: 60px;
          padding: 0px 30px;
          height: 100%;
          position: relative;
          z-index: 6;

          .arrow {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            transition: transform 0.3s ease;
          }

          .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            letter-spacing: 0.48px;
          }
        }

        .drop-down {
          display: none;
          background: $white;
          width: 100%;
          flex-shrink: 0;
          position: absolute;
          top: 100%;
          left: 0px;
          z-index: 3;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
          padding: 25px;
          padding-top: 50px;
          padding: 16px 26px;

          ul {
            list-style: none;
            margin: 0px;
            padding: 0px;
            display: flex;
            flex-direction: column;
            gap: 15px;

            li {
              a {
                transition: $transition-all-linear;

                &:hover {
                  color: $green-500;
                }
              }
            }
          }
        }
      }
    }
  }

  .order-by {
    margin-bottom: 30px;

    .container-md {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      color: $grey-400;
      text-align: right;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.6px;
    }

    .select {
      label {
        color: $grey-400;
        font-size: 16px;
        margin-left: 20px;
      }
    }
  }

  .photos-and-videos {
    display: flex;
    flex-direction: column;
    gap: 100px;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      gap: 60px 0;
    }

    .video {
      & + * {
        margin-top: 60px;
      }

      .title {
        color: $grey-400;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.6px;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          text-align: center;
        }
      }

      .video-container {
        position: relative;

        .play-button {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 100px;
          opacity: 0.8;
          background: $white;
          border: 0px;
          padding: 16px 38px;
          color: $grey-400;
          font-size: 22px;
          line-height: 22px;
          font-weight: 600;
          cursor: pointer;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            width: 192px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 8px;
            padding: 0;
          }

          i {
            margin-right: 18px;

            @include media-breakpoint-down(md) {
              font-size: 22px;
              color: $text-color;
            }
          }
        }

        .pause-button {
          display: none;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          border-radius: 100px;
          opacity: 0.8;
          background: $white;
          border: 0px;
          padding: 16px 38px;
          color: $grey-400;
          font-size: 22px;
          line-height: 22px;
          font-weight: 600;
          cursor: pointer;

          i {
            margin-right: 18px;
          }
        }

        .plyr {
          height: 799px;

          @include media-breakpoint-down(md) {
            height: auto;
          }
        }
      }
    }

    .gallery-first-type {
      margin-top: 60px;

      .title {
        color: $grey-400;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.6px;
        margin-bottom: 30px;
      }

      .grid {
        margin: 0 auto;
        display: grid;
        gap: 6px;

        &:after {
          content: '';
          display: block;
          clear: both;
        }

        &-item {

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412px;
          }

          &:nth-child(2) {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412px;
          }

          &:nth-child(3) {
            grid-column-start: 9;
            grid-column-end: 12;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412px;
          }

          &:nth-child(4) {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 490px;
          }

          &:nth-child(5) {
            grid-column-start: 5;
            grid-column-end: 8;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 490px;

          }

          &:nth-child(6) {
            grid-column-start: 8;
            grid-column-end: 12;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 490px;
          }

          &:nth-child(7) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 5;
            grid-row-end: 7;
            height: 350px;
          }

          &:nth-child(8) {
            grid-column-start: 4;
            grid-column-end: 7;
            grid-row-start: 5;
            grid-row-end: 7;
            height: 350px;
          }

          &:nth-child(9) {
            grid-column-start: 7;
            grid-column-end: 12;
            grid-row-start: 5;
            grid-row-end: 7;
            height: 350px;
          }
        }

        @include media-breakpoint-down(md) {
          display: flex;
          flex-wrap: wrap;
          gap: 0 2px;

          &-item {
            width: calc(50% - 1px);
            height: auto !important;

            img {
              width: 100%;
              height: 136px;
              object-fit: cover;
            }

            &:nth-child(3n) {
              width: 100%;

              img {
                height: 274px;
              }
            }
          }
        }
      }
    }

    .gallery-second-type {
      margin-top: 60px;

      .title {
        color: $grey-400;
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.6px;
        margin-bottom: 30px;
      }

      .grid {
        display: grid;
        gap: 6px;

        .image {
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          &:nth-child(1) {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412.19px;
          }

          &:nth-child(2) {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412.19px;
          }

          &:nth-child(3) {
            grid-column-start: 9;
            grid-column-end: 12;
            grid-row-start: 1;
            grid-row-end: 3;
            height: 412.19px;
          }

          &:nth-child(4) {
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 497.89px;
          }

          &:nth-child(5) {
            grid-column-start: 5;
            grid-column-end: 8;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 497.89px;

          }

          &:nth-child(6) {
            grid-column-start: 8;
            grid-column-end: 12;
            grid-row-start: 3;
            grid-row-end: 5;
            height: 497.89px;
          }

          &:nth-child(7) {
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row-start: 5;
            grid-row-end: 7;
            height: 353px;
          }

          &:nth-child(8) {
            grid-column-start: 4;
            grid-column-end: 12;
            grid-row-start: 5;
            grid-row-end: 7;
            height: 353px;
          }
        }

        @include media-breakpoint-down(md) {
          display: flex;
          flex-wrap: wrap;
          gap: 0 2px;
          height: auto;

          .image {
            width: calc(50% - 1px);
            height: auto !important;

            img {
              width: 100%;
              height: 136px;
              object-fit: cover;
            }

            &:nth-child(3n) {
              width: 100%;

              img {
                height: 274px;
              }
            }
          }
        }
      }
    }
  }

  .discover-other-parks {
    margin-bottom: 80px;

    .heading {
      margin-bottom: 30px;

      h2 {
        color: $green-400;
        font-size: 35px;
        font-weight: 700;
        @include media-breakpoint-down(md) {
          font-size: 24px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: 0em;
          text-align: right;
        }
      }
    }

    .tabs {
      .tabs-nav {
        display: flex;
        gap: 0px;
        margin-bottom: 30px;
        z-index: 1;
        position: relative;
        background-color: $grey-100;
        padding: 0px 30px;
        overflow: auto;

        @include media-breakpoint-down(sm) {
          padding: 0px;
          justify-content: center;
          gap: 0 40px;
          border-radius: 8px;
        }

        .tab {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 20px 10px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;

          @include media-breakpoint-down(sm) {
            font-size: 20px;
            margin: 0;
            padding: 15px 10px;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;
          }
        }
      }

      .tabs-contents {
        .tab-content {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    .slider {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      .slide {
        overflow: hidden;
        height: auto;
        border-radius: 14px;
        background-color: $white;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);

        @include media-breakpoint-down(md) {
          margin-bottom: 12px;
          border-radius: 6.762px;
          height: auto;
        }

        .image {
          img {
            width: 100%;
            height: 206px;
            object-fit: cover;

            @include media-breakpoint-down(md) {
              height: 139px;
              border-radius: 6.762px;
            }
          }
        }

        .text {
          padding: 16px 32px;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 12px;
          }

          .title {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 10px;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }

            a {
              color: $grey-400;
            }

            .status {
              padding: 8px;
              border-radius: 3px;
              background: $green-100;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 13px;
              letter-spacing: 0.3px;
            }
          }

          .description {
            margin-bottom: 8px;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey-400;

              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
          }

          .date {
            display: flex;
            gap: 10px;
            margin-bottom: 8px;
            color: $grey-400;
            font-size: 18px;
            line-height: 23.4px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          .cost {
            margin-left: 30px;

            .price {
              display: flex;
              gap: 5px;
              align-items: flex-end;

              .title {
                color: $grey-200;
                font-size: 15px;
                font-weight: 400;
                line-height: 22.5px;
                letter-spacing: -0.45px;
                margin: 0px;
              }

              .info {
                color: $grey-300;
                align-self: flex-start;
              }

              .was {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: $grey-400;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 17.357px;
                text-decoration: line-through;
              }

              .now {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: #e85481;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0.6px;
              }
            }
          }
        }

        .button {
          margin-top: auto;
        }
      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
          padding-left: 80px;
        }
      }

      .slick-slide {
        margin: 0 15px;
      }

      .slick-track {
        padding: 10px 0px;
        display: flex !important;
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        left: -70px;

        @include media-breakpoint-down(md) {
          display: none !important;
        }

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        right: -70px;

        @include media-breakpoint-down(md) {
          display: none;
        }

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;

        a {
          padding: 17px 30px;
          font-size: 16px;
        }
      }
    }
  }
}