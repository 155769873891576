@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-template-apartments-page {
	.page-head {
		display: flex;
		justify-content: center;
		background: $green-100;
		padding: 38px 0;
		
		h2 {
			color: $green-350;
			font-size: 35px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
		
		@include media-breakpoint-down(md) {
			
			h2 {
				font-size: 32px;
			}
		}
	}
	
	.secondary-menu {
		background: $white;
		box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
		
		.accommodation-menu {

			ul {
				display: flex;
				list-style: none;
				margin: 0;
				padding: 0;

				li {
					padding: 30px 20px;
					position: relative;

					&.current-menu-item {
						border-bottom: solid 5px $green-400;

						a {
							color: $green-400;
							font-size: 18px;
							font-style: normal;
							font-weight: 500;
							line-height: normal;
						}
					}

					&:not(:last-child)::after {
						content: '';
						position: absolute;
						background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" fill="none"><circle cx="4.5" cy="4.5" r="4.5" fill="black"/></svg>');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
						top: 0;
						left: -5px;
						width: 10px;
						height: 100%;
					}

					a {
						color: $black;
						font-size: 18px;
						font-style: normal;
						font-weight: 300;
						line-height: normal;
					}
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			
			.accommodation-menu {
				ul{
					gap: 30px;
					flex-wrap: wrap;

					li {
						padding: 20px 20px;

						&.current-menu-item {

							a {
								font-size: 12px;
							}
						}

						&:not(:last-child)::after {
							left: -20px;
						}

						a {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
	
	.hero {
		padding: 80px 0 40px 0;
		
		.container-md {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
		
		h1 {
			color: $black;
			font-size: 50px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			padding-bottom: 35px;
			position: relative;
			
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				width: 100px;
				height: 2px;
				background: $black;
			}
		}
		
		.subtitle {
			padding: 20px 0 30px 0;
			
			p {
				color: $black;
				font-size: 25px;
				font-style: normal;
				font-weight: 300;
				line-height: normal;
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: 40px 0;
			
			h1 {
				text-align: center;
				font-size: 32px;
				padding-bottom: 16px;
			}
			
			.subtitle {
				
				p {
					text-align: center;
					font-size: 16px;
				}
			}
		}
	}
	
	.apartments {
		padding: 35px 0 45px 0;
		
		.apartments-list {
			display: flex;
			flex-direction: column;
			gap: 30px;
			
			.apartments-item {
				display: flex;
				border-radius: 11.292px;
				background: $white;
				box-shadow: 0 0 18.83px 0 rgba(20, 6, 60, 0.15);
				min-height: 415px;
				overflow: hidden;
				
				.apartments-item-image {
					width: 60%;
					
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
				
				.apartments-item-info {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex: 1;
					
					.apartments-item-info-top {
						display: flex;
						flex-direction: column;
						gap: 30px;
						padding: 42px 55px;
						background: $white;
						
						h3 {
							color: $text-color;
							font-size: 26.451px;
							font-style: normal;
							font-weight: 700;
							line-height: 130%;
							letter-spacing: 0.794px;
						}
						
						.subtitle {
							p {
								color: $text-color;
								font-size: 17.634px;
								font-style: normal;
								font-weight: 400;
								line-height: 130%;
								letter-spacing: 0.529px;
							}
						}
						
						.description {
							strong {
								color: $text-color;
								font-size: 17.634px;
								font-style: normal;
								font-weight: 700;
								line-height: 130%;
								letter-spacing: 0.529px;
							}
							
							p {
								color: $text-color;
								font-size: 17.634px;
								font-style: normal;
								font-weight: 400;
								line-height: 130%;
								letter-spacing: 0.529px;
							}
						}
					}
					
					.apartments-item-info-bottom {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						grid-template-rows: repeat(2, 1fr);
						grid-column-gap: 0px;
						grid-row-gap: 0px;
						background: $grey-250;
						padding: 25px 55px;
						
						.apartments-item-info-people {
							display: flex;
							align-items: center;
							gap: 7px;
							grid-area: 1 / 1 / 3 / 2;
							
							span {
								color: $text-color;
								font-size: 15.43px;
								font-style: normal;
								font-weight: 400;
								line-height: 130%;
								letter-spacing: 0.463px;
							}
						}
						
						.apartments-item-info-parks {
							grid-area: 1 / 2 / 2 / 3;
							display: flex;
							justify-content: center;
							
							span {
								color: $text-color;
								font-size: 15.43px;
								font-style: normal;
								font-weight: 400;
								line-height: 130%;
								letter-spacing: 0.463px;
							}
						}
						
						.apartments-item-info-link {
							grid-area: 2 / 2 / 3 / 3;
							
							a {
								display: flex;
								padding: 15.855px 78.49px 14.046px 78.895px;
								justify-content: center;
								align-items: center;
								flex-shrink: 0;
								border-radius: 61.718px;
								background: $green-400;
								color: $white;
							}
						}
					}
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			padding: 0 0 75px 0;
			
			.apartments-list {
				gap: 20px;
				
				.apartments-item {
					flex-direction: column;
					min-height: auto;
					
					.apartments-item-image {
						width: 100%;
					}
					
					.apartments-item-info {
						
						.apartments-item-info-top {
							gap: 8px;
							padding: 10px 14px;
							
							h3 {
								font-size: 24px;
								letter-spacing: 0.72px;;
							}
							
							.subtitle {
								padding-bottom: 10px;
								
								p {
									font-size: 16px;
									letter-spacing: 0.48px;
								}
							}
						}
						
						.apartments-item-info-bottom {
							padding: 10px 20px 20px 20px;

							.apartments-item-info-people {
								display: flex;
								align-items: center;
								justify-content: center;
								grid-area: 1 / 1 / 2 / 2;
							}
							
							.apartments-item-info-parks {
								display: flex;
								align-items: center;
								justify-content: center;
								grid-area: 1 / 2 / 2 / 3;
							}
							
							.apartments-item-info-link {
								grid-area: 2 / 1 / 3 / 3;
							}
						}
					}
				}
			}
		}
	}
	
	.cta {
		padding-bottom: 50px;
		
		.container-md {
			.cta-wrapper {
				display: flex;
				background: $green-400;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 20px;
				padding: 30px 20px;
				
				.cta-title {
					font-size: 26px;
					font-weight: 700;
					line-height: 34px;
					letter-spacing: 0.03em;
					text-align: center;
					color: $white;
				}
				
				.cta-description {
					font-size: 18px;
					font-weight: 400;
					line-height: 23px;
					letter-spacing: 0.03em;
					text-align: center;
					color: $white;
				}
				
				.cta-link {
					padding: 15px 45px;
					background: $white;
					font-size: 18px;
					font-weight: 700;
					line-height: 23px;
					letter-spacing: 0.03em;
					text-align: center;
					color: $green-400;
					border-radius: 62px;
				}
			}
		}
		
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
}