@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/colors.scss";

.card-apartment {
  border-radius: 5px;
  background: $grey-50;
  height: 315px;
  flex-shrink: 0;
  padding: 20px;
  .checkbox {
    margin-bottom: 10px;
  }
  .thumbnail {
    margin-bottom: 10px;
  }
}
