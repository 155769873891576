@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.flexible-contents {
  .flexible-contents-banner {
    @include media-breakpoint-down(sm) {
      min-height: 200px;
      object-fit: cover;
      height: 100%;
      object-position: center center;
    }
  }

  .container {
    max-width: 1530px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .head {
    text-align: center;
    margin: 50px 0 80px 0;

    .breadcrumb {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.04px;
      margin-bottom: 38px;

      a {
        color: rgba(81, 83, 88, 1);
      }

      i {

      }

      span {
        color: rgba(40, 93, 81, 1);
      }
    }

    h1 {
      font-size: 50px;
      font-weight: 700;
      line-height: 68px;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      position: relative;
      padding-bottom: 20px;

      &:after {
        content: '';
        width: 32px;
        height: 1px;
        background: rgba(158, 160, 161, 1);
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
      }
    }
  }

  .text-content {
    margin: 60px 0;
    color: #404040;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;

    .container {
      max-width: 1190px;
    }

    strong, b {
      color: #000;
    }

    a {
      color: #025F52;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        color: #000;
      }
    }

    p {
      margin-bottom: 23px;
    }

    h2, h3 {
      color: #000000;
      font-size: 32px;
      font-weight: 700;
      line-height: 43.52px;
      margin-bottom: 13px;
    }

    h3 {
      color: #000000;
      font-size: 20px;
      font-weight: 700;
      line-height: 27px;
      margin-bottom: 13px;

      @include media-breakpoint-down(sm) {
        text-align: unset !important;
      }

    }

    ul {
      padding: 0;

      li {
        display: block;
        position: relative;
        padding-right: 20px;

        &:before {
          content: '';
          width: 5px;
          height: 5px;
          display: block;
          border-radius: 50%;
          background: #025F52;
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

  }

  .image-carousel {
    margin: 70px 0;
    overflow: hidden;

    .carousel {
      .slick-list {
        margin: 0 -20px;
        @include media-breakpoint-down(sm) {
          padding: 0 0 0 20% !important;
          margin: 0;
        }
      }

      .slick-track {
        display: flex;
        gap: 0 30px;
      }

      .slick-arrow {
        height: 70px;
        width: 70px;
        background: rgba(217, 217, 217, 1);
        border-radius: 50%;
        box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10;

        &:before {
          display: none;
        }

        &.slick-next {
          left: -90px;
        }

        &.slick-prev {
          right: -90px;
        }
      }

      .item {
        border-radius: 16px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
        width: 360px;
        height: 300px;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .video-content {
    margin: 60px 0;

    .container {
      max-width: 1190px;
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .video-container {
      position: relative;

      iframe, video {
        height: 690px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
      }

      .player-nav {
        z-index: 8;
        left: 15px;
        bottom: 18px;
        position: absolute;
        width: auto;

        button {
          width: 68px;
          height: 42px;
          background: #000;
          color: #fff;
          font-size: 25px;
          border-radius: 4px;
          border: none;
          cursor: pointer;
        }
      }
    }
  }

  .image-content {
    margin: 60px 0;

    .container {
      max-width: 1190px;
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }

    .image-container {
      position: relative;

      img {
        height: 690px;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
        @include media-breakpoint-down(sm) {
          border-radius: 0;
          height: auto;
        }
      }

    }
  }

  .quote-content {
    margin: 70px 0;

    .box {
      background: #FAFCFC;
      padding: 40px;
      border-radius: 30px;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);

      .content {
        background: #fff;
        border-radius: 30px;
        color: #404040;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;

        h2, h3 {
          color: #000000;
          font-size: 32px;
          font-weight: 700;
          line-height: 43.52px;
          margin-bottom: 18px;
        }
      }
    }
  }

  .cards-content {
    padding: 48px 0;
    background: rgba(243, 245, 245, 1);

    .heading {
      font-size: 32px;
      font-weight: 700;
      line-height: 43.52px;
      text-align: right;
      color: #000000;
      margin-bottom: 32px;
    }

    .description {
      font-size: 18px;
      font-weight: 400;
      line-height: 25px;
      margin-bottom: 24px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 32px;

      .item {
        width: calc(100% / 4 - 24px);

        .box {
          background: #fff;
          border-radius: 16px;
          overflow: hidden;
          width: 100%;
          display: flex;
          flex-direction: column;
          box-shadow: 0 0 20px 0 #cdcdcd;
          height: 100%;

          .image {
            border-radius: 16px 16px 8px 8px;
            overflow: hidden;
            height: 260px;
            background: #cdcdcd;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }

          .content {
            padding: 24px 24px 90px 24px;
            color: #404040;
            position: relative;
            flex: 1;

            .title {
              font-size: 20px;
              font-weight: 700;
              line-height: 26px;
              letter-spacing: 0.03em;
              margin-bottom: 6px;
            }

            .desc {
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }

            .btns {
              position: absolute;
              bottom: 24px;

              a {
                height: 47px;
                padding: 13px 30px 13px 30px;
                gap: 0px;
                border-radius: 50px;
                border: 0.8px solid #404040;
                font-size: 16px;
                font-weight: 700;
                line-height: 20.8px;
                letter-spacing: 0.03em;
                color: #404040;
                display: inline-flex;

                &:active {
                  box-shadow: 0 0 10px 0 #025f52;
                }

                &:hover {
                  background-color: #003a32;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }

    &.carousel {
      background: transparent;
      padding: 0;

      &[data-carousel_arrow="1"] {
        .container {
          max-width: 1190px;
        }
      }

      .heading {
        margin-bottom: 16px;
      }

      .list {
        //display: block;

        .slick-list {
          width: 100%;

          @include media-breakpoint-down(sm) {
            padding: 0 0 0 20% !important;
          }
        }

        .slick-track {
          // width: 100% !important;
        }

        .slick-arrow {
          height: 70px;
          width: 70px;
          background: rgba(217, 217, 217, 1);
          border-radius: 50%;
          box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.14);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;

          &:before {
            display: none;
          }

          &.slick-next {
            left: -90px;
          }

          &.slick-prev {
            right: -90px;
          }
        }

        .item {
          //opacity: 0;
          transition: all 0.3s;
          width: calc(100% / 3 - 24px);
          min-width: 360px;
          padding: 16px;

          @include media-breakpoint-down(sm) {
            min-width: unset;
          }

          &.slick-active {
            //opacity: 1;
          }

          .box {

          }
        }
      }
    }
  }

  .form-content {
    margin: 70px 0;

    .container {
      max-width: 1190px;
    }

    .box {
      background: #FAFCFC;
      padding: 40px;
      border-radius: 30px;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);

      .heading {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: 0.03em;
        color: #404040;
        margin-bottom: 24px;
      }

      .content {

        .screen-reader-response {
          display: none;
        }

        form {
          display: flex;
          gap: 24px 32px;
          align-items: end;

          @include media-breakpoint-down(lg) {
            flex-wrap: wrap;
          }
          @include media-breakpoint-down(md) {
            flex-direction: column;
          }

          & > font {
            @include media-breakpoint-down(lg) {
              flex-basis: 47%;
            }
          }

          label {
            flex: 1;
            color: #515358;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.76px;
            position: relative;

            @include media-breakpoint-down(lg) {
              flex-basis: 47%;
            }
            @include media-breakpoint-down(md) {
              width: 100%;
            }


            .wpcf7-not-valid-tip {
              position: absolute;
              font-size: 12px;
              top: 100%;
              right: 0;
            }

            input {
              height: 46px;
              border-radius: 5px;
              border: 1px solid #A4A4A4;
              width: 100%;
              margin-top: 4px;
            }
          }

          .wpcf7-submit {
            background: #045E51;
            color: #fff;
            font-size: 17.1px;
            font-weight: 600;
            line-height: 23.26px;
            height: 46px;
            border-radius: 50px;
            border: none;
            text-align: center;
            width: 140px;
            cursor: pointer;

            &:hover {
              background-color: #003a32;
            }

            &:active {
              box-shadow: 0 0 10px 0 #025f52;
            }
          }
        }
      }
    }
  }
}