@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.search-page, .search-packages {
  .banner {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    height: 202px;

    @include media-breakpoint-down(lg) {
      height: auto;
      padding: 24px 0;
      background-color: #cadac7;
    }

    .container-md {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;

      .search {
        width: 100%;
      }
    }
  }

  .pre-filter {
    display: none;

    @include media-breakpoint-down(md) {
      display: flex;
      justify-content: space-between;
      background: $white;
      box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
      padding: 10px 16px;
      align-items: center;

      .start-filter {
        border-radius: 8px;
        border: 1px solid $grey-200;
        height: 50px;
        width: 203px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0 8px;
      }

      .map {
        color: $black;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        letter-spacing: 0.48px;
        text-decoration-line: underline;
      }
    }
  }

  .filter {
    margin-bottom: 30px;
    width: 100%;
    height: 1px;
    background: $white;
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    height: 70px;

    @include media-breakpoint-down(md) {
      display: none;
    }

    .container-md {
      display: flex;
      align-items: center;
      position: relative;
    }

    .sorting {
      display: flex;
      align-items: center;
      height: 70px;

      .title {
        color: $grey-200;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.48px;
      }

      .select {
        height: 100%;
        display: inline-flex;
        align-items: center;
        gap: 114px;
        border-left: 1px solid $grey-200;
        border-bottom: 5px solid transparent;
        cursor: pointer;

        &.open {
          border-bottom: 5px solid $green-400;

          .label {
            &:before {
              content: "";
              position: absolute;
              transform: translateX(-50%);
              bottom: -15px;
              left: 50%;
              border-color: transparent transparent #fff;
              border-style: solid;
              border-width: 0 10px 10px;
              z-index: 4;
            }

            .arrow {
              transform: rotate(180deg);
            }
          }
        }

        .label {
          display: flex;
          align-items: center;
          gap: 60px;
          padding: 0px 30px;
          height: 100%;
          position: relative;
          z-index: 6;

          .arrow {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            transition: transform 0.3s ease;
          }

          .text {
            font-size: 16px;
            font-weight: 500;
            line-height: 20.8px;
            letter-spacing: 0.48px;
          }
        }

        .drop-down {
          display: none;
          border-radius: 5px;
          background: $white;
          width: calc(100% - 20px);
          flex-shrink: 0;
          position: absolute;
          top: 100%;
          left: 10px;
          z-index: 3;
          box-shadow: $box-shadow;
          margin-top: 10px;
          padding: 25px;
          padding-top: 50px;

          .close {
            position: absolute;
            left: 10px;
            top: 10px;
            cursor: pointer;
          }

          .content {
            .checkboxes-rectangle {
              margin-bottom: 50px;
            }

            .tabs {
              .tabs-nav {
                display: flex;
                gap: 0px;
                margin-bottom: 30px;
                z-index: 1;
                position: relative;
                border-bottom: 1px solid $grey-200;
                overflow: auto;

                @include media-breakpoint-down(sm) {
                  padding: 0px 50px;
                }

                .tab {
                  margin-left: 50px;
                  color: $grey-400;
                  text-align: center;
                  font-family: "Almoni", sans-serif;
                  font-size: 22px;
                  font-weight: 400;
                  cursor: pointer;
                  display: flex;
                  padding: 10px 50px;
                  align-items: center;
                  gap: 10px;
                  border-bottom: 6px solid transparent;
                  text-align: center;

                  span {
                    white-space: nowrap;
                  }

                  position: relative;

                  img {
                    position: absolute;
                    right: 0px;
                  }

                  &.active {
                    border-bottom: 6px solid $green-400;
                    color: $green-400;
                    font-weight: 700;
                  }
                }
              }

              .tabs-contents {
                .tab-content {
                  display: none;

                  &.active {
                    display: block;
                  }
                }
              }
            }

            .form {
              .checkboxes-rectangle {
                .checkboxes-horizontal {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);
                }
              }

              .apartments {
                margin-bottom: 35px;

                .cards {
                  display: grid;
                  grid-template-columns: repeat(4, 1fr);
                  gap: 20px;
                }
              }

              .nav {
                display: flex;
                justify-content: flex-end;
                gap: 30px;
                margin-top: 50px;

                .reset {
                  outline: none;
                  background: none;
                  border: none;
                  color: $green-400;
                  text-align: center;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20.8px;
                  letter-spacing: 0.48px;
                  text-decoration-line: underline;
                }
              }
            }
          }
        }
      }

      .range {
        direction: ltr;
      }
    }
  }

  .order-by {
    margin-bottom: 30px;
    margin-top: 30px;

    @include media-breakpoint-down(md) {
      padding: 16px 0;
      margin-bottom: 7px;
      margin-top: 7px;
    }

    .container-md {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .title {
      color: $grey-400;
      text-align: right;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.6px;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }

    .select {
      display: flex;
      min-width: 300px;
      align-items: baseline;
      @include media-breakpoint-down(md) {
        min-width: unset;
      }
      .chosen-container{
        @include media-breakpoint-down(md) {
          min-width: auto;
        }
      }
      label {
        color: $grey-400;
        font-size: 16px;
        margin-left: 20px;

        @include media-breakpoint-down(md) {
          margin-left: 8px;
        }
      }

      select {
        @include media-breakpoint-down(md) {
          border-radius: 5px;
          border: 1px solid #A4A4A4;
          width: 130px;
          height: 46px;
          color: #515358;
        }
      }
    }
  }

  .posts {
    .container-md {
      display: flex;
      flex-direction: column;
      gap: 30px;
      min-height: 150px;

      @include media-breakpoint-down(md) {
        gap: 12px;
      }
    }

    .post {
      display: flex;
      gap: 40px;
      border-radius: 9.065px;
      background-color: $white;
      box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
      padding: 22px;
      color: $text-color;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding: 16px;
        gap: 12px;
      }

      .thumbnail {
        flex: 0 1 650px;
        height: 451px;

        @include media-breakpoint-down(md) {
          width: 100%;
          height: 200px;
          flex: unset;
        }

        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      > .text {
        flex: 0 0 777px;
        width: 100%;
        @include media-breakpoint-down(xl) {
          flex-shrink: 1;
        }
        @include media-breakpoint-down(md) {
          flex: unset;
        }

        .title {
          display: flex;
          align-items: center;
          gap: 17px;
          margin-bottom: 5px;

          h4 {
            @include media-breakpoint-down(md) {
              font-size: 24px;
              color: $grey-400;
            }
          }

          .status {
            text-align: center;
            font-size: 13.479px;
            font-weight: 700;
            line-height: 17.523px;
            letter-spacing: 0.404px;
            border-radius: 4px;
            background: #d7ffd0;
            padding: 9px;
          }
        }

        .location-info {
          margin-bottom: 30px;

          h6 {
            @include media-breakpoint-down(md) {
              color: $grey-400;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
          }
        }

        .description {
          @include media-breakpoint-down(md) {
            display: none;
          }

          p {
            margin-bottom: 12px;

            &.sub-title {
              font-size: 20px;
              font-weight: 400;
              line-height: 26px;
            }
          }
        }

        .meta-data {
          display: flex;
          align-items: center;
          gap: 26px;
          margin-top: 16px;
          margin-bottom: 16px;

          @include media-breakpoint-down(md) {
            flex-wrap: wrap;
            gap: 14px 25px;
            color: $grey-400;
          }

          .events {
            display: flex;
            align-items: center;
            gap: 6px;

            .title {
              margin-bottom: 0px;
            }
          }

          .prestige-levels {
            display: flex;
            align-items: center;
            gap: 6px;

            .title {
              margin-bottom: 0px;
            }
          }

          .special-accommodations {
            display: flex;
            align-items: center;
            gap: 6px;

            .title {
              margin-bottom: 0px;
            }
          }
        }

        .read-more {
          margin-bottom: 18px;

          a {
            color: $green-400;
            text-align: right;
            font-size: 14px;
            font-weight: 600;
            line-height: 18.2px;
            letter-spacing: 0.42px;
          }
        }
      }

      .cost {
        display: flex;
        justify-content: space-between;
        border-radius: 8px;
        background: #f3f5f5;
        padding: 20px;
        margin-bottom: 18px;

        @include media-breakpoint-down(md) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 12px;
        }

        .duration-of-stay-and-number-of-people {
          color: $grey-400;
          text-align: right;
          font-size: 12px;
          font-weight: 400;
          line-height: 15.6px;
          letter-spacing: 0.36px;
          margin-top: 20px;

          @include media-breakpoint-down(md) {
            text-align: center;
          }
        }

        .price {
          .summa {
            display: flex;
            align-items: flex-end;
            gap: 10px;
            justify-content: flex-end;

            .main-price {
              display: flex;
              align-items: center;
              gap: 10px;

              color: #e85481;
              text-align: center;
              font-size: 28.844px;
              font-weight: 700;
              line-height: 37.497px;
              letter-spacing: 0.865px;

              i {
                color: $grey-300;
                font-size: 20px;

                @include media-breakpoint-down(md) {
                  color: $grey-600;
                  font-size: 16px;
                }
              }
            }

            .reduced-price {
              display: flex;
              gap: 10px;
              color: $grey-400;
              font-size: 16.688px;
              font-weight: 400;
              line-height: 25.033px;

              @include media-breakpoint-down(md) {
                font-size: 17px;
                gap: 4px;
              }

              .number {
                text-decoration: line-through;
              }

              .currency {
                @include media-breakpoint-down(md) {
                  font-size: 15px;
                }
              }
            }

            .text {
              display: flex;
              gap: 10px;
              color: $grey-400;
              font-size: 16.688px;
              font-weight: 400;
              line-height: 25.033px;

              @include media-breakpoint-down(md) {
                font-size: 15px;
              }
            }
          }

          .price-per-stay {
            color: $grey-400;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            margin-bottom: 12px;
            text-align: left;
          }

          .taxes {
            color: $grey-300;
            font-size: 10px;
            font-weight: 400;
            line-height: 13px;
            letter-spacing: 0.3px;
            text-align: left;

            @include media-breakpoint-down(md) {
              text-align: center;
              justify-content: center;
            }
          }
        }
      }

      .button {
        display: flex;
        justify-content: end;
        align-items: center;

        .info {
          color: $pink-400;
          text-align: right;
          font-size: 12px;
          font-weight: 700;
          line-height: 15.6px;
          letter-spacing: 0.36px;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        button {
          @include media-breakpoint-down(md) {
            width: 100%;
            max-width: 100%;
            padding: 12px 26px;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 130%;
            letter-spacing: 0.48px;
          }
        }
      }
    }
  }
}