$violet-400: #d3dfff;
$yellow-400: #ffe2a1;
$blue-400: #25368e;
$blue-100: #f9fbfb;
$greenDAE8DA: #DAE8DA;
$green-100: #d7ffd0;
$green-300: #4e944f;
$green-350: #175f62;
$green-400: #045e51;
$green-500: #025f52;
$green-510: #285D51;
$green-hover: #003a32;
$grey-50: #f4f5f5;
$grey-100: #f3f5f5;
$grey-150: #e9e9e9;
$grey-200: #d0d0d0;
$grey-250: #fafbfb;
$grey-300: #abb5b7;
$grey-320: #A4A4A4;
$grey-350: #9ea0a1;
$grey-370: #515358;
$grey-400: #404040;
$grey-500: #3d4246;
$grey-600: #7d7d7d;
$orange-400: #fa8443;
$pink-400: #ff4380;
$pink-hover: #ce3364;
$red-300: #D43C33;
$red-400: #ff001f;

$black: #000000;
$white: #ffffff;
$text-color: #404040;
