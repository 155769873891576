@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/_colors.scss";

.btn-white {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 30px;
  font-weight: 600;
  font-size: 22px;
  border-radius: 70px;
  background-color: $white;
  outline: none;
  border: 1px solid $grey-400;
  cursor: pointer;
  color: $grey-400;
  transition: $transition-all-linear;
  gap: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 30px;
    gap: 10px;
  }

  @include media-breakpoint-down(sm) {
    padding: 10px 26px;
    font-size: 16px;
  }

  // &:hover {
  //   background-color: $green-hover;
  // }

  // &:focus {
  //   box-shadow: 0px 0px 10px 0px $green-400;
  // }
  // &:active {
  //   box-shadow: 0px 0px 10px 0px $green-400;
  // }

  // &:disabled {
  //   color: rgba(9, 25, 50, 0.3);
  //   background: rgba(219, 222, 231, 0.3);
  //   border: 1px solid rgba(9, 25, 50, 0.3);
  // }
}
