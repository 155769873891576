@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.activities-tab {
  margin-top: -10px;
  @include media-breakpoint-down(md) {
    margin-top: 0;
  }

  .banner {
    height: 480px;
    display: grid;
    grid-template-columns: 1fr 250px;
    grid-template-rows: 1fr;
    gap: 5px;

    @include media-breakpoint-down(md) {
      display: flex;
      height: auto;
      flex-direction: column-reverse;
    }

    .video {
      background-image: url("../images/search-park-general-page/main-banner.jpg");
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      position: relative;

      @include media-breakpoint-down(md) {
        height: auto;
        background: none;
      }

      &::before {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;

        @include media-breakpoint-down(md) {
          display: none;
        }
      }

      .book-now {
        border-radius: 15px 15px 0px 0px;
        background: $white;
        box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
        height: 88.775px;
        flex-shrink: 0;
        position: absolute;
        right: 10%;
        bottom: 0px;
        z-index: 6;
        display: flex;
        align-items: center;
        padding: 20px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          position: relative;
          height: auto;
          right: 0;
          box-shadow: none;
          justify-content: space-between;
          padding: 12px;
          align-items: start;
        }

        .park {
          margin-left: 10px;

          @include media-breakpoint-down(md) {
            width: 50%;
          }

          .title {
            color: $grey-400;
            font-size: 22px;
            font-weight: 700;
            line-height: 28.6px;
            letter-spacing: 0.66px;
            display: flex;
            align-items: center;
            gap: 15px;

            @include media-breakpoint-down(md) {
              margin-bottom: 8px;
            }

            .tag {
              padding: 8px;
              border-radius: 3px;
              background: $green-100;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 13px;
              letter-spacing: 0.3px;
            }
          }

          .location {
            display: flex;
            gap: 5px;
            align-items: center;
            color: $green-400;
            font-size: 14px;
            font-weight: 400;
            line-height: 18.2px;
            letter-spacing: 0.28px;

            i {
              color: $green-400;
              font-size: 20px;
            }
          }
        }

        .park-map {
          padding: 10px 20px;
          display: flex;
          align-items: center;
          gap: 10px;
          border-radius: 9.236px;
          background: $grey-50;
          color: $green-400;
          font-weight: 700;
          letter-spacing: 0.45px;
          width: 127px;

          @include media-breakpoint-down(md) {
            width: 152px;
            height: 44px;
            font-size: 14px;
            padding: 10px;
            justify-content: center;
          }

          a {
            font-size: 16px;
            color: $green-400;
            word-break: break-all;

            @include media-breakpoint-down(md) {
              font-size: 14px;
            }
          }

          i {
            color: $green-400;
            font-size: 28px;

            @include media-breakpoint-down(md) {
              font-size: 20px;
            }
          }
        }

        .sep {
          width: 1px;
          height: 59px;
          margin: 0px 15px;
          background-color: $grey-200;

          @include media-breakpoint-down(md) {
            display: none;
          }
        }

        .cost {
          margin-left: 30px;

          .title {
            color: $grey-200;
            font-size: 15px;
            font-weight: 400;
            line-height: 22.5px;
            letter-spacing: -0.45px;
          }

          .price {
            display: flex;
            gap: 5px;
            align-items: flex-end;

            .info {
              color: $grey-300;
              align-self: flex-start;
            }

            .was {
              display: flex;
              gap: 5px;
              direction: ltr;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              line-height: 17.357px;
              text-decoration: line-through;
            }

            .now {
              display: flex;
              gap: 5px;
              direction: ltr;
              color: #e85481;
              text-align: center;
              font-size: 20px;
              font-weight: 600;
              line-height: 26px;
              letter-spacing: 0.6px;
            }
          }
        }
      }
    }

    .sidebar {
      display: grid;
      grid-template-columns: 250px;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 5px;

      @include media-breakpoint-down(md) {
        display: flex;
      }

      & > div {
        @include media-breakpoint-down(md) {
          height: 100px;
          background-size: cover;
          width: calc(100% / 3 - 0px);

          h4 {
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }
        }
      }

      .photos-and-videos {
        background-image: url("../images/search-park-general-page/photos-and-videos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .customer-photos {
        background-image: url("../images/search-park-general-page/customer-photos.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }

      .virtual-tour {
        background-image: url("../images/search-park-general-page/virtual-tour.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: $white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        position: relative;

        h4 {
          z-index: 2;
        }

        img {
          z-index: 2;
        }

        &::before {
          z-index: 1;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 1;
        }
      }
    }

    .thumbnail {
      display: none;

      @include media-breakpoint-down(md) {
        background-image: url("../images/search-park-general-page/main-banner.jpg");
        background-position: 50% 50%;
        background-size: cover;
        background-repeat: no-repeat;
        position: relative;
        height: 250px;
        display: block;
      }
    }
  }

  .navigation {
    box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
    background: $white;
    display: flex;
    gap: 0px;
    margin-bottom: 30px;
    z-index: 6;
    position: relative;
    overflow: auto;
    margin: 0px auto;
    margin-bottom: 35px;

    @include media-breakpoint-down(md) {
      padding: 0px;
    }

    ul {
      padding: 0px;
      margin: 0px;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(md) {
        gap: 10px;
      }

      li {
        @include media-breakpoint-down(md) {
          display: block;
        }

        a {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 15px 25px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;
          text-align: center;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 10px 0;
            margin: 0;
            width: max-content;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;

            @include media-breakpoint-down(md) {
              border-bottom: 5px solid $green-400;
            }
          }
        }
      }
    }
  }

  .text {
    margin-bottom: 30px;

    .title {
      color: $grey-400;
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.6px;

      @include media-breakpoint-down(md) {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 130%;
        /* 31.2px */
        letter-spacing: 0.72px;
        text-align: center;
      }
    }

    .description {
      color: $grey-400;
      font-size: 16px;
      font-weight: 400;
      line-height: 22.4px;
      letter-spacing: 0.48px;

      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }

  .main-tabs {
    .main-tabs-nav {
      display: flex;
      gap: 0px;
      margin-bottom: 30px;
      z-index: 1;
      position: relative;
      background-color: $grey-100;
      padding: 0px 30px;
      overflow: auto;
      border-radius: 8px;

      @include media-breakpoint-down(sm) {
        padding: 0px 50px;
      }

      .main-tab {
        margin-left: 50px;
        color: $grey-400;
        text-align: center;
        font-family: "Almoni", sans-serif;
        font-size: 16px;
        font-weight: 400;
        cursor: pointer;
        display: flex;
        padding: 20px 10px;
        align-items: center;
        gap: 10px;
        border-bottom: 6px solid transparent;
        padding-right: 40px;

        @include media-breakpoint-down(md) {
          padding: 15px 40px 15px 10px;
          font-size: 16px;
        }

        span {
          white-space: nowrap;
        }

        position: relative;

        img {
          position: absolute;
          right: 0px;
          max-width: 24px;
          max-height: 24px;
        }

        &.active {
          border-bottom: 6px solid $green-400;
          color: $green-400;
          font-weight: 700;
        }
      }
    }

    .result{
      padding: 0 15px;
    }

    .main-tabs-contents {
      .main-tab-content {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }

  .filters {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    gap: 10px;
    position: relative;
    border-radius: 15px;
    background-color: $white;
    padding: 12px;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      gap: 16px 0;
    }

    .container-md {
      display: flex;
      align-items: center;
    }

    .search {
      display: inline-flex;
      align-items: center;
      position: relative;
      width: 325px;
      height: 46px;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        width: 100%;
        justify-content: space-between;
      }

      &:hover {
        input {
          border-color: $green-400;
        }

        button {
          i {
            color: $green-400;
          }
        }
      }

      input {
        padding: 0px 20px;
        border: 1px solid $grey-300;
        border-radius: 50px;
        padding-left: 55px;
        width: 100%;
        height: 46px;
        outline: none;
      }

      button {
        top: 50%;
        left: 0px;
        transform: translateY(-50%);
        position: absolute;
        border: none;
        padding: 4px 0px;
        outline: none;
        background: none;
        width: 45px;
        height: 100%;
        border-radius: 50px 0px 0px 50px;
        cursor: pointer;

        &::before {
          content: "";
          border-right: 1px solid $grey-200;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          height: 20px;
          right: 0px;
        }
      }
    }


    .select {
      height: 100%;
      display: inline-flex;
      align-items: center;
      gap: 114px;
      cursor: pointer;
      height: 46px;

      @include media-breakpoint-down(md) {
        width: 100%;
        position: relative;
      }


      &.open {
        .label {
          border-color: $black;

          .arrow {
            transform: rotate(180deg);
          }
        }
      }

      .label {
        border-radius: 50px;
        border: 1px solid $grey-200;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 0px 30px;
        height: 100%;
        position: relative;
        z-index: 6;
        outline: none;

        @include media-breakpoint-down(md) {
          width: 100%;
          justify-content: space-between;
        }

        .number {
          opacity: 0;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: $green-400;
          color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 12px;
        }

        &:hover {
          border-color: $green-400;

          .arrow {
            i {
              color: $green-400;
            }
          }
        }

        .arrow {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.8px;
          transition: transform 0.3s ease;
        }

        .text {
          font-size: 16px;
          font-weight: 500;
          line-height: 20.8px;
          letter-spacing: 0.48px;
          margin: 0px;
        }
      }

      .drop-down {
        display: none;
        border-radius: 5px;
        background: $white;
        width: 557px;
        flex-shrink: 0;
        position: absolute;
        top: 100%;
        right: 0px;
        z-index: 3;
        box-shadow: $box-shadow;
        margin-top: 10px;
        padding: 25px;
        padding-top: 50px;

        @include media-breakpoint-down(md) {
          width: 100%;
          top: 50px;
          z-index: 10;
        }

        .close {
          position: absolute;
          left: 10px;
          top: 10px;
          cursor: pointer;
        }

        .content {
          .tabs {
            .tabs-nav {
              display: flex;
              gap: 0px;
              margin-bottom: 30px;
              z-index: 1;
              position: relative;
              border-bottom: 1px solid $grey-200;
              overflow: auto;

              @include media-breakpoint-down(sm) {
                padding: 0px 50px;
              }

              .tab {
                margin-left: 50px;
                color: $grey-400;
                text-align: center;
                font-family: "Almoni", sans-serif;
                font-size: 22px;
                font-weight: 400;
                cursor: pointer;
                display: flex;
                padding: 10px 50px;
                align-items: center;
                gap: 10px;
                border-bottom: 6px solid transparent;
                text-align: center;

                span {
                  white-space: nowrap;
                }

                position: relative;

                img {
                  position: absolute;
                  right: 0px;
                }

                &.active {
                  border-bottom: 6px solid $green-400;
                  color: $green-400;
                  font-weight: 700;
                }
              }
            }

            .tabs-contents {
              .tab-content {
                display: none;

                &.active {
                  display: block;
                }
              }
            }
          }

          .form {
            .checkboxes-rectangle {
              .checkboxes-horizontal {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                @include media-breakpoint-down(md) {
                  display: flex;
                  flex-direction: column;
                  gap: 8px;

                  .checkbox-rectangle-white {
                    width: 100%;
                  }

                }
              }

              label {
                @include media-breakpoint-down(md) {
                  padding: 15px;
                  border-radius: 8px;
                  width: 100%;
                }
              }
            }

            .apartments {
              margin-bottom: 35px;

              .cards {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
              }
            }

            .nav {
              display: flex;
              justify-content: flex-end;
              gap: 30px;
              margin-top: 50px;

              .reset {
                outline: none;
                background: none;
                border: none;
                color: $green-400;
                text-align: center;
                font-size: 16px;
                font-weight: 500;
                line-height: 20.8px;
                letter-spacing: 0.48px;
                text-decoration-line: underline;
              }
            }
          }
        }
      }
    }


  }

  .posts-title {
    color: $grey-400;
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0.6px;
    margin-bottom: 15px;
  }

  .posts {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    margin-bottom: 150px;

    @include media-breakpoint-down(sm) {
      display: flex;
      flex-direction: column;
      gap: 24px 0;
      margin-bottom: 120px;
    }

    .post {
      position: relative;
      border-radius: 9.065px;
      overflow: hidden;

      a {
        display: flex;
        align-items: flex-end;
        gap: 40px;
        background-color: #d7ffd0;
        box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);
        padding: 22px;
        height: 281px;
        background-repeat: no-repeat;
        background-size: cover;

        @include media-breakpoint-down(sm) {
          height: 264px;
        }


        .title {
          color: $white;
          text-shadow: 0px 3.833px 28.748px #000;
          font-size: 24px;
          font-weight: 700;
          line-height: 33px;
          letter-spacing: 0em;
          text-align: right;
          position: relative;
          z-index: 1;
        }

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: 75%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.79) 100%);
          position: absolute;
          left: 0;
          bottom: 0;
        }

        .more {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $white;
          background: rgba(4, 94, 81, 1);
          font-size: 20px;
          font-weight: 700;
          line-height: 26px;
          letter-spacing: 0.03em;
          text-align: center;
          width: 182px;
          height: 60px;
          border-radius: 70px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          opacity: 0;
        }

        &:hover {
          .more {
            opacity: 1;
          }
        }
      }

      .load-more {
        margin-top: 40px;
        text-align: center;
      }


    }
  }

  .content {
    padding: 0 0 27px 0;
    color: rgba(64, 64, 64, 1);

    .heading {
      font-size: 20px;
      font-weight: 700;
      line-height: 26px;
      letter-spacing: 0.03em;
      text-align: right;
      margin-bottom: 5px;
    }

    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.03em;
      text-align: right;
    }
  }

  .discover-other-parks {
    margin-bottom: 80px;

    @include media-breakpoint-down(sm) {
      overflow: hidden;
    }

    .heading {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 48px;
      }

      h2 {
        color: $green-400;
        font-size: 35px;
        font-weight: 700;

        @include media-breakpoint-down(sm) {
          font-size: 24px;
        }
      }
    }

    .tabs {
      .tabs-nav {
        display: flex;
        gap: 0px;
        margin-bottom: 30px;
        z-index: 1;
        position: relative;
        background-color: $grey-100;
        padding: 0px 30px;
        overflow: auto;
        border-radius: 8px;

        @include media-breakpoint-down(sm) {
          padding: 0px;
          justify-content: center;
          gap: 0 40px;
          border-radius: 8px;
        }

        .tab {
          margin-left: 50px;
          color: $grey-400;
          text-align: center;
          font-family: "Almoni", sans-serif;
          font-size: 22px;
          font-weight: 400;
          cursor: pointer;
          display: flex;
          padding: 20px 10px;
          align-items: center;
          gap: 10px;
          border-bottom: 6px solid transparent;

          @include media-breakpoint-down(sm) {
            font-size: 20px;
            margin: 0;
            padding: 15px 10px;
          }

          span {
            white-space: nowrap;
          }

          position: relative;

          img {
            position: absolute;
            right: 0px;
          }

          &.active {
            border-bottom: 6px solid $green-400;
            color: $green-400;
            font-weight: 700;
          }
        }
      }

      .tabs-contents {
        .tab-content {
          display: none;

          &.active {
            display: block;
          }
        }
      }
    }

    .slider {
      margin-bottom: 30px;

      @include media-breakpoint-down(sm) {
        margin-bottom: 30px;
      }

      .slide {
        overflow: hidden;
        height: auto;
        border-radius: 14px;
        background-color: $white;
        height: 100%;
        display: flex !important;
        flex-direction: column;
        box-shadow: 2px 0 9px 0 rgba(0, 0, 0, 0.13);

        @include media-breakpoint-down(md) {
          margin-bottom: 12px;
        }

        .image {
          img {
            width: 100%;
            height: 206px;
            object-fit: cover;
          }
        }

        .text {
          padding: 16px 32px;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            padding: 12px;
          }

          .title {
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            gap: 10px;

            @include media-breakpoint-down(sm) {
              font-size: 18px;
            }

            a {
              color: $grey-400;
            }

            .status {
              padding: 8px;
              border-radius: 3px;
              background: $green-100;
              color: $grey-400;
              text-align: center;
              font-size: 12px;
              font-weight: 700;
              line-height: 13px;
              letter-spacing: 0.3px;
            }
          }

          .description {
            margin-bottom: 8px;

            p {
              font-size: 16px;
              line-height: 20px;
              color: $grey-400;

              @include media-breakpoint-down(sm) {
                font-size: 14px;
              }
            }
          }

          .date {
            display: flex;
            gap: 10px;
            margin-bottom: 8px;
            color: $grey-400;
            font-size: 18px;
            line-height: 23.4px;

            @include media-breakpoint-down(sm) {
              font-size: 16px;
            }
          }

          .cost {
            margin-left: 30px;

            .price {
              display: flex;
              gap: 5px;
              align-items: flex-end;

              .title {
                color: $grey-200;
                font-size: 15px;
                font-weight: 400;
                line-height: 22.5px;
                letter-spacing: -0.45px;
                margin: 0px;
              }

              .info {
                color: $grey-300;
                align-self: flex-start;
              }

              .was {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: $grey-400;
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                line-height: 17.357px;
                text-decoration: line-through;
              }

              .now {
                display: flex;
                gap: 5px;
                direction: ltr;
                color: #e85481;
                text-align: center;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0.6px;
              }
            }
          }
        }

        .button {
          margin-top: auto;
        }
      }

      .slick-list {
        margin: 0 -15px;

        @include media-breakpoint-down(sm) {
          padding-left: 80px;
        }
      }

      .slick-slide {
        margin: 0 15px;
      }

      .slick-track {
        padding: 10px 0px;
        display: flex !important;
      }

      .slick-next {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        left: -70px;

        @include media-breakpoint-down(md) {
          display: none;
        }

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }

      .slick-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-100;
        background-color: $grey-100;
        transition: $transition-all-linear;
        z-index: 999;
        right: -70px;

        @include media-breakpoint-down(md) {
          display: none;
        }

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: $green-400;
        }

        &:hover {
          background-color: $grey-200;
        }

        &:focus {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }

        &:active {
          box-shadow: 0px 0px 10px 0px $grey-300;
        }
      }
    }

    .button {
      @include media-breakpoint-down(md) {
        text-align: center;

        a {
          padding: 17px 30px;
          font-size: 16px;
        }
      }
    }
  }
}