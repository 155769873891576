@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.modal-accommodation {
  position: relative;
  background: #fff;
  width: auto;
  max-width: 1024px;
  margin: 0 auto;
  box-shadow: 0 0 40px rgba(0,0,0,.25);
  border-radius: 10px;
  padding: 40px 10px 15px;

  .accommodation-slider-galery {

    .slick-arrow {
      display: flex;
      -ms-flex-align: center;
      -ms-flex-pack: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      background-color: #00000054;
      transition: all .3s linear;
      z-index: 9;

      &.slick-prev {
        right: unset;
        left: 5px;

        &::before {
          content: "\f053";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: white;
        }
      }

      &.slick-next {
        right: 5px;

        &::before {
          content: "\f054";
          font-family: "Font Awesome 6 Pro";
          font-weight: 700;
          color: white;
        }
      }
    }

    .slide {
      max-height: 450px;
      height: 450px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
      }
    }
  }

  .mfp-close {
    font-size: 50px;
  }
}

.accomodation-page {

  .back {
    margin: 30px 0px;
    color: $black;
    font-size: 30px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
    align-items: center;

    @include media-breakpoint-down(md) {
      font-size: 20px;
      margin: 18px 0;
    }

    i {
      margin-left: 10px;
    }
  }

  .thumbnails {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    direction: ltr;
    border-radius: 30px;
    overflow: hidden;
    height: 660px;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      grid-template-columns:  repeat(3, 1fr);;
      border-radius: 0;
      margin: 0 -10px;
    }

    .thumbnail {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @include media-breakpoint-down(md) {
        display: none;
      }

      &:nth-child(1) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;

        @include media-breakpoint-down(md) {
          display: block;
          grid-row-end: 1;
          grid-column-end: 4;
          max-height: 390px;
        }
      }

      &:nth-child(2) {
        grid-row-start: 1;
        grid-column-start: 3;
        grid-row-end: 2;
        grid-column-end: 4;

        @include media-breakpoint-down(md) {
          display: block;

          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 2;
        }
      }

      &:nth-child(3) {
        grid-row-start: 1;
        grid-column-start: 4;
        grid-row-end: 2;
        grid-column-end: 5;

        @include media-breakpoint-down(md) {
          display: block;
          grid-row-start: 2;
          grid-column-start: 2;
          grid-row-end: 2;
          grid-column-end: 3;
        }
      }

      &:nth-child(4) {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 4;
      }

      &:nth-child(5) {
        grid-row-start: 2;
        grid-column-start: 4;
        grid-row-end: 3;
        grid-column-end: 5;
        cursor: pointer;

        @include media-breakpoint-down(md) {
          display: block;
          grid-row-start: 2;
          grid-column-start: 3;
          grid-row-end: 2;
          grid-column-end: 4;
        }
      }
    }
  }

  .reference {
    color: $grey-600;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 20px;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      margin-bottom: 5px;
      margin-top: 20px;
      padding: 0 5px;
    }
  }

  .services {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30%;
    width: 100%;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
      margin-bottom: 60px;
      padding: 0 5px;
    }

    .info {
      .title {
        display: flex;
        align-items: center;
        gap: 17px;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
          display: block;
          margin-bottom: 25px;
        }

        h4 {
          color: $black;
          font-size: 35px;
          font-weight: 700;
        }


        .facilities {
          display: flex;
          align-items: center;
          gap: 16px;

          @include media-breakpoint-down(md) {
            gap: 20px;
            margin-top: 14px;
          }

          img {
            width: 36px;
            height: 36px;
            object-fit: cover;
          }
        }
      }

      .meta-data {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
          margin-bottom: 25px;
        }

        .parameters {
          display: flex;
          gap: 20px;
          align-items: center;

          @include media-breakpoint-down(md) {
            gap: 6px;
          }

          img {
            height: 30px;

            @include media-breakpoint-down(md) {
              height: 16px;
            }
          }
        }

        i {
          font-size: 30px;
          @include media-breakpoint-down(md) {
            color: $black;
            font-size: 16px;
          }
        }

        span {
          font-size: 20px;

          @include media-breakpoint-down(md) {
            font-size: 14px;
          }
        }

        .people {
          display: flex;
          align-items: center;
          gap: 20px;

          @include media-breakpoint-down(md) {
            gap: 6px;
          }
        }

        .bedroom {
          display: flex;
          align-items: center;
          gap: 20px;

          @include media-breakpoint-down(md) {
            gap: 6px;
          }
        }

        .square-meters {
          display: flex;
          align-items: center;
          gap: 20px;

          @include media-breakpoint-down(md) {
            gap: 6px;
          }
        }
      }

      .description {
        p {
          margin-bottom: 12px;

          @include media-breakpoint-down(md) {
            font-size: 16px;
            margin-bottom: 30px;
          }
        }

        ul {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
      }
    }

    .cost {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      border-radius: 8px;

      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 40px;
      }

      .duration-of-stay-and-number-of-people {
        color: $grey-400;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.6px;
        letter-spacing: 0.36px;
        margin-top: 20px;

        @include media-breakpoint-down(md) {
          margin-top: 0;
          width: calc(50% - 10px);
        }
      }

      .price {
        margin-bottom: 16px;

        @include media-breakpoint-down(md) {
          margin-top: 0;
          width: calc(50% - 10px);
          margin: 0;
        }

        .summa {
          display: flex;
          align-items: flex-end;
          gap: 10px;

          @include media-breakpoint-down(md) {
            justify-content: end;
          }

          .now {
            display: flex;
            align-items: center;
            gap: 10px;

            color: #e85481;
            text-align: center;
            font-size: 28.844px;
            font-weight: 700;
            line-height: 37.497px;
            letter-spacing: 0.865px;

            i {
              color: $grey-300;
              font-size: 20px;

              @include media-breakpoint-down(md) {
                color: $grey-600;
                font-size: 16px;
              }
            }
          }

          .was {
            display: flex;
            gap: 10px;
            color: $grey-400;
            font-size: 16.688px;
            font-weight: 400;
            line-height: 25.033px;
            text-decoration: line-through;
          }

          .text {
            display: flex;
            gap: 10px;
            color: $grey-400;
            font-size: 16.688px;
            font-weight: 400;
            line-height: 25.033px;
          }
        }

        .price-per-stay {
          color: $grey-400;
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          margin-bottom: 12px;
          text-align: left;

          @include media-breakpoint-down(md) {
            margin: 0;
          }
        }
      }

      .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 25px;

        @include media-breakpoint-down(md) {
          width: 100%;
        }
        .btn-white{
          @include media-breakpoint-down(md) {
            display: none;
          }
        }
        .btn-pink{
          @include media-breakpoint-down(md) {
            width: 100%;
            border-radius: 56px;
            height: 48px;
          }
        }
      }
    }
  }

  .service-and-equipment {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 62px;
    margin-bottom: 123px;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      gap: 20px;
      padding: 0 5px;
      margin-bottom: 60px;
    }

    .service {
      border-radius: 30px;
      background: $white;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
      //height: 841px;
      padding: 30px;

      @include media-breakpoint-down(md) {
        height: auto;
        padding: 24px 16px;
      }

      .title {
        color: $black;
        font-size: 45px;
        margin-bottom: 6px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          margin-bottom: 10px;
        }
      }

      .widget {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 20px;
        }

        .widget-title {
          display: inline-block;
          padding: 8px 16px;
          border-radius: 20px;
          background-color: $violet-400;
          color: $white;
          text-align: center;
          font-size: 25px;
          font-weight: 600;

          @include media-breakpoint-down(md) {
            font-size: 18px;
            padding: 6px 12px;
          }
        }

        .widget-list {
          ul {
            li {
              margin-bottom: 15px;
              color: $black;
              font-size: 25px;

              @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .equipment {
      border-radius: 30px;
      background: #FFF;
      box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.15);
      padding: 30px;

      @include media-breakpoint-down(md) {
        padding: 24px 16px;
      }


      .title {
        color: $black;
        font-size: 45px;
        font-weight: 400;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          font-size: 24px;
          margin-bottom: 20px;
        }
      }

      .equipment-types {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 60px;

        @include media-breakpoint-down(md) {
          grid-template-columns: 1fr;
          gap: 10px;
        }

        .equipment-item {
          .title {
            display: flex;
            align-items: center;
            gap: 15px;
            margin-bottom: 15px;

            h4 {
              @include media-breakpoint-down(md) {
                font-size: 30px;
              }
            }
          }
        }
      }
    }
  }

  .information {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 62px;

    @include media-breakpoint-down(md) {
      grid-template-columns: 1fr;
      padding: 0 5px;
      gap: 30px;
    }

    .included {
      border-radius: 30px;
      background: #F9FBFB;
      //height: 841px;
      padding: 30px;

      @include media-breakpoint-down(md) {
        height: auto;
        padding: 24px 16px;
        margin-bottom: 25px;
      }

      .title {
        display: flex;
        gap: 20px;
        color: $black;
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 24px;
        }

        .icon {
          @include media-breakpoint-down(md) {
           display: none;
          }
        }

        .text {
          display: flex;
          flex-direction: column;

          @include media-breakpoint-down(md) {
            flex-direction: row;
            flex-wrap: wrap;
            gap: 15px;
          }

          h4 {
            font-size: 30px;
            font-weight: 600;

            @include media-breakpoint-down(md) {
              font-size: 24px;
            }
          }

          p {
            font-size: 30px;
            font-weight: 400;

            @include media-breakpoint-down(md) {
              font-size: 24px;
            }
          }
        }
      }

      .widget {
        margin-bottom: 30px;

        @include media-breakpoint-down(md) {
          margin-bottom: 24px;
        }

        &:last-child {
          @include media-breakpoint-down(md) {
            margin-bottom: 0;
          }
        }

        .widget-title {
          display: inline-block;
          padding: 8px 16px;
          border-radius: 20px;
          background-color: $violet-400;
          color: $white;
          text-align: center;
          font-size: 25px;
          font-weight: 600;
        }

        .widget-list {
          ul {
            li {
              margin-bottom: 15px;
              color: $black;
              font-size: 25px;

              @include media-breakpoint-down(md) {
                font-size: 18px;
                margin-bottom: 10px;
              }
            }
          }
        }
      }
    }

    .location {
      border-radius: 30px;
      background: $white;
      padding: 30px;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 30px;

      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        padding: 10px;
      }

      .practical-information {
        @include media-breakpoint-down(md) {
         padding-top: 24px;
        }

        h3 {
          color: $black;
          font-size: 30px;
          font-weight: 600;
          margin-bottom: 50px;

          @include media-breakpoint-down(md) {
            font-size: 24px;
            color: #404040;
            font-weight: 700;
            margin-bottom: 20px;
          }
        }

        .plane {
          .plane-item {
            display: flex;
            gap: 10px;
            margin-bottom: 50px;
            position: relative;

            @include media-breakpoint-down(md) {
              margin-bottom: 40px;
            }

            &:last-child {
              @include media-breakpoint-down(md) {
                margin-bottom: 0;
              }

              &::before {
                display: none;
              }
            }


            &:nth-child(1) {
              .icon {
                img {
                  transform: translateX(-12px);
                }

              }
            }

            &:nth-child(3) {
              .icon {
                img {
                  transform: translateX(6px);
                }
              }
            }

            &:nth-child(4) {
              .icon {
                img {
                  transform: translateX(6px);
                }
              }
            }

            &::before {
              content: "";
              border: 1px dashed $green-400;
              position: absolute;
              top: 100%;
              height: calc(100% + 50px);
              right: 36px;
              transform: translate(0%, -50%);
            }

            .icon {
              width: 90px;
              flex-shrink: 0;

              img {
                position: relative;
                z-index: 2;
                background-color: $white;
              }



            }

            .text {
              .sub-title {
                color: $green-400;
                font-size: 20px;
                font-weight: 500;
              }

              .title {
                color: $black;
                font-size: 22px;
                font-weight: 500;
              }
            }
          }
        }
      }

      .address {
        .title {
          h3 {
            color: $black;
            font-size: 30px;
            font-weight: 600;
            margin-bottom: 50px;

            @include media-breakpoint-down(md) {
              margin-bottom: 24px;
            }
          }
        }

        .description {
          @include media-breakpoint-down(md) {
            margin-bottom: 5px;
          }

          p {
            text-align: left;
            margin-bottom: 30px;

            @include media-breakpoint-down(md) {
              text-align: start;
              font-size: 18px;
              line-height: 23px;
              color: #404040;
              margin-bottom: 0;
            }

            .phone {
              color: $black;
              direction: ltr;
              display: inline-block;
            }
          }
        }

        .button {
          .btn-green {
            @include media-breakpoint-down(md) {
              width: calc(100% - 30px);
              height: 60px;
              flex-direction: row-reverse;

              span {
                @include media-breakpoint-down(md) {
                  font-size: 18px;
                }
              }

              i {
                @include media-breakpoint-down(md) {
                  font-size: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}