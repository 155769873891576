@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/_colors.scss";

.input-number {
  display: inline-flex;
  align-items: center;
  background-color: $white;
  border-radius: 4px;
  padding: 0px 10px;

  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    flex-shrink: 0;
    border-radius: 50%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid $grey-400;
  }

  input {
    width: 40px;
    border: none;
    padding: 8px;
    text-align: center;
    line-height: 24px;
    outline: none;
    color: $grey-400;
    font-size: 20px;
    font-weight: 500;
  }
}
