@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

$color-515358: #515358;
$color-404040: #404040;
$color-045E51: #045E51;

.agent-icon {
  position: fixed;
  left: 0;
  z-index: 8;
  top: 50%;
  background: $grey-100;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0 5px 5px 0;
  padding: 24px;
  max-width: 170px;
  text-align: center;
  gap: 10px;
  border-right: 3px solid $green-400;
  font-weight: 600;
  line-height: 1.5em;
  color: $grey-400;
  box-shadow: 0 4px 30px 0 #00000026;
  @include font-size-responsive(18px, 12px);
  @include media-breakpoint-down(md) {
    max-width: 104px;
    padding: 12px 16px;

  }
}


.login-popup {
  opacity: 0;
  pointer-events: none;
  background: #00000075;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100dvh;
  z-index: 11;
  overflow-y: auto;

  @include media-breakpoint-down(sm) {
    z-index: 9;
    padding-top: 120px;
    height: 100dvh;
  }


  .error-login {
    color: $red-400;
    text-align: center;
    display: none;
  }

  .popup {
    background: white;
    position: absolute;
    left: 0;
    height: 100dvh;
    max-width: 580px;
    transform: translateX(-100%);
    transition: all 500ms ease-in-out;

    & > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }


    h5 {
      color: $color-404040;
      font-weight: 300;
      @include font-size-responsive(22px, 18px);
    }

    .popup-img {
      &.agent {
        h4 {
          bottom: 45px;
        }
      }

      position: relative;

      .close-login-popup {
        position: absolute;
        left: 0;
        cursor: pointer;
        opacity: .6;
        transition: all 200ms linear;
        @include media-breakpoint-down(sm) {
          top: 16px;
          left: 16px;
        }

        path {
          pointer-events: none;
        }

        &:hover {
          opacity: 1;
        }
      }

      h4 {
        position: absolute;
        color: white;
        bottom: 45px;
        right: 30px;
        font-weight: 700;
        @include font-size-responsive(35px, 32px);
      }

      .user-type {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        transform: translateY(50%);
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        z-index: 9;
        @include media-breakpoint-down(sm) {
          padding: 0 20px;
        }

        ul {
          background: #F3F5F5;
          display: flex;
          align-items: center;
          width: 100%;
          gap: 10px;
          border-radius: 8px;
          list-style: none;
          padding: 0;
          overflow: hidden;

          li {
            padding: 20px 10px 15px;
            color: $color-404040;
            border-bottom: 5px solid transparent;
            cursor: pointer;

            &.active {
              color: $color-045E51;
              font-size: 22px;
              font-weight: 700;
              border-bottom: 5px solid $color-045E51;
              @include font-size-responsive(22px, 20px);
            }
          }
        }
      }
    }

    .login-form-wrap {
      display: flex;
      flex-direction: column;
      padding: 50px 30px 30px 30px;
      gap: 20px;
      background-color: $white;

      @include media-breakpoint-down(sm) {
        padding: 50px 20px 20px 20px;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .chosen-container {
          width: 100% !important;

          .chosen-drop {
            max-height: 200px;
          }
        }

        .fr-fr {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 16px;

          @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
          }
        }

        .half-fr {
          display: grid;
          grid-template-columns: 1fr 30%;
          gap: 16px;
          @include media-breakpoint-down(sm) {
            grid-template-columns: 1fr;
          }

          &.first {
            @include media-breakpoint-down(sm) {
              grid-template-columns: 1fr 1fr;
            }
          }
        }

        label {
          color: $color-515358;
          line-height: 30px;
          position: relative;

          svg{
            position: absolute;
            left: 10px;
            top: 40px;
            cursor: pointer;
          }

          &.checkbox {
            flex-direction: row-reverse;
            gap: 8px;
            align-items: flex-start;
            justify-content: flex-end;
          }
        }

        input {
          color: $color-515358;
          padding-inline-end: 30px;

          &::placeholder {
            color: $color-515358;
            opacity: .6;
          }
        }

        .checkbox-custom {
          position: relative;
          width: 24px;
          height: 24px;
          min-width: 24px;
          cursor: pointer;

          input[type="checkbox"] {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            display: block;
            cursor: pointer;
          }

          input[type="checkbox"] + div {
            pointer-events: none;
          }

          input[type="checkbox"] + div:before {
            content: "";
            display: inline-block;
            width: 100%;
            height: 24px;
            border: 2px solid #abb5b7;
            margin-left: 14px;
            border-radius: 5px;
            -ms-flex-negative: 0;
            flex-shrink: 0;
            vertical-align: middle;
          }

          input[type="checkbox"]:checked + div:after {
            position: absolute;
            right: 4px;
            font-weight: 700;
            color: #045e51;
            font-size: 16px;
            content: "\f00c";
            font-family: Font Awesome\ 6 Pro;
            width: 16px;
            height: 16px;
            line-height: 1;
            text-align: center;
            top: 8px;
          }
        }

      }
    }
  }

  &.active {
    opacity: 1;
    pointer-events: painted;

    .popup {
      transform: translateX(0);

      &.hide {
        transform: translateX(-100%);
      }
    }


    button {
      font-size: 17px;

      &.btn-white {
        border: 1px solid $color-045E51;
        color: $color-045E51;
      }
    }

    .forgot-password, .forgot-password-agent, .to-login:not(.btn-green), .to-login-agent:not(.btn-green) {
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      color: $color-045E51;
    }

    .to-login.btn-green {
      font-size: 17px;
    }
  }

  .btn-green {
    margin-top: 20px;
  }
}

#header #logout i {
  color: white;
  font-size: 30px;
  font-weight: 100;
}

.button.representative {
  .btn-pink {
    font-size: 18px;
    width: 100%;
    margin-bottom: 26px;
  }
}

.representative-text {
  direction: ltr;
  display: flex;
  flex-direction: column;
  gap: 26px;

  p {
    font-size: 14px;
    font-weight: 200;
    line-height: 1.4em;
  }
}