@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

$pd-text-color: #333 !default;
$pd-title-color: #333 !default;
$pd-title-bg: #fff !default;
$pd-picker-bg: #fff !default;
$pd-picker-border: #ccc !default;
$pd-picker-border-bottom: #bbb !default;
$pd-picker-shadow: rgba(0, 0, 0, 0.5) !default;
$pd-th-color: #999 !default;
$pd-day-color: #666 !default;
$pd-day-bg: #f5f5f5 !default;
$pd-day-hover-color: #fff !default;
$pd-day-hover-bg: #ff8000 !default;
$pd-day-today-color: #33aaff !default;
$pd-day-selected-color: #fff !default;
$pd-day-selected-bg: $green-400 !default;
$pd-day-selected-shadow: $green-400 !default;
$pd-day-disabled-color: #999 !default;
$pd-day-inrange-disabled-color: #cdcdcd !default;
$pd-week-color: #999 !default;
$pd-inrange-color: #666 !default;
$pd-inrange-bg: $green-100 !default;
$pd-startrange-color: #fff !default;

$pd-startrange-bg: $green-100 !default;
$pd-startrange-button: $green-400 !default;
$pd-endrange-color: #fff !default;
$pd-endrange-bg: $green-100 !default;
$pd-endrange-button: $green-400 !default;

$pd-event-color: #fff !default;
$pd-event-bg: #33aaff !default;

$pd-z-index: 9999 !default;
$pd-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$pd-border-radius: 50% !default;

.pika-single {
  z-index: $pd-z-index;
  display: block;
  position: relative;
  color: $pd-text-color;
  background: $pd-picker-bg;
  border-bottom-color: $pd-picker-border-bottom;
  font-family: $pd-font-family;
  border: none;
  display: flex;
  justify-content: space-between;
  gap: 50px;
  margin: 0px -50px;
  &.is-hidden {
    display: none;
  }

  &.is-bound {
    position: absolute;
    box-shadow: 0 5px 15px -5px $pd-picker-shadow;
  }
}

// clear child float (pika-lendar), using the famous micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.pika-single {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.pika-lendar {
  width: 50%;
  margin: 0px;
  padding: 0px;
}

.pika-title {
  position: relative;
  text-align: center;

  select {
    cursor: pointer;
    position: absolute;
    z-index: $pd-z-index - 1;
    margin: 0;
    left: 0;
    top: 5px;
    opacity: 0;
  }
}

.pika-label {
  display: inline-block;
  position: relative;
  z-index: $pd-z-index;
  overflow: hidden;
  margin: 0;
  padding: 5px 3px;
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  color: $pd-title-color;
  background-color: $pd-title-bg;
}

.pika-prev,
.pika-next {
  display: block;
  cursor: pointer;
  position: relative;
  outline: none;
  border: 0;
  padding: 0;
  width: 20px;
  height: 30px;
  text-indent: 20px;
  white-space: nowrap;
  overflow: hidden;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 75% 75%;
  opacity: 0.5;

  &:hover {
    opacity: 1;
  }

  &.is-disabled {
    cursor: default;
    opacity: 0.2;
  }
}

.pika-prev,
.is-rtl .pika-next {
  float: left;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAUklEQVR42u3VMQoAIBADQf8Pgj+OD9hG2CtONJB2ymQkKe0HbwAP0xucDiQWARITIDEBEnMgMQ8S8+AqBIl6kKgHiXqQqAeJepBo/z38J/U0uAHlaBkBl9I4GwAAAABJRU5ErkJggg==");
}

.pika-next,
.is-rtl .pika-prev {
  float: right;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAeCAYAAAAsEj5rAAAAU0lEQVR42u3VOwoAMAgE0dwfAnNjU26bYkBCFGwfiL9VVWoO+BJ4Gf3gtsEKKoFBNTCoCAYVwaAiGNQGMUHMkjGbgjk2mIONuXo0nC8XnCf1JXgArVIZAQh5TKYAAAAASUVORK5CYII=");
}

.pika-select {
  display: inline-block;
}

.pika-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;

  td {
    padding-top: 14.5%;
    position: relative;
    width: 14.5%;
  }

  th {
    color: $pd-th-color;
    font-size: 12px;
    line-height: 25px;
    font-weight: bold;
    text-align: center;
  }

  abbr {
    border-bottom: none;
    cursor: help;
  }
}

.pika-button {
  cursor: pointer;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
  border: 0;
  margin: 0;
  width: 100%;
  padding: 5px;
  color: $pd-day-color;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  background: $white;
  width: 100%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: $pd-border-radius;
  height: 100%;
  position: absolute;
  top: 0px;
  .has-event & {
    color: $pd-event-color;
    background: $pd-event-bg;
  }

  .is-today & {
    color: $pd-day-today-color;
    font-weight: bold;
  }

  .is-selected & {
    color: $pd-day-selected-color;
    background: $pd-day-selected-bg;
    border-radius: $pd-border-radius;
  }

  .is-disabled &,
  .is-outside-current-month & {
    color: $pd-day-disabled-color;
    opacity: 0.3;
  }

  .is-disabled & {
    pointer-events: none;
    cursor: default;
  }

  .is-disabled.is-inrange & {
    color: $pd-day-inrange-disabled-color !important;
  }

  &:hover {
    box-shadow: none;
    border: 1px solid $green-400;
    border-radius: $pd-border-radius;
  }

  .is-selection-disabled {
    pointer-events: none;
    cursor: default;
  }
}

.pika-week {
  font-size: 11px;
  color: $pd-week-color;
}

.is-inrange {
  .pika-button {
    color: $pd-inrange-color;
    background: $pd-inrange-bg;
    opacity: 1 !important;
    border-radius: 0px;
  }
}

.is-startrange {
  background-color: $pd-startrange-bg;
  background-image: linear-gradient(
    270deg,
    #fff,
    #fff 50%,
    $pd-startrange-bg 50.1%,
    $pd-startrange-bg
  );

  .pika-button {
    background: $pd-startrange-button;
    color: $pd-startrange-color;
    box-shadow: none;
    border-radius: $pd-border-radius;
    opacity: 1 !important;
  }
}

.is-endrange {
  background-color: $pd-endrange-bg;
  background-image: linear-gradient(
    90deg,
    #fff,
    #fff 50%,
    $pd-startrange-bg 50.1%,
    $pd-startrange-bg
  );
  .pika-button {
    background: $pd-endrange-button;
    color: $pd-startrange-color;
    box-shadow: none;
    border-radius: $pd-border-radius;
  }
}

.is-startrange.is-endrange {
  background-color: transparent !important;
  background-image: none !important;
  border: none !important;
}

.pika-table {
  direction: ltr;
}
