@import "./_mixins.scss";
@import "./_variables.scss";
@import "./_colors.scss";

@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewThin/NotoSansHebrewThin", 100, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewExtraLight/NotoSansHebrewExtraLight", 200, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewLight/NotoSansHebrewLight", 300, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewRegular/NotoSansHebrewRegular", 400, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewMedium/NotoSansHebrewMedium", 500, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewSemiBold/NotoSansHebrewSemiBold", 600, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewBold/NotoSansHebrewBold", 700, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewExtraBold/NotoSansHebrewExtraBold", 800, normal);
@include font-face("NotoSansHebrew", "../fonts/NotoSansHebrewBlack/NotoSansHebrewBlack", 900, normal);

@include font-face("Inter", "../fonts/InterThin/InterThin", 100, normal);
@include font-face("Inter", "../fonts/InterExtraLight/InterExtraLight", 200, normal);
@include font-face("Inter", "../fonts/InterLight/InterLight", 300, normal);
@include font-face("Inter", "../fonts/InterRegular/InterRegular", 400, normal);
@include font-face("Inter", "../fonts/InterMedium/InterMedium", 500, normal);
@include font-face("Inter", "../fonts/InterSemiBold/InterSemiBold", 600, normal);
@include font-face("Inter", "../fonts/InterBold/InterBold", 700, normal);
@include font-face("Inter", "../fonts/InterExtraBold/InterExtraBold", 800, normal);
@include font-face("Inter", "../fonts/InterBlack/InterBlack", 900, normal);

@include font-face("Poppins", "../fonts/PoppinsThin/PoppinsThin", 100, normal);
@include font-face("Poppins", "../fonts/PoppinsExtraLight/PoppinsExtraLight", 200, normal);
@include font-face("Poppins", "../fonts/PoppinsLight/PoppinsLight", 300, normal);
@include font-face("Poppins", "../fonts/PoppinsRegular/PoppinsRegular", 400, normal);
@include font-face("Poppins", "../fonts/PoppinsMedium/PoppinsMedium", 500, normal);
@include font-face("Poppins", "../fonts/PoppinsSemiBold/PoppinsSemiBold", 600, normal);
@include font-face("Poppins", "../fonts/PoppinsBold/PoppinsBold", 700, normal);
@include font-face("Poppins", "../fonts/PoppinsExtraBold/PoppinsExtraBold", 800, normal);
@include font-face("Poppins", "../fonts/PoppinsBlack/PoppinsBlack", 900, normal);

h1 {
  @include font-size-responsive(50px, 45px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 48px;
}

h2 {
  @include font-size-responsive(35px, 30px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 43.2px;
}

h3 {
  @include font-size-responsive(25px, 20px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 39.9px;
}

h4 {
  @include font-size-responsive(20px, 18px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 31.92px;
}

h5 {
  @include font-size-responsive(18px, 16px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 24px;
}

h6 {
  @include font-size-responsive(16px, 14px, 480px, 1264px);
  margin: 0px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 25.92px;
}

p {
  @include font-size-responsive(18px, 16px, 480px, 1264px);
  margin: 0px;
  font-weight: 400;
  line-height: 27px;
}
