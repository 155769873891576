@import "./_mixins.scss";
@import "./_variables.scss";
@import "./_colors.scss";

html,
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "NotoSansHebrew", Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  min-width: 320px;
  overflow-x: hidden;
  background-color: $white;

  &.disable-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
}

* {
  font-family: "NotoSansHebrew", Arial, Helvetica, sans-serif;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

main {
  flex: 1;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
}

.container-lg {
  margin: 0px auto;
  width: 100%;
  max-width: 1630px;
  padding: 0px 10px;
}

.container-md {
  margin: 0px auto;
  width: 100%;
  max-width: 1530px;
  padding: 0px 10px;
}

.container-sm {
  margin: 0px auto;
  width: 100%;
  max-width: 1040px;
  padding: 0px 10px;
}

.overflow {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
}

.ui-autocomplete {
  position: absolute;
  cursor: default;
  z-index: 99999999 !important;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 50px;

  li {
    div {
      display: flex;
      align-items: center;
      height: 40px;
    }
  }
}

//error-alert popup
body > #header {
  & > .error-alert {
    opacity: 0;
    position: absolute;
    max-width: 300px;
    width: max-content;
    padding: 5px 10px 5px 40px;
    transform: translate(-50%, 0);
    background: $white;
    left: 50%;
    border-radius: 10px;
    box-shadow: $box-shadow;
    font-size: 14px;
    top: -30px;
    color: $red-400;
    z-index: 20;

    &::after {
      content: '';
      position: absolute;
      transform: translate(0, -50%);
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.0//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg height='32' style='overflow:visible;enable-background:new 0 0 32 32' viewBox='0 0 32 32' width='32' xml:space='preserve' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg%3E%3Cg id='Error_1_'%3E%3Cg id='Error'%3E%3Ccircle cx='16' cy='16' id='BG' r='16' style='fill:%23D72828;'/%3E%3Cpath d='M14.5,25h3v-3h-3V25z M14.5,6v13h3V6H14.5z' id='Exclamatory_x5F_Sign' style='fill:%23E6E6E6;'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 50%;
      height: 15px;
      width: 15px;
      left: 10px;
    }
  }

  & > .success-alert {
    opacity: 0;
    position: absolute;
    max-width: 300px;
    width: max-content;
    padding: 5px 10px 5px 40px;
    transform: translate(-50%, 0);
    background: $white;
    left: 50%;
    border-radius: 10px;
    box-shadow: $box-shadow;
    font-size: 14px;
    top: -30px;
    color: $green-300;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
      background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="12" width="12" viewBox="0 0 512 512"%3E%3C!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath fill="%234e944f" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"%3E%3C/path%3E%3C/svg%3E');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      height: 15px;
      width: 15px;
    }

  }
}

//hide element based on the screen size (xl, lg, md, sm, xs)
.hide {
  &-xl {
    &-down {
      @include media-breakpoint-down(xl) {
        display: none !important;
      }
    }

    &-up {
      @include media-breakpoint-up(xl) {
        display: none !important;
      }
    }
  }

  &-lg {
    &-down {
      @include media-breakpoint-down(lg) {
        display: none !important;
      }
    }

    &-up {
      @include media-breakpoint-up(lg) {
        display: none !important;
      }
    }
  }

  &-md {
    &-down {
      @include media-breakpoint-down(md) {
        display: none !important;
      }
    }

    &-up {
      @include media-breakpoint-up(md) {
        display: none !important;
      }
    }
  }

  &-sm {
    &-down {
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
    }

    &-up {
      @include media-breakpoint-up(sm) {
        display: none !important;
      }
    }
  }

  &-xs {
    &-down {
      @include media-breakpoint-down(xs) {
        display: none !important;
      }
    }

    &-up {
      @include media-breakpoint-up(xs) {
        display: none !important;
      }
    }
  }
}


/* __________   CONTACT FORM 7 BEGIN __________*/

.wpcf7 {
  direction: rtl;

  input,
  textarea {
    text-align: right;
  }

  form {
    .wpcf7-not-valid {
      border-color: #ff0000 !important;
    }

    .wpcf7-spinner {
      display: none !important;
    }
  }
}

.nice-select {
  overflow: hidden;

  @include media-breakpoint-down(lg) {
    white-space: wrap;
  }

  &.open {
    overflow: visible;
  }

  .list {
    max-height: 50dvh;
    overflow-y: scroll;
  }
}

/* __________  CONTACT FORM 7 END  __________*/