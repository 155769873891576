@import "../../../base/_mixins.scss";
@import "../../../base/_variables.scss";
@import "../../../base/_colors.scss";

.input {
  label {
    display: block;
    font-size: 18px;
    line-height: 24px;
    color: rgba(9, 25, 50, 0.5);
    margin-bottom: 5px;
  }

  input {
    display: block;
    padding: 12px 16px;
    gap: 10px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 0.42px;
    outline: none;
    font-weight: 400;
    background-color: $white;
    border-radius: 4px;
    border: none;
    color: $grey-300;
    outline: none;
    border: 1px solid $grey-600;
    height: 45px;
    // &::placeholder {
    //   color: $grey-600;
    // }
    // &:disabled {
    //   background-color: rgba(219, 222, 231, 0.3) !important;
    //   border: 1px solid $grey-600 !important;
    // }
    // &:focus {
    //   box-shadow: 0px 0px 10px 0px $primary-orange-30;
    // }
    // &:active {
    //   box-shadow: 0px 0px 10px 0px $primary-orange-30;
    // }
  }
}
