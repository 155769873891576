@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";

.page-template-event-page {
	#modal-search{
		.item.parcs{
			display: none;
		}
	}

	.banner {
		height: 480px;
		display: flex;
		justify-content: flex-start;
		position: relative;
		
		.banner-image {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			img{
				position: absolute;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		
		.banner-info-wrapper {
			position: relative;
			z-index: 5;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			
			.info {
				position: relative;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 8px;
				padding: 100px 30px 55px 30px;
				background: $violet-400;
				border-top-left-radius: 135px;
				border-top-right-radius: 135px;
				max-width: 270px;
				
				.title {
					color: $text-color;
					text-align: center;
					font-size: 17px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%;
					letter-spacing: 0.51px;
				}
				
				.description {
					color: $text-color;
					text-align: center;
					font-size: 17px;
					font-style: normal;
					font-weight: 500;
					line-height: 130%;
					letter-spacing: 0.51px;
				}
			}
		}
		
		.search {
			position: absolute;
			bottom: -30px;
			left: 50%;
			transform: translateX(-50%);
			width: max-content;
		}
	}
	
	.search {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 70px;
		background: $white;
		box-shadow: 0px 3.729px 27.961px 0px rgba(0, 0, 0, 0.15);
		
		.arrival {
			display: flex;
			align-items: center;
			gap: 16px;
			
			.arrival-date {
				display: flex;
				align-items: center;
				gap: 10px;
				height: 100%;
				padding: 0 60px;
				color: $grey-400;
				font-size: 22px;
				font-weight: 500;
				line-height: 28px;
				letter-spacing: 0.641px;
			}
			
			.arrow-date {
				font-size: 22px;
				font-weight: 500;
				line-height: 28px;
				color: $grey-200;
			}
			
			.departure-date {
				display: flex;
				align-items: center;
				padding: 0;
				gap: 16px;
				height: 100%;

				color: $grey-200;
				font-size: 22px;
				font-weight: 500;
				line-height: 27.791px;
				letter-spacing: 0.641px;
			}
		}
		
		.accommodation {
			border-right: 1px solid $grey-200;
			
			.select {
				height: 100%;
				display: flex;
				align-items: center;
				gap: 114px;
				padding: 0px 60px 0px 30px;
				position: relative;
				//border-bottom: 5px solid transparent;
				position: relative;
				
				&.open {
					.label {
						&:before {
							content: "";
							position: absolute;
							transform: translateX(-50%);
							bottom: -15px;
							left: 50%;
							border-color: transparent transparent #fff;
							border-style: solid;
							border-width: 0 10px 10px;
							z-index: 4;
						}
						
						.arrow {
							transform: rotate(180deg);
						}
					}
				}
				
				.arrow {
					display: flex;
					align-items: center;
					gap: 22px;
					font-size: 22px;
					font-weight: 500;
					line-height: 28px;
				}
				
				.label {
					font-size: 22px;
					font-weight: 500;
					line-height: 28px;
				}
			}
			
			.drop-down {
				display: none;
				border-radius: 5px;
				background: $white;
				width: 416px;
				flex-shrink: 0;
				position: absolute;
				top: 100%;
				left: 10px;
				z-index: 3;
				box-shadow: $box-shadow;
				//margin-top: 10px;
				padding: 25px;
				padding-top: 50px;
				
				&:before {
					content: "";
					z-index: 3;
					position: absolute;
					transform: translateX(-50%);
					top: -10px;
					left: 50%;
					border-color: transparent transparent #fff;
					border-style: solid;
					border-width: 0 10px 10px;
				}
				
				.close {
					position: absolute;
					left: 10px;
					top: 10px;
					cursor: pointer;
				}
				
				.options {
					display: flex;
					flex-direction: column;
					gap: 40px;
					
					.seniors {
						display: flex;
						justify-content: space-between;
					}
					
					.adults {
						display: flex;
						justify-content: space-between;
					}
					
					.children {
						display: flex;
						justify-content: space-between;
					}
					
					.pets {
						display: flex;
						justify-content: space-between;
					}
				}
			}
		}
		
		.submit {
			a {
				display: flex;
				width: 207px;
				height: 60px;
				padding: 10px 40px;
				justify-content: center;
				align-items: center;
				gap: 13.335px;
				flex-shrink: 0;
				border-radius: 70px 0px 0px 70px;
				background: $pink-400;
				color: $white;
				font-size: 22px;
				font-weight: 600;
			}
		}
	}
	
	.countries-events {
		padding: 100px 0 50px 0;
		
		.countries-events-wrapper {
			display: flex;
			flex-direction: column;
			gap: 33px;
			
			.countries-events-head {
				display: flex;
				flex-direction: column;
				gap: 12px;
				
				h1 {
					color: $text-color;
					font-size: 35px;
					font-style: normal;
					font-weight: 700;
					line-height: normal;
				}
				
				.description {
					p {
						color: $text-color;
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
						line-height: 138.889%;
					}
					
					strong {
						font-weight: 700;
					}
					
					a {
						color: $green-400;
						font-weight: 700;
						text-decoration-line: underline;
					}
				}
			}
			
			.countries-events-list {
				display: flex;
				flex-wrap: wrap;
				column-gap: 33px;
				row-gap: 53px;
				
				.country-events-item-wrapper {
					flex: 1 0 auto;
					max-width: calc(50% - 33px / 2);
					@include media-breakpoint-down(md){
						max-width: unset;
						width: 100%;
					}
					
					&.show-more {
						display: none;
					}
					
					.country-events-item {
						display: flex;
						flex-direction: column;
						border-radius: 15px;
						background: $white;
						box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
						overflow: hidden;
						
						.country-events-item-image {
							width: 100%;
							max-height: 320px;
							object-fit: cover;
						}
						
						.country-events-item-info {
							display: flex;
							flex-direction: column;
							gap: 33px;
							padding: 21px 33px 41px 33px;
							
							.country-events-item-head {
								display: flex;
								flex-direction: column;
								gap: 15px;
								
								h3 {
									color: $black;
									font-size: 25px;
									font-style: normal;
									font-weight: 700;
									line-height: normal;
								}
								
								.description {
									color: $text-color;
									font-size: 18px;
									font-style: normal;
									font-weight: 400;
									line-height: 138.889%;
								}
							}
							
							.country-events-item-features {
								display: flex;
								flex-direction: column;
								gap: 10px;
								
								.country-events-item-feature {
									padding-inline-start: 22px;
									position: relative;
									
									&:before {
										content: '';
										position: absolute;
										inset-inline-start: 0;
										top: 10px;
										width: 6px;
										height: 6px;
										background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none"><circle cx="3" cy="3" r="3" fill="%23404040"/></svg>');
										background-size: contain;
										background-position: center;
										background-repeat: no-repeat;
									}
									
									p {
										color: $text-color;
										font-size: 18px;
										font-style: normal;
										font-weight: 400;
										line-height: 138.889%;
									}
									
									strong {
										font-weight: 700;
									}
									
									a {
										color: $green-400;
										text-decoration-line: underline;
									}
								}
							}
							
							.country-events-item-link {
								display: flex;
								width: fit-content;
								padding: 10.882px 33.168px;
								justify-content: center;
								align-items: center;
								border-radius: 93.275px;
								border: 1px solid $text-color;
								color: $text-color;
								font-size: 17.1px;
								font-style: normal;
								font-weight: 600;
								line-height: normal;
							}
						}
					}
					
					&.horizontal {
						flex: 0 0 100%;
						max-width: 100%;
						
						.country-events-item {
							flex-direction: row;
							
							.country-events-item-image {
								width: 40%;
								max-height: 100%;
							}
							
							.country-events-item-info {
								gap: 13px;
								padding: 21px 33px 41px 56px;
							}
						}
					}
				}
				
				.btn-green-arrow {
					display: inline-flex;
					padding: 17px 35px;
					justify-content: center;
					align-items: center;
					gap: 29px;
					border-radius: 70px;
					border: 1px solid $green-400;
					background: $green-400;
					color: $white;
					font-size: 20px;
					font-style: normal;
					font-weight: 700;
					line-height: 130%;
					letter-spacing: 0.6px;
					cursor: pointer;
					
					.less {
						display: none;
					}
					
					&:after {
						content: '';
						height: 8.131px;
						width: 15px;
						background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="9" viewBox="0 0 16 9" fill="none"><path d="M0.057152 0.646073L7.17972 7.87823L7.49978 8.18909L7.82456 7.88315L15.0567 0.760591L14.4168 0.110826L7.50952 6.91343L0.706916 0.00615487L0.057152 0.646073Z" fill="white"/></svg>');
						background-size: contain;
						background-position: center;
						background-repeat: no-repeat;
					}
					
					&.active {
						.less {
							display: flex;
						}
						
						.more {
							display: none;
						}
						
						&:after {
							transform: rotate(180deg);
						}
					}
				}
			}
		}
	}
	
	.filter {
		margin-bottom: 30px;
		width: 100%;
		height: 1px;
		background: $white;
		box-shadow: 0px 5.334px 40px 0px rgba(0, 0, 0, 0.15);
		height: 70px;
		
		.container-md {
			display: flex;
			align-items: center;
			position: relative;
		}
		
		.sorting {
			display: flex;
			align-items: center;
			height: 70px;
			
			.title {
				color: $grey-200;
				text-align: center;
				font-size: 16px;
				font-weight: 400;
				line-height: 24px;
				letter-spacing: -0.48px;
			}
			
			.select {
				height: 100%;
				display: inline-flex;
				align-items: center;
				gap: 114px;
				border-left: 1px solid $grey-200;
				position: relative;
				cursor: pointer;
				
				&.open {
					.label {
						.arrow {
							transform: rotate(180deg);
						}
					}
				}
				
				.label {
					display: flex;
					align-items: center;
					gap: 60px;
					padding: 0px 30px;
					height: 100%;
					position: relative;
					z-index: 6;
					
					.arrow {
						font-size: 16px;
						font-weight: 500;
						line-height: 20.8px;
						transition: transform 0.3s ease;
					}
					
					.text {
						font-size: 16px;
						font-weight: 500;
						line-height: 20.8px;
						letter-spacing: 0.48px;
					}
				}
				
				.drop-down {
					display: none;
					background: $white;
					width: 100%;
					flex-shrink: 0;
					position: absolute;
					top: 100%;
					left: 0px;
					z-index: 3;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					padding: 25px;
					padding-top: 50px;
					padding: 16px 26px;
					
					ul {
						list-style: none;
						margin: 0px;
						padding: 0px;
						display: flex;
						flex-direction: column;
						gap: 15px;
						
						li {
							a {
								transition: $transition-all-linear;
								
								&:hover {
									color: $green-500;
								}
							}
						}
					}
				}
			}
		}
	}
	
	.order-by {
		margin-bottom: 30px;
		
		.container-md {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		
		.title {
			color: $grey-400;
			text-align: right;
			font-size: 20px;
			font-weight: 700;
			line-height: 26px;
			letter-spacing: 0.6px;
		}
		
		.select {
			label {
				color: $grey-400;
				font-size: 16px;
				margin-left: 20px;
			}
		}
	}
	
	.posts {
		.container-md {
			display: flex;
			flex-direction: column;
			gap: 30px;
		}
		
		.post {
			border-radius: 9.065px;
			background-color: $white;
			box-shadow: 0px 0px 15.11625px 0px rgba(20, 6, 60, 0.15);

			.post-header {
				background-color: #FAFBFC;
				border-bottom: solid 1px #E9E8EA;
				border-radius: 9.065px 9.065px 0 0;
				padding: 14px;
			}

			.post-content {
				display: flex;
				gap: 40px;
				padding-left: 22px;
			}

			.thumbnail {
				width: 480px;
				height: 238px;
				flex-shrink: 0;
				overflow: hidden;
				
				img {
					border-radius: 0 0 10px;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			
			.text {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				gap: 50px;
				width: 100%;
				padding: 22px 0;
				
				.info {
					.title {
						display: flex;
						align-items: center;
						gap: 17px;
						margin-bottom: 5px;
						
						.facilities {
							display: flex;
							align-items: center;
							gap: 8px;
						}
					}
					
					.meta-data {
						display: flex;
						align-items: center;
						gap: 26px;
						margin-top: 16px;
						margin-bottom: 16px;
						
						.people {
							display: flex;
							align-items: center;
							gap: 6px;
							
							.title {
								margin-bottom: 0px;
							}
						}
						
						.bedroom {
							display: flex;
							align-items: center;
							gap: 6px;
							
							.title {
								margin-bottom: 0px;
							}
						}
						
						.square-meters {
							display: flex;
							align-items: center;
							gap: 6px;
							
							.title {
								margin-bottom: 0px;
							}
						}
					}
					
					.description {
						p {
							margin-bottom: 12px;
						}
						
						ul {
							display: flex;
							flex-direction: column;
							gap: 10px;
						}
					}
				}
			}
			
			.cost {
				display: flex;
				flex-direction: column;
				align-items: flex-end;
				justify-content: space-between;
				border-radius: 8px;
				
				.duration-of-stay-and-number-of-people {
					color: $grey-400;
					text-align: right;
					font-size: 12px;
					font-weight: 400;
					line-height: 15.6px;
					letter-spacing: 0.36px;
					margin-top: 20px;
				}
				
				.price {
					margin-bottom: 16px;
					
					.summa {
						display: flex;
						align-items: flex-end;
						gap: 10px;
						
						.now {
							display: flex;
							align-items: center;
							gap: 10px;
							
							color: #e85481;
							text-align: center;
							font-size: 28.844px;
							font-weight: 700;
							line-height: 37.497px;
							letter-spacing: 0.865px;
							
							i {
								color: $grey-300;
								font-size: 20px;
							}
						}
						
						.was {
							display: flex;
							gap: 10px;
							color: $grey-400;
							font-size: 16.688px;
							font-weight: 400;
							line-height: 25.033px;
							text-decoration: line-through;
						}
						
						.text {
							display: flex;
							gap: 10px;
							color: $grey-400;
							font-size: 16.688px;
							font-weight: 400;
							line-height: 25.033px;
						}
					}
					
					.price-per-stay {
						color: $grey-400;
						font-size: 12px;
						font-weight: 400;
						line-height: 18px;
						margin-bottom: 12px;
						text-align: left;
					}
				}
				
				.nav {
					display: flex;
					justify-content: space-between;
					align-items: center;
					gap: 25px;
				}
			}
		}
		
		.load-more {
			margin-top: 40px;
			text-align: center;
		}
	}
	
	.bottom {
		padding: 40px 0;
		
		.container-md {
			display: flex;
			flex-direction: column;
			gap: 22px;
			
			.description {
				color: $text-color;
				font-size: 18px;
				font-style: normal;
				font-weight: 700;
				line-height: 155.556%;
			}
			
			.link-green-arrow {
				display: flex;
				width: fit-content;
				gap: 10px;
				align-items: center;
				color: $green-400;
				font-size: 18px;
				font-style: normal;
				font-weight: 400;
				line-height: 155.556%;
				
				&:before {
					content: '';
					width: 9px;
					height: 15px;
					background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="15" viewBox="0 0 9 15" fill="none"><path d="M0.189883 7.91162L7.89771 14.8304C8.15025 15.0569 8.55912 15.0565 8.81123 14.8292C9.06315 14.6019 9.0625 14.2337 8.80993 14.0069L1.56089 7.49997L8.81019 0.993044C9.06273 0.766287 9.06338 0.39829 8.81149 0.170947C8.68511 0.056983 8.51954 0 8.35397 0C8.18882 0 8.0239 0.0566015 7.89774 0.169775L0.189883 7.08835C0.0682516 7.19728 -9.53674e-07 7.34552 -9.53674e-07 7.49997C-9.53674e-07 7.65442 0.0684471 7.80249 0.189883 7.91162Z" fill="%23045E51"/></svg>');
					background-size: contain;
					background-position: center;
					background-repeat: no-repeat;
				}
			}
		}
	}
}