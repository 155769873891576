@import "../../base/_mixins.scss";
@import "../../base/_variables.scss";
@import "../../base/_colors.scss";


.video-page-wrqap {
  & > img {
    object-fit: contain;
    width: 100%;

    @include media-breakpoint-down(sm) {
      object-fit: cover;
      height: 200px;
    }
  }

  .container {
    max-width: 1650px;
    margin: 0 auto;
    padding: 0 20px;
  }

  .head {
    text-align: center;
    margin: 50px 0 80px 0;

    .breadcrumb {
      font-size: 14px;
      font-weight: 400;
      line-height: 19.04px;
      margin-bottom: 38px;

      a {
        color: rgba(81, 83, 88, 1);
      }

      i {

      }

      span {
        color: rgba(40, 93, 81, 1);
      }
    }

    h1 {
      font-size: $size-50;
      font-weight: 700;
      line-height: 68px;
      text-align: center;
      color: rgba(0, 0, 0, 1);
      position: relative;
      padding-bottom: 20px;
      @include media-breakpoint-down(sm) {
        font-size: $size-32;
      }

      &:after {
        content: '';
        width: 32px;
        height: 1px;
        background: rgba(158, 160, 161, 1);
        display: block;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
      }
    }
  }

  .video-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 75px;

    @include media-breakpoint-down(xl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(1, 1fr);
    }

    .video-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 9px;

      &.hidden {
        display: none;
      }

      .img-wrap {
        position: relative;

        svg {
          position: absolute;
          bottom: 10px;
          left: 10px;
          width: 15%;
          max-width: 70px;
          cursor: pointer;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        aspect-ratio: 1.8 / 1;
        border-radius: 8px;
      }

      .img-title {
        font-weight: 700;
        color: $grey-400;
        font-size: $size-24;
        line-height: calc($size-24 * 1.3);
      }

      .img-text {
        font-weight: 400;
        color: $grey-400;
        font-size: $size-20;
        line-height: calc($size-20 * 1.5);
      }
    }
  }

  .show-more-videos {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 60px;

    &.hidden {
      display: none;
    }
  }
}

.video-modal-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  z-index: 16;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #00000091;
  z-index: 99999999;
  pointer-events: none;
  opacity: 0;
  transition: all 200ms linear;

  &.active {
    pointer-events: painted;
    opacity: 1;

    .video-container {
      margin-top: 0;
    }
  }

  .video-container {
    margin-top: 50%;
    transition: all 500ms ease-in-out;
    width: 100%;
    position: relative;
    text-align: left;

    .close-modal{
      opacity: .7;
      cursor: pointer;
      transition: all 200ms linear;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 31;
      transform: translateY(-100%);
      pointer-events: none;

      &:hover{
        opacity: 1;
      }
    }

    .plyr {
      height: 100%;
      border-radius: 8px;
    }

    .player-nav {
      z-index: 7;
      left: 15px;
      bottom: 18px;
      position: absolute;
      width: auto;

      button {
        width: 68px;
        height: 42px;
        background: #000;
        color: #fff;
        font-size: 25px;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
      }
    }

    .player-vol {
      z-index: 7;
      right: 15px;
      bottom: 18px;
      position: absolute;
      width: auto;

      button {
        width: 50px;
        height: 42px;
        background: #000;
        color: #fff;
        font-size: 25px;
        border-radius: 4px;
        border: 1px solid #fff;
        cursor: pointer;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 60%;
      height: 60%;
    }
  }
}
